export const CONTENT_BLOCK_DISMISS_OPTIONS = {
  NOT_DISMISSIBLE_ONCE: 'Not Dismissible - Once',
  NOT_DISMISSIBLE_CONTINUOUS: 'Not Dismissible - Continuous',
  DISMISSIBLE_ONCE: 'Dismissible - Once',
  DISMISSIBLE_CONTINUOUS_UNTIL_CLOSE: 'Dismissible - Continuous',
};

export const CONTENT_BLOCK_KEYS = {
  DEFAULT_WHITE: 'Default White',
  CM_BLUE: 'CM Blue',
  CM_BLUE_LIGHT: 'CM Blue (Light)',
  SUPPORT_BLUE: 'Support Blue',
  SUPPORT_BLUE_LIGHT: 'Support Blue (Light)',
  LIGHT_YELLOW: 'Light Yellow',
  LIGHT_GRAY: 'Light Gray',
};

export const CONTENT_BLOCK_POSITIONS = {
  WINDOW_TOP_STICKY: 'Window Top (Sticky)',
  WINDOW_TOP_SCROLLS: 'Window Top (Scrolls)',
  BELOW_MENU_STICKY: 'Below Menu (Sticky)',
  BELOW_MENU_SCROLLS: 'Below Menu (Scrolls)',
  BELOW_HERO_INTRO: 'Below Hero (Intro)',
  USE_LANDING_SECTIONS: 'Use Landing Sections',
  TOP_OF_BODY_CONTENT: 'Top of Body Content',
  LAST_OF_BODY_CONTENT: 'Last of Body Content',
  ABOVE_FOOTER: 'Above Footer',
  BELOW_FOOTER: 'Below Footer',
  ASSESSMENT_RESULTS: 'Assessment Results (Sort Order)',
};

export const CONTENT_BLOCK_THEME = {
  [CONTENT_BLOCK_KEYS.DEFAULT_WHITE]: {
    backgroundColor: '#FFFFFF',
    color: '#4b4b4b',
    border: '1px solid #e3e3e3',
  },
  [CONTENT_BLOCK_KEYS.CM_BLUE]: {
    backgroundColor: '#01619b',
    color: '#FFFFFF',
    linkColor: '#FFFFFF',
  },
  [CONTENT_BLOCK_KEYS.CM_BLUE_LIGHT]: {
    backgroundColor: '#ECF4F8',
    color: '#01619b',
  },
  [CONTENT_BLOCK_KEYS.SUPPORT_BLUE]: {
    backgroundColor: '#1e62c0',
    color: '#FFFFFF',
    linkColor: '#FFFFFF',
  },
  [CONTENT_BLOCK_KEYS.SUPPORT_BLUE_LIGHT]: {
    backgroundColor: '#D8E5FD',
    color: '#1e62c0',
  },
  [CONTENT_BLOCK_KEYS.LIGHT_YELLOW]: {
    backgroundColor: '#FFE7B8',
    color: '#4b4b4b',
  },
  [CONTENT_BLOCK_KEYS.LIGHT_GRAY]: {
    backgroundColor: '#E3E3E3',
    color: '#4b4b4b',
  },
};

export const CONTENT_BLOCK_TYPES = {
  BASIC_CONTENT: 'Basic Content',
  ALERT: 'Alert',
  ACCORDION: 'Accordion',
};
