import _get from 'lodash/get';
import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import {
  getTakenSeriesProcessing,
  getTakenSeriesSuccess,
  seriesCompleted,
  seriesCompletedResourcesSaved,
  lastViewedResourceSaved,
  resourceProgressSaved,
  seriesCustomQuestionSaved,
} from './actions';

export const initialState = {
  allSeries: [],
  takenSeries: {},
  takenSeriesProcessing: false,
};

const seriesReducer = handleActions(
  {
    [seriesCompleted](
      state,
      {
        payload: { completedResourcesIds, id },
      },
    ) {
      return update(state, {
        takenSeries: {
          $merge: {
            [id]: {
              ...state.takenSeries[id],
              completedResourcesIds,
              completed: true,
            },
          },
        },
      });
    },
    [getTakenSeriesProcessing](state, action) {
      return update(state, {
        takenSeriesProcessing: { $set: action.payload },
      });
    },
    [seriesCompletedResourcesSaved](
      state,
      {
        payload: { completedResourcesIds, id, resourceId, completed },
      },
    ) {
      const currentLastViewed =
        state.takenSeries[id] && state.takenSeries[id].lastViewedResource;
      const lastViewedResource = currentLastViewed && {
        lastViewedResource: {
          ...currentLastViewed,
          completed,
        },
      };

      const currentResourcesProgress =
        state.takenSeries[id] && state.takenSeries[id].resourcesProgress;
      const resourcesProgress = currentResourcesProgress && {
        ...currentResourcesProgress,
        [resourceId]: null,
      };

      return update(state, {
        takenSeries: {
          $merge: {
            [id]: {
              ...state.takenSeries[id],
              completedResourcesIds,
              lastViewedResource,
              resourcesProgress,
              completed: false,
            },
          },
        },
      });
    },
    [lastViewedResourceSaved](
      state,
      {
        payload: { id, resourceId, completed },
      },
    ) {
      return update(state, {
        takenSeries: {
          $merge: {
            [id]: {
              ...state.takenSeries[id],
              lastViewedResource: {
                id: resourceId,
                completed,
              },
            },
          },
        },
      });
    },
    [resourceProgressSaved](
      state,
      {
        payload: { id, resourceId, seconds },
      },
    ) {
      const currentResourcesProgress =
        state.takenSeries[id] && state.takenSeries[id].resourcesProgress;

      return update(state, {
        takenSeries: {
          $merge: {
            [id]: {
              ...state.takenSeries[id],
              resourcesProgress: {
                ...currentResourcesProgress,
                [resourceId]: seconds,
              },
            },
          },
        },
      });
    },
    [getTakenSeriesSuccess](state, action) {
      return update(state, {
        takenSeries: { $set: action.payload.takenSeries },
        takenSeriesProcessing: { $set: false },
        ...(action.payload.allSeries && {
          allSeries: { $set: action.payload.allSeries },
        }),
      });
    },
    [seriesCustomQuestionSaved](state, action) {
      const { id, question } = action.payload;
      const taken = state.takenSeries[id];
      return update(state, {
        takenSeries: {
          $merge: {
            [id]: {
              ...state.takenSeries[id],
              seriesCustomQuestions: {
                ..._get(taken, 'seriesCustomQuestions'),
                [question.name]: question.value,
              },
            },
          },
        },
      });
    },
  },
  initialState,
);

export default seriesReducer;
