import { useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import useSiteCopySelector from 'components/useSiteCopySelector';
import { getLocalData } from 'utils/localDataStore';
import useBrowserBackStack from 'components/Hooks/useBrowserBackStack';
import {
  getCustomOnboardingLocalUtils,
  getCanDisplayModal,
} from '../CustomOnboardingDialog/utils';

const pages = {
  redirect: 'redirectModal',
  reporting: 'reportingModal',
};

const useIsCustomOnboardingEnabled = () => {
  const [customOnboardingSiteCopy] = useSiteCopySelector([
    'custom-onboarding-mapping',
  ]);
  const location = useLocation();
  const brand = getLocalData('brand');
  const { backStack } = useBrowserBackStack();

  const config = _get(customOnboardingSiteCopy, 'pageCopy');
  const includedClients = _get(config, 'includedClients', []);
  const currentConfig = _get(
    config,
    ['clientsConfig', brand],
    _get(config, 'default', {}),
  );

  const reportingConfig = _get(currentConfig, pages.reporting);
  const redirectConfig = _get(currentConfig, pages.redirect);

  const canDisplayReporting =
    !_isEmpty(reportingConfig) &&
    getCanDisplayModal({
      trigger: _get(reportingConfig, 'trigger'),
      pathname: location.pathname,
      backStack,
    });

  const canDisplayRedirect =
    !_isEmpty(redirectConfig) &&
    getCanDisplayModal({
      trigger: _get(redirectConfig, 'trigger'),
      pathname: location.pathname,
      backStack,
    });

  const { get } = getCustomOnboardingLocalUtils();
  const isReportingViewed = get(pages.reporting);
  const isRedirectViewed = get(pages.redirect);

  const shouldDisplayRedirect =
    includedClients.includes(brand) && !isRedirectViewed && canDisplayRedirect;
  const shouldDisplayReporting =
    includedClients.includes(brand) &&
    !isReportingViewed &&
    canDisplayReporting;

  return {
    showRedirect: shouldDisplayRedirect,
    showReporting: shouldDisplayReporting,
  };
};

export default useIsCustomOnboardingEnabled;
