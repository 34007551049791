import React from 'react';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { CONTENT_BLOCK_KEYS, CONTENT_BLOCK_THEME } from '../../utils';
import { AdminButton } from '../AdminButton';

const useStyles = makeStyles({
  root: props => ({
    borderRadius: '10px !important',
    boxShadow: 'none',
    transition: 'box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:before': {
      display: 'none',
    },
    '& + .MuiAccordion-root': {
      marginTop: 20,
    },
    ...CONTENT_BLOCK_THEME[props.backgroundColor],
    ...(props.backgroundColor === CONTENT_BLOCK_KEYS.DEFAULT_WHITE && {
      '&:hover:not(.Mui-expanded)': {
        boxShadow: '0px 2px 15px 0px rgba(0,0,0,0.1)',
      },
    }),
  }),
  summaryRoot: {
    padding: '0px 16px',
    '&, &.Mui-expanded': {
      minHeight: 48,
    },
  },
  summaryContent: {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
    alignItems: 'center',
  },
  detailsRoot: {
    padding: '8px 16px',
  },
  expandIcon: {
    color: '#c1bdbd',
    '&.Mui-expanded': {
      color: '#8899A6',
    },
  },
});

const Accordion = ({ data, id, expanded, onChange, isAdmin, isPreview }) => {
  const { backgroundColor, header, bodyContent } = data;
  const classes = useStyles({ backgroundColor });
  const headerId = id && `${id}-header`;
  const contentId = id && `${id}-content`;

  return (
    <MuiAccordion
      onChange={onChange}
      expanded={expanded}
      className={classes.root}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={contentId}
        id={headerId}
        classes={{
          root: classes.summaryRoot,
          content: classes.summaryContent,
          expandIcon: classes.expandIcon,
        }}
      >
        <Typography variant="h5" component="p">
          {header}
        </Typography>
        {isAdmin && !isPreview && <AdminButton data={data} />}
      </AccordionSummary>
      <AccordionDetails
        aria-labelledby={headerId}
        id={contentId}
        className={classes.detailsRoot}
      >
        <Typography variant="body2" component="p">
          {documentToPlainTextString(bodyContent)}
        </Typography>
      </AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
