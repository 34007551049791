/* eslint-disable no-empty */
import _get from 'lodash/get';
import { getFirebase } from 'react-redux-firebase';
import { isBot } from 'utils/stringUtils';
import { isLocalhost } from 'utils/networkUtils';
import { getStore } from 'configureStore';

// enable only in production mode
const isActive = !isLocalhost && !isBot();

const getAuth = store => {
  const state = store.getState();
  if (!_get(state, 'firebase.auth.isLoaded')) return undefined;
  return _get(state, 'firebase.auth', 'null');
};

const getUserId = async () =>
  new Promise(resolve => {
    const store = getStore();
    let auth = getAuth(store);
    if (auth !== undefined) {
      const { uid } = auth;
      resolve(uid);
    } else {
      const unsubscribe = store.subscribe(() => {
        auth = getAuth(store);
        if (auth !== undefined) {
          unsubscribe();
          const { uid } = auth;
          resolve(uid);
        }
      });
    }
  });

const UserInteractionService = {
  registerTopicInteraction: async ({ resource, topic, type }) => {
    const userId = await getUserId();
    if (userId && isActive) {
      try {
        const firebase = await getFirebase();
        await firebase
          .firestore()
          .collection('user_interaction')
          .add({
            type,
            topic,
            resourceID: resource,
            userId,
            timestamp: Date.now(),
          });
      } catch (e) {}
    }
  },
  batchRegisterTopicInteraction: async ({ topics }) => {
    const userId = await getUserId();
    if (userId && isActive) {
      try {
        const firebase = await getFirebase();
        const firestore = firebase.firestore();
        const batch = firestore.batch();
        const timestamp = Date.now();
        topics
          .filter(
            ({ fields: { reviewStatus, phi } }) =>
              reviewStatus === 'Accepted' && phi === 'Not Sensitive',
          )
          .slice(0, 3)
          .forEach(({ fields: { title }, sys: { id } }) => {
            const ref = firestore.collection('user_interaction').doc();
            batch.set(ref, {
              type: 'related-resource-view',
              topic: title,
              resourceID: id,
              userId,
              timestamp,
            });
          });
        await batch.commit();
      } catch (e) {}
    }
  },
  registerGeneralInteraction: async ({
    resourceAudiences,
    resourceTags,
    resourceType,
    resourceID,
    type,
  }) => {
    const userId = await getUserId();
    if (userId && isActive) {
      try {
        const firebase = await getFirebase();
        await firebase
          .firestore()
          .collection('user_interaction')
          .add({
            type,
            resourceID,
            resourceType,
            resourceAudiences: resourceAudiences || null,
            resourceTags: resourceTags || null,
            userId,
            timestamp: Date.now(),
          });
      } catch (e) {}
    }
  },
};

export default UserInteractionService;
