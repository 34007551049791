/**
 *
 * usePersonalEmail
 *
 */

import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { makeSelectAuth, makeSelectProfile } from 'containers/Auth/selectors';
import {
  hidePersonalEmailModal,
  showPersonalEmailModal,
} from 'containers/Main/actions';
import { makeSelectClientDetails } from 'containers/Main/selectors';

function usePersonalEmail() {
  const dispatch = useDispatch();
  const hasDisplayed = useRef(false);
  const auth = useSelector(makeSelectAuth());
  const profile = useSelector(makeSelectProfile());
  const clientDetails = useSelector(makeSelectClientDetails());

  const isEnabled = _get(clientDetails, 'metadata.requirePersonalEmail', false);

  useEffect(() => {
    if (profile.isLoaded && !profile.isEmpty) {
      const hasPersonalEmail = !_isEmpty(profile.personalEmail);

      if (
        isEnabled &&
        !hasPersonalEmail &&
        Date.now() - auth.createdAt < 5 * 60 * 1000 &&
        !hasDisplayed.current
      ) {
        hasDisplayed.current = true;
        dispatch(showPersonalEmailModal({ type: 'sign-up' }));
      } else {
        dispatch(hidePersonalEmailModal());
      }
    }
  }, [auth, dispatch, isEnabled, profile]);
}

export default usePersonalEmail;
