import { useLocation, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { setLocalData, getLocalData, isPublicMode } from 'utils/localDataStore';

// TODO: move to config when we support multiple brand
export const WITH_PUBLIC_MODE_CLIENTS = ['wellscreenmonterey'];

function useCheckModeParam() {
  const location = useLocation();
  const history = useHistory();
  const brand = getLocalData('brand');
  const isPublicDevice = isPublicMode();

  useEffect(() => {
    // force mode to none when switching from public device to non-supported client
    if (isPublicDevice && !WITH_PUBLIC_MODE_CLIENTS.includes(brand)) {
      setLocalData('mode', 'none', {
        expires: 365,
      });
    }
  }, [brand, isPublicDevice]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get('mode');
    const allowedModes = ['public', 'none'];

    if (allowedModes.includes(mode)) {
      setLocalData('mode', mode, {
        expires: 365,
      });
      localStorage.setItem('mode', mode);
      queryParams.delete('mode');

      // Change the URL without adding to the history stack
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [location, history]);
}

export default useCheckModeParam;
