import axios from 'axios';
import Config from 'utils/getEnvConfig';
import { getLocalData } from 'utils/localDataStore';
import { getSubdomain } from 'utils/stringUtils';
import { getFirebase } from 'react-redux-firebase';

const {
  LAMBDA: {
    COURSES_MAILER: { URL, API_KEY },
  },
} = Config;

export function sendWelcomeEmail({ email, courseId }) {
  const brand = getSubdomain() || getLocalData('brand') || 'none';
  const payload = {
    type: 'welcome-email',
    email,
    courseId,
    brand,
  };
  return axios.post(URL, payload, {
    headers: {
      'x-api-key': API_KEY,
    },
  });
}

export function moduleCompleteEmail({ email, courseId, moduleId }) {
  const brand = getSubdomain() || getLocalData('brand') || 'none';
  const payload = {
    type: 'module-complete',
    email,
    courseId,
    moduleId,
    brand,
  };
  return axios.post(URL, payload, {
    headers: {
      'x-api-key': API_KEY,
    },
  });
}

export function courseCompleteEmail({ email, userId, courseId }) {
  const brand = getSubdomain() || getLocalData('brand') || 'none';
  const payload = {
    type: 'course-complete',
    email,
    courseId,
    userId,
    brand,
  };
  return axios.post(URL, payload, {
    headers: {
      'x-api-key': API_KEY,
    },
  });
}

export async function setLastViewedOn({ courseId, userId, locale }) {
  const firebase = await getFirebase();
  const brand = getSubdomain() || getLocalData('brand') || 'none';
  if (firebase.auth().currentUser) {
    const firestore = firebase.firestore();

    const ref = firestore
      .collection('courses_last_viewed')
      .doc(courseId)
      .collection('users')
      .doc(userId);

    ref.get().then(snapshot => {
      if (snapshot.exists) {
        ref.set({ timestamp: Date.now(), brand, locale }, { merge: true });
      } else {
        ref.set({
          timestamp: Date.now(),
          brand,
          locale,
          completed: false,
          weekSentAt: null,
          twoWeekSentAt: null,
        });
      }
    });
  }
}

export async function setCompleted({ courseId, userId }) {
  const firebase = await getFirebase();
  if (firebase.auth().currentUser) {
    const firestore = firebase.firestore();
    firestore
      .collection('courses_last_viewed')
      .doc(courseId)
      .collection('users')
      .doc(userId)
      .set({ completed: true }, { merge: true });
  }
}
