import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setFreshworksLoaded } from 'containers/Main/actions';
import freshworksUtils from 'utils/freshworksUtils';
import { isBot } from 'utils/stringUtils';
import { isLocalhost } from 'utils/networkUtils';

const MAX_ITERATIONS = 40;
const MAX_DISABLED_COUNT = 5;

const useFreshworksWidget = () => {
  const dispatch = useDispatch();
  const isGTMEnabled = !isBot() && !isLocalhost;

  useEffect(() => {
    if (isGTMEnabled) {
      let count = 0;
      let disabledCount = 0;
      const interval = setInterval(() => {
        // console.log(1, { count });
        if (freshworksUtils.isLoaded()) {
          // console.log(2, { count, disabledCount });
          freshworksUtils.hideLauncher();
          dispatch(setFreshworksLoaded(true));

          if (disabledCount > MAX_DISABLED_COUNT) clearInterval(interval);
          else disabledCount += 1;
        }
        if (count > MAX_ITERATIONS) {
          clearInterval(interval);
        }
        count += 1;
      }, 500);
      return () => clearInterval(interval);
    }
    return () => null;
  }, [dispatch, isGTMEnabled]);
};

export default useFreshworksWidget;
