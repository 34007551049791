import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _classNames from 'classnames';
import _get from 'lodash/get';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 14,
    lineHeight: '22px',
    marginRight: 10,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  mobileLabel: {
    fontSize: 12,
    lineHeight: '22px',
  },
  pointBtn: {
    height: '41.6px',
    width: '41.6px',
    borderRadius: '50%',
    border: '1px solid #D8D8D8',
    fontFamily: 'PoppinsSemiBold',
    color: '#0061A0',
    fontSize: 12.8,
    textAlign: 'center',
    marginRight: 10,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  selected: {
    borderColor: '#01619B',
    backgroundColor: '#01619B',
    color: '#FFFFFF',
  },
  mobileLabelWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 4,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

function ScalePoint({ answers, onClick, labels }) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const [position, setPosition] = useState(1);

  const showLeftOptions = () => setPosition(0);
  const showRightOptions = () => setPosition(1);

  const ratings = () => {
    if (isXs) {
      if (position === 0) {
        return (
          <>
            {Array.from(Array(6).keys()).map(num => (
              <ButtonBase
                className={_classNames({
                  [classes.pointBtn]: true,
                  [classes.selected]: answers.npsScale === num,
                })}
                onClick={() => onClick('npsScale')(num)}
                disableRipple
              >
                {num}
              </ButtonBase>
            ))}
            <ButtonBase
              className={classes.pointBtn}
              onClick={showRightOptions}
              disableRipple
            >
              <ChevronRightIcon />
            </ButtonBase>
          </>
        );
      }
      if (position === 1) {
        return (
          <>
            <ButtonBase
              className={classes.pointBtn}
              onClick={showLeftOptions}
              disableRipple
            >
              <ChevronLeftIcon />
            </ButtonBase>
            {Array.from(Array(6).keys()).map(num => (
              <ButtonBase
                className={_classNames({
                  [classes.pointBtn]: true,
                  [classes.selected]: answers.npsScale === num + 5,
                })}
                onClick={() => onClick('npsScale')(num + 5)}
                disableRipple
              >
                {num + 5}
              </ButtonBase>
            ))}
          </>
        );
      }
      return null;
    }

    return Array.from(Array(11).keys()).map(num => (
      <ButtonBase
        className={_classNames({
          [classes.pointBtn]: true,
          [classes.selected]: answers.npsScale === num,
        })}
        onClick={() => onClick('npsScale')(num)}
        disableRipple
      >
        {num}
      </ButtonBase>
    ));
  };

  return (
    <>
      <Box
        component="div"
        display="flex"
        justifyContent={isXs ? 'space-between' : 'center'}
        alignItems="center"
        marginTop="10px"
      >
        <Typography className={classes.label}>
          {_get(labels, 'notLikelyLabel')}
        </Typography>
        {ratings()}
        <Typography className={classes.label}>
          {_get(labels, 'likelyLabel')}
        </Typography>
      </Box>
      <div className={classes.mobileLabelWrapper}>
        <Typography className={classes.mobileLabel}>
          {_get(labels, 'notLikelyLabel')}
        </Typography>
        <Typography className={classes.mobileLabel}>
          {_get(labels, 'likelyLabel')}
        </Typography>
      </div>
    </>
  );
}

ScalePoint.propTypes = {
  answers: PropTypes.object,
  labels: PropTypes.object,
  onClick: PropTypes.func,
};

export default ScalePoint;
