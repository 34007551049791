import ReactGA from 'react-ga4';
import _get from 'lodash/get';
/* eslint-disable */
const ga4Actions = {
  track: (name, props) => {
    try {
      // set non-interaction for all viewed and session related events
      let isNonInteraction = false;
      if (/Viewed/i.test(name) || /session/i.test(name)) {
        isNonInteraction = true;
      }
      const category = name.includes('All Resources Page')
        ? 'All Resources Page'
        : _get(props, 'type', 'Page Load|View');
      const action = name.includes('All Resources Page')
        ? `${props.type} - ${props.action}`
        : name;
      ReactGA.event({
        category,
        action: action || 'Loaded|Viewed',
        nonInteraction: isNonInteraction,
        label: name,
        ...props,
      });
    } catch (error) {}
  },
};

export default ga4Actions;
