import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import _startCase from 'lodash/startCase';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useSiteConfigSelector from 'components/useSiteConfigSelector';
import Mixpanel from 'utils/mixpanelService';
import { replacePlaceholders } from 'utils/stringUtils';
import NavBarContext from '../../navBarContext';
import CoursesSeriesItem from './AccountSidebarItem';

const useStyles = makeStyles({
  root: {
    paddingTop: 25,
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    color: props => props.theme.headerColor,
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '1.5',
  },
  counter: {
    color: '#000000',
    fontFamily: 'Mada',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '1',
    height: 18,
    width: 18,
    borderRadius: 9,
    backgroundColor: '#EE9E00',
    marginLeft: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
  },
  callToAction: {
    fontFamily: 'Mada',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '15.6px',
    color: props => props.theme.headerColor,
    textAlign: 'center',
  },
  viewAllWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
  },
  viewAll: {
    textDecoration: 'underline',
    fontFamily: 'Mada',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '15.6px',
    color: props => props.theme.linkColor,
  },
});

const CoursesSeriesSection = ({
  onHide,
  theme,
  inProgressSeries,
  inProgressCourses,
  inProgressResourcesCount,
}) => {
  const classes = useStyles({ theme });
  const { siteCopy } = useContext(NavBarContext);
  const [siteConfig] = useSiteConfigSelector(['Profile Sidebar']);

  const handleViewAll = () => {
    onHide();
    Mixpanel.track('Profile - View All - Clicked', {
      section: 'in progress',
    });
  };

  const hasCoursesSeriesInProgress = inProgressResourcesCount > 0;

  const data = inProgressSeries.concat(inProgressCourses);

  return (
    <Box className={classes.root}>
      <Box className={classes.headerWrapper}>
        <Typography className={classes.header}>
          {_get(siteCopy, ['pageCopy', 'profile-sidebar', 'inProgressHeader'])}
        </Typography>
        {hasCoursesSeriesInProgress && (
          <Typography className={classes.counter}>
            {inProgressResourcesCount}
          </Typography>
        )}
      </Box>
      <Box className={classes.itemsWrapper}>
        {hasCoursesSeriesInProgress ? (
          data
            .slice(0, _get(siteConfig, 'config.seriesCourses.displayCount', 3))
            .map(item => {
              const { type } = item;
              const { title, slug } = item.fields;
              const { missingResourcesCount } = item.userData;
              const subtitle = replacePlaceholders(
                _get(siteCopy, [
                  'pageCopy',
                  'profile-sidebar',
                  missingResourcesCount > 1
                    ? type === 'courses'
                      ? 'inProgressCoursesSubtitleMultiple'
                      : 'inProgressSeriesSubtitleMultiple'
                    : type === 'courses'
                    ? 'inProgressCoursesSubtitle'
                    : 'inProgressSeriesSubtitle',
                ]),
                {
                  '#type': _startCase(type),
                  '#count': missingResourcesCount,
                },
              );
              const resourceType = type === 'courses' ? 'Courses' : 'Series';
              const url = `/${type}/${slug}`;

              return (
                <CoursesSeriesItem
                  theme={theme}
                  type={type}
                  slug={slug}
                  iconColor="#01619B"
                  title={title}
                  subtitle={subtitle}
                  resourceType={resourceType}
                  url={url}
                  key={slug}
                  onClick={() => {
                    onHide();
                    Mixpanel.track('Resource Card - Clicked', {
                      type: resourceType,
                      section: 'in progress',
                      slug,
                      name: title,
                    });
                  }}
                />
              );
            })
        ) : (
          <>
            <Typography className={classes.callToAction}>
              {_get(siteCopy, ['pageCopy', 'profile-sidebar', 'inProgressCTA'])}
            </Typography>
            <CoursesSeriesItem
              type="series"
              theme={theme}
              title={_get(siteCopy, [
                'pageCopy',
                'profile-sidebar',
                'inProgressCTACardTitle',
              ])}
              subtitle={_get(siteCopy, [
                'pageCopy',
                'profile-sidebar',
                'inProgressCTACardSubtitle',
              ])}
              url="/learning-lab"
              onClick={onHide}
            />
          </>
        )}
      </Box>
      <Box className={classes.viewAllWrapper}>
        <Link to="/learning-lab" onClick={handleViewAll} disableRipple>
          <Typography className={classes.viewAll}>
            {_get(siteCopy, ['pageCopy', 'profile-sidebar', 'viewAllLabel'])}
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};

CoursesSeriesSection.propTypes = {
  onHide: PropTypes.func,
  theme: PropTypes.object,
  inProgressSeries: PropTypes.array,
  inProgressCourses: PropTypes.array,
  inProgressResourcesCount: PropTypes.number,
};

export default CoursesSeriesSection;
