import { useLocation, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { setLocalData } from 'utils/localDataStore';
import { isEmbedded } from '../../utils/embedded';

function useCheckViewParam() {
  const location = useLocation();
  const history = useHistory();
  const isEmbed = isEmbedded();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const view = queryParams.get('view');
    const allowedViews = ['carousel', 'none', 'list'];

    if (allowedViews.includes(view) && isEmbed) {
      setLocalData('list-view', view, {
        expires: 365,
      });
      localStorage.setItem('list-view', view);
    }
  }, [location, history, isEmbed]);
}

export default useCheckViewParam;
