/*
 *
 * HomePage actions
 *
 */
import { createAction } from 'redux-actions';
import {
  PROCESSING,
  GET_HOME_ASSESSMENTS,
  GET_HOME_ASSESSMENTS_SUCCESS,
  GET_HOME_NEWS,
  GET_HOME_NEWS_SUCCESS,
  GET_HOME_BLOGS,
  GET_HOME_BLOGS_SUCCESS,
  SET_GUIDE_ME_OPENED,
} from './constants';

export const processing = createAction(PROCESSING);
export const getHomeAssessments = createAction(GET_HOME_ASSESSMENTS);
export const getHomeAssessmentsSuccess = createAction(
  GET_HOME_ASSESSMENTS_SUCCESS,
);
export const getHomeNews = createAction(GET_HOME_NEWS);
export const getHomeNewsSuccess = createAction(GET_HOME_NEWS_SUCCESS);
export const getHomeBlogs = createAction(GET_HOME_BLOGS);
export const getHomeBlogsSuccess = createAction(GET_HOME_BLOGS_SUCCESS);
export const setGuideMeOpened = createAction(SET_GUIDE_ME_OPENED);
