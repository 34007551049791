export const DEFAULT_SURVEY = [
  {
    header: 'Was your visit today helpful to you?',
    name: 'helpful',
    type: 'radio',
    options: [
      {
        label: 'Yes! It was helpful',
        value: 1,
        icon: 'thumbs-up',
      },
      {
        label: 'No! It was unhelpful',
        value: 0,
        icon: 'thumbs-down',
      },
    ],
  },
  {
    header: 'How did this site help you today?',
    name: 'how',
    type: 'checkbox',
    options: [
      {
        label: 'I learned something',
        value: 0,
      },
      {
        label: 'I gained a skill',
        value: 1,
      },
      {
        label: 'I felt less alone',
        value: 2,
      },
      {
        label: 'I felt better',
        value: 3,
      },
      {
        label: 'Other',
        value: 4,
      },
    ],
    visibleIf: '[helpful] = 1',
  },
  {
    header: 'Let us know how we can do better.',
    name: 'improve',
    type: 'text',
    options: {
      multiline: true,
      numRows: 4,
      placeholder: 'Tell us more about your experience/feedback. (Optional)',
    },
  },
  {
    header: 'Thank you for taking the time to share your feedback with us!',
    name: 'thanks',
    type: 'message',
  },
];

export const DEFAULT_CONFIG = {
  daysForRetake: 30,
  shareCount: 2,
  favoriteCount: 2,
  activeMinutes: 5,
  playCountSession: 3,
  playCountTotal: 6,
  assessmentCount: 3,
  preConditionMinutes: 3,
  preConditionNumNavigation: 5,
};
