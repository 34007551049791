import React from 'react';

export default (color = '#FFFFFF') => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 17 17"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="news" transform="translate(0.3899994 0.39001465)">
      <path
        d="M6.09951 6.33333C6.09951 6.51743 5.94779 6.66667 5.76065 6.66667L2.37203 6.66667C2.18488 6.66667 2.03317 6.51743 2.03317 6.33333L2.03317 3C2.03317 2.81591 2.18488 2.66667 2.37203 2.66667L5.76065 2.66667C5.94779 2.66667 6.09951 2.81591 6.09951 3L6.09951 6.33333Z"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.45495 2.66667L9.48812 2.66667"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.45495 4.66667L9.48812 4.66667"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.45495 6.66667L9.48812 6.66667"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.03317 8.66667L9.48812 8.66667"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.03317 10.6667L9.48812 10.6667"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.03317 12.6667L9.48812 12.6667"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5545 15.3333L2.03317 15.3333C0.910281 15.3333 1.50485e-16 14.4379 0 13.3333L0 0.666667C-7.52424e-17 0.298477 0.303427 7.40149e-17 0.677723 0L10.8436 0C11.2179 0 11.5213 0.298477 11.5213 0.666667L11.5213 13.3333C11.5213 14.4379 12.4316 15.3333 13.5545 15.3333C14.6773 15.3333 15.5876 14.4379 15.5876 13.3333L15.5876 2"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5545 2L13.5545 13.6667"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
