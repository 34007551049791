import React from 'react';

const WelcomeIcon = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#01619B" />
    <path
      d="M11.5898 27.4971C10.4853 27.4971 9.58984 26.6017 9.58984 25.4971V14.5C9.58984 13.3954 10.4853 12.5 11.5898 12.5H22.8003C23.9048 12.5 24.8003 13.3954 24.8003 14.5V25.4971C24.8003 26.6017 23.9048 27.4971 22.8003 27.4971H11.5898Z"
      stroke="white"
    />
    <path
      d="M25.3222 22.4997C25.0018 22.3242 24.8027 21.988 24.8027 21.6227V18.376C24.8027 18.0107 25.0018 17.6744 25.3222 17.4989L28.9297 15.5228C29.5961 15.1578 30.4101 15.64 30.4101 16.3999V23.5988C30.4101 24.3586 29.5961 24.8409 28.9297 24.4758L25.3222 22.4997Z"
      stroke="white"
    />
    <path
      d="M14.0923 17.1211C13.926 17.1211 13.7598 17.2874 13.7598 17.4537C13.7598 17.6199 13.926 17.7862 14.0923 17.7862"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M14.0918 17.1211C14.2581 17.1211 14.4243 17.2874 14.4243 17.4537C14.4243 17.6199 14.2581 17.7862 14.0918 17.7862"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M20.3014 17.1211C20.1351 17.1211 19.9688 17.2874 19.9688 17.4537C19.9688 17.6199 20.1351 17.7862 20.3014 17.7862"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M20.3027 17.1211C20.469 17.1211 20.6352 17.2874 20.6352 17.4537C20.6352 17.6199 20.469 17.7862 20.3027 17.7862"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M20.4972 20.9922C19.9467 22.2697 18.6762 23.164 17.1968 23.164C15.7174 23.164 14.447 22.2697 13.8965 20.9922"
      stroke="white"
      strokeMiterlimit="10"
    />
  </svg>
);

export default WelcomeIcon;
