import React, { useState } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
  root: {
    borderRadius: 8,
  },
  title: {
    margin: 0,
  },
  description: {
    margin: 0,
    color: '#666666',
  },
  input: {
    width: '100%',
    height: 40,
    borderRadius: 0,
    border: '2px solid #e9ebeb',
    fontSize: 16,
    fontWeight: 500,
    padding: 5,
  },
  button: {
    width: '100%',
    background: '#E09900 ',
    color: 'white',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'PoppinsSemiBold',
    margin: '24px 0',
    height: 40,
    boxShadow: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default function SecurityModal({ setAuthenticated, password }) {
  const classes = useStyles();
  const [inputPassword, setInputPassword] = useState();
  const [error, setError] = useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setError(false);
    // TODO; apply crypto hash
    if (inputPassword !== password) {
      setError(true);
    } else {
      setAuthenticated(true);
    }
  };
  return (
    <div>
      <Dialog
        open
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.root}
      >
        <DialogTitle id="alert-dialog-title">
          <h1 className={classes.title}>Password protected site</h1>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p className={classes.description}>
              Please enter your password to get access.
            </p>
            {error && (
              <Alert severity="error">
                Something went wrong, please try again.
              </Alert>
            )}
          </DialogContentText>
          <form onSubmit={handleSubmit} name="password-form">
            <input
              type="password"
              placeholder="Password"
              className={classes.input}
              value={inputPassword}
              onChange={e => setInputPassword(e.target.value)}
            />
            <ButtonBase
              color="primary"
              className={classes.button}
              type="submit"
            >
              Submit
            </ButtonBase>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
