import { createSelector } from 'reselect';

/**
 * Direct selector to the assessments state domain
 */

const selectAssessmentsDomain = state => state.assessments;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Assessments
 */

const makeSelectAssessments = () =>
  createSelector(
    selectAssessmentsDomain,
    substate => substate.data,
  );
const makeSelectTakenAssessments = () =>
  createSelector(
    selectAssessmentsDomain,
    substate => substate.takenAssessments,
  );
const makeSelectSavedAssessments = () =>
  createSelector(
    selectAssessmentsDomain,
    substate => substate.savedAssessments,
  );
const makeSelectRecommendations = () =>
  createSelector(
    selectAssessmentsDomain,
    substate => substate.recommendations,
  );
const makeSelectProcessing = () =>
  createSelector(
    selectAssessmentsDomain,
    substate => substate.processing,
  );
const makeSelectSectionProcessing = () =>
  createSelector(
    selectAssessmentsDomain,
    substate => substate.sectionProcessing,
  );
const makeSelectMenuAssessments = () =>
  createSelector(
    selectAssessmentsDomain,
    substate => substate.menuAssessments,
  );
const makeSelectSaveAssessmentResult = () =>
  createSelector(
    selectAssessmentsDomain,
    substate => substate.saveResult,
  );
const makeSelectAllAssessmentsResults = () =>
  createSelector(
    selectAssessmentsDomain,
    substate => substate.allResults,
  );
export {
  makeSelectAssessments,
  makeSelectTakenAssessments,
  makeSelectSavedAssessments,
  makeSelectRecommendations,
  makeSelectProcessing,
  makeSelectSectionProcessing,
  makeSelectMenuAssessments,
  makeSelectSaveAssessmentResult,
  makeSelectAllAssessmentsResults,
};
