const darkGray1 = '#3E4543';
const lightGray1 = '#a4a4a4';

// these are all temporary for lo-fi ui
export default {
  primary: darkGray1,
  gray: '#D1D3D3',
  dark_gray_1: darkGray1,
  green: '#7BC84B',
  blue: '#01619B',
  lighterBlue: '#9BD1E0',
  link: '#01619B',
  textColor: darkGray1,
  lightTextColor: lightGray1,
  cardBg: '#DFE6F4',
};
