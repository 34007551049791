import { gql } from '@apollo/client';

export default gql`
  query SiteConfig($locale: String!) {
    siteConfigCollection(limit: 60, locale: $locale) {
      items {
        title
        config
      }
    }
  }
`;
