export const SAVE_SERIES_COMPLETED_RESOURCES =
  'app/Series/SAVE_SERIES_COMPLETED_RESOURCES';
export const SERIES_COMPLETED_RESOURCES_SAVED =
  'app/Series/SERIES_COMPLETED_RESOURCES_SAVED';
export const SAVE_LAST_VIEWED_RESOURCE = 'app/Series/SAVE_LAST_VIEWED_RESOURCE';
export const LAST_VIEWED_RESOURCE_SAVED =
  'app/Series/LAST_VIEWED_RESOURCE_SAVED';
export const GET_TAKEN_SERIES = 'app/Series/GET_TAKEN_SERIES';
export const GET_TAKEN_SERIES_PROCESSING =
  'app/Series/GET_TAKEN_SERIES_PROCESSING';
export const GET_TAKEN_SERIES_SUCCESS = 'app/Series/GET_TAKEN_SERIES_SUCCESS';
export const MARK_SERIES_COMPLETED = 'app/Series/MARK_SERIES_COMPLETED';
export const MOVE_ANONYMOUS_SERIES = 'app/Series/MOVE_ANONYMOUS_SERIES';
export const SERIES_COMPLETED = 'app/Series/SERIES_COMPLETED';
export const SAVE_RESOURCE_PROGRESS = 'app/Series/SAVE_RESOURCE_PROGRESS';
export const RESOURCE_PROGRESS_SAVED = 'app/Series/RESOURCE_PROGRESS_SAVED';
export const SERIES_SAVE_CUSTOM_QUESTION =
  'app/Series/SERIES_SAVE_CUSTOM_QUESTION';
export const SERIES_CUSTOM_QUESTION_SAVED =
  'app/Series/SERIES_CUSTOM_QUESTION_SAVED';

export const SERIES_SIDEBAR_TABS = {
  INTRODUCTION: 'INTRODUCTION',
  RESOURCE: 'RESOURCE',
  RESULTS: 'RESULTS',
};
