import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useBrowserBackStack = () => {
  const history = useHistory();
  const [backStack, setBackStack] = useState([]);
  useEffect(() => {
    history.listen((location, action) => {
      setBackStack(backStackToUpdate => {
        switch (action) {
          case 'REPLACE':
            return [
              ...backStackToUpdate.slice(0, backStackToUpdate.length - 1),
              location,
            ];
          default:
            return [...backStackToUpdate, location];
        }
      });
    });
  }, [setBackStack, history]);
  useEffect(() => {
    const previousRoute = backStack?.[backStack?.length - 2];
    if (previousRoute) {
      const { pathname } = previousRoute;
      sessionStorage.setItem('previousRoute', pathname);
      sessionStorage.setItem('restoreAssessmentResultState', true);
    } else {
      sessionStorage.removeItem('restoreAssessmentResultState');
    }
  }, [backStack]);
  return { backStack, previousPath: backStack?.[backStack?.length - 2] };
};

export default useBrowserBackStack;
