import _get from 'lodash/get';
import ctf from 'utils/contentfulService';

const queryFn = async (_key, clientId, clientGroupId, localeFilters) => {
  const clientIds = [clientId];
  if (clientGroupId) clientIds.push(clientGroupId);

  const sourceResponse = await ctf.getEntries({
    content_type: 'source',
    'fields.client.sys.id[in]': clientIds.join(','),
    'fields.reviewStatus': 'Accepted',
    include: 2,
    ...localeFilters,
  });
  // const activityResponse = await ctf.getEntries({
  //   content_type: 'activity',
  //   'fields.client.sys.id': clientId,
  //   'fields.reviewStatus': 'Accepted',
  //   include: 2,
  //   ...localeFilters,
  // });
  const servicesResponse = await ctf.getEntries({
    content_type: 'services',
    'fields.client.sys.id[in]': clientIds.join(','),
    'fields.reviewStatus': 'Accepted',
    include: 2,
    limit: 150, // TODO: too heavy, use infinite scroll
    ...localeFilters,
  });
  return (
    _get(sourceResponse, 'items', [])
      // .concat(_get(activityResponse, 'items', []))
      .concat(_get(servicesResponse, 'items', []))
  );
};

export default queryFn;
