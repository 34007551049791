import React from 'react';

const LearnIcon = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#01619B" />
    <g>
      <path d="M21.4512 31.0195H18.5762" stroke="white" />
      <path d="M22.4089 29.1055H17.6172" stroke="white" />
      <path d="M20.0137 8.98047V11.8555" stroke="white" />
      <path d="M11.8809 12.3477L13.9144 14.3803" stroke="white" />
      <path d="M28.144 12.3477L26.1113 14.3803" stroke="white" />
      <path d="M8.99219 20.4805H11.8672" stroke="white" />
      <path d="M31.0352 20.4805H28.1602" stroke="white" />
      <path
        d="M25.7637 20.479C25.7648 19.49 25.5108 18.5175 25.0262 17.6554C24.5417 16.7933 23.8429 16.0707 22.9976 15.5575C22.1522 15.0443 21.1887 14.7578 20.2003 14.7257C19.2119 14.6936 18.2319 14.917 17.355 15.3742C16.4781 15.8315 15.734 16.5072 15.1945 17.3361C14.655 18.165 14.3385 19.119 14.2754 20.1059C14.2123 21.0928 14.4048 22.0794 14.8344 22.9702C15.264 23.861 15.916 24.6259 16.7275 25.1911C16.8544 25.2794 16.9581 25.397 17.0297 25.534C17.1013 25.671 17.1387 25.8233 17.1387 25.9779V26.7082C17.1387 26.8352 17.1892 26.9571 17.279 27.047C17.3689 27.1368 17.4908 27.1873 17.6178 27.1873H22.4095C22.5366 27.1873 22.6585 27.1368 22.7483 27.047C22.8382 26.9571 22.8887 26.8352 22.8887 26.7082V25.9769C22.8887 25.8224 22.926 25.6701 22.9976 25.5331C23.0692 25.3961 23.1729 25.2784 23.2998 25.1902C24.0605 24.6623 24.682 23.958 25.1111 23.1376C25.5402 22.3171 25.7641 21.4049 25.7637 20.479Z"
        stroke="white"
      />
    </g>
  </svg>
);

export default LearnIcon;
