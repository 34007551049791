import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _classNames from 'classnames';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const autoCompleteStyles = makeStyles(theme => ({
  root: {
    width: props => props.width || '300px',
    [theme.breakpoints.down('xs')]: {
      width: '260px !important',
    },
  },
  popupIndicator: {
    transform: 'none',
    paddingTop: '0px',
    paddingBottom: '0px',
    '& .MuiIconButton-label': {
      height: '39px',
      marginTop: '-5px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '40px',
    },
  },
  inputRoot: {
    paddingLeft: theme.spacing(1.5),
    height: '40px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '8px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0.5),
    },
  },
}));

const useStyles = makeStyles(theme => ({
  toggleWrapper: {
    width: '60px',
    borderTopRightRadius: '6px',
    borderBottomRightRadius: '6px',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'normal',
    cursor: 'pointer',
  },
  icon: {
    height: 35,
    width: 35,
    color: theme.palette.common.white,
  },
  input: {
    fontSize: theme.typography.caption.fontSize,
  },
}));

const ToggleIcon = ({ isOpen, onToggle }) => {
  const classes = useStyles();

  const Icon = isOpen ? ArrowUp : ArrowDown;

  const handleChange = () => {
    onToggle();
  };

  return (
    <Box className={classes.toggleWrapper} onClick={handleChange}>
      <Icon className={classes.icon} />
    </Box>
  );
};

const CustomAutocomplete = ({
  options = [],
  onChange,
  value = '',
  placeholder,
  width,
  dataKey,
  noOptionsText,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const autoCompleteClasses = autoCompleteStyles({
    width,
  });
  const classes = useStyles();

  const handleChange = (_event, newValue) => {
    onChange(newValue);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const getOptionSelected = (option, selectedOption) =>
    option.value === selectedOption.value;

  return (
    <Box display="inline-flex" alignItems="center">
      <Autocomplete
        id={`autocomplete-${dataKey}`}
        options={options}
        value={value}
        openOnFocus
        classes={autoCompleteClasses}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        noOptionsText={noOptionsText}
        getOptionLabel={option =>
          typeof option === 'string' ? option : option.label
        }
        getOptionSelected={getOptionSelected}
        disableClearable
        popupIcon={<ToggleIcon isOpen={isOpen} onToggle={toggleOpen} />}
        renderInput={params => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              className: _classNames(
                params.InputProps.className,
                classes.input,
              ),
            }}
            placeholder={placeholder}
          />
        )}
      />
    </Box>
  );
};

CustomAutocomplete.propTypes = {
  dataKey: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  width: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ]),
  placeholder: PropTypes.string,
  noOptionsText: PropTypes.string,
};

export default CustomAutocomplete;
