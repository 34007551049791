/**
 *
 * NPS/Progress
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  wrapper: {
    height: 4,
    width: 80,
    position: 'relative',
  },
  bar: {
    height: 4,
    backgroundColor: '#ECF3F5',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 2,
  },
  innerBar: {
    height: 4,
    backgroundColor: '#01619B',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    borderRadius: 2,
  },
});

function PageIndicator({ progress }) {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.bar} />
      <div
        className={classes.innerBar}
        style={{
          width: progress * 80,
        }}
      />
    </div>
  );
}

PageIndicator.propTypes = {
  progress: PropTypes.number,
};

export default PageIndicator;
