/**
 *
 * UnavailableState
 *
 */

import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import AppContainer from 'components/AppContainer';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Mixpanel from 'utils/mixpanelService';

const useStyles = makeStyles(theme => ({
  mainText: {
    fontSize: 120,
    [theme.breakpoints.down('sm')]: {
      fontSize: 80,
    },
  },
  message: {
    color: 'rgba(0,0,0,.3)',
    fontSize: 55,
    paddingTop: 120,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 36,
      paddingTop: 40,
    },
  },
}));

function UnavailableState() {
  const classes = useStyles();

  useEffect(() => {
    const interval = setTimeout(() => {
      Mixpanel.track('Unavailable - Viewed', {
        path: window.location.pathname,
      });
    }, 100);
    return () => clearTimeout(interval);
  }, []);
  return (
    <>
      <AppContainer justify="center" alignItems="center">
        <Grid item xs={12} className="page-not-found">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={5}>
              <Typography
                variant="h2"
                className={classes.mainText}
                color="textPrimary"
                align="center"
              >
                Sorry!
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h3" className={classes.message}>
                This site is not publicly available.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </AppContainer>
    </>
  );
}

export default React.memo(UnavailableState);
