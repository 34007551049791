/*
 *
 * Auth constants
 *
 */

export const DEFAULT_ACTION = 'app/Auth/DEFAULT_ACTION';
export const PROCESSING = 'app/Auth/PROCESSING';
export const INCREMENT_SIGN_UP_COUNT = 'app/Auth/INCREMENT_SIGN_UP_COUNT';
export const SIGN_UP = 'app/Auth/SIGN_UP';
export const SIGN_UP_SUCCESS = 'app/Auth/SIGN_UP_SUCCESS';
export const SIGN_UP_FAIL = 'app/Auth/SIGN_UP_FAIL';
export const CHECK_EMAIL_AVAILABILITY = 'app/Auth/CHECK_EMAIL_AVAILABILITY';
export const CHECK_EMAIL_AVAILABILITY_RESULT =
  'app/Auth/CHECK_EMAIL_AVAILABILITY_RESULT';
export const COMPLETE_SIGN_UP = 'app/Auth/COMPLETE_SIGN_UP';
export const COMPLETE_SIGN_UP_SUCCESS = 'app/Auth/COMPLETE_SIGN_UP_SUCCESS';
export const COMPLETE_SIGN_UP_FAIL = 'app/Auth/COMPLETE_SIGN_UP_FAIL';
export const LOGIN = 'app/Auth/LOGIN';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'app/Auth/LOGIN_FAIL';
export const LOGOUT = 'app/Auth/LOGOUT';
export const AUTH_ERROR = 'app/Auth/AUTH_ERROR';
export const STORE_ITEM_FOR_SIGN_IN = 'app/Auth/STORE_ITEM_FOR_SIGN_IN';
export const SHOW_SUBSCRIPTION_MODAL = 'app/Auth/SHOW_SUBSCRIPTION_MODAL';
export const HIDE_SUBSCRIPTION_MODAL = 'app/Auth/HIDE_SUBSCRIPTION_MODAL';
export const SUBSCRIPTION_PROCESSING = 'app/Auth/SUBSCRIPTION_PROCESSING';
export const SUBMIT_SUBSCRIPTION = 'app/Auth/SUBMIT_SUBSCRIPTION';
export const SUBMIT_SUBSCRIPTION_RESULT = 'app/Auth/SUBMIT_SUBSCRIPTION_RESULT';
export const GET_SUBSCRIPTION_STATUS = 'app/Auth/GET_SUBSCRIPTION_STATUS';
export const GET_SUBSCRIPTION_STATUS_RESULT =
  'app/Auth/GET_SUBSCRIPTION_STATUS_RESULT';
export const UPDATE_SUBSCRIPTION_STATUS = 'app/Auth/UPDATE_SUBSCRIPTION_STATUS';
export const UPDATE_SUBSCRIPTION_STATUS_RESULT =
  'app/Auth/UPDATE_SUBSCRIPTION_STATUS_RESULT';
export const SET_AUTH_MODAL_STATE = 'app/Auth/SET_AUTH_MODAL_STATE';
export const SET_AUTH_MODAL_ACTION = 'app/Auth/SET_AUTH_MODAL_ACTION';
export const SHOW_AUTH_CTA_MODAL = 'app/Auth/SHOW_AUTH_CTA_MODAL';
export const HIDE_AUTH_CTA_MODAL = 'app/Auth/HIDE_AUTH_CTA_MODAL';
export const SET_HERO_VARIANT = 'app/Auth/SET_HERO_VARIANT';
export const GET_PROFILE_FILTERS = 'app/Auth/GET_PROFILE_FILTERS';
export const SET_PROFILE_FILTERS = 'app/Auth/SET_PROFILE_FILTERS';
export const REGENERATE_ANONYMOUS_ID = 'app/Auth/REGENERATE_ANONYMOUS_ID';
