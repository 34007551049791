/**
 *
 * StyledLink
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import _classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    textDecoration: 'none',
    zIndex: 99,
  },
}));
function StyledLink({ link, onClick, children, className }) {
  const classes = useStyles();
  return (
    <Link
      to={link}
      onClick={onClick}
      className={_classNames(classes.root, { [className]: !!className })}
    >
      {children}
    </Link>
  );
}

StyledLink.propTypes = {
  children: PropTypes.node,
  link: PropTypes.string,
  onClick: PropTypes.func,
};

export default StyledLink;
