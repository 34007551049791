import { useEffect } from 'react';

const useMediaIdleControl = ({ pause, start, enable }) => {
  const attachIdleControlEvents = mediaElement => {
    mediaElement.addEventListener('play', pause);
    mediaElement.addEventListener('pause', start);
    mediaElement.addEventListener('ended', start);
  };

  const detachIdleControlEvents = mediaElement => {
    mediaElement.removeEventListener('play', pause);
    mediaElement.removeEventListener('pause', start);
    mediaElement.removeEventListener('ended', start);
  };

  useEffect(() => {
    if (!enable) {
      return undefined;
    }

    let mediaElements = [];

    const observer = new MutationObserver(() => {
      mediaElements = document.querySelectorAll('audio, video');
      mediaElements.forEach(mediaElement => {
        detachIdleControlEvents(mediaElement);
        attachIdleControlEvents(mediaElement);
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
      mediaElements.forEach(detachIdleControlEvents);
    };
  }, [pause, start, enable]);
};

export default useMediaIdleControl;
