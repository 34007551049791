/*
 *
 * ClientLanding reducer
 *
 */
import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import {
  getClientLandingInfoSuccess,
  getTopicResourcesInfoSuccess,
  getPageLandingInfo,
  getPageLandingInfoSuccess,
  pageProcessing,
  processing,
  clearLandingInfo,
} from './actions';

export const initialState = {
  data: {},
  pageData: {},
  featuredTopics: {},
  processing: false,
  pageProcessing: false,
};
const clientLandingReducer = handleActions(
  {
    [getClientLandingInfoSuccess](state, action) {
      return update(state, { data: { $set: action.payload } });
    },
    [getPageLandingInfo](state) {
      return update(state, { pageData: { $set: {} } });
    },
    [getPageLandingInfoSuccess](state, action) {
      return update(state, { pageData: { $set: action.payload } });
    },
    [getTopicResourcesInfoSuccess](state, action) {
      return update(state, { featuredTopics: { $set: action.payload } });
    },
    [processing](state, action) {
      return update(state, { processing: { $set: action.payload } });
    },
    [pageProcessing](state, action) {
      return update(state, { pageProcessing: { $set: action.payload } });
    },
    [clearLandingInfo](state) {
      return update(state, { data: { $set: {} } });
    },
  },
  initialState,
);

export default clientLandingReducer;
