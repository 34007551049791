import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { isTakenSeriesCompleted, isTakenSeriesInProgress } from './utils';

const getSeriesProgress = (entry, taken) => {
  const resourcesLength = _get(entry, 'fields.resources.length', 0);
  const completedResourcesCount = _get(taken, 'completedResourcesIds.length');
  const progress = completedResourcesCount
    ? Math.round((completedResourcesCount / resourcesLength) * 100)
    : 0;

  return progress;
};

export const getAllSeries = createSelector(
  [state => state.series.takenSeries, state => state.series.allSeries],
  (takenSeries, allSeries) => {
    const completedIds = Object.keys(takenSeries).filter(
      key => takenSeries[key].completed,
    );
    const inProgressIds = Object.keys(takenSeries)
      .filter(key => !takenSeries[key].completed)
      .sort((a, b) => {
        const progressA = getSeriesProgress(
          allSeries.find(item => item.sys.id === a),
          takenSeries[a],
        );
        const progressB = getSeriesProgress(
          allSeries.find(item => item.sys.id === b),
          takenSeries[b],
        );

        return progressB - progressA;
      });

    const sortedAllSeries = allSeries.sort((a, b) => {
      if (completedIds.includes(a.sys.id) && !completedIds.includes(b.sys.id)) {
        return 1;
      }
      if (!completedIds.includes(a.sys.id) && completedIds.includes(b.sys.id)) {
        return -1;
      }
      if (completedIds.includes(a.sys.id) && completedIds.includes(b.sys.id)) {
        if (
          takenSeries[a.sys.id].completedAt > takenSeries[b.sys.id].completedAt
        ) {
          return -1;
        }
        return 1;
      }

      if (
        inProgressIds.includes(a.sys.id) &&
        !inProgressIds.includes(b.sys.id)
      ) {
        return -1;
      }
      if (
        !inProgressIds.includes(a.sys.id) &&
        inProgressIds.includes(b.sys.id)
      ) {
        return 1;
      }
      if (
        inProgressIds.includes(a.sys.id) &&
        inProgressIds.includes(b.sys.id)
      ) {
        return (
          inProgressIds.indexOf(a.sys.id) - inProgressIds.indexOf(b.sys.id)
        );
      }
      return 0;
    });

    return sortedAllSeries;
  },
);

export const getSeriesTakenInSession = state => state.series.takenSeries;

export const getCompletedSeries = createSelector(
  state => state.series.takenSeries,
  takenSeries =>
    Object.keys(takenSeries).filter(id =>
      isTakenSeriesCompleted(takenSeries[id]),
    ),
);

export const getTakenSeriesProcessing = state =>
  state.series.takenSeriesProcessing;

export const getInProgressSeries = createSelector(
  state => state.series.takenSeries,
  takenSeries =>
    Object.keys(takenSeries).filter(id =>
      isTakenSeriesInProgress(takenSeries[id]),
    ),
);
