import useElementPosition from './useElementPosition';

const useNavbarElementPosition = () => {
  const navBarMenuWrapperElement = document.getElementById(
    'navbar-menu-wrapper',
  );

  const navBarPosition = useElementPosition(navBarMenuWrapperElement);

  return navBarPosition;
};

export default useNavbarElementPosition;
