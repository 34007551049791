/*
 *
 * Account actions
 *
 */

import { createAction } from 'redux-actions';
import {
  DEFAULT_ACTION,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_RESULT,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_RESULT,
  PROCESSING,
  TOGGLE_CONTENTFUL_TOOLS,
  TOGGLE_CONTENTFUL_TOOLS_RESULT,
  GET_DETAILED_USER_HISTORY,
  GET_DETAILED_USER_HISTORY_RESULT,
} from './constants';

export const defaultAction = createAction(DEFAULT_ACTION);
export const updateAccount = createAction(UPDATE_ACCOUNT);
export const updateAccountResult = createAction(UPDATE_ACCOUNT_RESULT);
export const changePassword = createAction(CHANGE_PASSWORD);
export const changePasswordResult = createAction(CHANGE_PASSWORD_RESULT);
export const toggleContentfulTools = createAction(TOGGLE_CONTENTFUL_TOOLS);
export const toggleContentfulToolsResult = createAction(
  TOGGLE_CONTENTFUL_TOOLS_RESULT,
);
export const getDetailedUserHistory = createAction(GET_DETAILED_USER_HISTORY);
export const getDetailedUserHistoryResult = createAction(
  GET_DETAILED_USER_HISTORY_RESULT,
);
export const processing = createAction(PROCESSING);
