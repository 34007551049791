import { createAction } from 'redux-actions';
import {
  DEFAULT_ACTION,
  PROCESSING,
  GET_SAVED_BEST_RESOURCES,
  GET_SAVED_BEST_RESOURCES_SUCCESS,
} from './constants';

export const defaultAction = createAction(DEFAULT_ACTION);
export const processing = createAction(PROCESSING);
export const getSavedBestResources = createAction(GET_SAVED_BEST_RESOURCES);
export const getSavedBestResourcesSuccess = createAction(
  GET_SAVED_BEST_RESOURCES_SUCCESS,
);
