/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { getImageFile } from 'utils/stringUtils';
import StyledLink from 'components/StyledLink';
import { makeSelectTextDirection } from 'containers/Main/selectors';
import useAlgoliaLocale from 'components/useAlgoliaLocale';
import useSiteCopySelector from 'components/useSiteCopySelector';
import { getAlgoliaLocalizedField } from 'utils/localeUtils';
import ViewLink from '../ViewLink';
import TimeIcon from '../../images/time.svg';
import NavBarContext from '../../navBarContext';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 30px 30px',
    borderLeft: '1px solid #E3E3E3',
    background: 'white',
    maxWidth: 370,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      padding: '12px 20px 20px',
      display: 'block',
    },
  },
  header: {
    marginBottom: 8,
    height: 36,
  },
  lists: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  image: {
    width: 59,
    height: 60,
    marginRight: 21,
    objectFit: 'cover',
  },
  listItem: {
    width: 342,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    '& a': {
      marginTop: 0,
    },
  },
  title: {
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
    color: theme.palette.text.primary,
    direction: ({ textDirection }) => textDirection,
  },
  time: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    '& img': {
      marginRight: 5,
    },
  },
}));

function MostPopularAssessment({ data, clickHandler }) {
  const textDirection = useSelector(makeSelectTextDirection());
  const classes = useStyles({ textDirection });
  const { siteCopy } = useContext(NavBarContext);
  const [resourceItemSiteCopy] = useSiteCopySelector(['resource-item-page']);
  const locale = useAlgoliaLocale();

  if (!data) return null;
  return (
    <div className={classes.root}>
      <Typography
        className={classes.header}
        variant="subtitle1"
        color="textPrimary"
      >
        {_get(siteCopy, ['pageCopy', 'submenu-assessments', 'most-popular'])}
      </Typography>
      <div className={classes.lists} data-test-id="assessments-popular">
        {data.map(el => {
          const imageSrc = getImageFile(el);
          const { slug, name, time } = el;
          return (
            <StyledLink link={`/assessments/${slug}`} key={slug}>
              <div className={classes.listItem}>
                <div onClick={() => clickHandler(name, 'image')}>
                  <img src={imageSrc} alt={name} className={classes.image} />
                </div>
                <div className={classes.info}>
                  <Typography className={classes.title} align="left">
                    {getAlgoliaLocalizedField(el, 'name', locale)}
                  </Typography>
                  <Typography variant="overline" className={classes.time}>
                    <img src={TimeIcon} alt="" />
                    {`${time} ${_get(resourceItemSiteCopy, [
                      'pageCopy',
                      time > 1
                        ? 'durationLabelPlural'
                        : 'durationLabelSingular',
                    ])}`}
                  </Typography>
                  <ViewLink
                    label={_get(
                      resourceItemSiteCopy,
                      'pageCopy.takeAssessmentLabel',
                    )}
                    link={`/assessments/${slug}`}
                    isTextOnly
                    clickHandler={() => clickHandler(name)}
                    button
                  />
                </div>
              </div>
            </StyledLink>
          );
        })}
      </div>
      <ViewLink
        label={_get(siteCopy, ['pageCopy', 'submenu-assessments', 'view-all'])}
        link="/assessments"
      />
    </div>
  );
}

MostPopularAssessment.propTypes = {
  data: PropTypes.array,
  clickHandler: PropTypes.func,
};

export default MostPopularAssessment;
