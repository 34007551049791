/*
 *
 * ClientLanding constants
 *
 */

export const GET_CLIENT_LANDING_INFO =
  'app/ClientLanding/GET_CLIENT_LANDING_INFO';
export const GET_CLIENT_LANDING_INFO_SUCCESS =
  'app/ClientLanding/GET_CLIENT_LANDING_INFO_SUCCESS';
export const GET_PAGE_LANDING_INFO = 'app/ClientLanding/GET_PAGE_LANDING_INFO';
export const GET_PAGE_LANDING_INFO_SUCCESS =
  'app/ClientLanding/GET_PAGE_LANDING_INFO_SUCCESS';
export const PAGE_PROCESSING = 'app/ClientLanding/PAGE_PROCESSING';
export const GET_TOPIC_RESOURCES_INFO =
  'app/ClientLanding/GET_TOPIC_RESOURCES_INFO';
export const GET_TOPIC_RESOURCES_INFO_SUCCESS =
  'app/ClientLanding/GET_TOPIC_RESOURCES_INFO_SUCCESS';
export const PROCESSING = 'app/ClientLanding/PROCESSING';
export const CLEAR_LANDING_INFO = 'app/ClientLanding/CLEAR_LANDING_INFO';
