/*
 *
 * Assessment actions
 *
 */

import { createAction } from 'redux-actions';
import {
  GET_ASSESSMENT,
  GET_ASSESSMENT_SUCCESS,
  GET_ASSESSMENT_FAIL,
  GET_ANSWERS,
  GET_ANSWERS_BY_ACCESS_CODE,
  GET_RESULTS_BY_ADMIN_CODE,
  GET_ANSWERS_SUCCESS,
  GET_ANSWERS_FAIL,
  PUT_ASSESSMENT,
  PUT_ASSESSMENT_RESULT,
  GET_ASSESSMENT_RESULT,
  GET_ASSESSMENT_RESULT_SUCCESS,
  GET_ASSESSMENT_RESULT_FAIL,
  GET_ASSESSMENT_REPORT,
  GET_ASSESSMENT_REPORT_RESULT,
  SEND_ASSESSMENT_RESULTS_BY_EMAIL,
  SEND_ASSESSMENT_RESULTS_BY_EMAIL_RESULT,
  SET_ANSWERS_SOURCE,
  PROCESSING,
  ANSWERS_PROCESSING,
  SET_TAKEN_IN_SESSION,
  MOVE_ANONYMOUS_ASSESSMENTS,
  MOVE_ANONYMOUS_ASSESSMENTS_RESULT,
  SET_SMS_DIALOG,
  SET_ACCESS_CODE_COPIED,
  GET_ANSWERS_WITH_RESULTS,
  GET_COURSE_ASSESSMENT_RESULTS_SUCCESS,
} from './constants';

export const processing = createAction(PROCESSING);
export const answersProcessing = createAction(ANSWERS_PROCESSING);
export const getAssessment = createAction(GET_ASSESSMENT);
export const getAssessmentSuccess = createAction(GET_ASSESSMENT_SUCCESS);
export const getAssessmentFail = createAction(GET_ASSESSMENT_FAIL);
export const getAnswers = createAction(GET_ANSWERS);
export const getAnswersByAccessCode = createAction(GET_ANSWERS_BY_ACCESS_CODE);
export const getResultsByAdminCode = createAction(GET_RESULTS_BY_ADMIN_CODE);
export const getAnswersSuccess = createAction(GET_ANSWERS_SUCCESS);
export const getAnswersFail = createAction(GET_ANSWERS_FAIL);
export const putAssessment = createAction(PUT_ASSESSMENT);
export const putAssessmentResult = createAction(PUT_ASSESSMENT_RESULT);
export const getAssessmentResult = createAction(GET_ASSESSMENT_RESULT);
export const getAssessmentResultSuccess = createAction(
  GET_ASSESSMENT_RESULT_SUCCESS,
);
export const getAssessmentResultFail = createAction(GET_ASSESSMENT_RESULT_FAIL);
export const getAssessmentReport = createAction(GET_ASSESSMENT_REPORT);
export const getAssessmentReportResult = createAction(
  GET_ASSESSMENT_REPORT_RESULT,
);
export const sendAssessmentResultsByEmail = createAction(
  SEND_ASSESSMENT_RESULTS_BY_EMAIL,
);
export const sendAssessmentResultsByEmailResult = createAction(
  SEND_ASSESSMENT_RESULTS_BY_EMAIL_RESULT,
);
export const setTakenInSession = createAction(SET_TAKEN_IN_SESSION);
export const moveAnonymousAssessments = createAction(
  MOVE_ANONYMOUS_ASSESSMENTS,
);
export const moveAnonymousAssessmentsResult = createAction(
  MOVE_ANONYMOUS_ASSESSMENTS_RESULT,
);
export const setSMSDialog = createAction(SET_SMS_DIALOG);
export const setAccessCodeCopied = createAction(SET_ACCESS_CODE_COPIED);
export const setAnswersSource = createAction(SET_ANSWERS_SOURCE);
export const getAnswersWithResults = createAction(GET_ANSWERS_WITH_RESULTS);
export const getSeriesAssessmentResultSuccess = createAction(
  GET_COURSE_ASSESSMENT_RESULTS_SUCCESS,
);
