export const GET_TAKEN_COURSES = 'app/Courses/GET_TAKEN_COURSES';
export const GET_TAKEN_COURSES_PROCESSING =
  'app/Courses/GET_TAKEN_COURSES_PROCESSING';
export const GET_TAKEN_COURSES_SUCCESS =
  'app/Courses/GET_TAKEN_COURSES_SUCCESS';
export const COURSE_UPDATE_RESOURCE = 'app/Courses/COURSE_UPDATE_RESOURCE';
export const COURSE_UPDATE_RESOURCE_SAVED =
  'app/Courses/COURSE_UPDATE_RESOURCE_SAVED';
export const COURSE_SAVE_SERIES_COMPLETED_RESOURCES =
  'app/Courses/COURSE_SAVE_SERIES_COMPLETED_RESOURCES';
export const COURSE_SERIES_COMPLETED_RESOURCES_SAVED =
  'app/Courses/COURSE_SERIES_COMPLETED_RESOURCES_SAVED';
export const COURSE_COMPLETE_SERIES = 'app/Courses/COURSE_COMPLETE_SERIES';
export const COURSE_COMPLETED_SERIES_SAVED =
  'app/Course/COURSE_COMPLETED_SERIES_SAVED';
export const COURSE_SAVE_CUSTOM_QUESTION =
  'app/Course/COURSE_SAVE_CUSTOM_QUESTION';
export const COURSE_CUSTOM_QUESTION_SAVED =
  'app/Course/COURSE_CUSTOM_QUESTION_SAVED';
export const COURSE_ENROLL = 'app/Course/COURSE_ENROLL';
export const COURSE_ENROLLED = 'app/Course/COURSE_ENROLLED';
export const COURSE_SAVE_SERIES_ASSESSMENT =
  'app/Course/COURSE_SAVE_SERIES_ASSESSMENT';
export const COURSE_SERIES_ASSESSMENT_SAVED =
  'app/Course/COURSE_SERIES_ASSESSMENT_SAVED';
export const COURSE_SAVE_RESOURCE_FOR_LATER =
  'app/Course/COURSE_SAVE_RESOURCE_FOR_LATER';
export const COURSE_RESOURCE_FOR_LATER_SAVED =
  'app/Course/COURSE_RESOURCE_FOR_LATER_SAVED';
export const COURSE_RESOURCE_FOR_LATER_ERROR =
  'app/Course/COURSE_RESOURCE_FOR_LATER_ERROR';
export const COURSE_RESOURCE_FOR_LATER_PROCESSING =
  'app/Course/COURSE_RESOURCE_FOR_LATER_PROCESSING';
export const COURSE_SAVE_WORKSHEET_PROGRESS =
  'app/Course/COURSE_SAVE_WORKSHEET_PROGRESS';

export const COURSE_SIDEBAR_TABS = {
  WELCOME_SERIES: 'WELCOME_SERIES',
  RESOURCE: 'RESOURCE',
  REFLECTION: 'REFLECTION',
  NEXT_STEPS: 'NEXT_STEPS',
  RECOMMENDED: 'RECOMMENDED',
  WORKSHEET: 'WORKSHEET',
};

export const SERIES_TYPES = {
  WELCOME: 'welcome',
  LEARN: 'learn',
  APPLY: 'apply',
  REFLECTION: 'reflection',
  NEXT_STEPS: 'next steps',
};

export const SURVEY_TYPES = {
  PRE: 'pre',
  POST: 'post',
};

export const COURSE_REFLECT_CUSTOM_QUESTION_KEY = 'courseReflections';
export const COURSE_WORKSHEET_CUSTOM_QUESTION_KEY = 'worksheets';
export const COURSE_SAVED_RECOMMENDED_RESOURCE_KEY = 'savedResourceIds';
export const COURSE_SAVED_SERIES_RESOURCE_KEY = 'savedSeriesResourceIds';
export const COURSE_HEART_ICON_SEEN_LS_KEY = 'courseHeartSeen';
