import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import ButtonBase from '@material-ui/core/ButtonBase';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import DownArrowIcon from '@material-ui/icons/ArrowDropDown';
import Typography from '@material-ui/core/Typography';
import { getIsClientAdmin } from 'containers/Auth/utils';
import { getUserFirstName } from 'utils/stringUtils';
import NavBarContext from '../navBarContext';

const useStyles = makeStyles(theme => ({
  buttonRoot: {
    color: 'white',
    maxWidth: '100%',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  avatarContainer: {
    position: 'relative',
  },
  avatar: {
    height: 30,
    width: 30,
    color: theme.palette.text.primary,
  },
  downIcon: {
    color: theme.palette.primary.secondary,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  account: {
    color: theme.palette.text.secondary,
    width: '100%',
    marginLeft: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'MadaMedium',
    [theme.breakpoints.down('1200')]: {
      display: 'none',
    },
  },
  popperContainer: {
    zIndex: 9999,
  },
  seriesMenuItem: {
    '& span': {
      marginRight: 10,
    },
    borderBottom: '1px solid #E3E2E3',
    color: theme.palette.primary.main,
    ...theme.typography.pRegular,
  },
  accountBadge: {
    bottom: 3,
    right: 3,
    position: 'absolute',
    transform: 'translate(50%, 50%)',
  },
  badge: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 18,
    minWidth: 18,
    padding: '0 4px',
    borderRadius: '10px',
    color: theme.palette.text.white,
    backgroundColor: theme.palette.primary.secondary,
    fontSize: '0.75rem',
    lineHeight: 1,
  },
}));

const AccountInfo = ({ profile, logout, history, trackUserMenuEvent }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorEl = useRef();
  const { siteCopy, clientDetails } = useContext(NavBarContext);

  const handleToggle = () => setOpen(!open);

  const handleClose = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) return;

    setOpen(false);
  };

  const handleUserMenuClick = (redirect, isLogout) => {
    setOpen(false);

    const menusToNotTrackEvent = ['/admin', '/client-admin'];
    if (!menusToNotTrackEvent.includes(redirect)) {
      trackEvent(redirect, isLogout);
    }

    if (isLogout) {
      logout();
      history.replace('/', { showReminderOverlay: false });
    } else history.push(redirect);
  };

  const trackEvent = (redirect, isLogout) => {
    const redirectToTypeMapping = {
      '/favorites': 'resources',
      '/account': 'account',
      '/series': 'try series',
    };

    const type = redirectToTypeMapping[redirect] || 'logout';

    trackUserMenuEvent({
      type,
      ...(isLogout ? {} : { url: redirect }),
    });
  };

  const { avatarUrl } = profile;
  const name = getUserFirstName(profile);
  const userName =
    name.length > 9
      ? name.slice(0, 6).endsWith(' ')
        ? name.slice(0, 5)
        : `${name.slice(0, 6)}...`
      : name;

  const showAdminToolsOption =
    (profile.role === 'admin' ||
      profile.role === 'contentAdmin' ||
      profile.role === 'salesAdmin') &&
    profile.showContentfulTools;
  const hasClientAdminAccess =
    getIsClientAdmin(profile, clientDetails) ||
    ['admin', 'contentAdmin'].includes(_get(profile, 'role'));

  return (
    <div className={classes.root} data-test-id="menu-account-section">
      <ButtonBase
        ref={anchorEl}
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        aria-label="Avatar"
        onClick={handleToggle}
        classes={{ root: classes.buttonRoot }}
        data-test-id="profile-btn"
        disableRipple
      >
        <div className={classes.avatarContainer}>
          {avatarUrl ? (
            <Avatar
              alt="User Avatar"
              src={avatarUrl}
              className={classes.avatar}
            />
          ) : (
            <AccountCircle className={classes.avatar} />
          )}
        </div>

        <Typography className={classes.account}> {userName}</Typography>
        <DownArrowIcon className={classes.downIcon} />
      </ButtonBase>
      <Popper
        open={open}
        anchorEl={anchorEl.current}
        className={classes.popperContainer}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem onClick={() => handleUserMenuClick('/favorites')}>
                    {_get(siteCopy, ['pageCopy', 'dialog-menu', 'favorites'])}
                  </MenuItem>
                  <MenuItem onClick={() => handleUserMenuClick('/account')}>
                    {_get(siteCopy, ['pageCopy', 'dialog-menu', 'account'])}
                  </MenuItem>
                  {showAdminToolsOption && (
                    <MenuItem onClick={() => handleUserMenuClick('/admin')}>
                      Admin Tools
                    </MenuItem>
                  )}
                  {hasClientAdminAccess && !_isEmpty(clientDetails) && (
                    <MenuItem
                      onClick={() => handleUserMenuClick('/client-admin')}
                    >
                      Admin Portal
                    </MenuItem>
                  )}
                  <MenuItem onClick={() => handleUserMenuClick(null, true)}>
                    {_get(siteCopy, ['pageCopy', 'dialog-menu', 'logout'])}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

AccountInfo.propTypes = {
  profile: PropTypes.object,
  logout: PropTypes.func,
  history: PropTypes.object,
};

export default AccountInfo;
