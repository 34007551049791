import { useLocation, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { setLocalData } from 'utils/localDataStore';
import _isNan from 'lodash/isNaN';
import { isEmbedded } from '../../utils/embedded';
function useCheckNumCardsParam() {
  const location = useLocation();
  const history = useHistory();
  const isEmbed = isEmbedded();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const numCards = queryParams.get('numcards');

    const parsedNumber = parseInt(numCards, 10);
    if (
      typeof parsedNumber === 'number' &&
      !_isNan(parsedNumber) &&
      numCards &&
      isEmbed
    ) {
      setLocalData('list-view-num-cards', numCards, {
        expires: 365,
      });
      localStorage.setItem('list-view-num-cards', numCards);
    }
  }, [location, history, isEmbed]);
}

export default useCheckNumCardsParam;
