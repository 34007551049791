import { createSelector } from 'reselect';

/**
 * Direct selector to the resources state domain
 */

const selectResourcesDomain = state => state.resources;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Resources
 */

const makeSelectProcessing = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.processing,
  );

const makeSelectSubmittingFeedback = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.submittingFeedback,
  );
const makeSelectAlgoliaProcessing = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.algoliaProcessing,
  );
const makeSelectAlgoliaNextPageProcessing = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.algoliaNextPageProcessing,
  );
const makeSelectAlgoliaTopicsProcessing = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.algoliaTopicsProcessing,
  );
const makeSelectSavedResources = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.savedResources,
  );
const makeSelectSaveResourceResult = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.saveResult,
  );
const makeSelectAlgoliaResources = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.algoliaResources,
  );
const makeSelectAlgoliaFilters = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.algoliaFilters,
  );
const makeSelectRateResourceResult = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.rateResult,
  );
const makeSelectReview = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.review,
  );
const makeSelectReviewResourceResult = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.reviewResult,
  );
const makeSelectHelpfulResult = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.helpfulResult,
  );
const makeSelectHelpfulRatings = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.helpfulRatings,
  );
const makeSelectRecommendedResources = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.recommendedResources,
  );
const makeSelectRelatedNews = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.relatedNews,
  );
const makeSelectUserReviews = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.userReviews,
  );
const makeSelectFetchingUserReviews = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.fetchingUserReviews,
  );
const makeSelectAlgoliaTopicsQuery = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.algoliaTopicsQuery,
  );
const makeSelectUserHistory = () =>
  createSelector(
    selectResourcesDomain,
    substate => substate.userHistory,
  );

export {
  makeSelectProcessing,
  makeSelectAlgoliaProcessing,
  makeSelectAlgoliaNextPageProcessing,
  makeSelectAlgoliaTopicsProcessing,
  makeSelectSaveResourceResult,
  makeSelectSavedResources,
  makeSelectAlgoliaResources,
  makeSelectAlgoliaFilters,
  makeSelectAlgoliaTopicsQuery,
  makeSelectRateResourceResult,
  makeSelectReview,
  makeSelectReviewResourceResult,
  makeSelectHelpfulResult,
  makeSelectHelpfulRatings,
  makeSelectRecommendedResources,
  makeSelectRelatedNews,
  makeSelectUserReviews,
  makeSelectFetchingUserReviews,
  makeSelectSubmittingFeedback,
  makeSelectUserHistory,
};
