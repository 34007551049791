/* eslint-disable no-empty */
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import { getFirebase } from 'react-redux-firebase';
import { isBot } from 'utils/stringUtils';
import { isLocalhost } from 'utils/networkUtils';
import { getLocalData } from 'utils/localDataStore';

// enable only in production mode
const active = !isLocalhost && !isBot();

const getBrowser = (user_agent, vendor, opera) => {
  const finalVendor = vendor || ''; // vendor is undefined for at least IE9
  if (opera || _includes(user_agent, ' OPR/')) {
    if (_includes(user_agent, 'Mini')) {
      return 'Opera Mini';
    }
    return 'Opera';
  }
  if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
    return 'BlackBerry';
  }
  if (_includes(user_agent, 'IEMobile') || _includes(user_agent, 'WPDesktop')) {
    return 'Internet Explorer Mobile';
  }
  if (_includes(user_agent, 'SamsungBrowser/')) {
    // https://developer.samsung.com/internet/user-agent-string-format
    return 'Samsung Internet';
  }
  if (_includes(user_agent, 'Edge') || _includes(user_agent, 'Edg/')) {
    return 'Microsoft Edge';
  }
  if (_includes(user_agent, 'FBIOS')) {
    return 'Facebook Mobile';
  }
  if (_includes(user_agent, 'Chrome')) {
    return 'Chrome';
  }
  if (_includes(user_agent, 'CriOS')) {
    return 'Chrome iOS';
  }
  if (_includes(user_agent, 'UCWEB') || _includes(user_agent, 'UCBrowser')) {
    return 'UC Browser';
  }
  if (_includes(user_agent, 'FxiOS')) {
    return 'Firefox iOS';
  }
  if (_includes(finalVendor, 'Apple')) {
    if (_includes(user_agent, 'Mobile')) {
      return 'Mobile Safari';
    }
    return 'Safari';
  }
  if (_includes(user_agent, 'Android')) {
    return 'Android Mobile';
  }
  if (_includes(user_agent, 'Konqueror')) {
    return 'Konqueror';
  }
  if (_includes(user_agent, 'Firefox')) {
    return 'Firefox';
  }
  if (_includes(user_agent, 'MSIE') || _includes(user_agent, 'Trident/')) {
    return 'Internet Explorer';
  }
  if (_includes(user_agent, 'Gecko')) {
    return 'Mozilla';
  }
  return '';
};

const getBrowserVersion = (userAgent, vendor, opera) => {
  const browser = getBrowser(userAgent, vendor, opera);
  const versionRegexs = {
    'Internet Explorer Mobile': /rv:(\d+(\.\d+)?)/,
    'Microsoft Edge': /Edge?\/(\d+(\.\d+)?)/,
    Chrome: /Chrome\/(\d+(\.\d+)?)/,
    'Chrome iOS': /CriOS\/(\d+(\.\d+)?)/,
    'UC Browser': /(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/,
    Safari: /Version\/(\d+(\.\d+)?)/,
    'Mobile Safari': /Version\/(\d+(\.\d+)?)/,
    Opera: /(Opera|OPR)\/(\d+(\.\d+)?)/,
    Firefox: /Firefox\/(\d+(\.\d+)?)/,
    'Firefox iOS': /FxiOS\/(\d+(\.\d+)?)/,
    Konqueror: /Konqueror:(\d+(\.\d+)?)/,
    BlackBerry: /BlackBerry (\d+(\.\d+)?)/,
    'Android Mobile': /android\s(\d+(\.\d+)?)/,
    'Samsung Internet': /SamsungBrowser\/(\d+(\.\d+)?)/,
    'Internet Explorer': /(rv:|MSIE )(\d+(\.\d+)?)/,
    Mozilla: /rv:(\d+(\.\d+)?)/,
  };
  const regex = versionRegexs[browser];
  if (regex === undefined) {
    return '';
  }
  const matches = userAgent.match(regex);
  if (!matches) {
    return '';
  }
  return parseFloat(matches[matches.length - 2]);
};

const BrowserTracker = {
  track: () => {
    if (active) {
      (async () => {
        const firebase = await getFirebase();
        const auth = firebase.auth();
        const id = _get(auth, 'currentUser.uid') || getLocalData('userIdTemp');
        const browser = getBrowser(
          navigator.userAgent,
          navigator.vendor,
          window.opera,
        );
        const version = getBrowserVersion(
          navigator.userAgent,
          navigator.vendor,
          window.opera,
        );

        if (id && browser) {
          const firestore = firebase.firestore();
          firestore.collection('user_browser').add({
            id,
            browser: `${browser} ${version}`,
            timestamp: Date.now(),
          });
        }
      })();
    }
  },
  getBrowser,
};

export default BrowserTracker;
