import { useRef } from 'react';

/**
 * A custom React hook to track transitions from a falsy to a truthy state.
 * It returns a boolean value indicating the current truthiness of the condition.
 * The hook ensures that once the condition transitions from falsy to truthy,
 * subsequent transitions to falsy and back to truthy won't change the value again.
 * @param {Function} cb A callback function that determines the condition.
 * @returns {boolean} Returns true if the condition transitions from falsy to truthy,
 * and remains truthy thereafter; otherwise, returns false.
 */

const useTruthyState = cb => {
  const result = cb();
  const isTruthy = !!result;

  // Initialize with the initial value of isTruthy
  const wasTruthyRef = useRef(isTruthy);

  // Update wasTruthyRef if cb result changes
  if (!wasTruthyRef.current && isTruthy) {
    wasTruthyRef.current = true;
  }

  return wasTruthyRef.current;
};

export default useTruthyState;
