import _isEmpty from 'lodash/isEmpty';
import { getLocalData, setLocalData } from 'utils/localDataStore';
import { getSubdomain } from 'utils/stringUtils';

const getCustomOnboardingCacheKey = modalName => {
  const brand = getSubdomain() || getLocalData('brand');

  return `${brand}_${modalName}_customOnboardingViewed`;
};

export const getCustomOnboardingLocalUtils = () => {
  const cacheKey = modalName => getCustomOnboardingCacheKey(modalName);

  const get = modalName => getLocalData(cacheKey(modalName));
  const set = (modalName, value) => setLocalData(cacheKey(modalName), value);

  return {
    get,
    set,
  };
};

export const getCanDisplayModal = ({ trigger, pathname, backStack }) => {
  if (!trigger?.length) {
    const isFirstPage = _isEmpty(backStack);
    return pathname === '/' && isFirstPage;
  }

  const isArrayTrigger = Array.isArray(trigger);
  if (isArrayTrigger) return trigger.includes(pathname);

  if (trigger === 'all') return true;
  if (trigger === 'home') return pathname === '/';

  return false;
};
