import GqlClient from 'utils/graphqlService';
import { gql } from '@apollo/client';
import _get from 'lodash/get';
import { reverseLanguageCodeMapping } from 'containers/Main/utils';

const GetLanguageCollectionByCode = gql`
  query Language($code: String!) {
    languageCollection(where: { shortcode: $code }, limit: 1, preview: true) {
      items {
        title
        slug
        shortcode
        rtlLanguage
        menuName
        image {
          url
        }
      }
    }
  }
`;

const getLanguageByCode = async (_, code) => {
  const { data } = await GqlClient.query({
    query: GetLanguageCollectionByCode,
    context: { isPreview: true },
    variables: {
      code: reverseLanguageCodeMapping[code] || code,
    },
  });
  return _get(data, 'languageCollection.items.0', []);
};

export default getLanguageByCode;
