export const DEFAULT_ACTION = 'app/Favorites/DEFAULT_ACTION';
export const PROCESSING = 'app/Favorites/PROCESSING';
export const GET_SAVED_BEST_RESOURCES =
  'app/Favorites/GET_SAVED_BEST_RESOURCES';
export const GET_SAVED_BEST_RESOURCES_SUCCESS =
  'app/Favorites/GET_SAVED_BEST_RESOURCES_SUCCESS';
export const GET_FAVORITE_PUBLIC_LISTS =
  'app/Favorites/GET_FAVORITE_PUBLIC_LISTS';
export const GET_FAVORITE_PUBLIC_LISTS_SUCCESS =
  'app/Favorites/GET_FAVORITE_PUBLIC_LISTS_SUCCESS';
