import React from 'react';

export default (color = '#FFFFFF') => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>sha-cube</title>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Resources---2021---Client-Resources---Icon-options"
        transform="translate(-1119.000000, -402.000000)"
        stroke={color}
      >
        <g
          id="Get-Started-with-Crediblemind-Copy-8"
          transform="translate(1106.000000, 267.000000)"
        >
          <g id="EAP-Program" transform="translate(14.000000, 132.000000)">
            <g id="sha-cube" transform="translate(0.000000, 4.000000)">
              <polygon
                id="Stroke-255"
                points="0 2.66666667 5.77777778 0 11.5555556 2.66666667 11.5555556 9.33333333 5.77777778 12 0 9.33333333"
              ></polygon>
              <line
                x1="5.77777778"
                y1="5.33333333"
                x2="5.77777778"
                y2="12"
                id="Stroke-257"
              ></line>
              <polyline
                id="Stroke-259"
                points="0 2.66666667 5.77777778 5.33333333 11.5555556 2.66666667"
              ></polyline>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
