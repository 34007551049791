import React from 'react';

export default (color = '#FFFFFF') => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="App-option-2" transform="translate(0.3899994 0.39001465)">
      <path
        d="M12.0652 9.45652L12.0652 14.0217"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.999 0L5.02122 0Q5.11961 0 5.21611 0.0191955Q5.31261 0.038391 5.40352 0.0760443Q5.49442 0.113698 5.57623 0.168362Q5.65804 0.223026 5.72762 0.2926Q5.79719 0.362175 5.85185 0.443985Q5.90652 0.525796 5.94417 0.616699Q5.98183 0.707602 6.00102 0.804105Q6.02022 0.900607 6.02022 0.999L6.02022 5.02122Q6.02022 5.11961 6.00102 5.21611Q5.98183 5.31261 5.94417 5.40352Q5.90652 5.49442 5.85185 5.57623Q5.79719 5.65804 5.72762 5.72762Q5.65804 5.79719 5.57623 5.85185Q5.49442 5.90652 5.40352 5.94417Q5.31261 5.98183 5.21611 6.00102Q5.11961 6.02022 5.02122 6.02022L0.999 6.02022Q0.900607 6.02022 0.804105 6.00102Q0.707602 5.98183 0.616699 5.94417Q0.525796 5.90652 0.443985 5.85185Q0.362175 5.79719 0.2926 5.72762Q0.223026 5.65804 0.168362 5.57623Q0.113698 5.49442 0.0760443 5.40352Q0.038391 5.31261 0.0191955 5.21611Q0 5.11961 0 5.02122L0 0.999Q0 0.900607 0.0191955 0.804105Q0.038391 0.707602 0.0760443 0.616699Q0.113698 0.525796 0.168362 0.443985Q0.223026 0.362175 0.2926 0.2926Q0.362175 0.223026 0.443985 0.168362Q0.525796 0.113698 0.616699 0.0760443Q0.707602 0.038391 0.804105 0.0191955Q0.900607 0 0.999 0Z"
        id="Rectangle"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.97878 0L14.001 0Q14.0994 0 14.1959 0.0191955Q14.2924 0.038391 14.3833 0.0760443Q14.4742 0.113698 14.556 0.168362Q14.6378 0.223026 14.7074 0.2926Q14.777 0.362175 14.8316 0.443985Q14.8863 0.525796 14.924 0.616699Q14.9616 0.707602 14.9808 0.804105Q15 0.900607 15 0.999L15 5.02122Q15 5.11961 14.9808 5.21611Q14.9616 5.31261 14.924 5.40352Q14.8863 5.49442 14.8316 5.57623Q14.777 5.65804 14.7074 5.72762Q14.6378 5.79719 14.556 5.85185Q14.4742 5.90652 14.3833 5.94417Q14.2924 5.98183 14.1959 6.00102Q14.0994 6.02022 14.001 6.02022L9.97878 6.02022Q9.88039 6.02022 9.78389 6.00102Q9.68738 5.98183 9.59648 5.94417Q9.50558 5.90652 9.42377 5.85185Q9.34196 5.79719 9.27238 5.72762Q9.20281 5.65804 9.14814 5.57623Q9.09348 5.49442 9.05583 5.40352Q9.01817 5.31261 8.99898 5.21611Q8.97978 5.11961 8.97978 5.02122L8.97978 0.999Q8.97978 0.900607 8.99898 0.804105Q9.01817 0.707602 9.05583 0.616699Q9.09348 0.525796 9.14814 0.443985Q9.20281 0.362175 9.27238 0.2926Q9.34196 0.223026 9.42377 0.168362Q9.50558 0.113698 9.59648 0.0760443Q9.68738 0.038391 9.78389 0.0191955Q9.88039 0 9.97878 0Z"
        id="Rectangle"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.999 8.97978L5.02122 8.97978Q5.11961 8.97978 5.21611 8.99898Q5.31261 9.01817 5.40352 9.05583Q5.49442 9.09348 5.57623 9.14814Q5.65804 9.20281 5.72762 9.27238Q5.79719 9.34196 5.85185 9.42377Q5.90652 9.50558 5.94417 9.59648Q5.98183 9.68738 6.00102 9.78389Q6.02022 9.88039 6.02022 9.97878L6.02022 14.001Q6.02022 14.0994 6.00102 14.1959Q5.98183 14.2924 5.94417 14.3833Q5.90652 14.4742 5.85185 14.556Q5.79719 14.6378 5.72762 14.7074Q5.65804 14.777 5.57623 14.8316Q5.49442 14.8863 5.40352 14.924Q5.31261 14.9616 5.21611 14.9808Q5.11961 15 5.02122 15L0.999 15Q0.900607 15 0.804105 14.9808Q0.707602 14.9616 0.616699 14.924Q0.525796 14.8863 0.443985 14.8316Q0.362175 14.777 0.2926 14.7074Q0.223026 14.6378 0.168362 14.556Q0.113698 14.4742 0.0760443 14.3833Q0.038391 14.2924 0.0191955 14.1959Q0 14.0994 0 14.001L0 9.97878Q0 9.88039 0.0191955 9.78389Q0.038391 9.68738 0.0760443 9.59648Q0.113698 9.50558 0.168362 9.42377Q0.223026 9.34196 0.2926 9.27238Q0.362175 9.20281 0.443985 9.14814Q0.525796 9.09348 0.616699 9.05583Q0.707602 9.01817 0.804105 8.99898Q0.900607 8.97978 0.999 8.97978Z"
        id="Rectangle"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3478 11.7391L9.78261 11.7391"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
