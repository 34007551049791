import React from 'react';
import PropTypes from 'prop-types';
import Mixpanel from 'utils/mixpanelService';
import { withRouter } from 'react-router-dom';

class TrackedHtmlMedia extends React.PureComponent {
  trackEvent = event => {
    const { location, blog, contentType, name, subtype } = this.props;
    const pageName = location.pathname.split('/')[1];
    const finalPageName = pageName ? `${pageName}Page` : 'homePage';
    Mixpanel.track(event, {
      name,
      type: contentType,
      page: finalPageName,
      subtype,
      blog,
    });
  };

  render() {
    const { mimeGroup, source, description } = this.props;

    if (mimeGroup === 'audio')
      return (
        <div>
          <audio
            controls
            src={source}
            onPlay={() => this.trackEvent('RichText Audio - Play')}
            onPause={() => this.trackEvent('RichText Audio - Pause')}
          >
            Your browser does not support the
            <code>audio</code> element.
          </audio>
        </div>
      );
    if (mimeGroup === 'video')
      return (
        <div>
          <video
            controls
            poster={description || null}
            onPlay={() => this.trackEvent('RichText Video - Play')}
            onPause={() => this.trackEvent('RichText Video - Pause')}
          >
            <source src={source} type="video/mp4" />
            <p>Your browser doesnt support HTML5 video.</p>
          </video>
        </div>
      );
    return null;
  }
}

TrackedHtmlMedia.propTypes = {
  mimeGroup: PropTypes.string,
  source: PropTypes.string,
  description: PropTypes.string,
  location: PropTypes.object,
  name: PropTypes.string,
  blog: PropTypes.bool,
  contentType: PropTypes.string,
  subtype: PropTypes.string,
};

export default withRouter(React.memo(TrackedHtmlMedia));
