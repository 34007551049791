import { useSelector } from 'react-redux';
import {
  makeSelectAuth,
  makeSelectAnonymousId,
} from 'containers/Auth/selectors';
import { getLocalData } from 'utils/localDataStore';

const useUserId = () => {
  const auth = useSelector(makeSelectAuth());
  const sessionAnonymousId = useSelector(makeSelectAnonymousId());

  const id = auth.uid || (getLocalData('userIdTemp') || sessionAnonymousId);

  return id;
};

export default useUserId;
