import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _classNames from 'classnames';
import { sha256 } from 'js-sha256';
import _get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';
import { makeSelectAnonymousId } from 'containers/Auth/selectors';
import useUserId from 'components/Hooks/useUserId';
import { getLocalData, setLocalData } from 'utils/localDataStore';
import { shouldShowContentfulTools } from 'containers/Auth/utils';
import Accordion from './components/Accordion';
import Alert from './components/Alert';
import Basic from './components/Basic';
import {
  CONTENT_BLOCK_DISMISS_OPTIONS,
  CONTENT_BLOCK_POSITIONS,
} from './utils';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    paddingLeft: '5%',
    paddingRight: '5%',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'center',
    marginTop: 8,
    '&:first-child': {
      marginTop: 0,
    },
    '& > div:first-child': {
      width: '100%',
    },
    [theme.breakpoints.up('1140')]: {
      maxWidth: 1140,
      paddingLeft: 14,
      paddingRight: 14,
    },
    position: 'relative',
  },
  fullWidthContainer: {
    maxWidth: 'unset',
    paddingLeft: 0,
    paddingRight: 0,
    flexGrow: 1,
    [theme.breakpoints.up('1140')]: {
      maxWidth: '100%',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  firstContainerMargin: {
    '&:first-child': {
      marginTop: '8px !important',
    },
  },
  assessmentResultsContainer: {
    padding: '24px 5%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '0 24px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px',
    },
  },
}));

const Item = ({
  data,
  type,
  fullWidth = false,
  rounded,
  marginTop,
  marginBottom,
  position,
  isClientAdmin,
  isPreview = false,
  className,
  widgetProps,
}) => {
  const [dismissed, setDismissed] = useState(false);
  const classes = useStyles();
  const userId = useUserId();
  const sessionId = useSelector(makeSelectAnonymousId());
  const profile = useSelector(state => state.firebase.profile);
  const blockRef = useRef(null);
  const [marginBtm, setMarginBtm] = useState('0px');

  const key = `content_block_${sha256(`${_get(data, 'sys.id')}_${userId}`)}`;

  useEffect(() => {
    if (!_isEmpty(data)) {
      const { dismissOptions } = data;
      if (
        _isNil(getLocalData(key)) &&
        (dismissOptions === CONTENT_BLOCK_DISMISS_OPTIONS.DISMISSIBLE_ONCE ||
          dismissOptions === CONTENT_BLOCK_DISMISS_OPTIONS.NOT_DISMISSIBLE_ONCE)
      ) {
        setLocalData(key, sessionId);
      }
    }
  }, [data, key, sessionId]);

  useEffect(() => {
    const blockDiv = blockRef.current;
    if (style === 'Basic Content' && blockDiv) {
      const parentContainer = blockDiv.parentElement;
      const styles = window.getComputedStyle(parentContainer);
      const { paddingTop } = styles;

      setMarginBtm(paddingTop);
    }
  }, [blockRef, style]);

  if (_isEmpty(data) || dismissed) return null;

  const { dismissOptions, type: style } = data;

  const isAdmin = shouldShowContentfulTools(profile);

  if (
    (dismissOptions === CONTENT_BLOCK_DISMISS_OPTIONS.DISMISSIBLE_ONCE ||
      dismissOptions === CONTENT_BLOCK_DISMISS_OPTIONS.NOT_DISMISSIBLE_ONCE) &&
    !_isNil(getLocalData(key)) &&
    getLocalData(key) !== sessionId
  )
    return null;

  const handleDismiss = () => {
    setDismissed(true);
    if (
      dismissOptions ===
      CONTENT_BLOCK_DISMISS_OPTIONS.DISMISSIBLE_CONTINUOUS_UNTIL_CLOSE
    )
      setLocalData(key, sessionId);
  };

  if (style !== 'Accordion' && style !== 'Alert' && style !== 'Basic Content')
    return null;

  const widgetCommonProps = {
    data,
    onDismiss: handleDismiss,
    isAdmin,
    isPreview,
    ...(widgetProps || {}),
  };

  return (
    <Box
      className={_classNames(
        {
          [classes.container]: true,
          [classes.fullWidthContainer]: style === 'Alert' || fullWidth,
          [classes.firstContainerMargin]:
            position !== CONTENT_BLOCK_POSITIONS.WINDOW_TOP_STICKY &&
            position !== CONTENT_BLOCK_POSITIONS.WINDOW_TOP_SCROLLS &&
            position !== CONTENT_BLOCK_POSITIONS.ABOVE_FOOTER &&
            position !== CONTENT_BLOCK_POSITIONS.BELOW_FOOTER &&
            (isClientAdmin ||
              position !== CONTENT_BLOCK_POSITIONS.BELOW_HERO_INTRO ||
              style === 'Basic Content' ||
              style === 'Accordion') &&
            (isClientAdmin &&
              position !== CONTENT_BLOCK_POSITIONS.BELOW_MENU_STICKY),
          [classes.withAdminBtn]: isAdmin,
          [classes.assessmentResultsContainer]: type === 'assessment-results',
        },
        className,
      )}
      ref={blockRef}
      style={{ marginBottom: marginBtm }}
      data-style={style}
      data-color={data.backgroundColor}
    >
      {style === 'Accordion' && <Accordion {...widgetCommonProps} />}
      {style === 'Alert' && (
        <Alert
          marginTop={marginTop}
          marginBottom={marginBottom}
          rounded={rounded}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...widgetCommonProps}
        />
      )}
      {style === 'Basic Content' && (
        <Basic
          isAssessmentResultsPage={type === 'assessment-results'}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...widgetCommonProps}
        />
      )}
    </Box>
  );
};

Item.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  rounded: PropTypes.bool,
  marginTop: PropTypes.bool,
  marginBottom: PropTypes.bool,
  position: PropTypes.string,
  isClientAdmin: PropTypes.bool,
};

export default Item;
