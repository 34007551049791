import React, { useContext } from 'react';
import { Box, Grid, Hidden, makeStyles } from '@material-ui/core';
import _get from 'lodash/get';
import SearchBar from 'components/SearchBar';
import { Link } from 'react-router-dom';
import Logo from '../Logo';
import NavBarContext from '../../navBarContext';

const useStyles = makeStyles(() => ({
  helperText: {
    fontSize: '14px',
    textAlign: 'right',
    marginLeft: 'auto',
    fontWeight: 'bold',
    color: 'white',
    fontFamily: 'MadaBold',
    borderRadius: '10px',
    padding: '0px 7px',
    backgroundColor: '#AD0000',
  },
  helperContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  searchContainer: {
    width: '100%',
    paddingTop: '14px',
  },
}));

const MobileNavBar = () => {
  const classes = useStyles();
  const { siteCopy, clientDetails, menus } = useContext(NavBarContext);

  const isSeparateLogoLayout =
    _get(clientDetails, 'metadata.separateLogoLayout', false) ||
    _get(clientDetails, 'logosCollection.items', []).length > 2;

  const inCrisisMenu = menus.find(({ slug }) => slug === 'in-crisis');

  return (
    <Hidden smUp>
      <Grid container>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Logo alternative={isSeparateLogoLayout} />
          </Grid>
          <Grid item xs={6}>
            {inCrisisMenu && (
              <Box className={classes.helperContainer}>
                <Link
                  to={inCrisisMenu.link || '/in-crisis'}
                  className={classes.helperText}
                >
                  {_get(
                    siteCopy,
                    ['pageCopy', 'submenu-labels', inCrisisMenu.slug],
                    '',
                  )}
                </Link>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid item className={classes.searchContainer} xs={12}>
          <SearchBar mobile id="mobile-nav-menu-search-input" />
        </Grid>
      </Grid>
    </Hidden>
  );
};

export default MobileNavBar;
