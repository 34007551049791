import { createSelector } from 'reselect';

/**
 * Direct selector to the clientLanding state domain
 */

const selectClientLandingDomain = state => state.clientLanding;
const makeSelectClientLanding = () =>
  createSelector(
    selectClientLandingDomain,
    substate => substate.data,
  );
const makeSelectPageLanding = () =>
  createSelector(
    selectClientLandingDomain,
    substate => substate.pageData,
  );
const makeSelectFeaturedTopics = () =>
  createSelector(
    selectClientLandingDomain,
    substate => substate.featuredTopics,
  );

const makeSelectClientLandingProcessing = () =>
  createSelector(
    selectClientLandingDomain,
    substate => substate.processing,
  );
const makeSelectPageLandingProcessing = () =>
  createSelector(
    selectClientLandingDomain,
    substate => substate.pageProcessing,
  );

export {
  makeSelectClientLanding,
  makeSelectPageLanding,
  makeSelectFeaturedTopics,
  makeSelectClientLandingProcessing,
  makeSelectPageLandingProcessing,
};
