/*
 *
 * Sources reducer
 *
 */
import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { processing, getSourcesSuccess } from './actions';

export const initialState = {
  processing: false,
  data: {},
};
const sourcesReducer = handleActions(
  {
    [processing](state, action) {
      return update(state, { processing: { $set: action.payload } });
    },
    [getSourcesSuccess](state, action) {
      return update(state, {
        data: { [action.payload.type]: { $set: action.payload.data } },
      });
    },
  },
  initialState,
);

export default sourcesReducer;
