import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import ModalDialog from 'components/ModalDialog';
import Mixpanel from 'utils/mixpanelService';
import { getSubdomain } from 'utils/stringUtils';
import { getLocalData, setLocalData } from 'utils/localDataStore';
import CustomAutocomplete from 'components/CustomAutocomplete';
import useSiteCopySelector from 'components/useSiteCopySelector';
import useIsCustomOnboardingEnabled from '../hooks/useIsCustomOnboardingEnabled';
import { getCustomOnboardingLocalUtils } from './utils';

const pages = {
  redirect: 'redirectModal',
  reporting: 'reportingModal',
};

const useStyles = makeStyles(theme => ({
  title: {
    ...theme.typography.h4,
    textAlign: 'center',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  modalContent: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  subTitle: {
    textAlign: 'center',
  },
}));

const CustomOnboardingDialog = () => {
  const classes = useStyles();
  const history = useHistory();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [values, setValues] = useState({});
  const [isOpen, setIsOpen] = useState(true);
  const [customOnboardingSiteCopy] = useSiteCopySelector([
    'custom-onboarding-mapping',
  ]);

  const brand = getSubdomain() || getLocalData('brand');

  const config = _get(customOnboardingSiteCopy, 'pageCopy');
  const customOnboardingClientConfig = new Map(
    Object.entries(
      _get(config, ['clientsConfig', brand], _get(config, 'default', {})),
    ),
  );

  const { showRedirect, showReporting } = useIsCustomOnboardingEnabled();
  const isCustomOnboardingEnabled = showRedirect || showReporting;

  if (!showRedirect) customOnboardingClientConfig.delete(pages.redirect);
  if (!showReporting) customOnboardingClientConfig.delete(pages.reporting);

  const onboardingPageNames = [...customOnboardingClientConfig.keys()].sort(
    name => (name === pages.reporting ? -1 : 1),
  );
  const currentPage = onboardingPageNames[currentPageNumber - 1];

  useEffect(() => {
    if (!isCustomOnboardingEnabled || !isOpen) return;

    Mixpanel.track('Custom Onboarding - Viewed', { modal: currentPage });
  }, [isCustomOnboardingEnabled, isOpen]);

  if (!isCustomOnboardingEnabled) return null;

  const getMixpanelProperties = () => {
    const selectedReporting = _get(values, pages.reporting);
    const selectedRedirect = _get(values, pages.redirect);

    return {
      ...(selectedReporting ? { customGroup: selectedReporting.value } : {}),
      ...(selectedRedirect ? { customLink: selectedRedirect.value } : {}),
    };
  };

  const handleCustomBoardingViewed = () => {
    const selectedReporting = _get(values, pages.reporting) || {};
    const selectedRedirect = _get(values, pages.redirect) || {};

    const selectedReportingValue = selectedReporting.value;
    if (selectedReportingValue) {
      setLocalData(`${brand}_customOnboardingReport`, selectedReportingValue);
    }

    const selectedRedirectValue = selectedRedirect.value;
    if (selectedRedirectValue) {
      history.push(selectedRedirectValue);
    }

    const { set } = getCustomOnboardingLocalUtils();
    if (showReporting) set(pages.reporting, true);
    if (showRedirect) set(pages.redirect, true);

    setIsOpen(false);

    const customOnboardingObject = getMixpanelProperties();
    Mixpanel.track('Custom Onboarding - Completed', customOnboardingObject);
  };

  const totalNumberOfPages = onboardingPageNames.length;
  const isLastPage = currentPageNumber === totalNumberOfPages;

  const handleNavigateToNextPage = () => {
    if (isLastPage) {
      handleCustomBoardingViewed();
      return;
    }

    setCurrentPageNumber(currentPageNumber + 1);
  };

  const currentPageConfig = customOnboardingClientConfig.get(currentPage);

  const title = _get(currentPageConfig, 'title', '');
  const subtitle = _get(currentPageConfig, 'subtitle', '');
  const placeholder = _get(currentPageConfig, 'placeholder', '');
  const noOptionsText = _get(currentPageConfig, 'noOptionsText', '');
  const buttons = _get(config, 'buttons', {});

  const currentPageSelectedValue = values[currentPage];

  const modalButtons = [
    {
      label: isLastPage ? _get(buttons, 'complete') : _get(buttons, 'next'),
      color: 'primary',
      variant: 'contained',
      onClick: handleNavigateToNextPage,
      disabled: _isEmpty(currentPageSelectedValue),
    },
  ];

  // we would like to support both array and object options
  const currentPageConfigOptions = _get(currentPageConfig, 'options', []);
  const options = Array.isArray(currentPageConfigOptions)
    ? currentPageConfigOptions.map(value => ({
        label: value,
        value,
      }))
    : Object.keys(currentPageConfigOptions).map(key => ({
        label: key,
        value: _get(currentPageConfigOptions, key),
      }));

  const subtitleHTML = subtitle?.replace(/(?:\r\n|\r|\n)/g, '<br />');

  const handleClose = () => {
    Mixpanel.track('Custom Onboarding - Dismissed', { modal: currentPage });
    setIsOpen(false);
  };

  return (
    <ModalDialog
      open={isOpen}
      renderHeader={() => (
        <Typography color="primary" className={classes.title}>
          {title}
        </Typography>
      )}
      onClose={handleClose}
      buttons={modalButtons}
    >
      <Box display="flex" justifyContent="center">
        <Box>
          {subtitle && (
            <Typography variant="body2" className={classes.subTitle}>
              <article dangerouslySetInnerHTML={{ __html: subtitleHTML }} />
            </Typography>
          )}
          <Box className={classes.modalContent}>
            <CustomAutocomplete
              dataKey={currentPage}
              value={currentPageSelectedValue}
              onChange={item => setValues({ ...values, [currentPage]: item })}
              placeholder={placeholder}
              options={options}
              noOptionsText={noOptionsText}
            />
          </Box>
        </Box>
      </Box>
    </ModalDialog>
  );
};

export default CustomOnboardingDialog;
