import { useSelector } from 'react-redux';
const useContentfulLocale = () => {
  const language = useSelector(s => s.main.language);

  return {
    locale: language,
  };
};

export default useContentfulLocale;
