import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _get from 'lodash/get';
import classNames from 'classnames';
import { ButtonBase } from '@material-ui/core';
import { getImageFile } from 'utils/stringUtils';
import CheckIcon from './check.svg';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    margin: '30px 0',
    display: 'grid',
    // gridTemplateColumns: 'repeat(3, minmax(240px, 1fr))',
    gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
    gridGap: 10,
    [theme.breakpoints.down('xs')]: {
      gridGap: 5,
    },
  },
  gridItem: {
    height: 167,
    maxWidth: 300,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 8,
    position: 'relative',
    '&:hover': {
      border: '2px solid #0061A0',
    },
  },
  selectedList: {
    border: '2px solid #0061A0',
  },
  title: {
    position: 'absolute',
    bottom: 10,
    left: 10,
    fontSize: 20,
    fontFamily: 'PoppinsSemibold',
    color: 'white',
  },
  checkIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}));

export default function ListItems({ data, selectedList, clickHandler }) {
  const classes = useStyles();
  return (
    <div className={classes.gridContainer}>
      {data.map(({ fields, sys: { id } }) => {
        const alternateImage = _get(
          fields,
          'alternateImage.fields.file.url',
          null,
        );
        const imageField = _get(fields, 'image');
        const image =
          alternateImage ||
          getImageFile(
            imageField ? fields : _get(fields, 'resources.0.fields'),
          );
        const isSelected = selectedList === id;
        /* TODO: make this common once approved */
        return (
          <ButtonBase
            key={id}
            disableRipple
            className={classNames(
              classes.gridItem,
              isSelected && classes.selectedList,
            )}
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.5)), url(${image})`,
            }}
            onClick={() => clickHandler(id)}
          >
            {isSelected && (
              <img
                src={CheckIcon}
                alt="Selected"
                className={classes.checkIcon}
              />
            )}
            <span className={classes.title}>
              {_get(fields, 'shortTitle', _get(fields, 'title'))}
            </span>
          </ButtonBase>
        );
      })}
    </div>
  );
}
