import _isEmpty from 'lodash/isEmpty';
import _isNaN from 'lodash/isNaN';
import _has from 'lodash/has';
export const showByRating = data => {
  const hasValue = !_isNaN(data) && data !== 0 && data !== -1;
  return hasValue && data >= 2.5;
};
export const hasBadge = data => {
  const {
    researchRating = undefined,
    spiritualRating = undefined,
    thoughtRating = undefined,
    labels = [],
    context = {},
  } = data;
  const { AR = -1, SR = -1, RR = -1 } = context;
  return (
    showByRating(thoughtRating || AR) ||
    showByRating(spiritualRating || SR) ||
    showByRating(researchRating || RR) ||
    !_isEmpty(labels) ||
    data.try
  );
};

export const getUserRatingAndCount = (data, type) => {
  const {
    appleStoreRating = 0,
    appleStoreRatingCount = 0,
    googlePlayRating = 0,
    googlePlayRatingCount = 0,
    userRating = 0,
    userRatingCount = 0,
    goodReadsUserRating = 0,
    goodReadsRatingsCount = 0,
    amazonUserRating = 0,
    amazonReviewsNumber = 0,
    appleUserRating = 0,
    appleUserRatingCount = 0,
    youtubeLikeCount = 0,
    youtubeDislikeCount = 0,
    calculatedUserRating,
    eLearningUserRatingCount = 0,
  } = data;
  let rating;
  let count;
  const itemRating =
    userRating || (_has(data, 'user-rating') ? data['user-rating'] : 0);
  if (
    type === 'app' &&
    ((!!appleStoreRating && !!appleStoreRatingCount) ||
      (!!googlePlayRatingCount && !!googlePlayRating))
  ) {
    const divisor = !!appleStoreRating && !!googlePlayRating ? 2 : 1;
    rating =
      calculatedUserRating || (appleStoreRating + googlePlayRating) / divisor;
    count = appleStoreRatingCount + googlePlayRatingCount;
  } else if (
    type === 'book' &&
    ((!!goodReadsUserRating && !!goodReadsRatingsCount) ||
      (!!amazonUserRating && !!amazonReviewsNumber))
  ) {
    const divisor = !!goodReadsUserRating && !!amazonUserRating ? 2 : 1;
    rating =
      calculatedUserRating ||
      (goodReadsUserRating + amazonUserRating) / divisor;
    count = goodReadsRatingsCount + amazonReviewsNumber;
  } else if (
    type === 'podcast' &&
    !!appleUserRating &&
    !!appleUserRatingCount
  ) {
    rating = calculatedUserRating || appleUserRating;
    count = appleUserRatingCount;
  } else if (
    type === 'video' &&
    !_isNaN(youtubeLikeCount) &&
    !_isNaN(youtubeDislikeCount)
  ) {
    const value =
      (youtubeLikeCount / (youtubeDislikeCount + youtubeLikeCount)) * 5 - 0.3;
    rating =
      calculatedUserRating ||
      (Number.parseFloat(value).toFixed(1) <= '4.9' ? value : 4.9);
    count = youtubeLikeCount + youtubeDislikeCount;
  } else if (type === 'program') {
    rating = calculatedUserRating || itemRating;
    count = eLearningUserRatingCount;
  } else {
    rating = calculatedUserRating || itemRating;
    count = userRatingCount;
  }
  return { rating, count };
};
