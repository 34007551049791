import { gql } from '@apollo/client';

export default gql`
  query Themes($title: String, $locale: String) {
    themeCollection(
      where: { reviewStatus: "Accepted", title: $title }
      limit: 40
      order: sortOrder_ASC
      locale: $locale
    ) {
      items {
        title
        sortOrder
        reviewStatus
        sectors
        hideInMainMenu
        topicsCollection(limit: 75) {
          items {
            title
            slug
            reviewStatus
            sys {
              id
            }
            userRating
            image {
              url
            }
          }
        }
        clientCollection(limit: 75) {
          items {
            slug
          }
        }
        sys {
          id
        }
      }
    }
  }
`;
