/*
 *
 * Assessments reducer
 *
 */
import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import {
  processing,
  getAssessmentsSuccess,
  getAssessmentsFail,
  getAssessments,
  getTakenAssessmentsResult,
  getSavedAssessmentsResult,
  getRecommendedResourcesResult,
  saveAssessment,
  saveAssessmentResult,
  sectionProcessing,
  getAllAssessmentsResults,
  getAllAssessmentsResultsResult,
} from './actions';

export const initialState = {
  processing: false,
  data: {},
  savedAssessments: [],
  takenAssessments: [],
  recommendations: [],
  saveResult: {},
  error: {},
  sectionProcessing: false,
  menuAssessments: {},
  allResults: [],
};
const assessmentsReducer = handleActions(
  {
    [processing](state, action) {
      return update(state, { processing: { $set: action.payload } });
    },
    [sectionProcessing](state, action) {
      return update(state, { sectionProcessing: { $set: action.payload } });
    },
    [getAssessmentsSuccess](state, action) {
      if (action.payload.type === 'menu') {
        return update(state, {
          menuAssessments: { $set: action.payload.data },
        });
      }

      return update(state, { data: { $set: action.payload.data } });
    },
    [getAssessments](state) {
      return update(state, { error: { $set: {} } }); // reset any error object on fetch/refetch
    },
    [getAssessmentsFail](state, action) {
      return update(state, { error: { $set: action.payload } });
    },
    [getTakenAssessmentsResult](state, action) {
      return update(state, { takenAssessments: { $set: action.payload } });
    },
    [getSavedAssessmentsResult](state, action) {
      return update(state, { savedAssessments: { $set: action.payload } });
    },
    [getRecommendedResourcesResult](state, action) {
      return update(state, { recommendations: { $set: action.payload } });
    },
    [saveAssessment](state) {
      return update(state, { saveResult: { $set: {} } });
    },
    [saveAssessmentResult](state, action) {
      return update(state, { saveResult: { $set: action.payload } });
    },
    [getAllAssessmentsResults](state) {
      return update(state, { allResults: { $set: [] } });
    },
    [getAllAssessmentsResultsResult](state, action) {
      return update(state, { allResults: { $set: action.payload } });
    },
  },
  initialState,
);

export default assessmentsReducer;
