import { gql } from '@apollo/client';

export default gql`
  query SiteCopy($locale: String!) {
    siteCopyCollection(limit: 80, locale: $locale) {
      items {
        name
        slug
        pageHeader
        callToAction
        pageCopy
        body {
          json
        }
        section1Body {
          json
        }
        section1Header
        section1CallToAction
        section2Header
        section2CallToAction
        imageUrl
        imageAttribution
        image {
          url
        }
        style
      }
    }
  }
`;
