/*
 *
 * Home reducer
 *
 */

import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import {
  processing,
  getHomeAssessmentsSuccess,
  getHomeNewsSuccess,
  getHomeBlogsSuccess,
  setGuideMeOpened,
} from './actions';

export const initialState = {
  assessments: {},
  processing: false,
  news: {},
  blogs: [],
  guideMeOpened: false,
  blogsLanguage: null,
  newsLanguage: null,
};

const homeReducer = handleActions(
  {
    [processing](state, action) {
      return update(state, { processing: { $set: action.payload } });
    },
    [getHomeAssessmentsSuccess](state, action) {
      return update(state, { assessments: { $set: action.payload } });
    },
    [getHomeNewsSuccess](state, action) {
      return update(state, {
        news: { $set: action.payload },
        newsLanguage: { $set: action.payload.locale },
      });
    },
    [getHomeBlogsSuccess](state, action) {
      return update(state, {
        blogs: { $set: action.payload.data },
        blogsLanguage: { $set: action.payload.locale },
      });
    },
    [setGuideMeOpened](state, action) {
      return update(state, { guideMeOpened: { $set: action.payload } });
    },
  },
  initialState,
);

export default homeReducer;
