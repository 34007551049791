import { gql } from '@apollo/client';

export default gql`
  query AudienceTagsRelations($shortName: String!, $locale: String!) {
    audienceCollection(
      where: { reviewStatus_in: ["Accepted", "Internal"] }
      limit: 80
      locale: $locale
    ) {
      items {
        sys {
          id
        }
        name
        exclusive
        excludeCollection(limit: 20) {
          items {
            sys {
              id
            }
            name
          }
        }
      }
    }
    tagsCollection(
      where: { reviewStatus_in: ["Accepted", "Internal"] }
      limit: 200
      locale: $locale
    ) {
      items {
        sys {
          id
        }
        title
        excludeCollection(limit: 40) {
          items {
            sys {
              id
            }
            title
          }
        }
      }
    }
    clientTagsCollection(
      where: {
        reviewStatus_in: ["Accepted", "Internal"]
        client: { shortName: $shortName }
      }
      limit: 800
      locale: $locale
    ) {
      items {
        sys {
          id
        }
        title
      }
    }
  }
`;
