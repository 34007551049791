import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import useSiteCopySelector from 'components/useSiteCopySelector';
import {
  makeSelectClientDetails,
  makeSelectIsFreshworksLoaded,
  makeSelectRegion,
  makeSelectShowRegionBanner,
} from 'containers/Main/selectors';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { isLocalStorageAllowed, isIE } from 'utils/stringUtils';
import getRegionBySlug from 'containers/Main/services/getRegionBySlug.gql';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  showAbout,
  showRegionSelector,
  showRegionBanner,
} from 'containers/Main/actions';
import LandingMenuSelector from 'components/LandingMenuSelector';
import ClassNames from 'classnames';
import freshworksUtils from 'utils/freshworksUtils';
import useInCrisis from 'containers/InCrisis/useInCrisis';
import DownArrowIcon from './down-arrow.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: 36,
    zIndex: 999,
    background: 'white',
    width: '100%',
    marginRight: 15,
    borderBottom: '1px solid #E3E3E3',
    [theme.breakpoints.down('900')]: {
      borderBottom: 'none',
    },
    [theme.breakpoints.down('600')]: {
      position: 'inherit',
    },
  },
  stick: {
    background: '#fff',
    zIndex: 9,
  },
  container: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.up('1140')]: {
      width: 1140,
      padding: 0,
    },
    width: '100%',
    padding: '0 5%',
    [theme.breakpoints.down('350')]: {
      padding: 0,
    },
  },
  socialsWrapper: {
    position: 'absolute',
    right: 0,
  },
  regionInfo: {
    display: 'flex',
    paddingRight: 20,
    '& :hover': {
      background: 'transparent',
    },
  },
  img: {
    height: 20,
    marginRight: 10,
  },
  name: {
    ...theme.typography.overline,
    color: theme.palette.primary.main,
    marginRight: 10,
    [theme.breakpoints.down('900')]: {
      display: 'none',
    },
  },
  rightMenuWrapper: {
    position: 'absolute',
    right: 0,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('1140')]: {
      right: '5%',
    },
    '& a, & button': {
      ...theme.typography.overline,
      color: '#6C6C6C',
      padding: '0 5px',
    },
    '& button': {
      marginRight: 12,
      textTransform: 'uppercase',
    },
  },
  rightMenuWrapperIE11: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    top: 0,
  },
  rightMenuButton: {
    ...theme.typography.overline,
    color: '#6C6C6C',
    padding: '0 5px',
    marginRight: 12,
    textTransform: 'uppercase',
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

const RegionBanner = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const region = useSelector(makeSelectRegion());
  const showBanner = useSelector(makeSelectShowRegionBanner());
  const clientDetails = useSelector(makeSelectClientDetails());
  const isFreshworksLoaded = useSelector(makeSelectIsFreshworksLoaded());
  const [footerSiteCopy] = useSiteCopySelector(['footer']);
  const [hideAboutLink, setHideAboutLink] = useState(
    _get(clientDetails, 'metadata.hideAboutLink', false),
  );

  useEffect(() => {
    if (!_isEmpty(clientDetails))
      setHideAboutLink(_get(clientDetails, 'metadata.hideAboutLink', false));
  }, [clientDetails]);

  const isTopicPage = location.pathname.includes('/topics/');
  const isCompanyPage = location.pathname.includes('/company/');

  const { data, isFetching } = useQuery(
    region && ['region-banner', region],
    getRegionBySlug,
  );

  const hasBanner = showBanner && !_isEmpty(data);
  const hasMultipleLanding =
    _get(clientDetails, 'landingLinksCollection.items', [])
      .filter(item => !_isEmpty(item))
      .filter(({ showMenu }) => showMenu === 'yes').length > 1;

  const isRegionNotInBrand =
    _get(clientDetails, 'shortName') !==
    _get(data, 'clientCollection.items.0.shortName');

  const clearRegionStorageIfAllowed = () => {
    if (isRegionNotInBrand && isLocalStorageAllowed()) {
      // clear any localstorage
      localStorage.removeItem('region');
    }
  };
  useEffect(() => {
    if (!isFetching) {
      clearRegionStorageIfAllowed();
      if (!data) {
        clearRegionStorageIfAllowed();
        dispatch(showRegionBanner(false));
      } else {
        dispatch(showRegionBanner(!isRegionNotInBrand));
      }
    }
  }, [data, isFetching, clientDetails]);

  const { showInCrisisInHeader, renderInCrisisButton } = useInCrisis();

  if (isCompanyPage || _isEmpty(clientDetails)) return null;
  const hideButtons =
    !hasBanner &&
    !hasMultipleLanding &&
    !isFreshworksLoaded &&
    !showInCrisisInHeader;
  if (hideButtons) return null;

  const shouldShowAbout = !hideAboutLink;
  const showSelector = () => {
    dispatch(showRegionSelector(true));
  };
  const handleAboutClick = () => dispatch(showAbout(true));
  const handleSupportClick = () => {
    freshworksUtils.show();
  };
  const BtnContent = () => {
    if (!data) return null;
    const { title } = data;
    const url = _get(data, 'image.url');
    return (
      <>
        <img src={url} alt={title} className={classes.img} />
        <span className={classes.name}>{title}</span>
        <img src={DownArrowIcon} className={classes.icon} alt="" />
      </>
    );
  };

  return (
    <section
      className={ClassNames(classes.root, isTopicPage ? classes.stick : '')}
    >
      <div className={classes.container}>
        {hasBanner && (
          <ButtonBase className={classes.regionInfo} onClick={showSelector}>
            {!isFetching && BtnContent()}
          </ButtonBase>
        )}
        {hasMultipleLanding && <LandingMenuSelector />}
        {(isFreshworksLoaded || shouldShowAbout || showInCrisisInHeader) && (
          <div
            className={ClassNames({
              [classes.rightMenuWrapper]: true,
              [classes.rightMenuWrapperIE11]: isIE(),
            })}
          >
            {isFreshworksLoaded && (
              <ButtonBase
                onClick={handleSupportClick}
                disableRipple
                disableTouchRipple
                className={classes.rightMenuButton}
              >
                {_get(footerSiteCopy, 'pageCopy.supportLabel', 'Support')}
              </ButtonBase>
            )}
            {shouldShowAbout && (
              <ButtonBase
                onClick={handleAboutClick}
                disableRipple
                disableTouchRipple
                className={classes.rightMenuButton}
              >
                {_get(footerSiteCopy, 'pageCopy.aboutLabel')}
              </ButtonBase>
            )}
            {renderInCrisisButton()}
          </div>
        )}
      </div>
    </section>
  );
};

export default React.memo(RegionBanner);
