import React from 'react';
import ClassNames from 'classnames';
import _get from 'lodash/get';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  makeSelectClientDetails,
  makeSelectClientDetailsFetching,
  makeSelectIsFreshworksLoaded,
} from 'containers/Main/selectors';
import { getSubdomain } from 'utils/stringUtils';
import { isEmbedded } from 'utils/embedded';
import useSiteCopySelector from 'components/useSiteCopySelector';
import freshworksUtils from 'utils/freshworksUtils';
import SocialLinks from './SocialLinks';

const MENU = [
  {
    label: 'Employers',
    link: 'https://solutions.crediblemind.com/benefits/#employers',
  },
  {
    label: 'Health Systems',
    link: 'https://solutions.crediblemind.com/benefits/#hospitals',
  },
  {
    label: 'Public Sector',
    link: ' https://solutions.crediblemind.com/benefits/#plans',
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    height: 52,
    zIndex: 999,
    background: 'white',
    width: '100%',
    borderBottom: '1px solid #E3E3E3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '0 5%',
    position: 'relative',
    [theme.breakpoints.up('1140')]: {
      width: 1140,
      padding: '0 14px',
    },
  },
  menuWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  menu: {
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  menuItem: {
    ...theme.typography.overline,
    '& > a': {
      textDecoration: 'none',
    },
  },
  link: {
    textDecoration: 'none',
    ...theme.typography.overline,
  },
  verticalDivider: {
    borderRight: '1px solid #E3E3E3',
    height: 22,
    margin: '0 15px',
    [theme.breakpoints.down('400')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 10px',
    },
  },
  sponsors: {
    display: 'flex',
    '& button': {
      ...theme.typography.overline,
      color: theme.palette.primary.main,
      padding: 0,
      '& img': {
        display: 'none',
      },
    },
  },
  covidLink: {
    textDecoration: 'none',
    fontFamily: 'MadaBold',
    fontSize: 14,
    color: '#01619B',
    marginRight: 20,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginRight: 15,
    },
  },
  badge: {
    background: '#F8AC24',
    color: '#fff',
    fontSize: 10,
    borderRadius: 2,
    padding: '0 2px',
    left: '-28px',
    position: 'absolute',
  },
  socialWrapper: {
    [theme.breakpoints.down('900')]: {
      display: 'none',
    },
  },
  mt36: {
    marginTop: 36,
  },
}));

const TopBanner = () => {
  const classes = useStyles();
  const isEmbed = isEmbedded();
  const menuLastIndex = MENU.length - 1;
  const clientDetails = useSelector(makeSelectClientDetails());
  const isFetching = useSelector(makeSelectClientDetailsFetching());
  const isFreshworksLoaded = useSelector(makeSelectIsFreshworksLoaded());
  const [footerSiteCopy] = useSiteCopySelector(['footer']);
  const subdomain = getSubdomain();

  if ((subdomain && subdomain !== 'development') || isFetching) return null;

  const handleSupportClick = () => {
    freshworksUtils.show();
  };

  const useAppEmbedStyle =
    isEmbed || _get(clientDetails, 'metadata.useAppEmbedStyle', false);

  return (
    <div
      className={ClassNames(classes.root, {
        [classes.mt36]: useAppEmbedStyle,
      })}
    >
      <div className={classes.container}>
        <div className={classes.socialWrapper}>
          <SocialLinks />
        </div>

        <div className={classes.menuWrapper}>
          <ul className={classes.menu}>
            {MENU.map(({ label, link }, index) => (
              <React.Fragment key={label}>
                <li className={classes.menuItem}>
                  <a href={link} target="_blank" rel="noopener">
                    {label}
                  </a>
                </li>
                {index !== menuLastIndex && (
                  <div className={classes.verticalDivider} />
                )}
              </React.Fragment>
            ))}
          </ul>
        </div>
        <div className={classes.sponsors}>
          {/* <Link to="/landing/covid-19" className={classes.covidLink}>
            <span className={classes.badge}>NEW</span>
            COVID-19 Focus Center
          </Link> */}
          <Link to="/?showcorp=1" className={classes.link}>
            Solutions
          </Link>
          {isFreshworksLoaded && (
            <>
              <div className={classes.verticalDivider} />
              <ButtonBase
                className={classes.link}
                disableRipple
                disableTouchRipple
                onClick={handleSupportClick}
              >
                {_get(footerSiteCopy, 'pageCopy.supportLabel', 'Support')}
              </ButtonBase>
            </>
          )}
          {/* <a
            href="https://solutions.crediblemind.com"
            rel="noopener"
            target="_blank"
            className={classes.link}
          >
            Solutions
          </a> */}
          {/* <SponsorsMenu /> */}
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
