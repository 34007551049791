import React from 'react';

export const Exclusive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19px"
    height="19px"
    viewBox="0 0 19 19"
    version="1.1"
  >
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Mobile-Menu---Default---2023---option-9"
        transform="translate(-251.000000, -757.000000)"
        stroke="currentColor"
        strokeWidth="1.28"
      >
        <g id="Menu" transform="translate(0.000000, 743.000000)">
          <g id="Account" transform="translate(220.000000, 15.000000)">
            <g
              id="interface-user-check"
              transform="translate(32.000000, 0.000000)"
            >
              <polyline
                id="Path"
                points="17 9.80769231 11.6123077 17 8.01615385 14.3061538"
              />
              <circle
                id="Oval"
                cx="5.88461538"
                cy="2.94230769"
                r="2.94230769"
              />
              <path
                d="M3.26923077,15.6923077 L0,15.6923077 L0,13.7307692 C0.00209205206,11.3522732 1.43580733,9.20890714 3.63336935,8.29897911 C5.83093138,7.38905108 8.36018034,7.89150224 10.0430769,9.57230769"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
