import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from './check.svg';
import { OnboardingTopics } from './OnboardingTopics';

const useStyles = makeStyles(() => ({
  gridContainer: {
    margin: '10px 0 30px 0',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
    gridGap: 8,
  },
  checkIcon: {
    position: 'absolute',
    top: '9%',
    right: '9%',
    width: 20,
    height: 20,
  },
}));

export default function TopicItems({
  data,
  selectedTopics,
  clickHandler,
  toolTipMessage,
  isMobile,
  isMaxReached,
}) {
  const classes = useStyles();
  return (
    <div className={classes.gridContainer}>
      <OnboardingTopics
        data={data}
        isInLanding={false}
        isMobile={isMobile}
        selectedTopics={selectedTopics}
        toolTipMessage={toolTipMessage}
        onClick={clickHandler}
        checkIcon={
          <img src={CheckIcon} alt="Selected" className={classes.checkIcon} />
        }
        isMaxReached={isMaxReached}
      />
    </div>
  );
}
