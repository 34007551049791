import { call, put, takeEvery } from 'redux-saga/effects';
import _has from 'lodash/has';
import { getContentfulLocaleFilter } from 'containers/Main/utils';
import client from 'utils/contentfulService';
import { GET_SOURCES } from './constants';
import { processing, getSourcesSuccess, getSourcesFail } from './actions';

function* getSourceFromContentful(payload) {
  const localeFilters = yield call(getContentfulLocaleFilter);
  return yield call(() =>
    client.getEntries({
      content_type: 'source',
      'fields.reviewStatus': 'Accepted',
      order: '-fields.googleSearchResults',
      ...payload,
      ...localeFilters,
    }),
  );
}

function* getSourcesSaga({ payload }) {
  const typeFilter = _has(payload, 'type')
    ? { 'fields.type': payload.type }
    : {};
  const topicFilter = _has(payload, 'topicId')
    ? { 'fields.topics.sys.id[in]': payload.topicId }
    : {};
  const clientFilter = _has(payload, 'clientId')
    ? { 'fields.client.sys.id': payload.clientId }
    : {};
  const searchResultsFilter =
    _has(payload, 'type') && payload.type === 'Client Exclusive'
      ? { 'fields.googleSearchResults[exists]': true }
      : null;
  const limit = _has(payload, 'limit') ? { limit: payload.limit } : {};
  try {
    yield put(processing(true));
    const response = yield call(getSourceFromContentful, {
      ...typeFilter,
      ...topicFilter,
      ...clientFilter,
      ...limit,
      ...searchResultsFilter,
    });

    yield put(getSourcesSuccess({ type: payload.type, data: response }));
  } catch (error) {
    yield put(getSourcesFail(error));
  } finally {
    yield put(processing(false));
  }
}

// Individual exports for testing
export default function* defaultSaga() {
  yield takeEvery(GET_SOURCES, getSourcesSaga);
}
