import React from 'react';

export default (color = '#FFFFFF') => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 15 17"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="streamline-icon-single-neutral-shield@48x48"
      transform="translate(0.39001465 0.39001465)"
    >
      <path
        d="M2.3716 4.52174C2.3716 7.01903 4.4087 9.04348 6.9216 9.04348C9.4345 9.04348 11.4716 7.01903 11.4716 4.52174C11.4716 2.02445 9.4345 0 6.9216 0C4.4087 0 2.3716 2.02445 2.3716 4.52174Z"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 16C12.9288 13.0756 10.1316 11.1293 7 11.1293C3.86844 11.1293 1.07123 13.0756 0 16"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
