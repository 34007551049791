/**
 *
 * PersonalEmailContent
 *
 */

import React, { useEffect, useState } from 'react';
import _classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { toast } from 'react-toastify';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { hidePersonalEmailModal } from 'containers/Main/actions';
import {
  makeSelectClientDetails,
  makeSelectShowLanguageBanner,
} from 'containers/Main/selectors';
import { submitSubscription } from 'containers/Auth/actions';
import {
  makeSelectAuth,
  makeSelectProfile,
  makeSelectSubscriptionResult,
} from 'containers/Auth/selectors';
import useSiteCopySelector from 'components/useSiteCopySelector';
import SubscriptionOptions from 'components/SubscriptionOptions';
import {
  emailRegex,
  getLowerCaseEmailDomain,
  getUserFirstName,
} from 'utils/stringUtils';

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: 'white',
    height: 'min-content',
    maxHeight: '90%',
    borderRadius: 5,
    overflowY: 'auto',
    position: 'absolute',
    [theme.breakpoints.up('md')]: {
      width: 800,
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      width: 995,
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: 'auto',
    },
  },
  paddedWrapper: {
    top: 36,
  },
  contentContainer: {
    padding: '56px 100px 32px',
    [theme.breakpoints.down('xs')]: {
      padding: '32px',
    },
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  bodyWrapper: {
    marginTop: 24,
    [theme.breakpoints.down('xs')]: {
      '& > p': {
        fontSize: '1rem',
      },
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 12,
  },
  input: {
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    padding: '8px 20px',
    border: '1px solid #D8D8D8',
    width: '80%',
    maxWidth: 440,
    ...theme.typography.body2,
    [theme.breakpoints.down('600')]: {
      width: '100%',
    },
  },
  subscriptionWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 12,
  },
  subscriptionContainer: {
    width: '80%',
    maxWidth: 440,
  },
  disclaimerWrapper: {
    marginTop: 24,
  },
  disclaimer: {
    fontFamily: 'Open Sans',
    fontStyle: 'italic',
    fontSize: '0.875rem',
  },
  actionContainer: {
    position: 'relative',
    borderTop: '1px solid #E3E3E3',
    padding: '20px 0 25px',
    display: 'flex',
    justifyContent: 'center',
  },
  skipButton: {
    ...theme.typography.body2,
    color: theme.palette.primary.main,
    height: 43,
    position: 'absolute',
    left: 32,
    '&:hover': {
      opacity: 0.8,
    },
  },
  submitButton: {
    ...theme.typography.button,
    color: '#fff',
    boxShadow: 'none',
    width: 139,
    height: 43,
    borderRadius: 2,
    '&:hover': {
      backgroundColor: theme.palette.primary.secondary,
      opacity: 0.8,
    },
  },
}));

const PersonalEmailContent = ({ type }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const auth = useSelector(makeSelectAuth());
  const profile = useSelector(makeSelectProfile());
  const clientDetails = useSelector(makeSelectClientDetails());
  const subscriptionResult = useSelector(makeSelectSubscriptionResult());
  const showLanguageBanner = useSelector(makeSelectShowLanguageBanner());
  const [siteCopy, accountSiteCopy] = useSiteCopySelector([
    'personal-email-modal',
    'account',
  ]);
  const [email, setEmail] = useState('');
  const [subscription, setSubscription] = useState(
    _get(accountSiteCopy, 'pageCopy.subscription', [])
      .filter(item => item.default)
      .map(item => item.value),
  );

  const workEmailDomains = _get(
    clientDetails,
    'context.requiredEmailDomains',
    [],
  );
  const [error, setError] = useState(false);

  useEffect(() => {
    if (
      !_isEmpty(subscriptionResult) &&
      _get(subscriptionResult, 'error') === false
    ) {
      firebase.updateProfile({
        personalEmail: email,
      });
      dispatch(hidePersonalEmailModal());
    } else if (
      !_isEmpty(subscriptionResult) &&
      _get(subscriptionResult, 'error') === true
    ) {
      toast(_get(siteCopy, 'pageCopy.errorMessage', ''), {
        type: 'error',
      });
    }
  }, [subscriptionResult]);

  const handleSubscriptionChange = value => {
    if (subscription.includes(value)) {
      setSubscription(subscription.filter(item => item !== value));
    } else {
      setSubscription(subscription.concat(value));
    }
  };

  const handleClose = () => {
    dispatch(hidePersonalEmailModal());
  };

  const handleChange = e => {
    setEmail(e.target.value);
    if (!emailRegex.test(e.target.value)) {
      setError(true);
    } else if (error) {
      setError(false);
    }
  };

  const handleSubmit = () => {
    if (
      !workEmailDomains.includes(getLowerCaseEmailDomain(email)) &&
      email !== profile.email
    ) {
      if (!auth.isEmpty) {
        dispatch(
          submitSubscription({ email, subscription, isPersonalEmail: true }),
        );
      }
    } else {
      toast(_get(siteCopy, 'pageCopy.errorMessage', ''), {
        type: 'error',
      });
      setError(true);
    }
  };

  const firstName = getUserFirstName(profile);

  return (
    <Grid
      container
      className={_classNames(classes.wrapper, {
        [classes.paddedWrapper]: showLanguageBanner,
      })}
    >
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} className={classes.contentContainer}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  color="primary"
                  align="center"
                  className={classes.title}
                >
                  {_get(siteCopy, ['pageCopy', type, 'header'], '').replace(
                    '<firstName>',
                    firstName,
                  )}
                </Typography>
              </Grid>
              {!_isEmpty(
                _get(siteCopy, ['pageCopy', type, 'description'], []),
              ) && (
                <Grid item xs={12} className={classes.bodyWrapper}>
                  {_get(siteCopy, ['pageCopy', type, 'description'], []).map(
                    paragraph => (
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="center"
                        key={paragraph}
                      >
                        {paragraph}
                      </Typography>
                    ),
                  )}
                </Grid>
              )}
              <Grid item xs={12} className={classes.inputWrapper}>
                <InputBase
                  type="text"
                  className={classes.input}
                  value={email}
                  onChange={handleChange}
                  placeholder={_get(siteCopy, 'pageCopy.inputPlaceholder')}
                  error={error}
                />
              </Grid>
              <Grid item xs={12} className={classes.subscriptionWrapper}>
                <Box className={classes.subscriptionContainer}>
                  <SubscriptionOptions
                    subscription={subscription}
                    onChange={handleSubscriptionChange}
                    isSmall
                    useDisclaimerLabel
                  />
                </Box>
              </Grid>
              {!_isEmpty(_get(siteCopy, 'pageCopy.disclaimer', [])) && (
                <Grid item xs={12} className={classes.disclaimerWrapper}>
                  {_get(siteCopy, 'pageCopy.disclaimer', []).map(paragraph => (
                    <Typography
                      color="textPrimary"
                      align="center"
                      className={classes.disclaimer}
                      key={paragraph}
                    >
                      {paragraph}
                    </Typography>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.actionContainer}>
            <ButtonBase className={classes.skipButton} onClick={handleClose}>
              {_get(siteCopy, 'pageCopy.skipButton', '')}
            </ButtonBase>
            <Button
              variant="contained"
              disabled={error || !email}
              className={classes.submitButton}
              onClick={handleSubmit}
              color="secondary"
            >
              {_get(siteCopy, 'pageCopy.submitButton', '')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PersonalEmailContent;
