import { getFirebase } from 'react-redux-firebase';
const FirebaseAnalyticsService = {
  addEvent: async (name, props) => {
    const firebase = await getFirebase();
    if (await firebase.analytics.isSupported()) {
      firebase.analytics().logEvent(name, props);
    }
  },
  setUserProfile: async props => {
    const {
      $name = '',
      $last_login = '',
      maskedEmail = '',
      firebaseID = '',
      gender = '',
      age = '',
      socialLogin = '',
      sendResourceUpdates = false,
      $created = '',
      brand = '',
    } = props;
    if (!$name) return;
    const firebase = await getFirebase();
    if (await firebase.analytics.isSupported()) {
      firebase.analytics().setUserProperties({
        name: $name,
        maskedEmail,
        gender,
        byear: age,
        last_login: $last_login,
        firebaseID,
        socialLogin,
        sendResourceUpdates,
        created: $created,
        brand,
      });
    }
  },
};

export default FirebaseAnalyticsService;
