import React from 'react';

export const Assessments = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19px"
    height="19px"
    viewBox="0 0 19 19"
    version="1.1"
  >
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Mobile-Menu---Default---2023---option-9"
        transform="translate(-152.000000, -757.000000)"
        stroke="currentColor"
        strokeWidth="1.275"
      >
        <g id="Menu" transform="translate(0.000000, 743.000000)">
          <g id="Assessments" transform="translate(135.000000, 15.000000)">
            <g id="Assessments-Icon" transform="translate(18.000000, 0.000000)">
              <path
                d="M8.5,17 C13.1944204,17 17,13.1944204 17,8.5 C17,3.80557963 13.1944204,0 8.5,0 C3.80557963,0 0,3.80557963 0,8.5"
                id="Oval"
                transform="translate(8.500000, 8.500000) rotate(-180.000000) translate(-8.500000, -8.500000) "
              />
              <polyline
                id="Path-2"
                points="3.64285714 8.85456194 6.83219723 12.1203265 12.5028166 6.07142857"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
