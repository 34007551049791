/*
 *
 * Topics reducer
 *
 */

import update from 'immutability-helper';
import { handleActions } from 'redux-actions';
import {
  getTopicsSuccess,
  getAllTopicsSuccess,
  getSavedTopicsResult,
  saveTopic,
  saveTopicResult,
  processing,
  sectionProcessing,
} from './actions';

export const initialState = {
  topics: {},
  allTopics: [],
  allTopicsLists: [],
  savedTopics: [],
  saveResult: {},
  processing: false,
  menuTopics: {},
  filterTopics: {},
  sectionProcessing: false,
};

const topicsReducer = handleActions(
  {
    [processing](state, action) {
      return update(state, { processing: { $set: action.payload } });
    },
    [sectionProcessing](state, action) {
      return update(state, { sectionProcessing: { $set: action.payload } });
    },
    [getTopicsSuccess](state, action) {
      const { type } = action.payload;
      if (type === 'menu') {
        return update(state, { menuTopics: { $set: action.payload.data } });
      }
      if (type === 'home-filter') {
        return update(state, { filterTopics: { $set: action.payload.data } });
      }
      return update(state, { topics: { $set: action.payload.data } });
    },
    [getAllTopicsSuccess](state, action) {
      return update(state, {
        allTopics: { $set: action.payload.topics },
        allTopicsLists: { $set: action.payload.lists },
      });
    },
    [getSavedTopicsResult](state, action) {
      return update(state, { savedTopics: { $set: action.payload } });
    },
    [saveTopic](state) {
      return update(state, { saveResult: { $set: {} } });
    },
    [saveTopicResult](state, action) {
      return update(state, { saveResult: { $set: action.payload } });
    },
  },
  initialState,
);

export default topicsReducer;
