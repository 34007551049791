import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import _get from 'lodash/get';
import { setLeavingModal } from 'containers/Main/actions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useSiteCopySelector from 'components/useSiteCopySelector';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: '10000 !important', // higher than remind overlay
  },
  paper: {
    borderRadius: '5px !important',
    [theme.breakpoints.up('800')]: {
      width: 750,
      maxWidth: 750,
    },
    [theme.breakpoints.down('799')]: {
      margin: '12px',
    },
  },

  contentRoot: {
    margin: 0,
    padding: '10px 50px 50px',
    [theme.breakpoints.down('800')]: {
      padding: '10px 24px 30px',
    },
    '& h4': {
      padding: '0 55px',
      [theme.breakpoints.down('800')]: {
        padding: 0,
        fontSize: '18px',
      },
    },
  },

  button: {
    width: '50%',
    fontWeight: 600,
    marginTop: 30,
    padding: '20px 24px',
    borderRadius: 2,
    fontSize: 14,
    fontFamily: 'PoppinsRegular',
    [theme.breakpoints.down('800')]: {
      width: '100%',
      padding: '12px 16px',
      marginTop: '10px',
    },
  },

  buttonSpace: {
    columnGap: 10,
    [theme.breakpoints.down('800')]: {
      flexDirection: 'column',
    },
  },
}));

export default function InActivityModalConfirmation({ clickHandler }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [siteCopy] = useSiteCopySelector(['inactivity-modal']);
  const { pageHeader, callToAction, pageCopy } = siteCopy;
  const title = pageHeader;
  const message = _get(
    pageCopy,
    'message',
    'You have been inactive. Press continue to continue your session',
  );

  const handleClose = () =>
    dispatch(
      setLeavingModal({
        visible: false,
        title: null,
        url: null,
      }),
    );

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="inactivity-reminder-dialog"
      open
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogContent classes={{ root: classes.contentRoot }}>
        {title && (
          <Typography
            variant="h3"
            component="h3"
            align="center"
            color="error"
            gutterBottom
          >
            {title}
          </Typography>
        )}

        <Typography variant="h4" color="textPrimary" align="center">
          {message}
        </Typography>

        <Box
          display="flex"
          flex="1"
          justifyContent="center"
          className={classes.buttonSpace}
        >
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={clickHandler}
          >
            {callToAction}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
