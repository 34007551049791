/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { getImageFile, fallbackImage } from 'utils/stringUtils';
import StyledLink from 'components/StyledLink';
import useWindowSize from 'components/useWindowSize';
import { makeSelectTextDirection } from 'containers/Main/selectors';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  lists: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 28,
  },
  image: {
    width: '100%',
    height: ({ imageHeight }) => imageHeight,
    marginBottom: 6,
    objectFit: 'cover',
  },
  listItem: {
    width: 246,
    height: ({ listItemHeight }) => listItemHeight,
    marginRight: 21,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('1200')]: {
      width: 230,
    },
    [theme.breakpoints.down('1150')]: {
      width: 217,
    },
    [theme.breakpoints.down('1100')]: {
      width: 204,
    },
  },
  imageWrapper: {
    width: '100%',
  },
  nameWrapper: {
    width: '100%',
  },
  name: {
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '2.7475rem' /* Fallback for non-webkit */,
    direction: ({ textDirection }) => textDirection,
  },
}));

function BlogsNewsLatestItem({ clickHandler, data, type }) {
  const { height } = useWindowSize();
  const textDirection = useSelector(makeSelectTextDirection());

  let listItemHeight = 190;
  let imageHeight = 139;
  if (height < 700) {
    listItemHeight = 160;
    imageHeight = 110;
  }
  const classes = useStyles({
    listItemHeight,
    imageHeight,
    textDirection,
  });

  const imageSrc = getImageFile(data);
  const { slug, name } = data;

  return (
    <StyledLink link={`/${type}/${slug}`}>
      <div className={classes.listItem}>
        <div className={classes.imageWrapper}>
          <img
            src={imageSrc}
            alt={name}
            className={classes.image}
            onError={e => {
              e.target.src = fallbackImage;
            }}
            onClick={() => clickHandler(name, 'image')}
          />
        </div>
        <div
          className={classes.nameWrapper}
          onClick={() => clickHandler(name, 'text')}
        >
          <Typography
            className={classes.name}
            variant="overline"
            color="textPrimary"
          >
            {name}
          </Typography>
        </div>
      </div>
    </StyledLink>
  );
}

BlogsNewsLatestItem.propTypes = {
  clickHandler: PropTypes.func,
  data: PropTypes.object,
  type: PropTypes.string,
};

export default BlogsNewsLatestItem;
