import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withSizes } from 'react-sizes';
import _classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BigLogo from 'components/NavBarMenu/images/big-logo.svg';
import Mixpanel from 'utils/mixpanelService';
import SearchInput from './SearchInput';
import SearchIcon from '../images/icon_search2.svg';

const useStyles = makeStyles(theme => ({
  wrapper: {
    color: theme.palette.text.secondary,
    height: 72,
    boxShadow: '0 0 7px rgba(0,0,0,.1)!important',
    '-moz-box-shadow': '0 0 7px rgba(0,0,0,.1)!important',
    '-webkit-box-shadow': '0 0 7px rgba(0,0,0,.1)!important',
    transition: 'all .4s ease-in-out',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 9,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 80,
    },
  },
  miniWrapper: {
    height: 54,
  },
  toolbar: {
    minHeight: 54,
    height: '100%',
    width: '80%',
    maxWidth: 1080,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  logoRoot: {
    height: '47%',
    zIndex: 10,
    [theme.breakpoints.down('1320')]: {
      maxWidth: 180,
      height: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50%',
      height: 'auto',
    },
  },
  logo: {
    height: '100%',
    [theme.breakpoints.down('1320')]: {
      width: '100%',
      height: 'auto',
    },
  },
  linksRoot: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    opacity: 1,
    transition: 'all 1s cubic-bezier(.77,0,.175,1)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: 150,
    },
  },
  linksRootHidden: {
    top: '50%',
    zIndex: -1,
    opacity: 0,
  },
  searchContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    maxWidth: 643,
    width: '100%',
    opacity: 1,
    transition: 'all 1s cubic-bezier(.77,0,.175,1)',
  },
  searchContainerHidden: {
    opacity: 0,
    zIndex: -1,
    top: '-50%',
  },
  lists: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'flex-end',
    },
  },
  listItem: {
    ...theme.typography.body2,
    marginRight: 22,
    color: '#5C5C5C',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('1320')]: {
      marginRight: 10,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    background: '#FFFFFF',
    maxHeight: props => props.menuHeight || '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    boxShadow: '0 2px 5px rgba(0,0,0,.1)',
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
      width: '100%',
      paddingBottom: '20%',
      display: 'block',
    },
    [theme.breakpoints.down('380')]: {
      paddingBottom: '25%',
    },
    padding: 20,
    borderTopWidth: 3,
    borderTopStyle: 'solid',
    borderTopColor: '#FFA900',
  },
  menuLink: {
    color: 'rgba(0,0,0,.6)',
    lineHeight: '2em',
    fontSize: 13,
    fontFamily: 'PoppinsBold',
    fontWeight: '700',
    transition: 'all .4s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
  miniMenuLink: {
    fontSize: 12,
  },
  canHide: {
    [theme.breakpoints.down('1140')]: {
      display: 'none',
    },
  },
  optionLink: {
    padding: '6px 20px',
    width: 200,
    color: 'rgba(0,0,0,.6)',
    lineHeight: '2em',
    fontSize: 13,
    fontFamily: 'PoppinsBold',
    fontWeight: '700',
    textDecoration: 'none',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: '#F9F9F9',
      color: '#949494',
    },
  },
  miniOptionLink: {
    fontSize: 12,
  },
  searchIcon: {
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    filter:
      'invert(34%) sepia(0%) saturate(7457%) hue-rotate(9deg) brightness(101%) contrast(60%)',
    '&:hover': {
      filter:
        'invert(60%) sepia(60%) saturate(3553%) hue-rotate(178deg) brightness(100%) contrast(91%)',
    },
    '& > img': {
      height: 17,
      width: 17,
    },
  },
}));

function NavBar({ isMobile }) {
  const classes = useStyles();
  const [isOnTop, setOnTop] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopoverId, setOpenPopoverId] = useState();
  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset === 0) {
        setOnTop(true);
      } else if (isOnTop && window.pageYOffset !== 0) {
        setOnTop(false);
      }
    };

    return () => {
      window.onscroll = null;
    };
  }, []);

  const clearRef = () => {
    setAnchorEl(null);
    setOpenPopoverId(null);
  };

  const handleMouseEnter = (event, id) => {
    if (openPopoverId === id) {
      clearRef();
    } else {
      setAnchorEl(event.currentTarget);
      setOpenPopoverId(id);
    }
  };

  const handleScrollTo = hash => {
    const $anchor = document.getElementById(hash);
    if ($anchor) {
      const offsetTop =
        $anchor.getBoundingClientRect().top + window.pageYOffset;
      window.scroll({
        top: offsetTop - 54,
        behavior: 'smooth',
      });

      Mixpanel.track('Solutions Home Page - Navigation Clicked', {
        section: hash,
        source: 'navbar',
      });
    }
  };

  const handlePageExit = url => {
    Mixpanel.track('Solutions Home Page - Exit Page', {
      exitPage: url,
      source: 'navbar',
    });
  };

  const renderOptions = options =>
    options.map(option => (
      <a
        key={option.title}
        href={option.link}
        className={_classNames({
          [classes.optionLink]: true,
          [classes.miniOptionLink]: !isOnTop,
        })}
        onClick={() => handlePageExit(option.link)}
      >
        {option.title}
      </a>
    ));

  const menu = [
    {
      title: 'Our Markets',
      options: [
        {
          title: 'Health Plans',
          link: 'https://solutions.crediblemind.com/health-plans',
        },
        {
          title: 'Public Health',
          link: 'https://solutions.crediblemind.com/public-health',
        },
        {
          title: 'Employers',
          link: 'https://solutions.crediblemind.com/employers',
        },
        {
          title: 'Health Systems',
          link: 'https://solutions.crediblemind.com/providers',
        },
      ],
    },
    {
      title: 'Our Solutions',
      options: [
        {
          title: 'SelfCare',
          link: 'https://solutions.crediblemind.com/selfcare',
        },
        {
          title: 'SmartScreen',
          link: 'https://solutions.crediblemind.com/smartscreen',
        },
      ],
    },
    {
      title: 'Resources',
      options: [
        {
          title: 'News & Insights',
          link: 'https://solutions.crediblemind.com/blog',
        },
        {
          title: 'Videos',
          link: 'https://solutions.crediblemind.com/video-directory',
        },
        {
          title: 'Webinars',
          link: 'https://solutions.crediblemind.com/webinars',
        },
      ],
    },
    {
      title: 'About',
      options: [
        {
          title: 'Company & Leadership',
          link: 'https://solutions.crediblemind.com/about-us',
        },
        {
          title: 'Contact Us',
          link: 'https://solutions.crediblemind.com/connect',
        },
      ],
    },
    {
      title: 'Schedule a Demo',
      scrollTo: 'cta',
      canHide: true,
    },
  ];

  return (
    <AppBar
      className={_classNames({
        [classes.wrapper]: true,
        [classes.miniWrapper]: !isOnTop,
      })}
      position="relative"
    >
      <Toolbar className={classes.toolbar} disableGutters>
        <Link to="/" className={classes.logoRoot}>
          <img src={BigLogo} alt="CredibleMind" className={classes.logo} />
        </Link>
        <div
          className={_classNames({
            [classes.searchContainer]: true,
            [classes.searchContainerHidden]: !showSearch,
          })}
        >
          <SearchInput onClose={() => setShowSearch(false)} />
        </div>
        <div
          className={_classNames({
            [classes.linksRoot]: true,
            [classes.linksRootHidden]: showSearch,
          })}
        >
          {!isMobile && (
            <ul className={classes.lists} onMouseLeave={clearRef}>
              {menu.map(({ title, options, scrollTo, canHide }) => {
                const hasNoPopover = _isEmpty(options);
                return (
                  <li
                    key={title}
                    id={title}
                    className={_classNames({
                      [classes.listItem]: true,
                      [classes.canHide]: canHide,
                    })}
                    onMouseEnter={e => handleMouseEnter(e, title)}
                  >
                    {hasNoPopover ? (
                      <Typography
                        className={_classNames({
                          [classes.menuLink]: true,
                          [classes.miniMenuLink]: !isOnTop,
                        })}
                        onClick={() => handleScrollTo(scrollTo)}
                      >
                        {title}
                      </Typography>
                    ) : (
                      <>
                        <Typography
                          className={_classNames({
                            [classes.menuLink]: true,
                            [classes.miniMenuLink]: !isOnTop,
                          })}
                        >
                          {title}
                          <ExpandMoreIcon fontSize="small" />
                        </Typography>

                        <Popper
                          open={openPopoverId === title}
                          anchorEl={anchorEl}
                          className={classes.popover}
                          placement="bottom"
                          disablePortal
                          modifiers={{
                            flip: {
                              enabled: false,
                            },
                            preventOverflow: {
                              enabled: true,
                              boundariesElement: 'window',
                            },
                          }}
                        >
                          <div className={classes.paper}>
                            {renderOptions(options)}
                          </div>
                        </Popper>
                      </>
                    )}
                  </li>
                );
              })}
              <li
                key="search"
                id="search"
                className={classes.listItem}
                onMouseEnter={e => handleMouseEnter(e, 'search')}
              >
                <button
                  className={classes.searchIcon}
                  onClick={() => setShowSearch(true)}
                  type="button"
                >
                  <img src={SearchIcon} alt="Search" />
                </button>
              </li>
            </ul>
          )}
          {isMobile && (
            <>
              <ul className={classes.lists} onMouseLeave={clearRef}>
                <li key="search" id="search" className={classes.listItem}>
                  <button
                    className={classes.searchIcon}
                    onClick={() => setShowSearch(true)}
                    type="button"
                  >
                    <img src={SearchIcon} alt="Search" />
                  </button>
                </li>
              </ul>
            </>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 970,
});

NavBar.propTypes = {
  isMobile: PropTypes.bool,
};

export default withSizes(mapSizesToProps)(NavBar);
