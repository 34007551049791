import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { makeSelectAuth } from 'containers/Auth/selectors';
import { setClinicMode } from 'containers/Main/actions';
import {
  makeSelectClientDetails,
  makeSelectClinicMode,
} from 'containers/Main/selectors';
import { getLocalData, isPublicMode } from 'utils/localDataStore';

function useClinicMode() {
  const auth = useSelector(makeSelectAuth());
  const clientDetails = useSelector(makeSelectClientDetails());
  const clinicMode = useSelector(makeSelectClinicMode());
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const userId = auth.uid || getLocalData('userIdTemp');
  const isPublicDevice = isPublicMode();

  useEffect(() => {
    if (auth.isLoaded) {
      const doesClientSupportClinicMode = _get(
        clientDetails,
        'showAtTheClinicButton',
      );
      if (doesClientSupportClinicMode && !_isEmpty(userId)) {
        firebase
          .firestore()
          .collection('users_clinic_mode')
          .doc(userId)
          .get()
          .then(snapshot => {
            const data = snapshot.data();
            dispatch(setClinicMode(data.clinicMode));
          })
          .catch(() => {
            dispatch(setClinicMode(false));
          });
      } else {
        dispatch(setClinicMode(isPublicDevice));
      }
    }
  }, [dispatch, auth, clientDetails, firebase, userId, isPublicDevice]);

  useEffect(() => {
    if (!_isNil(clinicMode) && !_isEmpty(userId)) {
      firebase
        .firestore()
        .collection('users_clinic_mode')
        .doc(userId)
        .set({ clinicMode });
    }
  }, [clinicMode, firebase, userId]);
}

export default useClinicMode;
