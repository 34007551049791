import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { setLocalData, getLocalData } from 'utils/localDataStore';
import {
  getClientDetails,
  setRegion,
  updateBrand,
  clearClientDetails,
  setLanguage,
  showRegionBanner,
} from 'containers/Main/actions';
import {
  makeSelectLanguage,
  makeSelectLocales,
  makeSelectClientDetails,
} from 'containers/Main/selectors';
import { clearLandingInfo } from 'containers/ClientLanding/actions';
import { makeSelectProfile } from 'containers/Auth/selectors';
import Config from 'utils/getEnvConfig';
import { getSubdomain, isLocalStorageAllowed } from 'utils/stringUtils';

const withPageBranding = Component => props => {
  const patchedBrand = useRef();
  const clientDetailsFetched = useRef();
  const history = useHistory();
  const location = useLocation();
  const profile = useSelector(makeSelectProfile());
  const language = useSelector(makeSelectLanguage());
  const locales = useSelector(makeSelectLocales());
  const clientDetails = useSelector(makeSelectClientDetails());
  const { search, pathname } = location;
  const searchObject = qs.parse(search, {
    ignoreQueryPrefix: true,
  });
  const dispatch = useDispatch();
  const subdomain = getSubdomain();
  const demoBrands = Config.DEMO_BRANDS || [];
  const isProdPublicSite = ['crediblemind.com', 'crediblemind.net'].includes(
    window.location.host,
  );

  useEffect(() => {
    if (!_isEmpty(_get(searchObject, 'brand')) && !subdomain) {
      const { brand } = searchObject;
      setLocalData('brand', brand, {
        expires: 365,
      });

      // mixpanel.init(brand);
      if (brand && brand !== 'none') {
        dispatch(clearLandingInfo());
        dispatch(getClientDetails(brand));
        clientDetailsFetched.current = brand;
      } else {
        dispatch(clearLandingInfo());
        dispatch(clearClientDetails());
        clientDetailsFetched.current = 'none';
      }

      dispatch(setRegion(''));
      dispatch(setLanguage(''));
      dispatch(showRegionBanner(false));
      if (isLocalStorageAllowed()) {
        localStorage.removeItem('region');
      }
    } else {
      const brand = getLocalData('brand');
      if (
        demoBrands.includes(brand) &&
        (!subdomain || subdomain === 'development') &&
        isProdPublicSite
      ) {
        setLocalData('brand', 'none');
        dispatch(clearClientDetails());
        clientDetailsFetched.current = 'none';
        return;
      }
      if (brand && brand !== 'none') {
        dispatch(getClientDetails(!brand ? 'none' : brand));
        clientDetailsFetched.current = !brand ? 'none' : brand;
      } else {
        dispatch(clearClientDetails());
        clientDetailsFetched.current = 'none';
      }
    }
    if (_get(window, 'xprops.client', false)) {
      const {
        xprops: { client },
      } = window;
      setLocalData('brand', client, {
        expires: 365,
      });
      // mixpanel.init(client);
      dispatch(clearLandingInfo());
      dispatch(getClientDetails(client));
      clientDetailsFetched.current = client;
    }
  }, []);

  useEffect(() => {
    if (!_isEmpty(clientDetails)) {
      const clientConfigured = _get(clientDetails, 'clientConfigured');
      const redirectToBrandUrl =
        isProdPublicSite &&
        (clientConfigured ||
          clientConfigured === null ||
          clientConfigured === undefined);
      if (!_isEmpty(_get(searchObject, 'brand')) && !subdomain) {
        const { brand, ...params } = searchObject;
        // mixpanel.init(brand);
        if (brand && brand !== 'none') {
          if (redirectToBrandUrl && !demoBrands.includes(brand)) {
            window.location.href = `https://${brand}.crediblemind.com${pathname}${
              !_isEmpty(params) ? `?${qs.stringify(params)}` : ''
            }`;
          }
        }
      } else {
        const brand = getLocalData('brand');
        if (brand && brand !== 'none') {
          if (!subdomain && redirectToBrandUrl && !demoBrands.includes(brand)) {
            window.location.href = `https://${brand}.crediblemind.com${pathname}${
              !_isEmpty(searchObject) ? `?${qs.stringify(searchObject)}` : ''
            }`;
          }
        }
      }
    }
  }, [clientDetails]);

  useEffect(() => {
    if (profile.isLoaded && _isEmpty(_get(window, 'xprops.client'))) {
      const emailDomain = profile.email ? profile.email.split('@')[1] : '';
      const shouldEnforceBrand = Object.keys(Config.DOMAINS || {}).filter(
        brand =>
          emailDomain !== '' &&
          Config.DOMAINS[brand].includes(emailDomain.toLowerCase()),
      );
      const enforceBrand =
        shouldEnforceBrand.length > 0 ? shouldEnforceBrand[0] : null;
      if (!_isEmpty(_get(searchObject, 'brand'))) {
        const { brand, ...params } = searchObject;

        if (
          !profile.isEmpty &&
          patchedBrand.current !== brand &&
          profile.brand !== brand
        ) {
          patchedBrand.current = brand;
          dispatch(updateBrand({ brand, email: profile.email }));
        }

        history.replace(
          `${pathname}${!_isEmpty(params) ? `?${qs.stringify(params)}` : ''}`,
        );
      } else if (enforceBrand && !profile.isEmpty) {
        if (clientDetailsFetched.current !== enforceBrand) {
          setLocalData('brand', enforceBrand, {
            expires: 365,
          });
          dispatch(clearLandingInfo());
          dispatch(getClientDetails(enforceBrand));
          clientDetailsFetched.current = enforceBrand;
        }
        if (profile.brand !== enforceBrand) {
          dispatch(updateBrand({ brand: enforceBrand, email: profile.email }));
        }
      } else if (
        !profile.isEmpty &&
        profile.brand !== clientDetailsFetched.current
      ) {
        const cookieBrand = getLocalData('brand');
        if (subdomain && profile.brand !== subdomain) {
          patchedBrand.current = subdomain;
          setLocalData('brand', subdomain, { expires: 365 });
          dispatch(updateBrand({ brand: subdomain, email: profile.email }));
        } else if (cookieBrand !== profile.brand) {
          setLocalData('brand', profile.brand, { expires: 365 });
        } else if (profile.brand && profile.brand !== 'none') {
          dispatch(getClientDetails(!profile.brand ? 'none' : profile.brand));
          clientDetailsFetched.current = !profile.brand
            ? 'none'
            : profile.brand;
        } else {
          dispatch(clearClientDetails());
          clientDetailsFetched.current = 'none';
        }
      } else if (!clientDetailsFetched.current) {
        const brand = getLocalData('brand');
        if (brand && brand !== 'none') {
          dispatch(getClientDetails(!brand ? 'none' : brand));
          clientDetailsFetched.current = !brand ? 'none' : brand;
        } else {
          dispatch(clearClientDetails());
          clientDetailsFetched.current = 'none';
        }
      }
    }
  }, [profile]);

  // if subdomain, set firebase and cookie
  useEffect(() => {
    if (subdomain && subdomain !== 'development') {
      setLocalData('brand', subdomain, { expires: 365 });
      clientDetailsFetched.current = subdomain;
      dispatch(getClientDetails(subdomain));
      if (
        profile.isLoaded &&
        !profile.isEmpty &&
        _get(profile, 'brand') !== subdomain
      ) {
        patchedBrand.current = subdomain;
        dispatch(updateBrand({ brand: subdomain, email: profile.email }));
      }
    }
  }, [subdomain]);

  useEffect(() => {
    const brand = getLocalData('brand');
    if (brand && brand !== 'none') {
      dispatch(getClientDetails(brand));
    }
  }, [locales, language]);

  return <Component {...props} />;
};

export default withPageBranding;
