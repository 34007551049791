import React from 'react';

export default (color = '#FFFFFF') => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    enableBackground="new 0 0 512 512"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="m340.636 307.787-120.5-80.334a14.999 14.999 0 0 0-23.32 12.481v160.667a15 15 0 0 0 23.32 12.479l120.5-80.333a14.998 14.998 0 0 0 0-24.96zm-113.82 64.787V267.962l78.458 52.306-78.458 52.306z"
        fill={color}
        opacity="1"
        data-original="#000000"
        className=""
      ></path>
      <path
        d="M497 128.533H15c-8.284 0-15 6.716-15 15V497c0 8.284 6.716 15 15 15h482c8.284 0 15-6.716 15-15V143.533c0-8.284-6.716-15-15-15zM482 482H30V158.533h452V482zM448.801 64.268h-385.6c-8.284 0-15 6.716-15 15s6.716 15 15 15h385.6c8.284 0 15-6.716 15-15s-6.716-15-15-15zM400.6 0H111.4c-8.284 0-15 6.716-15 15s6.716 15 15 15h289.2c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
        fill={color}
        opacity="1"
        data-original="#000000"
        className=""
      ></path>
    </g>
  </svg>
);
