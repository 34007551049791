/*
 *
 * Account reducer
 *
 */
import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import {
  processing,
  updateAccount,
  updateAccountResult,
  changePassword,
  changePasswordResult,
  toggleContentfulTools,
  toggleContentfulToolsResult,
  getDetailedUserHistoryResult,
} from './actions';

export const initialState = {
  processing: false,
  updateAccountResult: {},
  changePasswordResult: {},
  contentfulToolsResult: {},
  userHistory: [],
};
const accountReducer = handleActions(
  {
    [processing](state, action) {
      return update(state, { processing: { $set: action.payload } });
    },
    [updateAccount](state) {
      return update(state, { updateAccountResult: { $set: {} } });
    },
    [updateAccountResult](state, action) {
      return update(state, { updateAccountResult: { $set: action.payload } });
    },
    [changePassword](state) {
      return update(state, { changePasswordResult: { $set: {} } });
    },
    [changePasswordResult](state, action) {
      return update(state, { changePasswordResult: { $set: action.payload } });
    },
    [toggleContentfulTools](state) {
      return update(state, { contentfulToolsResult: { $set: {} } });
    },
    [toggleContentfulToolsResult](state, action) {
      return update(state, { contentfulToolsResult: { $set: action.payload } });
    },
    [getDetailedUserHistoryResult](state, action) {
      return update(state, { userHistory: { $set: action.payload } });
    },
  },
  initialState,
);

export default accountReducer;
