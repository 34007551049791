import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';

const useStyles = makeStyles({
  root: {
    position: 'sticky',
    top: 0,
    zIndex: 999,
    background: 'white',
    boxShadow: '0 0 7px rgba(0,0,0,.1)!important',
    '-moz-box-shadow': '0 0 7px rgba(0,0,0,.1)!important',
    '-webkit-box-shadow': '0 0 7px rgba(0,0,0,.1)!important',
    transition: 'all .4s ease-in-out',
  },
});

function StickyHeader({ children, isClientAdmin }) {
  const classes = useStyles();

  return (
    <AppBar
      className={classes.root}
      id={isClientAdmin ? 'client-admin-header' : 'app-header'}
    >
      {children}
    </AppBar>
  );
}

export default StickyHeader;
