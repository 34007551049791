import { createSelector } from 'reselect';

/**
 * Direct selector to the assessment state domain
 */

const selectAssessmentDomain = state => state.assessment;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Assessment
 */

const makeSelectProcessing = () =>
  createSelector(
    selectAssessmentDomain,
    substate => substate.processing,
  );
const makeSelectAnswersProcessing = () =>
  createSelector(
    selectAssessmentDomain,
    substate => substate.answersProcessing,
  );
const makeSelectAssessment = () =>
  createSelector(
    selectAssessmentDomain,
    substate => substate.data,
  );
const makeSelectAnswers = () =>
  createSelector(
    selectAssessmentDomain,
    substate => substate.answers,
  );
const makeSelectAnswersSource = () =>
  createSelector(
    selectAssessmentDomain,
    substate => substate.answersSource,
  );
const makeSelectAnswersError = () =>
  createSelector(
    selectAssessmentDomain,
    substate => substate.answersError,
  );
const makeSelectPutAssessmentResult = () =>
  createSelector(
    selectAssessmentDomain,
    substate => substate.putAssessmentResult,
  );
const makeSelectAssessmentResult = () =>
  createSelector(
    selectAssessmentDomain,
    substate => substate.assessmentResult,
  );
const makeSelectAssessmentReport = () =>
  createSelector(
    selectAssessmentDomain,
    substate => substate.assessmentReport,
  );
const makeSelectSendAssessmentResult = () =>
  createSelector(
    selectAssessmentDomain,
    substate => substate.sendEmailResult,
  );
const makeSelectTakenInSession = () =>
  createSelector(
    selectAssessmentDomain,
    substate => substate.takenInSession,
  );
const makeSelectMoveAnonymousAssessmentsResult = () =>
  createSelector(
    selectAssessmentDomain,
    substate => substate.moveAnonymousAssessmentsResult,
  );
const makeSelectShowSMSDialog = () =>
  createSelector(
    selectAssessmentDomain,
    substate => substate.showSMSDialog,
  );
const makeSelectAccessCodeCopied = () =>
  createSelector(
    selectAssessmentDomain,
    substate => substate.accessCodeCopied,
  );
const makeSelectCustomAnswers = () =>
  createSelector(
    selectAssessmentDomain,
    substate => substate.customAnswers,
  );

export {
  makeSelectAssessment,
  makeSelectProcessing,
  makeSelectAnswersProcessing,
  makeSelectAnswers,
  makeSelectAnswersSource,
  makeSelectAnswersError,
  makeSelectPutAssessmentResult,
  makeSelectAssessmentResult,
  makeSelectAssessmentReport,
  makeSelectSendAssessmentResult,
  makeSelectTakenInSession,
  makeSelectMoveAnonymousAssessmentsResult,
  makeSelectShowSMSDialog,
  makeSelectAccessCodeCopied,
  makeSelectCustomAnswers,
};
