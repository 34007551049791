/**
 *
 * ErrorBoundary
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { makeSelectAuth } from 'containers/Auth/selectors';
import ErrorState from 'components/ErrorState';
import { captureMessage } from '@sentry/react';
import Mixpanel from 'utils/mixpanelService';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidCatch(error) {
    const { auth } = this.props;
    captureMessage(`Page Error | ${error}`, {
      user: {
        signedIn: !auth.isEmpty,
        id: auth.uid,
      },
    });

    const match = this.props.location.pathname.match(
      /\/assessments\/([a-z0-9-]+)\/page[0-9]+/,
    );
    if (match) {
      Mixpanel.track('Assessment Question Page - Internal App Error', {
        assessmentSlug: match[1],
      });
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <ErrorState />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  history: PropTypes.object,
  location: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  auth: makeSelectAuth(),
});

const withConnect = connect(mapStateToProps);

export default compose(
  withRouter,
  withConnect,
)(ErrorBoundary);
