/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-curly-brace-presence */
/**
 *
 * ErrorState
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import AppContainer from 'components/AppContainer';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import { useHistory } from 'react-router-dom';
import Colors from 'utils/colors';
import { isEmbedded } from 'utils/embedded';
import _get from 'lodash/get';

const styles = theme => ({
  verticalDivider: {
    height: '100%',
    width: 2,
    backgroundColor: Colors.primary,
  },
  mainWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  mainText: {
    fontSize: 30,
    fontWeight: 600,
  },
  link: {
    color: theme.palette.primary.secondary,
  },
});

/* eslint-disable react/prefer-stateless-function */
function ErrorState({ classes }) {
  const history = useHistory();
  const handleReturnToHomepage = () => {
    if (
      typeof _get(window, 'xprops.onRouteChange', '') === 'function' &&
      isEmbedded()
    ) {
      window.xprops.onRouteChange('');
    } else {
      history.push('/');
    }
  };
  return (
    <AppContainer justify="center">
      <Grid item xs={12} className="page-not-found">
        <Grid container direction="row">
          <Grid item xs={12} sm={5} className={classes.mainWrapper}>
            <Typography
              variant="h1"
              className={classes.mainText}
              color="primary"
              align="center"
            >
              {`It's Us, Not You:`}
            </Typography>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={1}>
              <div className={classes.verticalDivider} />
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={5}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" color="primary">
                  {`Something went wrong. We've been informed and we're working on it.`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <a onClick={handleReturnToHomepage} className={classes.link}>
          Back To Home Page
        </a>
      </Grid>
    </AppContainer>
  );
}

ErrorState.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(styles)(ErrorState);
