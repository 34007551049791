export const PROCESSING = 'app/Main/processing';
export const GET_CLIENT_DETAILS = 'app/Main/GET_CLIENT_DETAILS';
export const GET_CLIENT_DETAILS_SUCCESS = 'app/Main/GET_CLIENT_DETAILS_SUCCESS';
export const GET_CLIENT_DETAILS_FAIL = 'app/Main/GET_CLIENT_DETAILS_FAIL';
export const SHOW_ABOUT = 'app/Main/SHOW_ABOUT';
export const SHOW_TERMS_OF_USE = 'app/Main/SHOW_TERMS_OF_USE';
export const SHOW_PRIVACY_POLICY = 'app/Main/SHOW_PRIVACY_POLICY';
export const SHOW_FEEDBACK = 'app/Main/SHOW_FEEDBACK';
export const GET_FOOTER = 'app/Main/GET_FOOTER';
export const GET_FOOTER_SUCCESS = 'app/Main/GET_FOOTER_SUCCESS';
export const GET_HOME_HERO = 'app/Main/GET_HOME_HERO';
export const GET_HOME_HERO_SUCCESS = 'app/Main/GET_HOME_HERO_SUCCESS';
export const SEND_MAIL_PROCESSING = 'app/Main/SEND_MAIL_PROCESSING';
export const SEND_MAIL = 'app/Main/SEND_MAIL';
export const SEND_MAIL_SUCCESS = 'app/Main/SEND_MAIL_SUCCESS';
export const GET_HOME_HEADERS = 'app/Main/GET_HOME_HEADERS';
export const GET_HOME_HEADERS_SUCCESS = 'app/Main/GET_HOME_HEADERS_SUCCESS';
export const GET_FAVORITES_HEADERS = 'app/Main/GET_FAVORITES_HEADERS';
export const GET_FAVORITES_HEADERS_SUCCESS =
  'app/Main/GET_FAVORITES_HEADERS_SUCCESS';
export const GET_SITE_COPY = 'app/Main/GET_SITE_COPY';
export const GET_SITE_COPY_SUCCESS = 'app/Main/GET_SITE_COPY_SUCCESS';
export const GET_SITE_CONFIG = 'app/Main/GET_SITE_CONFIG';
export const GET_SITE_CONFIG_SUCCESS = 'app/Main/GET_SITE_CONFIG_SUCCESS';
export const CLIENT_DETAILS_FETCHING = 'app/Main/CLIENT_DETAILS_FETCHING';
export const GET_PROGRAMS_BY_TOPIC = 'app/Main/GET_PROGRAMS_BY_TOPIC';
export const GET_PROGRAMS_BY_TOPIC_SUCCESS =
  'app/Main/GET_PROGRAMS_BY_TOPIC_SUCCESS';
export const GET_THEMES = 'app/Main/GET_THEMES';
export const GET_THEMES_SUCCESS = 'app/Main/GET_THEMES_SUCCESS';
export const GET_RESOURCES_COUNT_BY_TOPIC =
  'app/Main/GET_RESOURCES_COUNT_BY_TOPIC';
export const GET_RESOURCES_COUNT_BY_TOPIC_SUCCESS =
  'app/Main/GET_RESOURCES_COUNT_BY_TOPIC_SUCCESS';
export const GET_CLIENT_RESOURCES = 'app/Main/GET_CLIENT_RESOURCES';
export const GET_CLIENT_RESOURCES_SUCCESS =
  'app/Main/GET_CLIENT_RESOURCES_SUCCESS';
export const SHOW_REGION_SELECTOR = 'app/Main/SHOW_MAIN_SELECTOR';
export const SET_REGION = 'app/Main/SET_REGION';
export const UPDATE_BRAND = 'app/Main/UPDATE_BRAND';
export const SHOW_REGION_BANNER = 'app/Main/SHOW_REGION_BANNER';
export const CLEAR_CLIENT_DETAILS = 'app/Main/CLEAR_CLIENT_DETAILS';
export const SET_LANDING = 'app/Main/SET_LANDING';
export const SHOW_HOMEPAGE_PRO = 'app/Main/SHOW_HOMEPAGE_PRO';
export const GET_AUDIENCE_TAGS_RELATIONS =
  'app/Main/GET_AUDIENCE_TAGS_RELATIONS';
export const SET_AUDIENCE_TAGS_RELATIONS =
  'app/Main/SET_AUDIENCE_TAGS_RELATIONS';
export const SET_NPS_VISIBILITY = 'app/Main/SET_NPS_VISIBILITY';
export const INCREMENT_NPS_PLAY_COUNT = 'app/Main/INCREMENT_NPS_PLAY_COUNT';
export const MEET_NPS_PRECONDITIONS = 'app/Main/MEET_NPS_PRECONDITIONS';
export const SET_HELPFUL_LOCATION = 'app/Main/SET_HELPFUL_LOCATION';
export const SHOW_FLOATING_HELPFUL_AGAIN =
  'app/Main/SHOW_FLOATING_HELPFUL_AGAIN';
export const SET_LEAVING_MODAL = 'app/Main/SET_LEAVING_MODAL';
export const SAVE_REMIND_ME_LATER_RESOURCE =
  'app/Main/SAVE_REMIND_ME_LATER_RESOURCE';
export const SAVE_REMIND_ME_LATER_RESOURCE_RESULT =
  'app/Main/SAVE_REMIND_ME_LATER_RESOURCE_RESULT';
export const GET_REMIND_ME_LATER_RESOURCE =
  'app/Main/GET_REMIND_ME_LATER_RESOURCE';
export const GET_REMIND_ME_LATER_RESOURCE_RESULT =
  'app/Main/GET_REMIND_ME_LATER_RESOURCE_RESULT';
export const GET_PAGE_INTROS = 'app/Main/GET_PAGE_INTROS';
export const GET_PAGE_INTROS_RESULT = 'app/Main/GET_PAGE_INTROS_RESULT';
export const GET_REFERRALS = 'app/Main/GET_REFERRALS';
export const GET_HOME_REFERRALS = 'app/Main/GET_HOME_REFERRALS';
export const GET_REFERRALS_RESULT = 'app/Main/GET_REFERRALS_RESULT';
export const GET_HOME_REFERRALS_RESULT = 'app/Main/GET_HOME_REFERRALS_RESULT';
export const SHOW_LANGUAGE_BANNER = 'app/Main/SHOW_LANGUAGE_BANNER';
export const SET_LANGUAGE = 'app/Main/SET_LANGUAGE';
export const GET_LOCALES = 'app/Main/GET_LOCALES';
export const SET_LOCALES = 'app/Main/SET_LOCALES';
export const GET_AICC_USER = 'app/Main/GET_AICC_USER';
export const SET_AICC_USER = 'app/Main/SET_AICC_USER';
export const SET_AICC_REDIRECTED = 'app/Main/SET_AICC_REDIRECTED';
export const SET_CLINIC_MODE = 'app/Main/SET_CLINIC_MODE';
export const SHOW_ONBOARDING_QUESTIONS = 'app/Main/SHOW_ONBOARDING_QUESTIONS';
export const SHOW_INSURANCE_MODAL = 'app/Main/SHOW_INSURANCE_MODAL';
export const HIDE_INSURANCE_MODAL = 'app/Main/HIDE_INSURANCE_MODAL';
export const SHOW_PERSONAL_EMAIL_MODAL = 'app/Main/SHOW_PERSONAL_EMAIL_MODAL';
export const HIDE_PERSONAL_EMAIL_MODAL = 'app/Main/HIDE_PERSONAL_EMAIL_MODAL';
export const SET_FIRESTORE_STATUS = 'app/Main/SET_FIRESTORE_STATUS';
export const GET_CONTENT_BLOCKS = 'app/Main/GET_CONTENT_BLOCKS';
export const SET_CONTENT_BLOCKS = 'app/Main/SET_CONTENT_BLOCKS';
export const SET_IS_ENDING_SESSION = 'app/Main/SET_IS_ENDING_SESSION';
export const UPDATE_CLIENT_DETAILS = 'app/Main/UPDATE_CLIENT_DETAILS';
export const SET_FRESHWORKS_LOADED = 'app/Main/SET_FRESHWORKS_LOADED';
export const SET_TEXT_DIRECTION = 'app/Main/SET_TEXT_DIRECTION';
