import React from 'react';

export default (color = '#FFFFFF') => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Assessments</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Topic-Icons"
        transform="translate(-1033.000000, -625.000000)"
        stroke={color}
      >
        <g id="White" transform="translate(1033.000000, 359.000000)">
          <g id="Assessments" transform="translate(1.000000, 261.000000)">
            <g transform="translate(0.000000, 6.000000)">
              <path
                d="M7,14 C10.8659932,14 14,10.8659932 14,7 C14,3.13400675 10.8659932,0 7,0 C3.13400675,0 0,3.13400675 0,7"
                id="Oval"
                transform="translate(7.000000, 7.000000) rotate(-180.000000) translate(-7.000000, -7.000000) "
              ></path>
              <polyline
                id="Path-2"
                points="3 7.29199219 5.62651536 9.98144531 10.2964372 5"
              ></polyline>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
