import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { makeSelectProfile } from 'containers/Auth/selectors';
import { SURVEY_TYPES } from 'containers/Course/constants';

const useHideNPSSurvey = () => {
  const location = useLocation();
  const profile = useSelector(makeSelectProfile());

  const paths = location.pathname.split('/');

  // Assessment
  const isAssessmentPage =
    paths.includes('assessments') && !paths.includes('results');

  // Auth
  const isAuthPage = [
    '/signup',
    '/signup/data',
    '/signup/email',
    '/login',
    '/logincm',
    '/signupcm',
    '/forgot_password',
    '/new_password',
  ].includes(location.pathname);

  // Course
  const isCoursePage = paths.includes('courses');
  const isCourseDashboardPage = paths.includes('dashboard');
  const coursePostSurveyCompleted = !_isEmpty(
    _get(profile, `courseSurveys.${SURVEY_TYPES.POST}`),
  );
  const shouldHideForCourses =
    isCoursePage && (!isCourseDashboardPage || !coursePostSurveyCompleted);

  return isAssessmentPage || isAuthPage || shouldHideForCourses;
};

export default useHideNPSSurvey;
