import { select, take } from 'redux-saga/effects';
import { actionTypes } from 'react-redux-firebase';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _some from 'lodash/some';
import { ASSESSMENT_COLORS } from 'containers/Assessment/constants';
import TakeArrowWhite from 'containers/Assessments/images/assessment-arrow-white.svg';
import TakeArrowBlack from 'containers/Assessments/images/assessment-arrow-black.svg';
import { SET_PROFILE_FILTERS } from 'containers/Auth/constants';
import AssessmentWhiteIcon from 'components/Resources/images/White/Assessments.svg';
import AssessmentBlackIcon from 'components/Resources/images/Black/Assessments.svg';

export const isAssessmentSaved = (savedAssessments, assessment) => {
  const id =
    _get(assessment, 'id') ||
    _get(assessment, 'sys.id') ||
    _get(assessment, 'objectID') ||
    '';
  return savedAssessments && savedAssessments.map(el => el.sys.id).includes(id);
};

export const isAssessmentTaken = (takenAssessments, assessment) => {
  const slug = _has(assessment, 'slug')
    ? assessment.slug
    : _has(assessment, 'fields')
    ? assessment.fields.slug
    : '';
  const id = _has(assessment, 'id')
    ? assessment.id
    : _has(assessment, 'sys')
    ? assessment.sys.id
    : '';
  return (
    (!_isEmpty(takenAssessments) &&
      Boolean(slug) &&
      takenAssessments.map(el => el.assessment.slug).includes(slug)) ||
    (!_isEmpty(takenAssessments) &&
      Boolean(id) &&
      takenAssessments.map(el => el.assessment.assessmentID).includes(id))
  );
};
// default to aqua in case of broken data
export const getColorsConfig = item => {
  const colorName = _get(item, 'fields.color') || _get(item, 'color');

  if (colorName) {
    const lowerCasedName = colorName.toLowerCase();
    const isWhite = ['aqua', 'gold', 'blue', 'grey'].includes(lowerCasedName);
    const takeArrowSvg = isWhite ? TakeArrowWhite : TakeArrowBlack;
    const checkSvg = isWhite ? AssessmentWhiteIcon : AssessmentBlackIcon;

    return {
      color: ASSESSMENT_COLORS[lowerCasedName],
      colorName,
      takeArrowSvg,
      checkSvg,
    };
  }

  return {
    color: ASSESSMENT_COLORS.aqua,
    colorName: 'aqua',
    takeArrowSvg: TakeArrowWhite,
    checkSvg: AssessmentWhiteIcon,
  };
};

export function* filterAssessmentByRequiredTags(payload) {
  const profile = yield select(state => state.firebase.profile);
  if (!profile.isLoaded) {
    yield take(actionTypes.SET_PROFILE);
  }
  let profileFilters = yield select(state => state.auth.profileFilters);
  if (!profileFilters.isLoaded) {
    yield take(SET_PROFILE_FILTERS);
    profileFilters = yield select(state => state.auth.profileFilters);
  }
  const profileInsuranceTags = _get(profileFilters, 'insuranceTags', []).map(
    tag => tag.id,
  );

  const filteredItems = payload.filter(item => {
    const requiredTags = _get(item, 'fields.requiredTags', []).map(tag =>
      _get(tag, 'sys.id'),
    );

    if (!requiredTags.length) return true;
    if (!profileInsuranceTags.length) return false;
    return _some(requiredTags, tag => profileInsuranceTags.includes(tag));
  });

  return filteredItems;
}
