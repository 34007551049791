import GqlClient from 'utils/graphqlService';
import { gql } from '@apollo/client';
import _get from 'lodash/get';
import { SourceFragment, ActivityFragment } from './fragments';
const GetRegionCollectionBySlug = gql`
  ${SourceFragment}
  ${ActivityFragment}

  query Region($slug: String!) {
    regionCollection(where: { slug: $slug }, limit: 1) {
      items {
        title
        slug
        image {
          url
        }
        clientResourcesCollection {
          items {
            ...ClientSourceFields
            ...ClientActivityFields
          }
        }
        clientCollection {
          items {
            shortName
          }
        }
      }
    }
  }
`;

const getRegionBySlug = async (_, slug) => {
  const { data } = await GqlClient.query({
    query: GetRegionCollectionBySlug,
    variables: {
      slug,
    },
  });
  return _get(data, 'regionCollection.items.0', []);
};

export default getRegionBySlug;
