/**
 *
 * AccountExtraInfoModal
 *
 */

import React from 'react';
import { useSelector } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import { makeSelectProfile } from 'containers/Auth/selectors';
import {
  makeSelectFirestoreStatus,
  makeSelectInsuranceModal,
  makeSelectPersonalEmailModal,
} from 'containers/Main/selectors';
import InsuranceContent from './components/InsuranceContent';
import PersonalEmailContent from './components/PersonalEmailContent';

function AccountExtraInfoModal() {
  const profile = useSelector(makeSelectProfile());
  const firestoreStatus = useSelector(makeSelectFirestoreStatus());
  const personalEmailStatus = useSelector(makeSelectPersonalEmailModal());
  const insuranceStatus = useSelector(makeSelectInsuranceModal());

  const personalEmailVisibility =
    firestoreStatus &&
    !profile.isEmpty &&
    !profile.personalEmail &&
    personalEmailStatus.visible;
  const insuranceVisibility = insuranceStatus.visible;
  const open = personalEmailVisibility || !!insuranceVisibility;

  return (
    <Modal open={!!open}>
      <div>
        {personalEmailVisibility && (
          <PersonalEmailContent type={personalEmailStatus.type} />
        )}
        {!personalEmailVisibility && insuranceVisibility && (
          <InsuranceContent />
        )}
      </div>
    </Modal>
  );
}

export default AccountExtraInfoModal;
