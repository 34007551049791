/* eslint-disable no-param-reassign */
import React from 'react';
import _classNames from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  insuranceOptionsSelector,
  makeSelectClientDetails,
  makeSelectInsuranceModal,
} from 'containers/Main/selectors';
import { getTokenFriendlyType } from 'components/ResourceItemSelector/utils';
import useWindowSize from 'components/useWindowSize';
import Dropdown from './Dropdown';

const useStyles = makeStyles(theme => ({
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
    },
  },
  body: {
    padding: '12px 64px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 12px 0',
    },
  },
  button: {
    ...theme.typography.button,
    color: '#fff',
    boxShadow: 'none',
    minWidth: 181,
    height: 43,
    borderRadius: 2,
    '&:hover': {
      backgroundColor: theme.palette.primary.secondary,
      opacity: 0.8,
    },
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    width: 135,
    marginBottom: 28,
  },
  dropdownWrapper: {
    marginTop: 18,
    display: 'flex',
    justifyContent: 'center',
  },
  buttonWrapper: {
    marginTop: ({ extraMargin }) => (extraMargin ? 80 : 28),
    display: 'flex',
    justifyContent: 'center',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  backButton: {
    ...theme.typography.button,
    color: theme.palette.primary.main,
    height: 50,
    '&:hover': {
      opacity: 0.8,
    },
  },
  disclaimer: {
    fontSize: '0.875rem',

    '& + &': {
      marginTop: 8,
    },
  },
  optionDisclaimer: {
    ...theme.typography.pSmallBold,
    marginTop: 8,
    '&.hidden': {
      opacity: 0,
    },
  },
}));

const InsuranceStep = ({
  error,
  insurance,
  siteCopy,
  showBackButton,
  onBack,
  onInsuranceChange,
  onSubmit,
}) => {
  const clientDetails = useSelector(makeSelectClientDetails());
  const status = useSelector(makeSelectInsuranceModal());
  const insuranceOptions = useSelector(insuranceOptionsSelector);
  const locale = useSelector(state => state.main.language || 'en-US');
  useWindowSize();
  const currentOption = insuranceOptions.find(o => o.value === insurance);
  const optionWithLongestDisclaimer = insuranceOptions
    .filter(o => o.disclaimer)
    .reduce(
      (accu, next) =>
        _get(next, ['disclaimer', locale], '').length >
        _get(accu, ['disclaimer', locale], '').length
          ? next
          : accu || next,
      null,
    );

  const classes = useStyles({});

  const header = _get(siteCopy, ['header', status.type], '').replace(
    '#type',
    getTokenFriendlyType(status.resourceType),
  );

  const refSetter = el => {
    if (
      _get(el, 'textContent') ===
      _get(optionWithLongestDisclaimer, ['disclaimer', locale])
    ) {
      el.style.minHeight = `0px`;
      requestAnimationFrame(() => {
        el.style.minHeight = `${el.offsetHeight}px`;
      });
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} className={classes.logoWrapper}>
        <img
          src={_get(clientDetails, 'crediblemindLogo.url', '')}
          className={classes.logo}
          alt={_get(clientDetails, 'formalShortName')}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          color="primary"
          align="center"
          className={classes.title}
        >
          {header}
        </Typography>
      </Grid>
      {!_isEmpty(_get(siteCopy, 'description', [])) && (
        <Grid item xs={12} className={classes.body}>
          {_get(siteCopy, 'description', []).map(paragraph => (
            <Typography
              variant="body2"
              color="textPrimary"
              align="center"
              key={paragraph}
            >
              {paragraph}
            </Typography>
          ))}
        </Grid>
      )}
      {_get(siteCopy, 'displayDropdown') && (
        <Grid item xs={12} className={classes.dropdownWrapper}>
          <Dropdown
            error={error}
            value={insurance}
            options={insuranceOptions}
            onChange={onInsuranceChange}
          />
        </Grid>
      )}
      {(!_isEmpty(_get(siteCopy, 'disclaimer', [])) ||
        optionWithLongestDisclaimer) && (
        <Grid item xs={12} className={classes.body}>
          {_get(siteCopy, 'disclaimer', []).map(paragraph => (
            <>
              <Typography
                className={classes.disclaimer}
                color="textPrimary"
                align="center"
                key={paragraph}
              >
                {paragraph}
              </Typography>
            </>
          ))}
          {optionWithLongestDisclaimer && (
            <Typography
              className={_classNames(
                classes.optionDisclaimer,
                !_get(currentOption, ['disclaimer', locale]) && 'hidden',
              )}
              color="textPrimary"
              align="center"
              innerRef={refSetter}
            >
              {_get(currentOption, ['disclaimer', locale]) ||
                _get(optionWithLongestDisclaimer, ['disclaimer', locale])}
            </Typography>
          )}
        </Grid>
      )}
      <Grid
        item
        xs={12}
        className={_classNames(classes.buttonWrapper, {
          [classes.spaceBetween]:
            _get(siteCopy, 'button') &&
            showBackButton &&
            _get(siteCopy, 'backButton'),
        })}
      >
        {showBackButton && _get(siteCopy, 'backButton') && (
          <ButtonBase className={classes.backButton} onClick={onBack}>
            <ChevronLeftIcon className={classes.icon} />
            {_get(siteCopy, 'backButton', '')}
          </ButtonBase>
        )}
        {_get(siteCopy, 'button') && (
          <Button
            variant="contained"
            disabled={insurance === '-1'}
            className={classes.button}
            onClick={onSubmit}
            color="secondary"
          >
            {_get(siteCopy, 'button', '')}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default InsuranceStep;
