import React from 'react';

export default (color = '#FFFFFF') => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 17 17"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="online-program" transform="translate(0.39001465 0.39001465)">
      <path
        d="M0.948802 11.3333L6.10817 11.3333"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.36551 3.33333L14.2497 3.33333"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.42948 7.33333L0.00746554 7.33333"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.30876 0.0173333C3.23665 4.35067 3.31063 9.98933 7.38274 15.3227"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.30032 0.0173333C9.82121 1.57236 10.8309 3.54069 11.1983 5.66667"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6098 7.66667C15.6098 3.43248 12.1154 1.22125e-15 7.80488 0C3.49436 -1.22125e-15 5.27444e-16 3.43248 0 7.66667C-5.27444e-16 11.9008 3.49436 15.3333 7.80488 15.3333"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2106 10.6053L14.6976 9.144C14.7825 9.0604 14.816 8.93892 14.7858 8.82465C14.7555 8.71037 14.6659 8.62035 14.5503 8.588L7.15266 6.51733C7.03444 6.48456 6.90741 6.51711 6.82055 6.60244C6.73369 6.68776 6.70055 6.81254 6.73391 6.92867L8.83784 14.1953C8.87064 14.3092 8.96242 14.3974 9.07899 14.4272C9.19556 14.4569 9.31946 14.4237 9.40454 14.34L10.7958 12.974L12.9554 15.0947C13.0879 15.2248 13.3027 15.2248 13.4352 15.0947L15.3702 13.2C15.5027 13.0698 15.5027 12.8588 15.3702 12.7287L13.2106 10.6053Z"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
