import React from 'react';

const ReflectIcon = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#01619B" />
    <g>
      <path
        d="M16.5898 28.5H9.58984C9.32463 28.5 9.07027 28.3946 8.88274 28.2071C8.6952 28.0196 8.58984 27.7652 8.58984 27.5V11.5C8.58984 11.2348 8.6952 10.9804 8.88274 10.7929C9.07027 10.6054 9.32463 10.5 9.58984 10.5H23.5898C23.8551 10.5 24.1094 10.6054 24.297 10.7929C24.4845 10.9804 24.5898 11.2348 24.5898 11.5V17.5"
        stroke="white"
      />
      <path d="M12.5898 12.5V8.5" stroke="white" />
      <path d="M16.5898 12.5V8.5" stroke="white" />
      <path d="M20.5898 12.5V8.5" stroke="white" />
      <path d="M18.5898 31.4988L25.9988 24.0898" stroke="white" />
      <path
        d="M24.0906 21.9001C24.0905 21.8051 24.0634 21.712 24.0124 21.6319C23.9614 21.5517 23.8887 21.4877 23.8026 21.4474C23.7166 21.4071 23.6208 21.3922 23.5266 21.4044C23.4324 21.4165 23.3436 21.4553 23.2706 21.5161C21.7706 22.7631 19.2566 26.0741 21.6086 28.4241C22.6266 29.4431 24.9016 29.2831 27.6436 26.5421C29.7436 24.4371 30.9436 20.8231 31.3916 19.2571C31.414 19.1751 31.4152 19.0888 31.395 19.0062C31.3748 18.9236 31.3339 18.8476 31.2762 18.7852C31.2184 18.7229 31.1457 18.6763 31.065 18.6498C30.9842 18.6234 30.898 18.618 30.8146 18.6341C29.3137 18.9223 27.847 19.3667 26.4386 19.9601C26.4386 19.9601 25.7496 23.0001 24.0906 23.0001V21.9001Z"
        stroke="white"
      />
    </g>
  </svg>
);

export default ReflectIcon;
