import Config from 'utils/getEnvConfig';
import {
  ApolloLink,
  HttpLink,
  ApolloClient,
  InMemoryCache,
} from '@apollo/client';

import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';

const {
  SPACE_ID,
  ACCESS_TOKEN,
  ENVIRONMENT,
  PREVIEW_ACCESS_TOKEN,
} = Config.CONTENTFUL;
const retryLink = new RetryLink();

const httpLink = new HttpLink({
  uri: `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}/environments/${ENVIRONMENT}`,
});

const authLink = setContext((_, context) => {
  const { isPreview = false } = context;
  return {
    headers: {
      authorization: `Bearer ${
        isPreview ? PREVIEW_ACCESS_TOKEN : ACCESS_TOKEN
      }`,
    },
  };
}).concat(httpLink);

const link = ApolloLink.from([retryLink, authLink]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
  },
});

export default client;
