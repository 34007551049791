import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { showOnboardingQuestions } from 'containers/Main/actions';
import { makeSelectShowOnboardingQuestions } from 'containers/Main/selectors';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  makeSelectAuthModal,
  makeSelectProfile,
} from 'containers/Auth/selectors';
import { useFirebase } from 'react-redux-firebase';
import MixPanel from 'utils/mixpanelService';
import useContentfulLocale from 'components/useContentfulLocale';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useQuery } from 'react-query';
import useSiteCopySelector from 'components/useSiteCopySelector';
import { Slide, Snackbar, Typography } from '@material-ui/core';
import withSizes from 'react-sizes';
import { useHistory, useLocation } from 'react-router-dom';
import { replacePlaceholders, getUserFirstName } from 'utils/stringUtils';
import ListItems from './ListItems';
import TopicItems from './TopicItems';
import { getListsFn } from '../services/getList';
import { findElement } from './utils';

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: props => !props.isMobile && '10px !important',
    maxWidth: props => !props.isMobile && 996,
    minWidth: props => !props.isMobile && 995,
    height: props => props.isMobile && '100%',
    maxHeight: props => props.isMobile && '100%',
    margin: props => props.isMobile && 0,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    },
  },
  contentRoot: {
    margin: 0,
    padding: '45px !important',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 30px 0px !important',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 12,
    top: 12,
    color: theme.palette.grey[500],
    padding: 0,
  },
  agreeBtn: {
    height: props => (props.isMobile ? 40 : 43),
    borderRadius: 2,
    fontSize: 14,
    fontFamily: 'MadaBold',
    padding: '0 24px',
    backgroundColor: '#F8AC24',
    width: props => (props.isMobile ? 271 : 181),
    color: 'white',
    '&:hover': {
      backgroundColor: '#F2A50C',
    },
  },
  header: {
    display: 'flex',
    borderBottom: '1px solid #E3E3E3',
  },
  backBtn: {
    textTransform: 'none',
  },
  skipButton: {
    textTransform: 'none',
    fontFamily: 'MadaRegular',
  },
  emphasis: {
    color: theme.palette.primary.main,
  },
  errorMessage: {
    color: '#4BB543',
    height: 30,
  },
  desktopFooter: {
    display: 'flex',
    flex: '1',
    justifyContent: props =>
      props.isOnboardingCompleted ? 'flex-end' : 'space-between',
    paddingTop: '20px',
    borderTop: '1px solid #E3E3E3',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  mobileFooter: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    gap: 8,
    width: '100%',
    padding: '20px 30px',
    borderTop: '1px solid #E3E3E3',
    backgroundColor: '#ffffff',
  },
  saveBtn: {
    textTransform: 'uppercase',
    width: 149,
    height: 43,
    backgroundColor: '#F8AC24',
  },
  alert: {
    position: 'fixed',
    right: 10,
    top: 20,
    fontSize: '0.875rem',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    backgroundColor: '#ffffff',
    '& .MuiAlert-icon': {
      color: theme.palette.primary.main,
    },
    '&. MuiAlert-message': {
      padding: 0,
    },
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
  },
  subtitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
}));

const OnboardingQuestionsDialog = ({ isMobile, clientShortName }) => {
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const history = useHistory();
  const location = useLocation();
  const openOnboarding = useSelector(makeSelectShowOnboardingQuestions());
  const authModal = useSelector(makeSelectAuthModal());
  const profile = useSelector(makeSelectProfile());
  const clientDetails = useSelector(state => state.main.clientDetails);
  const profileOnboarding = _get(profile, 'onboarding');
  const [page, setPage] = useState(1);
  const [topicError, setTopicError] = useState(false);
  const [selectedList, setSelectedList] = useState(
    _get(profileOnboarding, 'lists.0', null),
  );
  const [selectedTopics, setSelectedTopics] = useState(
    _get(profileOnboarding, 'topics', []),
  );
  const [showMaxReachedAlert, setShowMaxReachedAlert] = useState(false);
  const showedMaxReachedAlert = useRef(false);
  const dialogContentRef = useRef();
  const ctfLocaleFilter = useContentfulLocale();
  const isOnboardingCompleted = !!_get(profileOnboarding, 'topics');
  const classes = useStyles({ isMobile, isOnboardingCompleted });
  const [siteCopy] = useSiteCopySelector(['onboarding-questions']);
  // TODO: make this as util

  const isAuthModalDisplayed = authModal.show;
  const name = getUserFirstName(profile);
  const onboardingConfig = _get(siteCopy, 'pageCopy');
  const questions = _get(onboardingConfig, 'questions', {});
  const listsConfig = _get(onboardingConfig, 'lists', []);
  const buttons = _get(onboardingConfig, 'buttons', {});
  const title = isOnboardingCompleted
    ? _get(questions, 'onboardingCompletedTitle')
    : _get(questions, 'title', '').replace('#name', name);
  const subtitle = _get(questions, 'subtitle');
  const agreeBtn = _get(buttons, 'save');
  const skipBtn = _get(buttons, 'skip');
  const backBtn = _get(buttons, 'back');
  const toolTipMessage = _get(onboardingConfig, 'errors.maxTopicsTooltip', '');
  const maxApproachLabel = _get(onboardingConfig, 'errors.maxTopics');
  const clientTopicsLimit = _get(onboardingConfig, [
    'clientApproachesLimit',
    clientShortName,
  ]);
  const clientExcludedTopicIds = _get(
    clientDetails,
    'excludeTopicCollection.items',
    [],
  ).map(s => s.sys.id);
  const defaultTopicsLimit = _get(
    onboardingConfig,
    'defaultApproachesLimit',
    1,
  );
  const suggestedApproachesTitle = _get(
    questions,
    'suggestedApproachesTitle',
    '',
  );
  const topicsLimit = clientTopicsLimit || defaultTopicsLimit;

  const listsIds = listsConfig.map(list => {
    const [listKey] = Object.keys(list).filter(key => key !== 'list-resources');
    return list[listKey];
  });

  const { data: lists = [] } = useQuery(
    !_isEmpty(listsIds) &&
      openOnboarding && [
        'onboarding-lists',
        {
          listsIds,
          clientExcludedTopicIds,
          localeFilters: ctfLocaleFilter,
        },
      ],
    getListsFn,
  );

  const getOnboardingPath = () => {
    const queryparams = new URLSearchParams(window.location.search);
    const onboardingPath = queryparams.get('onboarding_path');
    return onboardingPath || 'onboarding';
  };

  useEffect(() => {
    if (selectedTopics.length === topicsLimit && page === 2) {
      setTopicError(true);
    }
  }, [selectedTopics, topicsLimit, page]);

  useEffect(() => {
    if (topicError && isMobile && !showedMaxReachedAlert.current) {
      setShowMaxReachedAlert(true);
      showedMaxReachedAlert.current = true;
    }
  }, [topicError, isMobile]);

  if (_isEmpty(lists)) return null;

  const isSignUpPage = location.pathname.startsWith('/signup');

  if (isSignUpPage || isAuthModalDisplayed) return null;

  const smoothScroll = element => {
    if (element) {
      const offsetTop =
        element.getBoundingClientRect().top + window.pageYOffset;
      window.scroll({
        top: offsetTop - 150,
        behavior: 'smooth',
      });
    }
  };

  const resetState = () => {
    setPage(1);
    setTopicError(false);
    setSelectedTopics([]);
  };

  const handleSave = async () => {
    if (page === 1) {
      setPage(2);
      setSelectedTopics([]);
      dialogContentRef.current.scroll(0, 0);
    } else {
      const list = lists.find(({ sys }) => sys.id === selectedList);
      const topics = _get(list, 'fields.resources', [])
        .filter(({ sys }) => selectedTopics.includes(sys.id))
        .map(topic => _get(topic, 'fields.title'));

      firebase.updateProfile({
        noGoal: false,
        onboarding: {
          topics: selectedTopics,
          lists: [selectedList], // make an array in case we want to support multiple soon
        },
      });

      const onboardingPath = getOnboardingPath();

      if (!isOnboardingCompleted) {
        MixPanel.track('Onboarding - Completed', {
          path: onboardingPath,
        });
      }

      MixPanel.track('Onboarding - Saved', {
        approach: topics,
        topic: _get(list, 'fields.shortTitle', _get(list, 'fields.title')),
        path: onboardingPath,
      });
      dispatch(showOnboardingQuestions(false));
      resetState();
      const element = await findElement('#personalized-resources-landing');
      smoothScroll(element);
      history.replace('/');
    }
  };

  const handleNoGoal = async () => {
    const onboardingPath = getOnboardingPath();
    history.replace('/');
    await firebase.updateProfile({
      onboarding: { noGoal: true, skipTimestamp: Date.now(), skipPage: page },
    });

    MixPanel.track('Onboarding - No Topic - Clicked', {
      path: onboardingPath,
    });
    dispatch(showOnboardingQuestions(false));
    resetState();
    const element =
      document.getElementById('trending-topics') ||
      document.getElementById('personalization-list');
    smoothScroll(element);
  };

  const handleSelectTopic = id => {
    setTopicError(false);
    const selectedTopicsLen = selectedTopics.length;
    if (selectedTopics.includes(id)) {
      setSelectedTopics(selectedTopics.filter(topic => topic !== id));
    } else if (topicsLimit === 1 && selectedTopicsLen === 1) {
      setSelectedTopics([id]);
      setTopicError(true);
    } else if (selectedTopicsLen < topicsLimit) {
      setSelectedTopics([...selectedTopics, id]);
      if (selectedTopicsLen === topicsLimit - 1) {
        setTopicError(true);
      }
    }
  };

  const onSkip = () => {
    const onboardingPath = getOnboardingPath();
    history.replace('/');
    firebase.updateProfile({
      onboarding: { skip: true, skipTimestamp: Date.now(), skipPage: page },
    });
    MixPanel.track('Onboarding - Dismissed', {
      path: onboardingPath,
    });
    dispatch(showOnboardingQuestions(false));
    resetState();
  };

  const handleBack = () => {
    resetState();
  };

  const handleCloseDialog = (e, reason) => {
    if (reason === 'backdropClick') {
      onSkip();
    }
  };

  const handleAlertClose = () => setShowMaxReachedAlert(false);

  const selectedListData =
    page === 2 && selectedList
      ? lists.find(({ sys }) => sys.id === selectedList)
      : [];

  const footerContent = (
    <>
      {skipBtn && !isOnboardingCompleted && (
        <Button
          variant="text"
          color="primary"
          className={classes.skipButton}
          onClick={handleNoGoal}
        >
          {skipBtn}
        </Button>
      )}
      <Box>
        {backBtn && !isMobile && page === 2 && (
          <Button
            variant="text"
            color="primary"
            className={classes.backBtn}
            onClick={handleBack}
          >
            {backBtn}
          </Button>
        )}
        {agreeBtn && (
          <Button
            variant="contained"
            className={classes.agreeBtn}
            onClick={handleSave}
            disabled={page === 1 ? !selectedList : _isEmpty(selectedTopics)}
          >
            {agreeBtn}
          </Button>
        )}
      </Box>
    </>
  );

  return (
    <Dialog
      aria-labelledby="onboarding-questions"
      open={openOnboarding}
      classes={{
        paper: classes.paper,
      }}
      onClose={handleCloseDialog}
    >
      {isMobile && (
        <Box
          className={classes.header}
          justifyContent={page === 2 ? 'space-between' : 'flex-end'}
        >
          {page === 2 && (
            <IconButton onClick={handleBack} aria-label="Back">
              <ArrowBackIcon />
            </IconButton>
          )}
          <IconButton onClick={onSkip} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <DialogContent
        classes={{ root: classes.contentRoot }}
        ref={dialogContentRef}
      >
        {page === 1 ? (
          <Typography
            variant="h3"
            align="center"
            color="primary"
            gutterBottom
            className={classes.title}
          >
            {title}
          </Typography>
        ) : (
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            className={classes.title}
          >
            {replacePlaceholders(suggestedApproachesTitle, {
              '#name': name,
              '#list': (
                <span className={classes.emphasis}>
                  {_get(
                    selectedListData,
                    'fields.shortTitle',
                    _get(selectedListData, 'fields.title'),
                  )}
                </span>
              ),
            })}
          </Typography>
        )}

        {page === 1 && (
          <Typography
            variant="body1"
            color="textPrimary"
            align="center"
            className={classes.subtitle}
          >
            {subtitle}
          </Typography>
        )}

        {page === 2 && !isMobile && (
          <Typography
            variant="body1"
            className={classes.errorMessage}
            align="center"
          >
            {topicError && maxApproachLabel}
          </Typography>
        )}

        {page === 1 ? (
          <ListItems
            data={lists}
            selectedList={selectedList}
            clickHandler={setSelectedList}
            handleSave={handleSave}
            agreeBtn={agreeBtn}
            isMobile={isMobile}
          />
        ) : (
          <TopicItems
            data={_get(selectedListData, 'fields.resources', [])}
            selectedTopics={selectedTopics}
            clickHandler={handleSelectTopic}
            toolTipMessage={toolTipMessage}
            isMobile={isMobile}
            isMaxReached={
              topicsLimit > 1 && selectedTopics.length === topicsLimit
            }
          />
        )}
        {!isMobile && (
          <Box className={classes.desktopFooter}>{footerContent}</Box>
        )}
      </DialogContent>
      {isMobile && <Box className={classes.mobileFooter}>{footerContent}</Box>}
      <Snackbar
        open={showMaxReachedAlert}
        onClose={handleAlertClose}
        autoHideDuration={3000}
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'left' }}
      >
        <Alert className={classes.alert} onClose={handleAlertClose}>
          {maxApproachLabel}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width <= 600,
});

export default withSizes(mapSizesToProps)(OnboardingQuestionsDialog);
