import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

const getExpertRating = fields => {
  const expertRating = _get(fields, 'calculatedExpertRating');
  if (!_isNil(expertRating)) return expertRating;

  const adjustedCredibilityScore = _get(fields, 'adjustedCredibilityScore');
  if (!_isNil(adjustedCredibilityScore)) return adjustedCredibilityScore / 20;

  const credibilityScore = _get(fields, 'credibilityScore');
  if (!_isNil(credibilityScore)) return credibilityScore / 20;

  return undefined;
};

const getResourceRating = resource => ({
  userRating: _get(resource, 'fields.calculatedUserRating'),
  expertRating: getExpertRating(resource.fields),
  averageRating: _get(resource, 'fields.calculatedAverageRating'),
});

const isRatingBelowCutoff = (rating, cutoff) =>
  !_isNil(rating) && cutoff && rating < cutoff;

const isResourceExempted = resource => {
  const exemptedResources = [
    'topics',
    'link',
    'services',
    'assessments',
    'source',
  ];
  const contentType = resource.sys.contentType.id;
  const fieldType = _get(resource, 'fields.type');
  const isBlog = _get(resource, 'fields.blog');

  if (contentType === 'activity' && fieldType === 'News') return true;
  if (contentType === 'activity' && isBlog) return true;
  if (exemptedResources.includes(contentType)) return true;

  return false;
};

export const filterHandpickedResourceByCutoff = ({
  resource,
  averageRatingCutoff,
  userRatingCutoff,
  expertRatingCutoff,
}) => {
  if (!_has(resource, 'fields')) return false;
  if (isResourceExempted(resource)) return true;
  if (!_isEmpty(_get(resource, 'fields.client'))) return true;

  const { userRating, expertRating, averageRating } = getResourceRating(
    resource,
  );

  if (isRatingBelowCutoff(userRating, userRatingCutoff)) return false;
  if (isRatingBelowCutoff(expertRating, expertRatingCutoff)) return false;
  if (isRatingBelowCutoff(averageRating, averageRatingCutoff)) return false;

  return true;
};
