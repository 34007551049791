import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import CloseIcon from '../images/icon_close.svg';

const useStyles = makeStyles(theme => ({
  input: {
    width: '80%',
    fontFamily: 'PoppinsBold',
    fontSize: 13,
    color: '#333333',
    '& > input': {
      padding: 0,
      '&::placeholder': {
        color: '#666666',
        opacity: 1,
      },
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 4,
      paddingLeft: '55%',
      width: '100%',
    },
  },
  close: {
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    padding: 0,
  },
}));

function SearchInput({ onClose }) {
  const classes = useStyles();
  const [value, setValue] = useState('');

  const handleChange = e => setValue(e.target.value);

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      window.location.href = `https://solutions.crediblemind.com/?s=${value}`;
    }
  };

  return (
    <>
      <InputBase
        placeholder="Search..."
        onChange={handleChange}
        value={value}
        onKeyPress={handleKeyPress}
        classes={{ root: classes.input }}
      />
      <button type="button" onClick={onClose} className={classes.close}>
        <img alt="Close search" src={CloseIcon} />
      </button>
    </>
  );
}

SearchInput.propTypes = {
  onClose: PropTypes.func,
};

export default SearchInput;
