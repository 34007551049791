import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import buildTheme from 'utils/theme';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const ClientFontWrapper = ({
  children,
  onlyFor = [],
  shouldInherit = true,
}) => {
  const { pathname } = useLocation();
  const clientDetails = useSelector(makeSelectClientDetails());
  const hasCustomFont = !_isEmpty(
    _get(clientDetails, 'customFontsCollection.items', []),
  );

  const isPathIncluded = onlyFor.length === 0 || onlyFor.includes(pathname);

  if (shouldInherit && hasCustomFont && isPathIncluded) {
    const customTheme = createTheme(
      buildTheme(
        _get(clientDetails, 'context.customHomeHeaderFont'),
        _get(clientDetails, 'context.customHomeBodyFont'),
        _get(clientDetails, 'context.customHomeHeroHeaderFont'),
      ),
    );

    return <MuiThemeProvider theme={customTheme}>{children}</MuiThemeProvider>;
  }

  return <>{children}</>;
};

export default ClientFontWrapper;
