/**
 *
 * useAppcues
 *
 */

import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { makeSelectProfile } from 'containers/Auth/selectors';
import {
  makeSelectInsuranceModal,
  makeSelectPersonalEmailModal,
} from 'containers/Main/selectors';

function useAppcues() {
  const hasIdentified = useRef(false);
  const profile = useSelector(makeSelectProfile());
  const personalEmailStatus = useSelector(makeSelectPersonalEmailModal());
  const insuranceStatus = useSelector(makeSelectInsuranceModal());

  useEffect(() => {
    if (!profile.isEmpty && !hasIdentified.current) {
      if (
        personalEmailStatus.visible === false &&
        insuranceStatus.visible === false
      ) {
        if (!window.dataLayer) {
          window.dataLayer = [];
        }
        window.dataLayer.push({ event: 'AppcuesAuthLoaded' });
        hasIdentified.current = true;
      }
    }
  }, [profile, personalEmailStatus, insuranceStatus]);
}

export default useAppcues;
