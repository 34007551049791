import React from 'react';

const NavBarContext = React.createContext({
  trendingTopics: [],
  recommendedTopics: [],
  assessments: [],
  topBlogs: [],
  topNews: [],
  topClientResources: [],
  clientDetails: {},
  siteCopy: {},
  auth: {},
  profile: {},
  clientResources: [],
  isAuthenticated: false,
  logout: () => {},
  clientLanding: {},
  sendMixpanelEvent: () => {},
  clientFetching: false,
});

export default NavBarContext;
