import React from 'react';

export const Topics = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="19px"
    viewBox="0 0 20 19"
    version="1.1"
  >
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Mobile-Menu---Default---2023---option-9"
        transform="translate(-83.000000, -758.000000)"
        stroke="currentColor"
      >
        <g id="Menu" transform="translate(0.000000, 743.000000)">
          <g id="Topics" transform="translate(79.000000, 16.000000)">
            <g id="Topic-Option-2" transform="translate(5.000000, 0.000000)">
              <g id="streamline-icon-messages-bubble@48x48" strokeWidth="1.28">
                <path
                  d="M9,0 C4.02965217,0 0,3.28640909 0,7.34090909 C0.0422816823,9.38549836 1.02076989,11.3010002 2.66086957,12.5498636 L0.782608696,17 L5.7693913,14.1865 C6.81402251,14.5147999 7.90372936,14.6818741 9,14.6818182 C13.9703478,14.6818182 18,11.3954091 18,7.34090909 C18,3.28640909 13.9703478,0 9,0 Z"
                  id="Path"
                />
              </g>
              <g
                id="streamline-icon-hash@48x48"
                transform="translate(5.625000, 3.400000)"
                strokeWidth="0.9724"
              >
                <line
                  x1="0.586956522"
                  y1="2.36521739"
                  x2="6.75"
                  y2="2.36521739"
                  id="Path"
                />
                <line
                  x1="0"
                  y1="4.43478261"
                  x2="6.16304348"
                  y2="4.43478261"
                  id="Path"
                />
                <line
                  x1="1.76703261"
                  y1="6.8"
                  x2="2.94388043"
                  y2="0"
                  id="Path"
                />
                <line
                  x1="3.82138043"
                  y1="6.8"
                  x2="4.99822826"
                  y2="0"
                  id="Path"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
