import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { makeSelectAuth } from 'containers/Auth/selectors';
import { makeSelectSaveRemindMeLaterResourceResult } from 'containers/Main/selectors';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ClassNames from 'classnames';
import _get from 'lodash/get';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  saveRemindMeLaterResource,
  saveRemindMeLaterResourceResult,
} from 'containers/Main/actions';
import useSiteCopySelector from 'components/useSiteCopySelector';
import Mixpanel from 'utils/mixpanelService';

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: '10px !important',
    [theme.breakpoints.up('800')]: {
      width: 755,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 8,
  },
  contentRoot: {
    margin: 0,
    padding: '0px 45px 45px',
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
  subtitle: {
    marginTop: 17,
  },
  actionBtn: {
    marginTop: 20,
    margin: '0 5px',
    width: '100%',
    height: 57,
    borderRadius: 10,
    border: '1px solid #D8D8D8',
    color: '#01619B',
    fontSize: 17,
    lineHeight: '24px',
  },
  error: {
    color: '#E14B4B',
  },
  linkWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 24,
  },
}));

export default function RemindMeDialog({
  toggle,
  data,
  link,
  thanksLink,
  redirect,
}) {
  const classes = useStyles();
  const [thanksMessage, setThanksMessage] = useState(null);
  const dispatch = useDispatch();
  const saveRemindResult = useSelector(
    makeSelectSaveRemindMeLaterResourceResult(),
  );
  const auth = useSelector(makeSelectAuth());
  const [remindMeConfig] = useSiteCopySelector(['remind-me']);
  const {
    pageCopy: {
      actions,
      mainLabel,
      subtitle,
      title,
      signUpThanksMessage,
      signUpThanksHeader,
      signUpThanksLink,
    },
  } = remindMeConfig;

  useEffect(() => {
    if (data)
      Mixpanel.track('Remind Me Dialog - Viewed', {
        path: window.location.pathname,
        name:
          _get(data, 'fields.name') ||
          _get(data, 'fields.title') ||
          _get(data, 'fields.article'),
        slug: _get(data, 'fields.slug'),
        type: data.sys.contentType.sys.id,
        entryId: data.sys.id,
        exitPage: thanksLink,
      });
  }, []);

  useEffect(() => {
    if (_get(saveRemindResult, 'added')) {
      if (!thanksMessage) {
        setThanksMessage(
          _get(saveRemindResult, 'fromAuth') ? 'auth' : 'redirect',
        );
        setTimeout(() => {
          redirect();
        }, 500);
      }
      dispatch(saveRemindMeLaterResourceResult({}));
    }
  }, [saveRemindResult]);

  useEffect(() => {
    if (thanksMessage === 'redirect') {
      setTimeout(() => {
        toggle();
      }, 5000);
    }
  }, [thanksMessage]);

  const remindMe = value => {
    if (!value || !auth.isEmpty) {
      setThanksMessage('redirect');
      setTimeout(() => {
        redirect();
      }, 500);
    }
    dispatch(
      saveRemindMeLaterResource({ ...data, answer: value ? 'yes' : 'no' }),
    );

    Mixpanel.track('Remind Me Dialog - Clicked', {
      path: window.location.pathname,
      name:
        _get(data, 'fields.name') ||
        _get(data, 'fields.title') ||
        _get(data, 'fields.article'),
      slug: _get(data, 'fields.slug'),
      type: data.sys.contentType.sys.id,
      exitPage: thanksLink,
      entryId: data.sys.id,
      answer: value ? 'yes' : 'no',
    });
  };

  const handleActionBtn = value => {
    remindMe(value);
  };

  if (thanksMessage) {
    return (
      <Dialog
        onClose={toggle}
        aria-labelledby="remind-me-later-thanks-dialog"
        open
        classes={{
          paper: classes.paper,
        }}
      >
        <Box className={classes.header}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={toggle}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent classes={{ root: classes.contentRoot }}>
          {thanksMessage === 'auth' && (
            <Typography variant="h4" align="center">
              {signUpThanksHeader}
            </Typography>
          )}
          <Typography variant="h4" align="center">
            {signUpThanksMessage.replace('#link', thanksLink)}
          </Typography>
          <div className={classes.linkWrapper}>
            <ButtonBase
              onClick={() => {
                redirect();
                toggle();
              }}
              disableRipple
            >
              <Typography align="center" variant="body2" color="primary">
                {signUpThanksLink.replace('#link', thanksLink)}
              </Typography>
            </ButtonBase>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <Dialog
      onClose={toggle}
      aria-labelledby="remind-me-later-dialog"
      open
      classes={{
        paper: classes.paper,
      }}
    >
      <Box className={classes.header}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={toggle}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent classes={{ root: classes.contentRoot }}>
        <Typography variant="h4" align="center">
          {mainLabel.replace('#link', link)}
        </Typography>
        <Typography variant="h4" align="center">
          {title}
        </Typography>
        <Typography align="center" variant="body2" className={classes.subtitle}>
          {subtitle}
        </Typography>
        <Box display="flex" flex="1">
          {actions.map(action => (
            <ButtonBase
              className={ClassNames(classes.actionBtn, {
                [classes.error]: action.value === 0,
              })}
              onClick={() => handleActionBtn(action.value)}
            >
              {action.label}
            </ButtonBase>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
