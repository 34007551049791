import { gql } from '@apollo/client';
import {
  ActivityFragment,
  LandingFragment,
  LinkFragment,
  SourceFragment,
  ServiceFragment,
  FontsFragment,
} from './fragments';
import {
  PodcastFragment,
  VideoFragment,
  AppsFragment,
} from '../../ClientLanding/services/fragments';
import { DUPLICATED_FIELDS } from './getClientDetails.gql';

export default gql`
  ${SourceFragment}
  ${ActivityFragment}
  ${LinkFragment}
  ${LandingFragment}
  ${ServiceFragment}
  ${FontsFragment}
  ${VideoFragment}
  ${PodcastFragment}
  ${AppsFragment}
  query ClientGroup($id: String!, $locale: String!) {
    clientGroup(
      id: $id
      locale: $locale
    ) {
        sys {
            id
          }
          formalShortName
          shortName
          ${DUPLICATED_FIELDS}
        }
  }
`;
