/* eslint-disable import/no-cycle */
import {
  deleteCookie,
  getCookie,
  isCookieEnabled,
  setCookie,
} from 'utils/cookieStore';
import { isLocalhost } from 'utils/networkUtils';
import {
  getDomain,
  getSubdomain,
  isLocalStorageAllowed,
  isSessionStorageAllowed,
} from 'utils/stringUtils';
import _isNan from 'lodash/isNaN';
import { isEmbedded } from './embedded';

let engine;

const parseStorageEngine = () => {
  if (isCookieEnabled()) return 'cookie';
  if (isLocalStorageAllowed()) return 'localstorage';
  if (isSessionStorageAllowed()) return 'sessionstorage';
  return undefined;
};

export const getStorageEngine = () => {
  if (!engine) {
    engine = parseStorageEngine();
  }

  return engine;
};

export const getLocalData = name => {
  if (!engine) {
    engine = parseStorageEngine();
  }

  let item;
  if (engine === 'cookie') {
    item = getCookie(name);
  } else if (engine === 'localstorage') {
    item = localStorage.getItem(name);
  } else if (engine === 'sessionstorage') {
    item = sessionStorage.getItem(name);
  }

  if (name !== 'brand') {
    return item;
  }
  const subdomain = getSubdomain();
  // subdomain value is priority
  return subdomain && subdomain !== 'development' ? subdomain : item;
};

export const setLocalData = (name, value, options = {}) => {
  if (!engine) {
    engine = getStorageEngine();
  }

  if (engine === 'cookie') {
    const domain = getDomain();
    const isNetlify = domain.includes('netlify.app');
    setCookie(name, value, {
      secure: !isLocalhost,
      sameSite: isEmbedded()
        ? 'none'
        : isLocalhost || isNetlify
        ? 'lax'
        : 'strict',
      domain: isNetlify ? window.location.hostname : domain,
      ...options,
    });
  } else if (engine === 'localstorage') {
    localStorage.setItem(name, value);
  } else if (engine === 'sessionstorage') {
    localStorage.setItem(name, value);
  }
};

export const removeLocalData = name => {
  if (!engine) {
    engine = getStorageEngine();
  }

  if (engine === 'cookie') {
    deleteCookie(name);
  } else if (engine === 'localstorage') {
    localStorage.clear(name);
  } else if (engine === 'sessionstorage') {
    localStorage.clear(name);
  }
};

export const cleanAllCookies = () => {
  const cookies = document.cookie.split(';');

  cookies.forEach(cookie => {
    const [name] = cookie.split('=');
    const trimmedName = name.trim();
    if (trimmedName !== 'brand') {
      deleteCookie(trimmedName);
    }
  });
};

export const isPublicMode = () => getLocalData('mode') === 'public';
export const isCarouselViewList = () =>
  isEmbedded() && getLocalData('list-view') === 'carousel';
export const getListMaxNumCards = () => {
  const numCards = getLocalData('list-view-num-cards');
  const parsedNumber = parseInt(numCards, 10);
  return parsedNumber && !_isNan(parsedNumber) ? parsedNumber : 5;
};
