import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NavBarContext from '../../navBarContext';
import ViewLink from '../ViewLink';
import TopicCard from '../TopicCard';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  lists: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginBottom: 30,
    maxWidth: '100%',
    overflowX: 'auto',
    maxHeight: 215,
  },
}));

function TrendingTopics({ isSubMenu }) {
  const classes = useStyles();
  const { trendingTopics, siteCopy, sendMixpanelEvent } = useContext(
    NavBarContext,
  );
  const clickHandler = (name, element = 'text') => {
    sendMixpanelEvent('Menu Item - Clicked', {
      type: 'Topics',
      name,
      element,
    });
  };
  if (!trendingTopics) return null;
  return (
    <>
      <div className={classes.header}>
        <Typography variant="h6" color="textPrimary">
          {_get(siteCopy, ['pageCopy', 'submenu-topics', 'top-topics'])}
        </Typography>
        {!isSubMenu && (
          <ViewLink label="View all Topics" link="/topics" mt={0} isTextOnly />
        )}
      </div>
      <div className={classes.lists}>
        <TopicCard
          data={trendingTopics.slice(0, isSubMenu ? 4 : 9)}
          clickHandler={clickHandler}
        />
      </div>
    </>
  );
}

TrendingTopics.propTypes = {
  isSubMenu: PropTypes.bool,
};

export default TrendingTopics;
