import React, { useContext } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import useWindowSize from 'components/useWindowSize';
import NavBarContext from '../../navBarContext';
import BlogsNewsMainItem from './BlogsNewsMainItem';
import BlogsNewsLatestItem from './BlogsNewsLatestItem';
import ViewLink from '../ViewLink';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('600')]: {
      display: 'flex',
    },
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '17px 34px',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 20px',
    },
  },
  lists: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 28,
    height: ({ listsHeight }) => listsHeight,
    overflow: 'hidden',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
}));

function Blogs() {
  const { blogs, siteCopy, sendMixpanelEvent } = useContext(NavBarContext);
  const { height } = useWindowSize();

  let listsHeight = 190;
  if (height < 700) listsHeight = 170;

  const classes = useStyles({ listsHeight });

  const clickHandler = (name, element) => {
    sendMixpanelEvent('Menu Item - Clicked', {
      type: 'Blogs',
      name,
      element,
    });
  };

  if (_isEmpty(blogs)) return null;

  const firstItem = _get(blogs, [0, 'fields']);
  const remainingItem = blogs.slice(1, 4);

  return (
    <div className={classes.root}>
      <div className={classes.rightContent}>
        <div
          data-test-id="menu-blog-header"
          className={classes.headerContainer}
        >
          <Typography variant="h6" color="textPrimary">
            {_get(siteCopy, ['pageCopy', 'submenu-blogs', 'Latest'])}
          </Typography>
          <ViewLink
            label={_get(siteCopy, ['pageCopy', 'submenu-blogs', 'view-all'])}
            link="/insights"
            mt={0}
          />
        </div>
        <BlogsNewsMainItem data={firstItem} clickHandler={clickHandler} />
        <Hidden xsDown>
          <div className={classes.lists} data-test-id="blogs-list">
            {remainingItem.map(el => {
              const finalFields = el.fields;
              const { slug } = finalFields;
              return (
                <BlogsNewsLatestItem
                  data={finalFields}
                  type="insights"
                  key={slug}
                />
              );
            })}
          </div>
        </Hidden>
      </div>
    </div>
  );
}

export default Blogs;
