import _get from 'lodash/get';
import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import {
  getTakenCoursesProcessing,
  getTakenCoursesSuccess,
  courseCompletedSeriesSaved,
  updateCourseResourceSaved,
  courseCustomQuestionsSaved,
  courseEnrolled,
  courseSeriesAssessmentSaved,
  courseResourceForLaterSaved,
  courseResourceForLaterProcessing,
  courseResourceForLaterError,
} from './actions';

export const initialState = {
  allCourses: [],
  takenCourses: {},
  takenCoursesProcessing: false,
  courseResourcesSaveForLaterProcessingList: [],
};

const coursesReducer = handleActions(
  {
    [getTakenCoursesProcessing](state, action) {
      return update(state, {
        takenCoursesProcessing: { $set: action.payload },
      });
    },
    [getTakenCoursesSuccess](state, action) {
      return update(state, {
        takenCourses: { $set: action.payload.takenCourses },
        takenCoursesProcessing: { $set: false },
        ...(action.payload.allCourses && {
          allCourses: { $set: action.payload.allCourses },
        }),
      });
    },
    [updateCourseResourceSaved](state, action) {
      const {
        id,
        activeModule,
        completedSeriesIds,
        completedModulesIds,
      } = action.payload;
      return update(state, {
        takenCourses: {
          $merge: {
            [id]: {
              ...state.takenCourses[id],
              ...activeModule,
              completedSeriesIds,
              completedModulesIds,
            },
          },
        },
      });
    },
    [courseCompletedSeriesSaved](state, action) {
      const {
        id,
        seriesProgress,
        completedSeriesIds,
        completedModulesIds,
        completionData,
      } = action.payload;
      return update(state, {
        takenCourses: {
          $merge: {
            [id]: {
              ...state.takenCourses[id],
              completedSeriesIds,
              completedModulesIds,
              seriesProgress,
              ...completionData,
            },
          },
        },
      });
    },
    [courseCustomQuestionsSaved](state, action) {
      const { id, field, updatedFields } = action.payload;
      const taken = state.takenCourses[id];
      return update(state, {
        takenCourses: {
          $merge: {
            [id]: {
              ...state.takenCourses[id],
              [field]: {
                ..._get(taken, field),
                ...updatedFields,
              },
            },
          },
        },
      });
    },
    [courseEnrolled](state, action) {
      const { id } = action.payload;

      return update(state, {
        takenCourses: {
          $merge: {
            [id]: {
              ...state.takenCourses[id],
              enrolled: true,
            },
          },
        },
      });
    },
    [courseSeriesAssessmentSaved](state, action) {
      const { id, updatedCompletedAssessments } = action.payload;
      return update(state, {
        takenCourses: {
          $merge: {
            [id]: {
              ...state.takenCourses[id],
              completedAssessments: updatedCompletedAssessments,
            },
          },
        },
      });
    },
    [courseResourceForLaterSaved](state, action) {
      const { id, resourceId, updatedSavedResources, field } = action.payload;

      // Remove id from processing list
      const updatedSavedResourcesProcessingList = state.courseResourcesSaveForLaterProcessingList.filter(
        savedResourceId => savedResourceId !== resourceId,
      );

      return update(state, {
        courseResourcesSaveForLaterProcessingList: {
          $set: updatedSavedResourcesProcessingList,
        },
        takenCourses: {
          $merge: {
            [id]: {
              ...state.takenCourses[id],
              [field]: updatedSavedResources,
            },
          },
        },
      });
    },
    [courseResourceForLaterError](state, action) {
      const { resourceId } = action.payload;
      const updatedSavedResourcesProcessingList = state.courseResourcesSaveForLaterProcessingList.filter(
        savedResourceId => savedResourceId !== resourceId,
      );
      return update(state, {
        courseResourcesSaveForLaterProcessingList: {
          $set: updatedSavedResourcesProcessingList,
        },
      });
    },
    [courseResourceForLaterProcessing](state, action) {
      const { resourceId } = action.payload;
      return update(state, {
        courseResourcesSaveForLaterProcessingList: {
          $push: [resourceId],
        },
      });
    },
  },
  initialState,
);

export default coursesReducer;
