import md5 from 'md5';
import stringify from 'fast-json-stable-stringify';

const cachedMd5Lookup = new Map();
const cachedMd5 = arg => {
  const string = typeof arg === 'object' ? stringify(arg) : arg;
  if (!cachedMd5Lookup.has(string))
    cachedMd5Lookup.set(string, md5(string).substr(0, 8));

  return cachedMd5Lookup.get(string);
};

export default cachedMd5;
