/**
 *
 * InactiveState
 *
 */

import React, { useEffect } from 'react';
import AppContainer from 'components/AppContainer';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import _get from 'lodash/get';
import useSiteCopySelector from 'components/useSiteCopySelector';
import CMLogo from 'components/UserReviews/images/cm.svg';
import Mixpanel from 'utils/mixpanelService';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '12vh',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20vh',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '15vh',
    },
  },
  mainText: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  message: {
    fontSize: '1rem',
    fontWeight: 'normal',
  },
  pageContainer: {
    [theme.breakpoints.up('md')]: {
      padding: '0 10rem',
    },
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
  },
  logo: {
    width: 120,
  },
}));

function InactiveState() {
  const [retiredClientSiteCopy] = useSiteCopySelector(['retired-client-page']);
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      Mixpanel.track('Retired Client - Viewed', {
        path: window.location.pathname,
      });
    }, 100);
  }, []);

  return (
    <>
      <AppContainer
        justify="center"
        alignItems="center"
        customClasses={classes}
      >
        <div className={classes.pageContainer}>
          <div>
            <img
              src={_get(retiredClientSiteCopy, 'imageUrl') || CMLogo}
              alt="CredibleMind"
              className={classes.logo}
            />
          </div>
          <div>
            <Typography
              variant="h2"
              className={classes.mainText}
              color="textPrimary"
              align="center"
            >
              {_get(
                retiredClientSiteCopy,
                'pageCopy.pageHeader',
                'We regret to inform you that this platform is no longer available as a free service.',
              )}
            </Typography>
          </div>
          <div>
            <Typography variant="h3" className={classes.message} align="center">
              {_get(
                retiredClientSiteCopy,
                'pageCopy.pageBody',
                'If you have any questions about CredibleMind or are looking for additional mental health and wellbeing resources, please reach out to your sponsoring organization for more information.',
              )}
            </Typography>
          </div>
        </div>
      </AppContainer>
    </>
  );
}

export default React.memo(InactiveState);
