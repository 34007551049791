import React from 'react';

export default (color = '#FFFFFF') => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.8042 17.5L13.3829 10.2439C13.4106 10.2133 13.4327 10.1758 13.4479 10.1337C13.463 10.0917 13.4709 10.0461 13.4709 10C13.4709 9.9539 13.463 9.90833 13.4479 9.86628C13.4327 9.82424 13.4106 9.78668 13.3829 9.75609L6.8042 2.5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
