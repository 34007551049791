import _isEmpty from 'lodash/isEmpty';

export function filterBySlug(data, slug) {
  if (_isEmpty(data)) return {};
  return data.filter(el => el.slug === slug)[0];
}

export function filterByTitle(data, title) {
  if (_isEmpty(data)) return {};
  return data.filter(el => el.title === title)[0];
}
