import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import { createStructuredSelector } from 'reselect';
import { emitter } from '@marvelapp/react-ab-test';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { FREE_CLIENTS } from 'components/NavBarMenu/constants';

const stateSelector = createStructuredSelector({
  clientDetails: makeSelectClientDetails(),
});

function useShortMenu() {
  const { clientDetails } = useSelector(stateSelector);
  const [shortMenu, setShortMenu] = useState(false);
  const [hasClient, setHasClient] = useState(false);
  const activeVariant = emitter.getActiveVariant('main-menu');
  useEffect(() => {
    if (!_isEmpty(clientDetails)) {
      if (
        FREE_CLIENTS.includes(_get(clientDetails, 'shortName')) &&
        activeVariant === 'variantD'
      ) {
        setShortMenu(true);
      } else if (!FREE_CLIENTS.includes(_get(clientDetails, 'shortName'))) {
        setShortMenu(true);
      } else {
        setShortMenu(false);
      }
      setHasClient(true);
    } else if (
      activeVariant === 'variantD' ||
      (_isEmpty(clientDetails) && activeVariant === 'variantD')
    ) {
      setShortMenu(true);
    } else {
      setShortMenu(false);
      setHasClient(false);
    }
  }, [clientDetails, activeVariant]);
  return { shortMenu, hasClient };
}

export default useShortMenu;
