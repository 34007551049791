import { useEffect, useState } from 'react';
import { getFirebase } from 'react-redux-firebase';
import { getLocalData } from 'utils/localDataStore';
import { deleteCookie } from 'utils/cookieStore';

export const useCustomTokenSignIn = () => {
  const [isSignInProcessing, setIsSignInProcessing] = useState(() =>
    getLocalData('customToken'),
  );

  async function signIn(customToken) {
    try {
      await getFirebase()
        .auth()
        .signInWithCustomToken(customToken);
    } finally {
      setIsSignInProcessing(false);
      deleteCookie('customToken');
    }
  }

  useEffect(() => {
    const token = getLocalData('customToken');
    if (token) {
      signIn(token);
    }
  }, []);

  return isSignInProcessing;
};
