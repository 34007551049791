/* eslint-disable no-restricted-syntax */
import _get from 'lodash/get';
import ctf from 'utils/contentfulService';

export const getListsFn = async (
  _,
  { listsIds, clientExcludedTopicIds = [], localeFilters, isResources },
) => {
  const response = await ctf.getEntries({
    content_type: 'list',
    'fields.reviewStatus': 'Accepted',
    'sys.id[in]': listsIds.join(),
    'fields.whatHelpsTopics.sys.id[nin]': clientExcludedTopicIds.join(','),
    include: 3,
    ...localeFilters,
  });
  const sortedItems = [];
  const items = _get(response, 'items');

  if (isResources) return _get(items, '0', []);

  for (const listId of listsIds) {
    const matchedItem = items.find(item => _get(item, 'sys.id') === listId);
    const nonExcludedTopics = _get(matchedItem, 'fields.resources', []).filter(
      topic => !clientExcludedTopicIds.includes(topic.sys.id),
    );
    if (nonExcludedTopics.length) {
      matchedItem.fields.resources = nonExcludedTopics;
      sortedItems.push(matchedItem);
    }
  }

  return sortedItems;
};

export const getTopicsInfoFn = async (_, topicIds, localeFilters) => {
  const response = await ctf.getEntries({
    content_type: 'topics',
    'fields.reviewStatus': 'Accepted',
    'sys.id[in]': topicIds.join(),
    select: ['sys', 'fields.title', 'fields.shortTitle'].join(','),
    ...localeFilters,
  });

  return _get(response, 'items', []);
};
