/*
 *
 * Assessments constants
 *
 */

export const PROCESSING = 'app/Assessments/PROCESSING';
export const GET_ASSESSMENTS = 'app/Assessments/GET_ASSESSMENTS';
export const GET_ASSESSMENTS_SUCCESS =
  'app/Assessments/GET_ASSESSMENTS_SUCCESS';
export const GET_ASSESSMENTS_FAIL = 'app/Assessments/GET_ASSESSMENTS_FAIL';
export const GET_TAKEN_ASSESSMENTS = 'app/Assessments/GET_TAKEN_ASSESSMENTS';
export const GET_TAKEN_ASSESSMENTS_RESULT =
  'app/Assessments/GET_TAKEN_ASSESSMENTS_RESULT';
export const GET_SAVED_ASSESSMENTS = 'app/Assessments/GET_SAVED_ASSESSMENTS';
export const GET_SAVED_ASSESSMENTS_RESULT =
  'app/Assessments/GET_SAVED_ASSESSMENTS_RESULT';
export const GET_RECOMMENDED_RESOURCES =
  'app/Assessments/GET_RECOMMENDED_RESOURCES';
export const GET_RECOMMENDED_RESOURCES_RESULT =
  'app/Assessments/GET_RECOMMENDED_RESOURCES_RESULT';
export const SAVE_ASSESSMENT = 'app/Assessments/SAVE_ASSESSMENT';
export const SAVE_ASSESSMENT_RESULT = 'app/Assessments/SAVE_ASSESSMENT_RESULT';
export const SECTION_PROCESSING = 'app/Assessments/SECTION_PROCESSING';
export const GET_ALL_ASSESSMENTS_RESULTS =
  'app/Assessments/GET_ALL_ASSESSMENTS_RESULTS';
export const GET_ALL_ASSESSMENTS_RESULTS_RESULT =
  'app/Assessments/GET_ALL_ASSESSMENTS_RESULTS_RESULT';
