/**
 *
 * AppContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ClassNames from 'classnames';
import _get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import { embeddedStyles, isValidPadding } from 'utils/embedded';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
  },
  container: {
    [theme.breakpoints.up('1140')]: {
      maxWidth: 1140,
      padding: '24px 14px',
    },
    width: '100%',
    padding: '24px 5%',
  },
  embedContainer: {
    [theme.breakpoints.up('1140')]: {
      padding: props => (props.padding ? props.padding : '24px 14px'),
    },
    padding: props => (props.padding ? props.padding : '24px 5%'),
  },
  customPadding: {
    [theme.breakpoints.up('970')]: {
      padding: '0px 14px',
    },
    padding: '0 5%',
  },
  topPadding: {
    padding: '14px 0',
    width: '100%',
  },
  inverseBackground: {
    background: '#F8F9F9',
  },
}));

function AppContainer({
  children,
  justify,
  align,
  customPadding,
  topPadding,
  inverse,
  spacing,
  id,
  customClasses,
}) {
  const embedStyles = embeddedStyles();

  const embedContainerPadding = _get(embedStyles, 'containerPadding', false);
  const hasValidEmbedPadding = embedContainerPadding
    ? isValidPadding(embedContainerPadding)
    : false;
  const classes = useStyles(
    hasValidEmbedPadding ? { padding: embedContainerPadding } : {},
  );

  return (
    <div
      className={ClassNames(
        classes.root,
        inverse ? classes.inverseBackground : undefined,
        customClasses.root,
      )}
      id={id}
    >
      <Grid
        container
        spacing={spacing}
        className={ClassNames(classes.container, customClasses.container, {
          [classes.embedContainer]: hasValidEmbedPadding,
          [classes.customPadding]: customPadding,
          [classes.topPadding]: topPadding,
        })}
        justifyContent={justify}
        alignItems={align}
      >
        {children}
      </Grid>
    </div>
  );
}

AppContainer.propTypes = {
  customClasses: PropTypes.object,
  children: PropTypes.node,
  justify: PropTypes.string,
  align: PropTypes.string,
  customPadding: PropTypes.bool,
  topPadding: PropTypes.bool,
  inverse: PropTypes.bool,
  spacing: PropTypes.number,
};

AppContainer.defaultProps = {
  justify: 'center',
  align: 'flex-start',
  inverse: false,
  spacing: 1,
  customClasses: {},
};

export default AppContainer;
