import { select } from 'redux-saga/effects';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

export const getAlgoliaLocalizedField = (
  data,
  field,
  locale = 'en-US',
  fallback = '',
) => {
  const prefix = locale === 'en-US' ? '' : `${locale}_`;
  const key = `${prefix}${field}`;
  const fallbackKey = field;

  const value = _get(data, key);
  if (
    (Array.isArray(value) && !_isEmpty(value)) ||
    (!Array.isArray(value) && value)
  ) {
    return value;
  }

  return _get(data, fallbackKey, fallback);
};

export function* getAlgoliaLocaleCodeGenerator() {
  const language = yield select(s => s.main.language);
  return language || 'en-US';
}
