import React from 'react';
import _get from 'lodash/get';
import { getFormattedType } from 'components/ResourceItemSelector/utils';
import { AssessmentCard } from './AssessmentCard';

const ResourceCard = () => <div></div>;

export const ReferenceCard = ({ reference }) => {
  const type = getFormattedType(_get(reference, 'sys.contentType.sys.id'));

  if (type === 'assessment')
    return <AssessmentCard item={_get(reference, 'fields')} />;

  // TODO: Add other resources as reference
  if (['article', 'video', 'podcast'].includes(type)) {
    const route = {
      article: '/articles',
      video: '/videos',
      podcast: '/podcasts',
    };

    return (
      <ResourceCard
        item={_get(reference, 'fields')}
        route={route[type]}
        type={type}
      />
    );
  }

  return <></>;
};
