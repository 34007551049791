import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import _isNil from 'lodash/isNil';
import { useHistory, useLocation } from 'react-router-dom';
import { makeSelectAuth } from 'containers/Auth/selectors';
import { showHomepagePro } from 'containers/Main/actions';
import { getLocalData } from 'utils/localDataStore';
import { getSubdomain } from 'utils/stringUtils';
import { isEmbedded } from 'utils/embedded';

function useHomepagePro() {
  const auth = useSelector(makeSelectAuth());
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const params = qs.parse(location.search.slice(1));
  const initialTouch = useRef(location.pathname);
  const shouldSkip = useRef(params.skip === '1');
  const hasAuthenticated = useRef(false);

  const brand = getLocalData('brand');
  const isSubdomain = !_isNil(getSubdomain());

  useEffect(() => {
    if (!params.skip) return;

    delete params.skip;
    history.replace(`${location.pathname}?${qs.stringify(params)}`);
  }, [params]);

  useEffect(() => {
    const isBranded = brand && brand !== 'none';
    if (
      !hasAuthenticated.current &&
      (initialTouch.current === '/' ||
        initialTouch.current === '/login' ||
        initialTouch.current === '/forgot_password' ||
        initialTouch.current === '/new_password' ||
        initialTouch.current === '/signup') &&
      !shouldSkip.current &&
      !isBranded &&
      !isSubdomain &&
      auth.isLoaded &&
      auth.isEmpty &&
      !isEmbedded()
    )
      dispatch(showHomepagePro(true));
    else if (auth.isLoaded && !auth.isEmpty) dispatch(showHomepagePro(false));
  }, [auth, brand, dispatch]);
  useEffect(() => {
    if (auth.isLoaded && !auth.isEmpty) hasAuthenticated.current = true;
  }, [auth]);
}

export default useHomepagePro;
