import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    padding: '8px 0px',
  },
  paper: {
    borderRadius: '5px !important',
    padding: '24px',
    [theme.breakpoints.down('xs')]: {
      padding: '8px',
    },
  },
  footer: {
    paddingTop: '16px',
    borderTop: '1px solid #E3E3E3',
    flexWrap: 'wrap',
  },
  button: {
    minWidth: '150px',
    padding: '10px',
  },
}));

const ModalDialog = ({
  children,
  open,
  renderHeader,
  onClose,
  maxWidth = 'md',
  buttons = [],
}) => {
  const classes = useStyles();

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        {typeof renderHeader === 'function' ? renderHeader() : renderHeader}
      </DialogTitle>
      <DialogContent>
        {typeof children === 'function' ? children() : children}
      </DialogContent>
      <DialogActions className={classes.footer}>
        {buttons.map(button => (
          <Button
            key={button.label}
            onClick={button.onClick}
            variant={button.variant}
            color={button.color}
            className={classes.button}
            disabled={button.disabled}
          >
            {button.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

ModalDialog.propTypes = {
  open: PropTypes.bool,
  renderHeader: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  maxWidth: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      variant: PropTypes.string,
      color: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
};

export default ModalDialog;
