import React from 'react';
import _classNames from 'classnames';
import { Box, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import Mixpanel from 'utils/mixpanelService';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import useSiteCopySelector from 'components/useSiteCopySelector';
import useNavbarElementPosition from 'components/Hooks/useNavbarElementPosition';
import {
  replacePlaceholders,
  replaceRichTextPlaceholders,
} from 'utils/stringUtils';
import RichTextReactRenderer from 'components/RichTextReactRenderer';
import { makeSelectClientLanding } from 'containers/ClientLanding/selectors';
import {
  CONTENT_BLOCK_THEME,
  CONTENT_BLOCK_DISMISS_OPTIONS,
  CONTENT_BLOCK_KEYS,
  CONTENT_BLOCK_POSITIONS,
} from '../../utils';
import { AdminButton } from '../AdminButton';

const mapBgThemeToBeforeStyles = {
  [CONTENT_BLOCK_KEYS.DEFAULT_WHITE]: {
    backgroundColor: '#E3E3E3',
  },
  [CONTENT_BLOCK_KEYS.CM_BLUE]: {
    backgroundColor:
      CONTENT_BLOCK_THEME[CONTENT_BLOCK_KEYS.CM_BLUE].backgroundColor,
  },
  [CONTENT_BLOCK_KEYS.CM_BLUE_LIGHT]: {
    backgroundColor: '#01619b',
  },
  [CONTENT_BLOCK_KEYS.SUPPORT_BLUE]: {
    backgroundColor:
      CONTENT_BLOCK_THEME[CONTENT_BLOCK_KEYS.SUPPORT_BLUE].backgroundColor,
  },
  [CONTENT_BLOCK_KEYS.SUPPORT_BLUE_LIGHT]: {
    backgroundColor: '#1e62c0',
  },
  [CONTENT_BLOCK_KEYS.LIGHT_YELLOW]: {
    backgroundColor: '#FFA900',
  },
  [CONTENT_BLOCK_KEYS.LIGHT_GRAY]: {
    backgroundColor:
      CONTENT_BLOCK_THEME[CONTENT_BLOCK_KEYS.LIGHT_GRAY].backgroundColor,
  },
};

const useStyles = makeStyles({
  container: props => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: props.shouldHaveStraightEdges ? 0 : 5,
    marginTop: props.hasTopMargin && 24,
    marginBottom: props.hasBottomMargin && 24,
    ...CONTENT_BLOCK_THEME[props.backgroundColor],
    '&::before': {
      content: "''",
      position: 'absolute',
      left: 0,
      top: 0,
      width: 8,
      height: '100%',
      borderTopLeftRadius: props.shouldHaveStraightEdges ? 0 : 5,
      borderBottomLeftRadius: props.shouldHaveStraightEdges ? 0 : 5,
      ...mapBgThemeToBeforeStyles[props.backgroundColor],
    },
  }),
  adminPadding: {
    padding: '8px 8px 8px 20px',
  },
  adminPaddingWithClose: {
    padding: '8px 20px',
  },
  defaultPadding: {
    padding: '16px 20px',
  },
  closeIcon: {
    height: 16,
    width: 16,
    color: '#c1bdbd',
    cursor: 'pointer',
    marginLeft: 16,
  },
});

const shouldShowCloseIcon = dismissOptions =>
  dismissOptions === CONTENT_BLOCK_DISMISS_OPTIONS.DISMISSIBLE_ONCE ||
  dismissOptions ===
    CONTENT_BLOCK_DISMISS_OPTIONS.DISMISSIBLE_CONTINUOUS_UNTIL_CLOSE;

const LANDING_PAGE_REGEX = /^\/landing\/([a-zA-Z0-9-]+)/;

const hasLandingAssociatedResourceFromUrl = (
  associatedPages,
  clientLanding,
) => {
  const { pathname } = window.location;

  if (LANDING_PAGE_REGEX.test(pathname)) {
    const [, slug] = pathname.match(LANDING_PAGE_REGEX);

    return associatedPages.some(
      resource => _get(resource, 'fields.slug') === slug,
    );
  }

  if (pathname === '/') {
    const homePageId = _get(clientLanding, 'sys.id');

    return associatedPages.some(
      resource => _get(resource, 'sys.id') === homePageId,
    );
  }

  return false;
};

const shouldApplyHeaderPadding = ({
  pagePosition,
  commonPages = [],
  associatedPages = [],
  clientLanding,
}) => {
  if (
    [
      CONTENT_BLOCK_POSITIONS.WINDOW_TOP_STICKY,
      CONTENT_BLOCK_POSITIONS.WINDOW_TOP_SCROLLS,
      CONTENT_BLOCK_POSITIONS.BELOW_MENU_STICKY,
      CONTENT_BLOCK_POSITIONS.BELOW_MENU_SCROLLS,
    ].includes(pagePosition)
  )
    return true;

  const isBelowHero = [
    CONTENT_BLOCK_POSITIONS.BELOW_HERO_INTRO,
    CONTENT_BLOCK_POSITIONS.BELOW_HERO_INTRO_SCROLLS,
  ].includes(pagePosition);

  if (!isBelowHero) return false;

  // If it's below hero and the common page is all-home, then it should use header padding
  if (commonPages.length === 1 && commonPages.includes('all-home')) return true;

  const landingAssociatedPages = associatedPages.filter(
    page => _get(page, 'sys.contentType.sys.id') === 'landing',
  );
  // If there's no landing associated pages, then it should use not header padding
  if (!landingAssociatedPages.length) return false;

  // Get the associated page for the current page
  const hasLandingAssociatedPage = hasLandingAssociatedResourceFromUrl(
    landingAssociatedPages,
    clientLanding,
  );

  return hasLandingAssociatedPage;
};

const Alert = ({
  data,
  onDismiss,
  rounded,
  marginTop,
  marginBottom,
  isAdmin,
  isPreview,
  canApplyHeaderPadding = true,
}) => {
  const navBarPosition = useNavbarElementPosition();

  const [disclaimerSiteCopy] = useSiteCopySelector(['resources-disclaimer']);
  const clientDetails = useSelector(makeSelectClientDetails());
  const clientLanding = useSelector(makeSelectClientLanding());

  const {
    dismissOptions,
    backgroundColor,
    bodyContent,
    pagePosition,
    associatedPages,
    commonPages,
  } = data;

  const shouldHaveStraightEdges =
    rounded === undefined
      ? [
          CONTENT_BLOCK_POSITIONS.WINDOW_TOP_STICKY,
          CONTENT_BLOCK_POSITIONS.WINDOW_TOP_SCROLLS,
          CONTENT_BLOCK_POSITIONS.BELOW_MENU_STICKY,
          CONTENT_BLOCK_POSITIONS.ABOVE_FOOTER,
          CONTENT_BLOCK_POSITIONS.BELOW_FOOTER,
          CONTENT_BLOCK_POSITIONS.USE_LANDING_SECTIONS,
        ].includes(pagePosition)
      : !rounded;

  const hasTopMargin =
    marginTop === undefined
      ? pagePosition === CONTENT_BLOCK_POSITIONS.BELOW_HERO_INTRO
      : marginTop;
  const hasBottomMargin =
    marginBottom === undefined
      ? !pagePosition.includes('Sticky') &&
        pagePosition !== CONTENT_BLOCK_POSITIONS.ASSESSMENT_RESULTS
      : marginBottom;

  const classes = useStyles({
    backgroundColor,
    hasTopMargin,
    hasBottomMargin,
    shouldHaveStraightEdges,
  });

  const { color, linkColor } = CONTENT_BLOCK_THEME[backgroundColor];

  const showCloseIcon = shouldShowCloseIcon(dismissOptions);

  const clientSuffix = _isEmpty(clientDetails)
    ? ''
    : replacePlaceholders(
        _get(disclaimerSiteCopy, 'pageCopy.clientSuffix', ''),
        {
          '#clientName': _get(clientDetails, 'name'),
          '#clientFormatShortName': _get(clientDetails, 'formalShortName'),
        },
      );

  const handleCMLinkClick = () => {
    Mixpanel.track('Resource Card - Clicked', {
      path: `Alert - ${pagePosition || ''}`,
    });
  };

  const shouldApplyNavBarPadding =
    canApplyHeaderPadding &&
    shouldApplyHeaderPadding({
      pagePosition,
      commonPages,
      associatedPages,
      clientLanding,
    });

  const navBarPositionHorizontalPadding = `${navBarPosition.left}px`;

  return (
    <Box
      className={_classNames({
        [classes.container]: true,
        [classes.adminPadding]: isAdmin,
        [classes.defaultPadding]: !isAdmin,
        [classes.adminPaddingWithClose]: isAdmin && showCloseIcon,
      })}
      style={
        shouldApplyNavBarPadding
          ? {
              paddingLeft: navBarPositionHorizontalPadding,
              paddingRight: navBarPositionHorizontalPadding,
            }
          : {}
      }
      role="alert"
    >
      <Box mr={1}>
        <RichTextReactRenderer
          data={replaceRichTextPlaceholders(bodyContent, {
            '#clientSuffix': clientSuffix,
            '#clientName': _get(clientDetails, 'name'),
            '#clientFormatShortName': _get(clientDetails, 'formalShortName'),
          })}
          paragraphVariant="overline"
          color={color}
          linkColor={linkColor}
          onCMLinkClick={handleCMLinkClick}
        />
      </Box>
      {isAdmin && !isPreview && <AdminButton data={data} />}
      {showCloseIcon && (
        <CloseIcon
          onClick={onDismiss}
          className={_classNames({
            [classes.closeIcon]: true,
          })}
        />
      )}
    </Box>
  );
};

export default Alert;
