/*
 *
 * Home constants
 *
 */

export const DEFAULT_ACTION = 'app/Home/DEFAULT_ACTION';
export const PROCESSING = 'app/HomePage/PROCESSING';
export const EVENT_PROCESSING = 'app/HomePage/EVENT_PROCESSING';
export const GET_HOME_ASSESSMENTS = 'app/HomePage/GET_HOME_ASSESSMENTS';
export const GET_HOME_ASSESSMENTS_SUCCESS =
  'app/HomePage/GET_HOME_ASSESSMENTS_SUCCESS';
export const GET_HOME_NEWS = 'app/HomePage/GET_HOME_NEWS';
export const GET_HOME_NEWS_SUCCESS = 'app/HomePage/GET_HOME_NEWS_SUCCESS';
export const GET_HOME_BLOGS = 'app/HomePage/GET_HOME_BLOGS';
export const GET_HOME_BLOGS_SUCCESS = 'app/HomePage/GET_HOME_BLOGS_SUCCESS';
export const SET_GUIDE_ME_OPENED = 'app/HomePage/SET_GUIDE_ME_OPENED';
