import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import useSiteCopySelector from 'components/useSiteCopySelector';
import _get from 'lodash/get';
import { useIdleConfig } from './hooks/useIdleConfig';
import useMediaIdleControl from '../../components/Hooks/useMediaIdleControl';

const DEFAULT_IDLE_TIME_IN_SEC = 1200; // 20 mins
const PROMPT_TIMEOUT_IN_MS = 5000; // 5 sec

export const IdleTimeoutContext = createContext(null);

export const IdleTimeoutProvider = ({ children, onPrompt, closePrompt }) => {
  const [siteCopy] = useSiteCopySelector(['inactivity-modal']);
  const promptTimeoutInSec = _get(
    siteCopy,
    'pageCopy.inactivityModalExpirationTime',
    0,
  );
  const {
    enable,
    timeout = DEFAULT_IDLE_TIME_IN_SEC,
    handleIdle,
  } = useIdleConfig();
  const promptTimeoutInMs = promptTimeoutInSec * 1000 || PROMPT_TIMEOUT_IN_MS;
  const finalIdleTime = timeout - promptTimeoutInSec;
  const idleTimeout = 1000 * finalIdleTime;

  const onIdle = () => {
    closePrompt();
    handleIdle();
  };

  const idleTimer = useIdleTimer(
    useMemo(
      () => ({
        timeout: idleTimeout,
        promptTimeout: promptTimeoutInMs,
        onPrompt,
        onIdle,
        disabled: !enable,
        debounce: 500,
        startManually: true,
      }),
      [idleTimeout, promptTimeoutInMs],
    ),
  );

  useEffect(() => {
    if (enable) {
      idleTimer.start();
    } else {
      idleTimer.pause();
    }
  }, [enable]);

  useMediaIdleControl({
    pause: idleTimer.pause,
    start: idleTimer.start,
    enable,
  });

  const value = useMemo(() => ({ idleTimer }), [idleTimer]);

  return (
    <IdleTimeoutContext.Provider value={value}>
      {children}
    </IdleTimeoutContext.Provider>
  );
};

export const useIdleTimeoutContext = () => {
  const context = useContext(IdleTimeoutContext);
  if (!context) {
    throw new Error(
      'useIdleTimeout must be used within an IdleTimeoutProvider',
    );
  }
  return context;
};
