import React from 'react';
import { useSelector } from 'react-redux';
import _classNames from 'classnames';
import _get from 'lodash/get';
import {
  getCompletedSeries,
  getTakenSeriesProcessing,
} from 'containers/Series/selectors';
import { makeStyles } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';

export const useMyLearningBadgeStyles = makeStyles(theme => ({
  badge: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 18,
    minWidth: 18,
    padding: '0 4px',
    borderRadius: '10px',
    color: theme.palette.text.white,
    backgroundColor: '#377A00',
    fontSize: '0.625rem',
    lineHeight: 1,
    whiteSpace: 'nowrap',
  },
}));

const selector = createStructuredSelector({
  completedSeries: getCompletedSeries,
  takenSeriesProcessing: getTakenSeriesProcessing,
});

export const MyLearningBadge = ({ forceShow, className, siteCopy }) => {
  const classes = useMyLearningBadgeStyles();
  const { takenSeriesProcessing, completedSeries } = useSelector(selector);

  if (takenSeriesProcessing || (!forceShow && completedSeries.length)) {
    return null;
  }

  return (
    <span className={_classNames(classes.badge, className)}>
      {_get(siteCopy, ['pageCopy', 'dialog-menu', 'new'], 'NEW')}
    </span>
  );
};
