/**
 *
 * useInsuranceModal
 *
 */

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { makeSelectAuth, makeSelectProfile } from 'containers/Auth/selectors';
import {
  hideInsuranceModal,
  showInsuranceModal,
} from 'containers/Main/actions';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import useSiteConfigSelector from 'components/useSiteConfigSelector';
import { getLocalData } from 'utils/localDataStore';
import { getLowerCaseEmailDomain } from 'utils/stringUtils';

function useInsuranceModal() {
  const dispatch = useDispatch();
  const auth = useSelector(makeSelectAuth());
  const profile = useSelector(makeSelectProfile());
  const clientDetails = useSelector(makeSelectClientDetails());
  const [siteConfig] = useSiteConfigSelector(['Insurance']);

  const shouldShowInsuranceType = _get(
    clientDetails,
    'metadata.showInsuranceType',
    false,
  );

  const isEnabled =
    _get(siteConfig, 'config.onboardingEnabledClients', []).includes(
      _get(clientDetails, 'shortName'),
    ) && shouldShowInsuranceType;

  const showInsuranceModalOnLoad = _get(
    clientDetails,
    'metadata.showInsuranceModalOnLoad',
    false,
  );

  useEffect(() => {
    if (profile.isLoaded && showInsuranceModalOnLoad) {
      const hasInsuranceSet =
        (!profile.isEmpty && !_isEmpty(profile.insurance)) ||
        (profile.isEmpty && !_isEmpty(getLocalData('insurance')));

      if (isEnabled && !hasInsuranceSet) {
        dispatch(
          showInsuranceModal({
            type: 'common',
          }),
        );
      } else {
        dispatch(hideInsuranceModal());
      }
    }
  }, [dispatch, isEnabled, profile, showInsuranceModalOnLoad]);

  useEffect(() => {
    if (profile.isLoaded) {
      const hasInsuranceSet =
        (!profile.isEmpty && !_isEmpty(profile.insurance)) ||
        (profile.isEmpty && !_isEmpty(getLocalData('insurance')));
      const requiredEmailDomains = _get(
        clientDetails,
        'context.requiredEmailDomains',
        [],
      );

      const shouldLimitEmailDomains = !_isEmpty(requiredEmailDomains);
      const isDomainAllowed =
        !shouldLimitEmailDomains ||
        requiredEmailDomains.includes(
          getLowerCaseEmailDomain(_get(profile, 'email') || ''),
        );

      if (
        isEnabled &&
        !profile.isEmpty &&
        !hasInsuranceSet &&
        isDomainAllowed &&
        Date.now() - auth.createdAt < 5 * 60 * 1000
      ) {
        setTimeout(() => {
          dispatch(
            showInsuranceModal({
              type: 'sign-up',
            }),
          );
        }, 2000);
      } else {
        dispatch(hideInsuranceModal());
      }
    }
  }, [auth, dispatch, isEnabled, profile]);
}

export default useInsuranceModal;
