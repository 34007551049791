import React from 'react';

export default (color = '#FFFFFF') => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>streamline-icon-interface-link@48x48</title>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Link-and-Local-Resources---2021---2"
        transform="translate(-412.000000, -440.000000)"
        stroke={color}
        strokeWidth="0.857142857"
      >
        <g id="Link-Resources" transform="translate(404.000000, 220.000000)">
          <g transform="translate(0.000000, 87.000000)" id="Link">
            <g transform="translate(9.000000, 130.000000)">
              <g
                id="streamline-icon-interface-link@48x48"
                transform="translate(0.000000, 4.000000)"
              >
                <path
                  d="M2.97065011,5.06884615 L0.250645715,7.75721722 C0.090230997,7.91595505 0,8.13203572 0,8.35745101 C0,8.58286631 0.090230997,8.79894697 0.250645715,8.9576848 L2.04703491,10.7499322 C2.20613958,10.909977 2.4227196,11 2.64865582,11 C2.87459205,11 3.09117207,10.909977 3.25027673,10.7499322 L5.94486053,8.03619912"
                  id="Path"
                ></path>
                <path
                  d="M8.05477049,5.93115385 L10.7493543,3.24278278 C10.909769,3.08404495 11,2.86796428 11,2.64254899 C11,2.41713369 10.909769,2.20105303 10.7493543,2.0423152 L8.97838569,0.250067817 C8.81928102,0.0900229572 8.602701,0 8.37676478,0 C8.15082856,0 7.93424853,0.0900229572 7.77514387,0.250067817 L5.08056007,2.96380088"
                  id="Path"
                ></path>
                <line
                  x1="7.20741709"
                  y1="3.80920059"
                  x2="3.81800351"
                  y2="7.19079941"
                  id="Path"
                ></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
