import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core';
import _get from 'lodash/get';
import _classNames from 'classnames';
import AdminBtn from 'components/ResourcePageLeftPanel/AdminBtn';
import { CONTENT_BLOCK_KEYS } from '../../utils';

const useStyles = makeStyles({
  whiteIcon: {
    color: '#FFFFFF !important',
  },
  adminButtonContainer: {
    marginLeft: 'auto',
  },
  adminButton: {
    marginBottom: 0,
  },
});

export const AdminButton = ({ data }) => {
  const classes = useStyles();
  return (
    <AdminBtn
      id={data.sys.id}
      tooltipId={data.sys.id}
      tooltipProps={{ place: 'left' }}
      linkClassName={_classNames({
        [classes.adminButtonContainer]: true,
        [classes.whiteIcon]:
          _get(data, 'backgroundColor') === CONTENT_BLOCK_KEYS.CM_BLUE ||
          _get(data, 'backgroundColor') === CONTENT_BLOCK_KEYS.SUPPORT_BLUE,
      })}
      btnClassName={classes.adminButton}
      icon={<SettingsIcon color="inherit" />}
    />
  );
};
