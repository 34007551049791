import React from 'react';
import InActivityModalConfirmation from 'components/InactivityModalConfirmation';
import { useIdleTimeoutContext } from 'containers/InactivityTimeout/IdleTimeoutContext';

const InactivityTimeout = ({ closeDialog, isDialogOpen }) => {
  const { idleTimer } = useIdleTimeoutContext();
  const continueSession = () => {
    closeDialog();
    idleTimer.start();
  };

  return (
    isDialogOpen && (
      <InActivityModalConfirmation clickHandler={continueSession} />
    )
  );
};

export default InactivityTimeout;
