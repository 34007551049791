/**
 *
 * useAICC
 *
 */

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _trim from 'lodash/trim';
import _unescape from 'lodash/unescape';
import { getAICCUser } from 'containers/Main/actions';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import { getAICC, setCompletionAICC } from 'utils/aiccService';

const getUrlVar = urlVar => {
  const match = RegExp(`[?&]${urlVar}=([^&]*)`).exec(window.location.search);
  return _unescape(match && decodeURIComponent(match[1].replace(/\+/g, ' ')));
};

const parseAiccResponse = response => {
  const responseObject = {};
  const responseLines = response.split('\n');

  for (let i = 0; i < responseLines.length; i += 1) {
    const line = _trim(responseLines[i]);
    const pairs = line.split('=');
    if (pairs.length === 2) {
      const key = _trim(pairs[0].toLowerCase());
      const keyL = key.split(']');
      if (keyL.length === 2) {
        _trim(keyL[1].toLowerCase());
      }

      const value = _trim(pairs[1]);
      responseObject[key] = value;
    }
  }
  return responseObject;
};

function useAICC() {
  const clientDetails = useSelector(makeSelectClientDetails());
  const dispatch = useDispatch();
  const history = useHistory();
  const [userData, setUserData] = useState();
  const [config, setConfig] = useState({});
  const location = useLocation();
  const touchURL = useRef(location.pathname);

  const isAICC = _get(clientDetails, 'aicc', false);
  useEffect(() => {
    if (isAICC) {
      const aiccSid = getUrlVar('AICC_SID');
      const aiccUrl = getUrlVar('AICC_URL');
      if (aiccSid && aiccUrl) {
        const finalUrl = new URL(
          window.location.href.includes('https://')
            ? aiccUrl.replace('http://', 'https://')
            : aiccUrl,
        );
        setConfig({ url: finalUrl, sid: aiccSid });

        (async () => {
          try {
            const response = await getAICC(
              finalUrl.origin,
              finalUrl.pathname.slice(1),
              aiccSid,
            );
            const parsedResponse = parseAiccResponse(response.data);
            // console.log({ parsedResponse });
            setUserData(parsedResponse);

            if (parsedResponse.error === '1') {
              // console.log('response error = 1');
              history.replace('/unavailable');
            }
          } catch (e) {
            // console.log('error', e);
            history.replace('/unavailable');
          }
        })();
      } else {
        // console.log('aicc not set');
        history.replace('/unavailable');
      }
    }
  }, [isAICC]);

  useEffect(() => {
    if (!_isEmpty(userData)) {
      dispatch(getAICCUser(userData));
    }
  }, [dispatch, userData]);

  useEffect(() => {
    if (isAICC) {
      const initialAssessmentRegex = new RegExp(
        /\/assessments\/[a-zA-Z0-9-]+(\/page[0-9]+)?/,
      );
      const resultsRegex = new RegExp(/\/assessments\/[a-zA-Z0-9-]+\/results/);
      // console.log({
      //   initial: initialAssessmentRegex.test(touchURL.current),
      //   results: resultsRegex.test(location.pathname),
      //   config,
      //   userData,
      // });
      if (
        initialAssessmentRegex.test(touchURL.current) &&
        resultsRegex.test(location.pathname)
      ) {
        (async () => {
          const origin = _get(config, 'url.origin');
          const pathname = _get(config, 'url.pathname', '').slice(1);
          const sid = _get(config, 'sid');

          if (origin && pathname && sid) {
            await setCompletionAICC(origin, pathname, sid, {
              lesson_location: location.pathname,
              lesson_status: 'completed',
            });
          }
        })();
      }
    }
  }, [config, isAICC, location]);
}

export default useAICC;
