import React from 'react';

export default (color = '#FFFFFF') => (
  <svg
    height="100%"
    width="100%"
    viewBox="0 0 17 17"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Blog" transform="translate(0.3899994 0.39001465)">
      <path
        d="M9.5256 1.50778C9.5256 1.50778 10.4998 3.46491 9.95859 3.83333C9.41736 4.20176 7.36069 2.98148 7.36069 2.98148C6.6422 3.63421 6.01348 4.36354 5.48264 5.15091C4.14229 7.13897 3.42593 9.497 3.46386 11.9259C6.69274 11.9501 9.80226 10.7265 12.1235 8.51852C14.3681 6.23511 15.6119 3.17627 15.5874 0C13.4713 0.00330909 11.3889 0.521273 9.5256 1.50778L9.5256 1.50778Z"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.28313 6.20154L0 15.3334"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
