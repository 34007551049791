import React from 'react';

export default (color = '#FFFFFF') => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 19 17"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="organization" transform="translate(0.39001465 0.39001465)">
      <path
        d="M6.72075 2.3C6.72075 3.57025 7.78275 4.6 9.09278 4.6C10.4028 4.6 11.4648 3.57025 11.4648 2.3C11.4648 1.02975 10.4028 -3.40468e-16 9.09278 0C7.78275 3.40468e-16 6.72075 1.02975 6.72075 2.3Z"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.77828e-17 13.0333C-1.26279e-08 13.855 0.452106 14.6143 1.18602 15.0252C1.91992 15.436 2.82414 15.436 3.55805 15.0252C4.29195 14.6143 4.74406 13.855 4.74406 13.0333C4.74406 12.2116 4.29195 11.4523 3.55805 11.0415C2.82414 10.6306 1.91992 10.6306 1.18602 11.0415C0.452106 11.4523 -1.26279e-08 12.2116 8.77828e-17 13.0333Z"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4415 13.0333C13.4415 14.3036 14.5035 15.3333 15.8135 15.3333C17.1236 15.3333 18.1856 14.3036 18.1856 13.0333C18.1856 11.7631 17.1236 10.7333 15.8135 10.7333C14.5035 10.7333 13.4415 11.7631 13.4415 13.0333Z"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.74406 13.0333L13.4415 13.0333"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.59287 4.08173L3.41572 10.9672"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5927 4.08173L14.7698 10.9672"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
