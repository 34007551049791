/**
 *
 * RemindMeAnchor
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useSiteCopySelector from 'components/useSiteCopySelector';
import Mixpanel from 'utils/mixpanelService';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import RemindMeDialog from 'components/RemindMeLaterDialog';
import {
  makeSelectGetRemindMeLaterResourceResult,
  makeSelectClientDetails,
} from 'containers/Main/selectors';

const RemindMeAnchor = props => {
  const { data, label, target, url } = props;
  const [remind, setRemind] = useState(false);
  const [remindMeSiteCopy] = useSiteCopySelector(['remind-me']);
  const exitLinkMapping = _get(
    remindMeSiteCopy,
    'pageCopy.exitLinkMapping',
    {},
  );
  const thanksLinkMapping = _get(
    remindMeSiteCopy,
    'pageCopy.thanksLinkMapping',
    {},
  );
  const remindMeLaterResource = useSelector(
    makeSelectGetRemindMeLaterResourceResult(),
  );
  const clientDetails = useSelector(makeSelectClientDetails());
  const allowSignIn = _get(clientDetails, 'allowSignIn', true);
  const redirect = () => {
    Mixpanel.track('Rich Text Link Exited', {
      page: window.location.pathname,
      exitPage: url,
    });
    window.open(url, target, 'noopener');
  };

  const handleButtonClick = e => {
    e.preventDefault();

    Mixpanel.track('Rich Text Link Exited', {
      page: window.location.pathname,
      exitPage: url,
    });
    if (!allowSignIn) {
      redirect();
      return;
    }
    if (!_get(remindMeLaterResource, 'added', false) && !_isEmpty(data)) {
      setRemind(true);
    } else {
      redirect();
    }
  };
  return (
    <>
      {remind && (
        <RemindMeDialog
          data={data}
          toggle={() => setRemind(false)}
          link={exitLinkMapping.website || 'website'}
          thanksLink={thanksLinkMapping.website || 'website'}
          redirect={redirect}
        />
      )}
      <a href={url} target={target} onClick={handleButtonClick}>
        {label}
      </a>
    </>
  );
};

RemindMeAnchor.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  target: PropTypes.string,
  data: PropTypes.object,
};

export default RemindMeAnchor;
