import { createSelector } from 'reselect';

/**
 * Direct selector to the topics state domain
 */

const selectTopicsDomain = state => state.topics;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Topics
 */

const makeSelectTopics = () =>
  createSelector(
    selectTopicsDomain,
    substate => substate.topics,
  );
const makeSelectAllTopics = () =>
  createSelector(
    selectTopicsDomain,
    substate => substate.allTopics,
  );
const makeSelectSavedTopics = () =>
  createSelector(
    selectTopicsDomain,
    substate => substate.savedTopics,
  );
const makeSelectProcessing = () =>
  createSelector(
    selectTopicsDomain,
    substate => substate.processing,
  );
const makeSelectMenuTopics = () =>
  createSelector(
    selectTopicsDomain,
    substate => substate.menuTopics,
  );
const makeSelectSectionProcessing = () =>
  createSelector(
    selectTopicsDomain,
    substate => substate.sectionProcessing,
  );
const makeSelectFilterTopics = () =>
  createSelector(
    selectTopicsDomain,
    substate => substate.filterTopics,
  );
const allTopicsListsSelector = createSelector(
  selectTopicsDomain,
  substate => substate.allTopicsLists,
);
export {
  makeSelectTopics,
  makeSelectAllTopics,
  makeSelectSavedTopics,
  makeSelectProcessing,
  makeSelectMenuTopics,
  makeSelectSectionProcessing,
  makeSelectFilterTopics,
  allTopicsListsSelector,
};
