import {
  put,
  select,
  takeLatest,
  take,
  call,
  takeEvery,
} from 'redux-saga/effects';
import { getFirebase } from 'react-redux-firebase';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import contentfulClient from 'utils/contentfulService';
import { makeSelectProfile } from 'containers/Auth/selectors';
import { getContentfulLocaleFilter } from 'containers/Main/utils';
import { isClientAccepted } from 'utils/analyzeClientResource';
import { setAuthModalState, storeItemForSignIn } from 'containers/Auth/actions';
import {
  courseCompleteEmail,
  moduleCompleteEmail,
  sendWelcomeEmail,
  setCompleted,
} from 'utils/coursesService';
import {
  COURSE_COMPLETE_SERIES,
  COURSE_ENROLL,
  COURSE_SAVE_RESOURCE_FOR_LATER,
  COURSE_SAVE_CUSTOM_QUESTION,
  COURSE_SAVE_SERIES_ASSESSMENT,
  COURSE_SAVE_SERIES_COMPLETED_RESOURCES,
  COURSE_SAVE_WORKSHEET_PROGRESS,
  COURSE_UPDATE_RESOURCE,
  COURSE_WORKSHEET_CUSTOM_QUESTION_KEY,
  GET_TAKEN_COURSES,
  GET_TAKEN_COURSES_SUCCESS,
  COURSE_RESOURCE_FOR_LATER_SAVED,
  COURSE_RESOURCE_FOR_LATER_ERROR,
} from './constants';
import {
  courseCompletedSeriesSaved,
  courseCustomQuestionsSaved,
  courseEnrolled,
  courseResourceForLaterError,
  courseResourceForLaterProcessing,
  courseResourceForLaterSaved,
  courseSeriesAssessmentSaved,
  getTakenCoursesProcessing,
  getTakenCoursesSuccess,
  updateCourseResourceSaved,
} from './actions';
import {
  getCourseResourcesSaveForLaterProcessing,
  getTakenCoursesProcessing as getTakenCoursesProcessingSelector,
} from './selectors';
import {
  createNewAnswers,
  getCourseAnswers,
  updateLastViewedResourceInModule,
} from './utils';

const getCoursesDoc = ({ firestore, userId, courseId, collection }) =>
  firestore
    .collection(collection)
    .doc(userId)
    .collection('courses')
    .doc(courseId);

function* getTakenCoursesSaga() {
  try {
    const clientDetails = yield select(state => state.main.clientDetails);

    yield put(getTakenCoursesProcessing(true));

    const currentUser = yield getFirebase().auth().currentUser;
    const localeFilters = yield getContentfulLocaleFilter();
    const takenCourses = {};
    const coursesQuery = {
      content_type: 'courses',
      'fields.reviewStatus': 'Accepted',
      limit: 1000,
      include: 1,
      ...localeFilters,
    };

    if (!currentUser) {
      const data = yield contentfulClient.getEntries(coursesQuery);
      const filteredCourses = data.items.filter(item =>
        isClientAccepted(item, clientDetails),
      );
      yield put(
        getTakenCoursesSuccess({ takenCourses, allCourses: filteredCourses }),
      );
      return;
    }

    const [coursesCollection, allCoursesData] = yield Promise.all([
      getFirebase()
        .firestore()
        .collection('user_resources')
        .doc(currentUser.uid)
        .collection('courses')
        .get(),
      contentfulClient.getEntries(coursesQuery),
    ]);

    const filteredCourses = allCoursesData.items.filter(item =>
      isClientAccepted(item, clientDetails),
    );

    coursesCollection.docs.forEach(doc => {
      const { id } = doc;

      if (filteredCourses.some(s => s.sys.id === id)) {
        const data = doc.data();
        takenCourses[id] = data;
      }
    });

    yield put(
      getTakenCoursesSuccess({
        takenCourses,
        allCourses: filteredCourses,
      }),
    );
  } catch (error) {
    yield put(getTakenCoursesProcessing(false));
  }
}

function* updateCourseResourceSaga({
  payload: {
    userId,
    id,
    moduleId,
    seriesId,
    resourceId,
    collection,
    isResourceCompleted,
    resourcesProgress,
  },
}) {
  const takenCourses = yield select(state => state.courses.takenCourses);
  const course = takenCourses[id];
  const activeModule = updateLastViewedResourceInModule({
    course,
    seriesId,
    moduleId,
    resourceId,
    isResourceCompleted,
    resourcesProgress,
  });

  getCoursesDoc({
    firestore: getFirebase().firestore(),
    userId,
    courseId: id,
    collection,
  }).set(
    {
      ...activeModule,
    },
    { merge: true },
  );

  yield put(
    updateCourseResourceSaved({
      id,
      activeModule,
      completedSeriesIds: _get(course, 'completedSeriesIds', []),
      completedModulesIds: _get(course, 'completedModulesIds', []),
    }),
  );
}

function* courseSaveSeriesCompletedResourcesSaga({
  payload: {
    id,
    userId,
    moduleId,
    seriesId,
    resourceId,
    collection,
    isResourceCompleted,
    completedResourcesIds,
  },
}) {
  const takenCourses = yield select(state => state.courses.takenCourses);
  const course = takenCourses[id];
  const activeModule = updateLastViewedResourceInModule({
    course,
    seriesId,
    moduleId,
    resourceId,
    isResourceCompleted,
    completedResourcesIds,
    resourcesProgress: {
      [resourceId]: null,
    },
  });
  const completedSeriesIds = _get(course, 'completedSeriesIds', []).filter(
    item => item !== seriesId,
  );
  const modulesIds = _get(course, 'completedModulesIds', []);
  const completedModulesIds = isResourceCompleted
    ? modulesIds
    : modulesIds.filter(item => item !== moduleId);

  getCoursesDoc({
    firestore: getFirebase().firestore(),
    userId,
    courseId: id,
    collection,
  }).set(
    {
      ...activeModule,
      completedSeriesIds,
      completedModulesIds,
    },
    { merge: true },
  );

  yield put(
    updateCourseResourceSaved({
      id,
      activeModule,
      completedSeriesIds,
      completedModulesIds,
    }),
  );
}

function* completeModuleSeriesSaga({
  payload: {
    collection,
    userId,
    item,
    moduleId,
    seriesId,
    completedModulesIds,
    completedResourcesIds,
    completedSeriesIds,
    nextSeriesId,
    isCompleted,
  },
}) {
  const takenCourses = yield select(state => state.courses.takenCourses);
  const { id } = item.sys;
  const course = takenCourses[id];

  const courseSeriesProgress = _get(course, 'seriesProgress', {});

  // If series is completed add a seriesProgress object with completedResourcesIds
  // If series is not completed remove the seriesProgress object

  const seriesProgress = {
    ...courseSeriesProgress,
    [seriesId]: isCompleted
      ? {
          ...(courseSeriesProgress[seriesId] || {}),
          completedResourcesIds,
        }
      : {},
  };

  const isCourseCompleted = item.fields.modules.every(m =>
    completedModulesIds.includes(m.sys.id),
  );
  const completionData = isCourseCompleted
    ? {
        completedAt: Date.now(),
        completed: true,
      }
    : null;

  yield getCoursesDoc({
    firestore: getFirebase().firestore(),
    userId,
    courseId: id,
    collection,
  }).set(
    {
      activeSeriesId: nextSeriesId,
      seriesProgress,
      completedSeriesIds,
      completedModulesIds,
      ...completionData,
    },
    { merge: true },
  );

  if (isCourseCompleted || isCompleted) {
    const profile = yield select(makeSelectProfile());
    if (profile.email) {
      if (isCourseCompleted) {
        courseCompleteEmail({ courseId: id, userId, email: profile.email });
        setCompleted({ courseId: id, userId });
      } else if (completedModulesIds.includes(moduleId)) {
        moduleCompleteEmail({ moduleId, courseId: id, email: profile.email });
      }
    }
  }

  yield put(
    courseCompletedSeriesSaved({
      id,
      seriesProgress,
      completedSeriesIds,
      completedModulesIds,
      completionData,
    }),
  );
}

// enroll course - add enrolled flag to course
function* enrollCourseSaga({
  payload: {
    item,
    userId,
    collection,
    courseId,
    isAfterSuccessfulAuth,
    isAuthenticated,
  },
}) {
  if (!isAuthenticated) {
    yield put(storeItemForSignIn({ type: 'course', payload: { item } }));
    yield put(
      setAuthModalState({
        show: true,
        type: 'course',
      }),
    );
    return;
  }

  const isTakenCoursesProcessing = yield select(
    getTakenCoursesProcessingSelector,
  );
  if (isAfterSuccessfulAuth && isTakenCoursesProcessing)
    yield take(GET_TAKEN_COURSES_SUCCESS);

  yield getCoursesDoc({
    firestore: getFirebase().firestore(),
    userId,
    courseId,
    collection,
  }).set(
    {
      enrolled: true,
    },
    { merge: true },
  );

  const profile = yield select(makeSelectProfile());
  if (profile.email) {
    sendWelcomeEmail({ courseId, email: profile.email });
  }

  yield put(
    courseEnrolled({
      id: courseId,
    }),
  );
}

function* courseSaveCustomQuestionSaga({
  payload: { item, userId, collection, question, field },
}) {
  const takenCourses = yield select(state => state.courses.takenCourses);
  const answers = getCourseAnswers(takenCourses, _get(item, 'sys.id'), field);
  const currentAnswers = answers[question.name] || [];
  const isUpdate = !!question.answerId;

  const newAnswers = createNewAnswers({
    currentAnswers,
    isUpdate,
    question,
  });

  const updatedFields = {
    [question.name]: newAnswers,
  };

  getCoursesDoc({
    firestore: getFirebase().firestore(),
    userId,
    courseId: item.sys.id,
    collection,
  }).set({ [field]: updatedFields }, { merge: true });

  yield put(
    courseCustomQuestionsSaved({ id: item.sys.id, updatedFields, field }),
  );
}

function* courseSaveSeriesAssessmentSaga({
  payload: { assessmentId, accessCode, userId, item, seriesId, collection },
}) {
  const completedAssessments = yield select(state =>
    _get(state.courses.takenCourses, [item.sys.id, 'completedAssessments'], []),
  );
  const updatedCompletedAssessments = [
    ...completedAssessments,
    {
      id: assessmentId,
      seriesId,
      accessCode,
    },
  ];

  getCoursesDoc({
    firestore: getFirebase().firestore(),
    userId,
    courseId: item.sys.id,
    collection,
  }).set(
    { completedAssessments: updatedCompletedAssessments },
    { merge: true },
  );

  yield put(
    courseSeriesAssessmentSaved({
      id: item.sys.id,
      updatedCompletedAssessments,
    }),
  );
}

// Saga to save resource to savedResourceIds property of course
function* saveResourceForLaterSaga({
  payload: { userId, courseId, collection, resourceId, isRemove, field },
}) {
  if (field === 'savedSeriesResourceIds') {
    yield call(handleSaveResource, {
      userId,
      courseId,
      collection,
      resourceId,
      isRemove,
      field,
    });
    return;
  }

  yield put(
    courseResourceForLaterProcessing({
      resourceId,
    }),
  );

  const courseResourceForLaterProcessingList = yield select(
    getCourseResourcesSaveForLaterProcessing,
  );

  const isAlreadyProcessingAnotherResource =
    courseResourceForLaterProcessingList.length > 1;

  if (isAlreadyProcessingAnotherResource) {
    yield take([
      COURSE_RESOURCE_FOR_LATER_SAVED,
      COURSE_RESOURCE_FOR_LATER_ERROR,
    ]);
  }

  try {
    yield call(handleSaveResource, {
      userId,
      courseId,
      collection,
      resourceId,
      isRemove,
      field,
    });
  } catch {
    yield put(
      courseResourceForLaterError({
        resourceId,
      }),
    );
  }
}

function* handleSaveResource({
  userId,
  courseId,
  collection,
  resourceId,
  isRemove,
  field,
}) {
  const takenCourses = yield select(state => state.courses.takenCourses);
  const course = takenCourses[courseId];
  const savedResourceIds = _get(course, field, []);
  const updatedSavedResources = isRemove
    ? savedResourceIds.filter(id => id !== resourceId)
    : _uniq([...savedResourceIds, resourceId]);

  yield call(() =>
    getCoursesDoc({
      firestore: getFirebase().firestore(),
      userId,
      courseId,
      collection,
    }).set({ [field]: updatedSavedResources }, { merge: true }),
  );

  yield put(
    courseResourceForLaterSaved({
      id: courseId,
      resourceId,
      updatedSavedResources,
      field,
    }),
  );
}

function* courseSaveWorksheetProgressSaga({
  payload: { userId, item, id, collection, answers, stepsPayload },
}) {
  const updatedFields = {
    [id]: {
      ...stepsPayload,
      answers,
    },
  };

  getCoursesDoc({
    firestore: getFirebase().firestore(),
    userId,
    courseId: item.sys.id,
    collection,
  }).set(
    {
      [COURSE_WORKSHEET_CUSTOM_QUESTION_KEY]: updatedFields,
    },
    { merge: true },
  );

  yield put(
    courseCustomQuestionsSaved({
      id: item.sys.id,
      updatedFields,
      field: COURSE_WORKSHEET_CUSTOM_QUESTION_KEY,
    }),
  );
}

export default function* defaultSaga() {
  yield takeLatest(GET_TAKEN_COURSES, getTakenCoursesSaga);
  yield takeLatest(COURSE_UPDATE_RESOURCE, updateCourseResourceSaga);
  yield takeLatest(
    COURSE_SAVE_SERIES_COMPLETED_RESOURCES,
    courseSaveSeriesCompletedResourcesSaga,
  );
  yield takeLatest(COURSE_COMPLETE_SERIES, completeModuleSeriesSaga);
  yield takeLatest(COURSE_SAVE_CUSTOM_QUESTION, courseSaveCustomQuestionSaga);
  yield takeLatest(COURSE_ENROLL, enrollCourseSaga);
  yield takeLatest(
    COURSE_SAVE_SERIES_ASSESSMENT,
    courseSaveSeriesAssessmentSaga,
  );
  yield takeEvery(COURSE_SAVE_RESOURCE_FOR_LATER, saveResourceForLaterSaga);
  yield takeLatest(
    COURSE_SAVE_WORKSHEET_PROGRESS,
    courseSaveWorksheetProgressSaga,
  );
}
