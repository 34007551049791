import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import SearchBar from 'components/SearchBar';
import useSiteConfigSelector from 'components/useSiteConfigSelector';
// import useWindowSize from 'components/useWindowSize';
import Hidden from '@material-ui/core/Hidden';
import _isEmpty from 'lodash/isEmpty';
import { isEmbedded } from 'utils/embedded';
import _get from 'lodash/get';
import ClassNames from 'classnames';
import update from 'immutability-helper';
import Logo from './Logo';
import SubMenu from './SubMenu';
import NavBarContext from '../navBarContext';
import AccountMenu from './AccountMenu';
import { menuSorter } from '../helpers/menuSorter';
import MobileNavBar from './MobileNavBar';

const useStyles = makeStyles(theme => ({
  toolBar: {
    padding: 0,
    height: 100,
    [theme.breakpoints.down('600')]: {
      height: 'auto',
    },
  },
  toolBarWithoutSubMenu: {
    height: 60,
  },
  isEmbed: {
    height: 60,
    [theme.breakpoints.up('600')]: {
      height: 0,
      minHeight: 0,
    },
  },
  mainHeaderWrapper: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  searchBarWrapper: {
    width: '100%',
    maxWidth: 500,
    paddingLeft: 24,
    paddingRight: 12,
  },
}));

const covidMenu = {
  slug: 'covid-19',
  name: 'COVID-19',
  link: '/landing/covid-19',
};

function Content() {
  const classes = useStyles();
  const { clientDetails, clientFetching, isEmbed } = useContext(NavBarContext);
  const { pathname } = window.location;
  const [siteConfig] = useSiteConfigSelector(['Main Menu']);
  const clientMenu = _get(clientDetails, 'metadata.menu', []);
  const configMenu = _get(siteConfig, 'config.items', []);
  const finalMenu = !_isEmpty(clientMenu) ? clientMenu : configMenu;

  const [menu, setMenu] = useState(finalMenu.map(slug => ({ slug })));
  const hasClientDetails = !_isEmpty(clientDetails) && !clientFetching;
  const shouldShowNavBarSubMenu =
    !_get(clientDetails, 'metadata.hideNavBarSubMenu', false) &&
    pathname !== '/unavailable';
  const shouldShowSearchBar = !_get(
    clientDetails,
    'metadata.hideSearchBar',
    false,
  );
  const isAdmin = pathname.startsWith('/admin') && !isEmbedded();

  useEffect(() => {
    const landingMenuCollection = _get(
      clientDetails,
      'landingMenuCollection.items',
      [],
    ).filter(Boolean);

    const hasMenu = hasClientDetails && !_isEmpty(landingMenuCollection);
    if (hasMenu) {
      const newState = menuSorter(menu, landingMenuCollection);

      setMenu(newState);
    } else {
      const shouldShowCovidLanding = false;
      if (shouldShowCovidLanding) {
        const newState = update(menu, {
          $push: covidMenu,
        });
        setMenu(newState);
      }
    }

    // reset menu if no client details
    if (_isEmpty(clientDetails) && !clientFetching) {
      setMenu(configMenu.map(slug => ({ slug })));
    }
  }, [clientDetails, clientFetching, configMenu]);

  const isSeparateLogoLayout =
    _get(clientDetails, 'metadata.separateLogoLayout', false) ||
    _get(clientDetails, 'logosCollection.items', []).length > 2;

  const useAppEmbedStyle =
    isEmbed || _get(clientDetails, 'metadata.useAppEmbedStyle', false);
  return (
    <Box id="navbar-menu-wrapper">
      {!useAppEmbedStyle && (
        <Toolbar
          className={
            !isAdmin &&
            ClassNames(classes.toolBar, {
              [classes.isEmbed]: isEmbed,
              [classes.toolBarWithoutSubMenu]: !shouldShowNavBarSubMenu,
            })
          }
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.mainHeaderWrapper}
          >
            <Hidden xsDown>
              <Grid
                item
                xs={isSeparateLogoLayout ? false : hasClientDetails ? 7 : 6}
                className={classes.logoWrapper}
              >
                <Logo isAdmin={isAdmin} alternative={isSeparateLogoLayout} />
              </Grid>
              {shouldShowSearchBar &&
                (isAdmin ? (
                  <AccountMenu />
                ) : (
                  <>
                    <Grid
                      item
                      xs={
                        isSeparateLogoLayout ? true : hasClientDetails ? 5 : 6
                      }
                      className={classes.minWidth}
                    >
                      <Box
                        className={ClassNames({
                          [classes.searchBarWrapper]: isSeparateLogoLayout,
                        })}
                      >
                        <SearchBar id="nav-menu-search-input" />
                      </Box>
                    </Grid>
                    {isSeparateLogoLayout && (
                      <Grid item className={classes.logoWrapper}>
                        <Logo alternative rightLogo />
                      </Grid>
                    )}
                  </>
                ))}
            </Hidden>
            <MobileNavBar />
          </Grid>
        </Toolbar>
      )}
      {shouldShowNavBarSubMenu && (
        <SubMenu
          menu={isAdmin ? [{ slug: 'client-tools' }] : menu}
          isAdmin={isAdmin}
        />
      )}
    </Box>
  );
}

export default React.memo(Content);
