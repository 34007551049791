/* eslint-disable import/no-cycle */
import Cookie from 'js-cookie';
import { generateKey, getDomain, getSubdomain } from 'utils/stringUtils';
import { isEmbedded } from 'utils/embedded';
import { isLocalhost } from 'utils/networkUtils';

const COOKIE_TEST_NAME = 'iscookieenabledtest';

export const getCookie = name => {
  const cookieValue = Cookie.get(name);
  if (name !== 'brand') {
    return cookieValue;
  }
  const subdomain = getSubdomain();
  // subdomain value is priority
  return subdomain && subdomain !== 'development' ? subdomain : cookieValue;
};
export const setCookie = (name, value, options = {}) => {
  const domain = getDomain();
  const isNetlify = domain.includes('netlify.app');
  Cookie.set(name, value, {
    secure: !isLocalhost,
    sameSite: isEmbedded()
      ? 'none'
      : isLocalhost || isNetlify
      ? 'lax'
      : 'strict',
    domain: isNetlify ? window.location.hostname : domain,
    ...options,
  });
};

export const deleteCookie = name => {
  Cookie.remove(name);
};

export const isCoBranded = () => {
  const brandName = getCookie('brand');
  return brandName && brandName !== 'none';
};

export const isCookieEnabled = () => {
  try {
    const randomString = generateKey();
    setCookie(COOKIE_TEST_NAME, randomString);

    if (getCookie(COOKIE_TEST_NAME) !== randomString) {
      deleteCookie(COOKIE_TEST_NAME);
      return false;
    }
    deleteCookie(COOKIE_TEST_NAME);
    return true;
  } catch (error) {
    deleteCookie(COOKIE_TEST_NAME);
    return false;
  }
};
