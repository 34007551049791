import React from 'react';

export const Home = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19px"
    height="19px"
    viewBox="0 0 19 19"
    version="1.1"
  >
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Mobile-Menu---Default---2023---option-9"
        transform="translate(-27.000000, -758.000000)"
        stroke="currentColor"
        strokeWidth="1.275"
      >
        <g id="Menu" transform="translate(0.000000, 743.000000)">
          <g id="Home" transform="translate(22.750000, 16.000000)">
            <path
              d="M13.75,0 L5.25,6.18181818 L5.25,17 L10.6590909,17 L10.6590909,13.1363636 C10.6590909,12.3165773 10.9847414,11.5304045 11.564395,10.9507818 C12.1440564,10.3710818 12.9302136,10.0454545 13.75,10.0454545 C14.5697864,10.0454545 15.3559591,10.3710818 15.9355818,10.9507818 C16.5152818,11.5304045 16.8409091,12.3165773 16.8409091,13.1363636 L16.8409091,17 L22.25,17 L22.25,6.18181818 L13.75,0 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
