const freshworksActions = {
  hideLauncher: () => {
    if (window.FreshworksWidget) {
      window.FreshworksWidget('hide', 'launcher');
    }
  },
  isLoaded: () => !!window.FreshworksWidget,
  show: () => {
    if (window.FreshworksWidget) {
      window.FreshworksWidget('open');
    }
  },
};

export default freshworksActions;
