/*
 *
 * Sources constants
 *
 */

export const PROCESSING = 'app/Sources/PROCESSING';
export const GET_SOURCES = 'app/Sources/GET_SOURCES';
export const GET_SOURCES_SUCCES = 'app/Sources/GET_SOURCES_SUCCESS';
export const GET_SOURCES_FAIL = 'app/Sources/GET_SOURCES_FAIL';
