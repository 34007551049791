import { takeLatest, call, put, takeEvery, select } from 'redux-saga/effects';
import client from 'utils/contentfulService';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { getFirebase } from 'react-redux-firebase';
import { storeItemForSignIn, setAuthModalState } from 'containers/Auth/actions';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import GqlClient from 'utils/graphqlService';
import TopicsInteraction from 'utils/userInteractionService';
import {
  getContentfulTopicsQuery,
  getContentfulLocaleFilter,
} from 'containers/Main/utils';
import { makeSelectClientLanding } from 'containers/ClientLanding/selectors';
import { injectTimestampIntoFavoritedItem } from 'containers/Resources/utils';
import { getListPurposeField } from 'containers/EditorList/utils';
import {
  GET_TOPICS,
  GET_ALL_TOPICS,
  GET_SAVED_TOPICS,
  SAVE_TOPIC,
} from './constants';
import {
  getTopicsSuccess,
  getAllTopicsSuccess,
  getSavedTopics,
  getSavedTopicsResult,
  saveTopicResult,
  processing,
  sectionProcessing,
} from './actions';
import GetHomeTopicsQuery from './homeFilterTopics.gql';
import { makeSelectProfileFilters } from '../Auth/selectors';

function* getTopicsSaga({ payload }) {
  const { queryOpt = {}, type = '' } = payload || {};
  try {
    // add section processing to properly indicate which part of app is fetching new data since they are using the same actions/saga
    if (type === 'menu') {
      yield put(sectionProcessing(true));
    } else {
      yield put(processing(true));
    }
    const localeFilters = yield call(getContentfulLocaleFilter);
    const clientDetails = yield select(makeSelectClientDetails());
    const clientLanding = yield select(makeSelectClientLanding());
    const clientTopTopics = !_isEmpty(clientDetails)
      ? _get(clientLanding, 'topTopicsCollection.items')
      : [];
    if (
      !_isEmpty(clientTopTopics) &&
      (type === 'menu' || type === 'home-filter')
    ) {
      const finalData =
        type === 'menu'
          ? { items: clientTopTopics }
          : clientTopTopics.map(el => ({
              title: el.title,
              slug: el.slug,
              sys: { id: el.sys.id },
            }));
      yield put(getTopicsSuccess({ type, data: finalData }));
    } else {
      const clientExcludedTopic = _get(
        clientDetails,
        'excludeTopicCollection.items',
      );
      const excludedTopicId =
        !_isEmpty(clientExcludedTopic) &&
        clientExcludedTopic.map(({ sys }) => sys.id).join(',');
      const excludeTopicQuery = excludedTopicId
        ? { 'sys.id[nin]': excludedTopicId }
        : {};
      let finalData;
      if (type === 'home-filter') {
        const { data } = yield call(() =>
          GqlClient.query({
            query: GetHomeTopicsQuery,
            variables: {
              excludedTopicId: excludedTopicId || '',
              ...localeFilters,
            },
          }),
        );
        finalData = data.topicsCollection.items;
      } else {
        finalData = yield call(() =>
          client.getEntries({
            content_type: 'topics',
            'fields.reviewStatus': 'Accepted',
            ...queryOpt,
            ...excludeTopicQuery,
            ...localeFilters,
          }),
        );
      }
      yield put(getTopicsSuccess({ type, data: finalData }));
    }
  } catch (error) {
    console.log('error handler');
  } finally {
    if (type === 'menu') {
      yield put(sectionProcessing(false));
    } else {
      yield put(processing(false));
    }
  }
}

function* getAllTopicsSaga({ payload: allTopicsListTitle }) {
  try {
    yield put(processing(true));
    const localeFilters = yield call(getContentfulLocaleFilter);
    const clientDetails = yield select(makeSelectClientDetails());
    const clientExcludedTopic = _get(
      clientDetails,
      'excludeTopicCollection.items',
    );
    const profileFilters = yield select(makeSelectProfileFilters());

    const audienceIds = _get(profileFilters, 'excludedAudiencesIds', []);

    const excludedTopicIds =
      !_isEmpty(clientExcludedTopic) &&
      clientExcludedTopic.map(({ sys }) => sys.id);
    const excludeTopicQuery = excludedTopicIds
      ? { 'sys.id[nin]': excludedTopicIds.join(',') }
      : {};
    const contentfulTopicsQuery = yield call(getContentfulTopicsQuery, true);
    const purposeField = getListPurposeField();
    const [topicsResponse, listsResponse] = yield call(() =>
      Promise.all([
        client.getEntries({
          content_type: 'topics',
          'fields.reviewStatus': 'Accepted',
          order: '-fields.userRating',
          select:
            'fields.title,fields.slug,fields.phi,fields.description,fields.image,fields.aliases,fields.relatedKeywords,sys.id',
          include: 2,
          limit: 1000, // set to maximum
          ...contentfulTopicsQuery,
          ...excludeTopicQuery,
          ...localeFilters,
        }),
        client.getEntries({
          content_type: 'list',
          'fields.reviewStatus': 'Accepted',
          [`fields.${purposeField}[in]`]: 'All Topics',
          'fields.resources[exists]': true,
          select:
            'fields.resources,fields.title,fields.client,fields.audienceType',
          order: '-fields.topicOrder,-sys.updatedAt',
          include: 1,
          ...localeFilters,
        }),
      ]),
    );

    const filteredLists = [
      {
        fields: {
          title: allTopicsListTitle,
          resources: topicsResponse.items,
        },
      },
    ];
    listsResponse.items.forEach(item => {
      const clientIds = _get(item.fields, 'client', []).map(c => c.sys.id);
      const matchesClient =
        clientIds.length === 0 ||
        clientIds.includes(_get(clientDetails, 'sys.id'));

      if (!matchesClient) {
        return;
      }

      const audienceTypesIds = _get(item.fields, 'audienceType', []).map(
        c => c.sys.id,
      );

      if (
        audienceTypesIds.length &&
        !audienceIds.some(id => audienceTypesIds.includes(id))
      ) {
        return;
      }

      if (excludedTopicIds) {
        // eslint-disable-next-line no-param-reassign
        item.fields.resources = (_get(item, 'fields.resources') || []).filter(
          topic => !excludedTopicIds.some(id => topic.sys.id === id),
        );
      }

      filteredLists.push(item);
    });

    yield put(
      getAllTopicsSuccess({
        topics: topicsResponse.items,
        lists: filteredLists,
      }),
    );
  } catch (error) {
    console.log('error handler');
  } finally {
    yield put(processing(false));
  }
}

function* getSavedTopicsSaga() {
  try {
    yield put(processing(true));
    const localeFilters = yield call(getContentfulLocaleFilter);
    const userID = yield getFirebase().auth().currentUser.uid;
    const savedTopicsDocs = yield getFirebase()
      .firestore()
      .collection('user_resources')
      .doc(userID)
      .collection('saved_topics')
      .get();
    const savedTopics = savedTopicsDocs.docs.map(doc => doc.data());
    const savedTopicsIDs = savedTopics.map(item => item.topicID);
    if (savedTopicsIDs.length > 0) {
      const idString = savedTopicsIDs.join();
      const response = yield call(() =>
        client.getEntries({
          content_type: 'topics',
          'sys.id[in]': idString,
          'fields.reviewStatus': 'Accepted',
          order: '-fields.title',
          include: 1,
          ...localeFilters,
        }),
      );
      const finalItems = injectTimestampIntoFavoritedItem(
        savedTopics,
        response.items,
      );
      yield put(getSavedTopicsResult(finalItems));
    } else {
      yield put(getSavedTopicsResult([]));
    }
  } catch (error) {
    yield put(getSavedTopicsResult([]));
  } finally {
    yield put(processing(false));
  }
}

function* saveTopicSaga({ payload }) {
  try {
    yield put(processing(true));
    yield put(storeItemForSignIn({}));
    let userID;
    try {
      userID = yield getFirebase().auth().currentUser.uid;
    } catch (error) {
      // const {
      //   location: { pathname },
      // } = payload;
      yield put(storeItemForSignIn({ type: 'topic', payload }));
      yield put(saveTopicResult({ error: true }));
      yield put(setAuthModalState({ show: true, type: 'topic' }));
      // yield put(push(`/login?redirect=${pathname}`));
    }
    if (userID != null) {
      const { save, topic, phi } = payload;
      const userTopicDoc = yield getFirebase()
        .firestore()
        .collection('user_resources')
        .doc(userID)
        .collection('saved_topics')
        .doc(topic.topicID);
      if (save) {
        if (phi === 'Not Sensitive')
          TopicsInteraction.registerTopicInteraction({
            type: 'save',
            topic: topic.name,
            resource: topic.topicID,
          });
        if (!userTopicDoc.exists) {
          yield userTopicDoc.set({ ...topic, timestamp: Date.now() });
          yield put(
            saveTopicResult({
              added: true,
              error: false,
            }),
          );
        } else {
          yield put(
            saveTopicResult({
              added: false,
              error: false,
            }),
          );
        }
      } else {
        yield userTopicDoc.delete();
        yield put(
          saveTopicResult({
            added: false,
            error: false,
          }),
        );
      }
    }
  } catch (error) {
    yield put(saveTopicResult({ error: true }));
  } finally {
    yield put(getSavedTopics());
  }
}

// Individual exports for testing
export default function* defaultSaga() {
  yield takeEvery(GET_TOPICS, getTopicsSaga);
  yield takeLatest(GET_ALL_TOPICS, getAllTopicsSaga);
  yield takeLatest(GET_SAVED_TOPICS, getSavedTopicsSaga);
  yield takeLatest(SAVE_TOPIC, saveTopicSaga);
}
