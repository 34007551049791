import { useEffect } from 'react';
import { useFirebase } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'react-redux';
import { setFirestoreStatus } from 'containers/Main/actions';
import { makeSelectFirestoreStatus } from 'containers/Main/selectors';
import { toast } from 'react-toastify';

const TOAST_ID = 'firestore-listener-toast';

function useFirestoreListener() {
  const dispatch = useDispatch();
  const firestoreStatus = useSelector(makeSelectFirestoreStatus());
  const firebase = useFirebase();
  const firestore = firebase.firestore();

  useEffect(() => {
    const checkFirestoreStatus = () => {
      // console.log('checking firestore status');
      if (document.visibilityState === 'visible') {
        // console.log('document visible');
        firestore
          .collection('connection_health_check')
          .doc('status')
          .get()
          .then(() => {
            if (!firestoreStatus) {
              toast(
                'Your connection to our database has been restored. All features have been enabled.',
                {
                  type: 'success',
                  toastId: TOAST_ID,
                  pauseOnFocusLoss: false,
                },
              );
              dispatch(setFirestoreStatus(true));
            }
          })
          .catch(e => {
            if (e.code === 'unavailable' && firestoreStatus) {
              toast(
                'Could not reach our database. Some features will be disabled.',
                {
                  type: 'error',
                  toastId: TOAST_ID,
                  pauseOnFocusLoss: false,
                },
              );
              dispatch(setFirestoreStatus(false));
            }
          });
      }
    };

    checkFirestoreStatus();
    const interval = setInterval(checkFirestoreStatus, 60000);

    return () => clearInterval(interval);
  }, [dispatch, firestore, firestoreStatus]);
}

export default useFirestoreListener;
