import { takeLatest, call, put, all } from 'redux-saga/effects';
import GqlClient from 'utils/graphqlService';
import AlgoliaClient from 'utils/algoliaService';
import Config from 'utils/getEnvConfig';
import {
  getAlgoliaRatingQuery,
  getContentfulLocaleFilter,
} from 'containers/Main/utils';
import {
  GET_CLIENT_LANDING_INFO,
  GET_PAGE_LANDING_INFO,
  GET_TOPIC_RESOURCES_INFO,
} from './constants';
import {
  getClientLandingInfoSuccess,
  getPageLandingInfoSuccess,
  getTopicResourcesInfoSuccess,
  pageProcessing,
  processing,
} from './actions';
import createClientLandingQuery from './services/clientLandingInfo.gql';

const {
  ALGOLIA: { RESOURCES_AVG_RATED_INDEX },
} = Config;

function* getClientLandingInfoSaga({ payload }) {
  try {
    const localeFilters = yield call(getContentfulLocaleFilter);
    const { slug, isPreview } = payload;
    const items = yield call(() => getLandingInfo(slug || '', isPreview));
    yield put(getClientLandingInfoSuccess({ ...items[0], ...localeFilters }));
  } catch (error) {
    throw new Error(error);
  } finally {
    yield put(processing(false));
  }
}

function* getPageLandingInfoSaga({ payload }) {
  try {
    yield put(pageProcessing(true));
    const { slug, isPreview } = payload;
    const items = yield call(() => getLandingInfo(slug || '', isPreview));
    yield put(getPageLandingInfoSuccess(items[0]));
  } catch (error) {
    throw new Error(error);
  } finally {
    yield put(pageProcessing(false));
  }
}

function* getTopicsResourcesCountSaga({ payload: topics }) {
  try {
    const ResourcesIndex = AlgoliaClient.initIndex(RESOURCES_AVG_RATED_INDEX);
    const algoliaRatingQuery = yield call(getAlgoliaRatingQuery);
    const topicsCount = yield all(
      topics.map(el =>
        call(() =>
          ResourcesIndex.searchForFacetValues('allTopics.slug', el.slug, {
            maxFacetHits: 1,
            filters: `reviewStatus:'Accepted' ${algoliaRatingQuery}`,
          }),
        ),
      ),
    );
    const finalTopics = topics.map(topic => ({
      ...topic,
      quantity: topicsCount.find(el => el.facetHits[0].value === topic.slug)
        .facetHits[0].count,
    }));
    yield put(getTopicResourcesInfoSuccess(finalTopics));
  } catch (error) {
    throw new Error(error);
  }
}

function* getLandingInfo(slug, isPreview) {
  const localeFilters = yield call(getContentfulLocaleFilter);
  const {
    data: {
      landingCollection: { items },
    },
  } = yield call(() =>
    GqlClient.query({
      query: createClientLandingQuery(isPreview),
      context: { isPreview },
      variables: {
        slug,
        ...localeFilters,
      },
    }),
  );

  return items;
}

// Individual exports for testing
export default function* defaultSaga() {
  yield takeLatest(GET_CLIENT_LANDING_INFO, getClientLandingInfoSaga);
  yield takeLatest(GET_PAGE_LANDING_INFO, getPageLandingInfoSaga);
  yield takeLatest(GET_TOPIC_RESOURCES_INFO, getTopicsResourcesCountSaga);
}
