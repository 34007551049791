import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  icon: {
    height: 30,
    marginRight: 10,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    borderRadius: 0,
    opacity: '1 !important',
    fontSize: 12,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Mada',
    fontWeight: 400,
  },
  topLabel: {
    color: '#000000',
    fontWeight: 700,
    backgroundColor: '#EE9E00',
    fontFamily: 'Mada',
    fontSize: 14,
    lineHeight: '1',
    position: 'absolute',
    top: -1,
    right: 0,
    height: 18,
    width: 18,
    borderRadius: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const AccountIcon = ({ enabled, icon, iconAlt, tooltipLabel, topLabel }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!enabled) setOpen(false);
  }, [enabled]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
      }}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      placement="bottom"
      title={tooltipLabel}
    >
      <Box className={classes.container}>
        <img src={icon} className={classes.icon} alt={iconAlt} />
        {topLabel && (
          <Typography className={classes.topLabel}>{topLabel}</Typography>
        )}
      </Box>
    </Tooltip>
  );
};

AccountIcon.propTypes = {
  enabled: PropTypes.bool,
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
  tooltipLabel: PropTypes.string,
  topLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AccountIcon;
