import { createAction } from 'redux-actions';
import {
  PROCESSING,
  GET_CLIENT_DETAILS,
  GET_CLIENT_DETAILS_SUCCESS,
  GET_CLIENT_DETAILS_FAIL,
  SHOW_ABOUT,
  SHOW_TERMS_OF_USE,
  SHOW_PRIVACY_POLICY,
  SHOW_FEEDBACK,
  SEND_MAIL_PROCESSING,
  SEND_MAIL,
  SEND_MAIL_SUCCESS,
  GET_SITE_COPY,
  GET_SITE_COPY_SUCCESS,
  GET_SITE_CONFIG,
  GET_SITE_CONFIG_SUCCESS,
  CLIENT_DETAILS_FETCHING,
  GET_PROGRAMS_BY_TOPIC,
  GET_PROGRAMS_BY_TOPIC_SUCCESS,
  GET_THEMES,
  GET_THEMES_SUCCESS,
  GET_RESOURCES_COUNT_BY_TOPIC,
  GET_RESOURCES_COUNT_BY_TOPIC_SUCCESS,
  GET_CLIENT_RESOURCES,
  GET_CLIENT_RESOURCES_SUCCESS,
  SHOW_REGION_SELECTOR,
  SET_REGION,
  UPDATE_BRAND,
  SHOW_REGION_BANNER,
  CLEAR_CLIENT_DETAILS,
  SET_LANDING,
  SHOW_HOMEPAGE_PRO,
  GET_AUDIENCE_TAGS_RELATIONS,
  SET_AUDIENCE_TAGS_RELATIONS,
  SET_NPS_VISIBILITY,
  INCREMENT_NPS_PLAY_COUNT,
  MEET_NPS_PRECONDITIONS,
  SET_HELPFUL_LOCATION,
  SAVE_REMIND_ME_LATER_RESOURCE,
  GET_REMIND_ME_LATER_RESOURCE,
  SET_LEAVING_MODAL,
  SAVE_REMIND_ME_LATER_RESOURCE_RESULT,
  GET_REMIND_ME_LATER_RESOURCE_RESULT,
  GET_PAGE_INTROS,
  GET_PAGE_INTROS_RESULT,
  GET_REFERRALS,
  GET_REFERRALS_RESULT,
  SET_LANGUAGE,
  SHOW_LANGUAGE_BANNER,
  GET_LOCALES,
  SET_LOCALES,
  GET_AICC_USER,
  SET_AICC_USER,
  SET_AICC_REDIRECTED,
  SET_CLINIC_MODE,
  SHOW_ONBOARDING_QUESTIONS,
  SHOW_INSURANCE_MODAL,
  HIDE_INSURANCE_MODAL,
  SHOW_PERSONAL_EMAIL_MODAL,
  HIDE_PERSONAL_EMAIL_MODAL,
  SET_FIRESTORE_STATUS,
  GET_CONTENT_BLOCKS,
  SET_CONTENT_BLOCKS,
  SET_IS_ENDING_SESSION,
  SHOW_FLOATING_HELPFUL_AGAIN,
  UPDATE_CLIENT_DETAILS,
  SET_FRESHWORKS_LOADED,
  SET_TEXT_DIRECTION,
  GET_HOME_REFERRALS,
  GET_HOME_REFERRALS_RESULT,
} from './constants';

export const processing = createAction(PROCESSING);
export const getClientDetails = createAction(GET_CLIENT_DETAILS);
export const getClientDetailsSuccess = createAction(GET_CLIENT_DETAILS_SUCCESS);
export const getClientDetailsFail = createAction(GET_CLIENT_DETAILS_FAIL);
export const clearClientDetails = createAction(CLEAR_CLIENT_DETAILS);
export const showAbout = createAction(SHOW_ABOUT);
export const showTermsOfUse = createAction(SHOW_TERMS_OF_USE);
export const showPrivacyPolicy = createAction(SHOW_PRIVACY_POLICY);
export const showFeedback = createAction(SHOW_FEEDBACK);
export const sendMailProcessing = createAction(SEND_MAIL_PROCESSING);
export const sendMail = createAction(SEND_MAIL);
export const sendMailSuccess = createAction(SEND_MAIL_SUCCESS);
export const getSiteCopy = createAction(GET_SITE_COPY);
export const getSiteCopySuccess = createAction(GET_SITE_COPY_SUCCESS);
export const getSiteConfig = createAction(GET_SITE_CONFIG);
export const getSiteConfigSuccess = createAction(GET_SITE_CONFIG_SUCCESS);
export const clientDetailsFetching = createAction(CLIENT_DETAILS_FETCHING);
export const getProgramsByTopic = createAction(GET_PROGRAMS_BY_TOPIC);
export const getProgramsByTopicSuccess = createAction(
  GET_PROGRAMS_BY_TOPIC_SUCCESS,
);
export const getThemes = createAction(GET_THEMES);
export const getThemesSuccess = createAction(GET_THEMES_SUCCESS);
export const getResourcesCountByTopic = createAction(
  GET_RESOURCES_COUNT_BY_TOPIC,
);
export const getResourcesCountByTopicSuccess = createAction(
  GET_RESOURCES_COUNT_BY_TOPIC_SUCCESS,
);
export const getClientResources = createAction(GET_CLIENT_RESOURCES);
export const getClientResourcesSuccess = createAction(
  GET_CLIENT_RESOURCES_SUCCESS,
);
export const showRegionSelector = createAction(SHOW_REGION_SELECTOR);
export const setRegion = createAction(SET_REGION);
export const updateBrand = createAction(UPDATE_BRAND);
export const showRegionBanner = createAction(SHOW_REGION_BANNER);
export const setLandingMenu = createAction(SET_LANDING);
export const showHomepagePro = createAction(SHOW_HOMEPAGE_PRO);
export const getAudienceTagsRelations = createAction(
  GET_AUDIENCE_TAGS_RELATIONS,
);
export const setAudienceTagsRelations = createAction(
  SET_AUDIENCE_TAGS_RELATIONS,
);
export const setNPSVisibility = createAction(SET_NPS_VISIBILITY);
export const incrementNPSPlayCount = createAction(INCREMENT_NPS_PLAY_COUNT);
export const meetNPSPreconditions = createAction(MEET_NPS_PRECONDITIONS);
export const setHelpfulLocation = createAction(SET_HELPFUL_LOCATION);
export const showFloatingHelpfulAgain = createAction(
  SHOW_FLOATING_HELPFUL_AGAIN,
);
export const setLeavingModal = createAction(SET_LEAVING_MODAL);
export const saveRemindMeLaterResource = createAction(
  SAVE_REMIND_ME_LATER_RESOURCE,
);
export const getRemindMeLaterResource = createAction(
  GET_REMIND_ME_LATER_RESOURCE,
);
export const saveRemindMeLaterResourceResult = createAction(
  SAVE_REMIND_ME_LATER_RESOURCE_RESULT,
);
export const getRemindMeLaterResourceResult = createAction(
  GET_REMIND_ME_LATER_RESOURCE_RESULT,
);
export const getPageIntros = createAction(GET_PAGE_INTROS);
export const getPageIntrosResult = createAction(GET_PAGE_INTROS_RESULT);
export const getReferrals = createAction(GET_REFERRALS);
export const getHomeReferrals = createAction(GET_HOME_REFERRALS);
export const getReferralsResult = createAction(GET_REFERRALS_RESULT);
export const getHomeReferralsResult = createAction(GET_HOME_REFERRALS_RESULT);
export const setLanguage = createAction(SET_LANGUAGE);
export const showLanguageBanner = createAction(SHOW_LANGUAGE_BANNER);
export const getLocales = createAction(GET_LOCALES);
export const setLocales = createAction(SET_LOCALES);
export const getAICCUser = createAction(GET_AICC_USER);
export const setAICCUser = createAction(SET_AICC_USER);
export const setAICCRedirected = createAction(SET_AICC_REDIRECTED);
export const setClinicMode = createAction(SET_CLINIC_MODE);
export const showOnboardingQuestions = createAction(SHOW_ONBOARDING_QUESTIONS);
export const showInsuranceModal = createAction(SHOW_INSURANCE_MODAL);
export const hideInsuranceModal = createAction(HIDE_INSURANCE_MODAL);
export const showPersonalEmailModal = createAction(SHOW_PERSONAL_EMAIL_MODAL);
export const hidePersonalEmailModal = createAction(HIDE_PERSONAL_EMAIL_MODAL);
export const setFirestoreStatus = createAction(SET_FIRESTORE_STATUS);
export const getContentBlocks = createAction(GET_CONTENT_BLOCKS);
export const setContentBlocks = createAction(SET_CONTENT_BLOCKS);
export const setIsEndingSession = createAction(SET_IS_ENDING_SESSION);
export const updateClientDetails = createAction(UPDATE_CLIENT_DETAILS);
export const setFreshworksLoaded = createAction(SET_FRESHWORKS_LOADED);
export const setTextDirection = createAction(SET_TEXT_DIRECTION);
