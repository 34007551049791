const borderAndShadow = {
  borderRadius: 0,
  boxShadow: '0 4px 6px 0 hsla(0, 0%, 0%, 0.2) !important',
};

// primary | main = blue, secondary = orange
// secondary | main = 'grey
export const HeaderFont = 'Poppins';
export const HomeHeroHeaderFont = 'PlusJakartaSansBold';
export const BodyFont = 'Mada';

const PrimaryTextColor = '#4B4B4B';

const buildTheme = (
  customHeaderFont,
  customBodyFont,
  customHomeHeroHeaderFont,
) => ({
  typography: {
    fontFamily: customBodyFont || BodyFont,
    fontWeight: 400,
    letterSpacing: 0,
    h1: {
      fontSize: '2.625rem',
      lineHeight: '1.35',
      fontFamily: customHeaderFont || HeaderFont,
      fontWeight: 600,
    },
    h2: {
      fontSize: '2.313rem',
      lineHeight: '1.35',
      fontFamily: customHeaderFont || HeaderFont,
      fontWeight: 600,
    },
    h3: {
      fontSize: '2rem',
      lineHeight: '1.41',
      fontFamily: customHeaderFont || HeaderFont,
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.688rem',
      lineHeight: '1.37',
      fontFamily: customHeaderFont || HeaderFont,
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.375rem',
      lineHeight: '1.32',
      fontFamily: customHeaderFont || HeaderFont,
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.063rem',
      lineHeight: '1.23',
      fontFamily: customHeaderFont || HeaderFont,
      fontWeight: 600,
    },
    button: {
      fontSize: '0.875rem',
      lineHeight: '1.21',
      fontFamily: customBodyFont || BodyFont,
      fontWeight: 700,
    },
    subtitle1: {
      // p bold in sketch
      fontSize: '1.063rem',
      lineHeight: '1.35',
      fontFamily: customBodyFont || BodyFont,
      fontWeight: 700,
    },
    subtitle2: {
      // p large bold in sketch
      fontSize: '1.25rem',
      lineHeight: '1.5',
      fontFamily: customBodyFont || BodyFont,
      fontWeight: 700,
    },
    body1: {
      // p large in sketch
      fontSize: '1.25rem',
      lineHeight: '1.5',
      fontFamily: customBodyFont || BodyFont,
      fontWeight: 400,
    },
    body2: {
      // p in sketch
      fontSize: '1.0625rem',
      lineHeight: '1.35',
      fontFamily: customBodyFont || BodyFont,
      fontWeight: 400,
    },
    caption: {
      // p.small.bold in sketch
      fontSize: '0.875rem',
      lineHeight: '1.57',
      fontFamily: customBodyFont || BodyFont,
      fontWeight: 700,
    },
    overline: {
      // p.small in sketch
      fontSize: '0.875rem',
      lineHeight: '1.57',
      textTransform: 'none',
      fontFamily: customBodyFont || BodyFont,
      fontWeight: 400,
    },
    pxSmall: {
      fontSize: '0.75rem',
      lineHeight: '1.5',
      fontFamily: customBodyFont || BodyFont,
      fontWeight: 400,
    },
    pSmallBold: {
      // p.small.bold in sketch
      fontSize: '0.875rem',
      lineHeight: '1.57',
      fontFamily: customBodyFont || BodyFont,
      fontWeight: 700,
    },
    pBold: {
      fontSize: '1.063rem',
      lineHeight: '1.35',
      fontFamily: customBodyFont || BodyFont,
      fontWeight: 500,
    },
    pxSmallBold: {
      fontSize: '0.75rem',
      lineHeight: '1.5',
      fontFamily: customBodyFont || BodyFont,
      fontWeight: 500,
    },
    pRegular: {
      fontSize: '1rem',
      lineHeight: '1.375',
      fontFamily: customBodyFont || BodyFont,
      fontWeight: 400,
    },
    h7: {
      lineHeight: 1.4,
      fontSize: '1.05em',
      fontFamily: customHeaderFont || HeaderFont,
      fontWeight: 600,
    },
    homeHeaderFont: customHeaderFont || HeaderFont,
    homeHeroHeaderFont: customHomeHeroHeaderFont || HomeHeroHeaderFont,
    homeBodyFont: customBodyFont || BodyFont,
  },
  palette: {
    primary: {
      main: '#01619B', // blue
      secondary: '#EE9E00', // orange
      light: '#ECF2F5', // gray light
      contrastText: PrimaryTextColor, // charcoal
    },
    secondary: {
      main: '#EDEEF7',
    },
    text: {
      primary: PrimaryTextColor, // typography black
      secondary: '#01619B', // typography primary
      disabled: '#505050', // typography muted
      hint: '#F8AC24', // typography secondary
      white: '#fff',
    },
    blue100: '#F8FAFB',
    ADACompliantRed: '#E60000',
  },
  overrides: {
    MuiCard: {
      root: borderAndShadow,
    },
    MuiDialogContent: {
      root: {
        borderRadius: 0,
      },
    },
    MuiPaper: {
      root: {
        borderRadius: '0 !important',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
      },
      contained: {
        boxShadow: 'none',
        textTransform: 'none',
        fontWeight: 400,
        '&:hover': {
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        backgroundColor: '#01619B',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#0D73B1',
        },
      },
      containedSecondary: {
        backgroundColor: '#EE9E00',
        color: '#4B4B4B',
        '&:hover': {
          backgroundColor: '#F2A50C',
        },
      },
      outlined: {
        backgroundColor: '#fff',
        '&.Mui-disabled': {
          border: '1px solid #E0E0E0',
          color: '#4B4B4B',
          backgroundColor: '#fff',
          pointerEvents: 'auto',
          '&:hover': {
            backgroundColor: '#F8F9F9',
          },
        },
      },
      outlinedPrimary: {
        border: '1px solid #01619B',
        color: '#01619B',
        '&:not(.Mui-disabled):hover': {
          color: '#0D73B1',
          backgroundColor: '#fff',
        },
      },
      outlinedSecondary: {
        border: '1px solid #EE9E00',
        color: '#EE9E00',
        '&:not(.Mui-disabled):hover': {
          color: '#F2A50C',
          backgroundColor: '#fff',
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: PrimaryTextColor,
      },
    },
  },
});

export default buildTheme;
