/*
 *
 * Account constants
 *
 */

export const DEFAULT_ACTION = 'app/Account/DEFAULT_ACTION';
export const UPDATE_ACCOUNT = 'app/Account/UPDATE_ACCOUNT';
export const UPDATE_ACCOUNT_RESULT = 'app/Account/UPDATE_ACCOUNT_RESULT';
export const CHANGE_PASSWORD = 'app/Account/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_RESULT = 'app/Account/CHANGE_PASSWORD_RESULT';
export const TOGGLE_CONTENTFUL_TOOLS = 'app/Account/TOGGLE_CONTENTFUL_TOOLS';
export const TOGGLE_CONTENTFUL_TOOLS_RESULT =
  'app/Account/TOGGLE_CONTENTFUL_TOOLS_RESULT';
export const GET_DETAILED_USER_HISTORY =
  'app/Account/GET_DETAILED_USER_HISTORY';
export const GET_DETAILED_USER_HISTORY_RESULT =
  'app/Account/GET_DETAILED_USER_HISTORY_RESULT';
export const PROCESSING = 'app/Account/PROCESSING';

export const ACCOUNT_TOAST_ID = 'TOAST_ID/ACCOUNT';
