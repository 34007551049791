import React from 'react';

export default (color = '#FFFFFF') => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 19 17"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="list" transform="translate(0.3899994 0.39001465)">
      <path
        d="M6.33516 1.53333L18.2136 1.53333"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33516 7.66667L18.2136 7.66667"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33516 13.8L18.2136 13.8"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.389 0L2.77858 0Q2.81689 0 2.85447 0.00747452Q2.89205 0.014949 2.92744 0.0296109Q2.96284 0.0442727 2.9947 0.0655583Q3.02655 0.086844 3.05365 0.113935Q3.08074 0.141027 3.10202 0.172883Q3.12331 0.204739 3.13797 0.240136Q3.15263 0.275533 3.16011 0.31311Q3.16758 0.350687 3.16758 0.389L3.16758 2.67767Q3.16758 2.71598 3.16011 2.75356Q3.15263 2.79113 3.13797 2.82653Q3.12331 2.86193 3.10202 2.89378Q3.08074 2.92564 3.05365 2.95273Q3.02655 2.97982 2.9947 3.00111Q2.96284 3.02239 2.92744 3.03706Q2.89205 3.05172 2.85447 3.05919Q2.81689 3.06667 2.77858 3.06667L0.389 3.06667Q0.350687 3.06667 0.31311 3.05919Q0.275533 3.05172 0.240136 3.03706Q0.204739 3.02239 0.172883 3.00111Q0.141027 2.97982 0.113935 2.95273Q0.086844 2.92564 0.0655583 2.89378Q0.0442727 2.86193 0.0296109 2.82653Q0.014949 2.79113 0.00747452 2.75356Q0 2.71598 0 2.67767L0 0.389Q0 0.350687 0.00747452 0.31311Q0.014949 0.275533 0.0296109 0.240136Q0.0442727 0.204739 0.0655583 0.172883Q0.086844 0.141027 0.113935 0.113935Q0.141027 0.086844 0.172883 0.0655583Q0.204739 0.0442727 0.240136 0.0296109Q0.275533 0.014949 0.31311 0.00747452Q0.350687 0 0.389 0Z"
        id="Rectangle"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.389 6.13333L2.77858 6.13333Q2.81689 6.13333 2.85447 6.14081Q2.89205 6.14828 2.92744 6.16294Q2.96284 6.17761 2.9947 6.19889Q3.02655 6.22018 3.05365 6.24727Q3.08074 6.27436 3.10202 6.30622Q3.12331 6.33807 3.13797 6.37347Q3.15263 6.40886 3.16011 6.44644Q3.16758 6.48402 3.16758 6.52233L3.16758 8.811Q3.16758 8.84931 3.16011 8.88689Q3.15263 8.92447 3.13797 8.95986Q3.12331 8.99526 3.10202 9.02711Q3.08074 9.05897 3.05365 9.08606Q3.02655 9.11315 2.9947 9.13444Q2.96284 9.15573 2.92744 9.17039Q2.89205 9.18505 2.85447 9.19252Q2.81689 9.2 2.77858 9.2L0.389 9.2Q0.350687 9.2 0.31311 9.19252Q0.275533 9.18505 0.240136 9.17039Q0.204739 9.15573 0.172883 9.13444Q0.141027 9.11315 0.113935 9.08606Q0.086844 9.05897 0.0655583 9.02711Q0.0442727 8.99526 0.0296109 8.95986Q0.014949 8.92447 0.00747452 8.88689Q0 8.84931 0 8.811L0 6.52233Q0 6.48402 0.00747452 6.44644Q0.014949 6.40886 0.0296109 6.37347Q0.0442727 6.33807 0.0655583 6.30622Q0.086844 6.27436 0.113935 6.24727Q0.141027 6.22018 0.172883 6.19889Q0.204739 6.17761 0.240136 6.16294Q0.275533 6.14828 0.31311 6.14081Q0.350687 6.13333 0.389 6.13333Z"
        id="Rectangle"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.389 12.2667L2.77858 12.2667Q2.81689 12.2667 2.85447 12.2741Q2.89205 12.2816 2.92744 12.2963Q2.96284 12.3109 2.9947 12.3322Q3.02655 12.3535 3.05365 12.3806Q3.08074 12.4077 3.10202 12.4395Q3.12331 12.4714 3.13797 12.5068Q3.15263 12.5422 3.16011 12.5798Q3.16758 12.6174 3.16758 12.6557L3.16758 14.9443Q3.16758 14.9826 3.16011 15.0202Q3.15263 15.0578 3.13797 15.0932Q3.12331 15.1286 3.10202 15.1604Q3.08074 15.1923 3.05365 15.2194Q3.02655 15.2465 2.9947 15.2678Q2.96284 15.2891 2.92744 15.3037Q2.89205 15.3184 2.85447 15.3259Q2.81689 15.3333 2.77858 15.3333L0.389 15.3333Q0.350687 15.3333 0.31311 15.3259Q0.275533 15.3184 0.240136 15.3037Q0.204739 15.2891 0.172883 15.2678Q0.141027 15.2465 0.113935 15.2194Q0.086844 15.1923 0.0655583 15.1604Q0.0442727 15.1286 0.0296109 15.0932Q0.014949 15.0578 0.00747452 15.0202Q0 14.9826 0 14.9443L0 12.6557Q0 12.6174 0.00747452 12.5798Q0.014949 12.5422 0.0296109 12.5068Q0.0442727 12.4714 0.0655583 12.4395Q0.086844 12.4077 0.113935 12.3806Q0.141027 12.3535 0.172883 12.3322Q0.204739 12.3109 0.240136 12.2963Q0.275533 12.2816 0.31311 12.2741Q0.350687 12.2667 0.389 12.2667Z"
        id="Rectangle"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
