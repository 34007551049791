import { useEffect, useState, useCallback } from 'react';

const useElementPosition = element => {
  const [position, setPosition] = useState({});

  const handleResize = useCallback(() => {
    if (element) {
      const rect = element.getBoundingClientRect();

      setPosition({
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY,
        right: rect.right + window.scrollX,
        bottom: rect.bottom + window.scrollY,
      });
    }
  }, [element]);

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return position;
};

export default useElementPosition;
