import Mixpanel, { injectAssessmentResultsABData } from 'utils/mixpanelService';
import _has from 'lodash/has';

export const trackFavoriteAction = (
  event,
  data,
  auth,
  isSaved,
  isVariantB,
  shouldABTest,
) => {
  const isSigned = _has(auth, 'uid');
  Mixpanel.track(
    `${event} - Favorite an Item`,
    injectAssessmentResultsABData(
      {
        ...data,
        isSigned,
        action: isSaved ? 'unfavorite' : 'favorite',
      },
      shouldABTest,
      isVariantB,
    ),
  );
};
