import React from 'react';

export default (color = '#FFFFFF') => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 21 19"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="article" transform="translate(0.4500122 0.44999695)">
      <path
        d="M9.75 3.15005C9.75 1.45895 14.0524 0.0801574 19.0497 5.76807e-05C19.1685 -0.001872 19.283 0.0446852 19.3677 0.129286C19.4524 0.213887 19.5 0.329451 19.5 0.450056L19.5 14.4C19.5 14.6458 19.3059 14.8461 19.0639 14.85C14.0604 14.9283 9.75 16.3071 9.75 18C9.75 16.3089 5.43961 14.9301 0.436091 14.85C0.194102 14.8461 -3.09769e-05 14.6458 3.6365e-09 14.4L3.6365e-09 0.450056C-1.52009e-05 0.329451 0.0476492 0.213887 0.132302 0.129286C0.216955 0.0446852 0.33151 -0.001872 0.450273 5.76807e-05C5.4467 0.0801574 9.75 1.45895 9.75 3.15005Z"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 3.14825L9.75 17.8857"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.53409 4.95004C5.67546 4.30229 3.73562 3.92594 1.77273 3.83225"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9659 4.95004C13.8245 4.30229 15.7644 3.92594 17.7273 3.83225"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.53409 8.78223C5.67537 8.13509 3.73554 7.75934 1.77273 7.66623"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.53409 12.6C5.67546 11.9523 3.73562 11.5759 1.77273 11.4822"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9659 8.78223C13.8247 8.13519 15.7645 7.75944 17.7273 7.66623"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9659 12.6C13.8247 11.953 15.7645 11.5772 17.7273 11.484"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
