import React, { useEffect, useRef, useState } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Slide,
  Snackbar,
  Typography,
} from '@material-ui/core';
import RichTextReactRenderer from 'components/RichTextReactRenderer';
import useSiteCopySelector from 'components/useSiteCopySelector';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  snackbar: {
    [theme.breakpoints.up('md')]: {
      top: '34px',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #E3E3E3',
    backgroundColor: '#ffffff',
    width: '360px',
    padding: '20px 27px 27px 17px',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
    '& > svg': {
      fill: theme.palette.primary.main,
      fontSize: '1.2rem',
      marginRight: '8px',
    },
  },
  title: {
    fontSize: '17px',
    fontFamily: 'PoppinsSemiBold',
    fontWeight: 600,
  },
  closeButton: {
    position: 'absolute',
    top: '4px',
    right: '6px',
    padding: '6px',
    '&  svg': {
      fontSize: '1.1rem',
    },
  },
  continueButton: {
    padding: '13px 20px',
    marginTop: '8px',
    alignSelf: 'flex-end',
    borderRadius: '3px',
    fontFamily: 'PoppinsSemiBold',
    fontSize: '0.75rem',
  },
}));

export const LanguageDisclaimer = ({ clientDetails }) => {
  const [isOpen, setIsOpen] = useState(false);
  const prevLanguage = useRef();
  const classes = useStyles();
  const processing = useSelector(state => state.main.processing);
  const [disclaimer] = useSiteCopySelector(['resources-disclaimer']);

  useEffect(() => {
    if (_isEmpty(clientDetails)) {
      return;
    }

    const language = _get(clientDetails, 'language');
    const isDefaultLanguage = language === 'en-US';
    const hasLanguageChanged =
      prevLanguage.current && prevLanguage.current !== language;
    prevLanguage.current = language;

    setIsOpen(!isDefaultLanguage && hasLanguageChanged);
  }, [clientDetails]);

  const disclaimerRichText = _get(clientDetails, 'languageDisclaimer.json');
  if (processing || !disclaimerRichText) return null;

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'up' }}
      className={classes.snackbar}
    >
      <Box className={classes.container}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Box className={classes.titleWrapper}>
          <InfoOutlinedIcon />
          <Typography className={classes.title} variant="h5">
            {_get(disclaimer, 'pageCopy.disclaimerTitle')}
          </Typography>
        </Box>
        <RichTextReactRenderer
          data={disclaimerRichText}
          paragraphVariant="overline"
        />
        <Button
          className={classes.continueButton}
          variant="contained"
          color="primary"
          onClick={onClose}
        >
          {_get(disclaimer, 'pageCopy.languageButtonLabel')}
        </Button>
      </Box>
    </Snackbar>
  );
};
