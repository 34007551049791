import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import Config from 'utils/getEnvConfig';
import { getStore } from 'configureStore';
import { getLocalData } from 'utils/localDataStore';
import {
  getSubdomain,
  getUserDisplayName,
  getUserFirstName,
  getUserLastName,
} from 'utils/stringUtils';
import { filterByTitle } from 'utils/objectUtils';

export const { env } = Config;

export const getFirebaseConfig = () => {
  const { SAML_CLIENTS = [], ...config } = Config.FIREBASE;
  const isSAMLSet = SAML_CLIENTS.includes(getSubdomain());
  const authDomain = isSAMLSet
    ? window.location.hostname
    : Config.FIREBASE.authDomain;

  return {
    ...config,
    authDomain,
  };
};

const getIsInsuranceSet = insurance => {
  if (!_isEmpty(insurance)) return false;

  const store = getStore();
  const state = store.getState();
  const substate = _get(state, 'main');
  const shortName = _get(substate, 'clientDetails.shortName');

  const insuranceSiteConfig = filterByTitle(substate.siteConfig, 'Insurance');
  const insuranceOptions = _get(
    insuranceSiteConfig,
    'config.options',
    [],
  ).filter(item => {
    if (item.exclude.includes(shortName) || item.assessmentOnly) return false;
    if (!_isEmpty(item.client)) {
      if (item.client.includes(shortName)) return true;
      return false;
    }
    return true;
  });

  return insuranceOptions.map(option => option.value).includes(insurance);
};

export const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  updateProfileOnLogin: false,
  profileFactory: (data, profile) => {
    const helperProviderData =
      profile.providerData || data.providerData || data.user.providerData;
    const verified =
      _get(data, 'emailVerified', false) ||
      _get(data, ['user', 'emailVerified'], false) ||
      false;
    const providerData = JSON.parse(JSON.stringify(helperProviderData));
    const localDataInsurance = getLocalData('insurance');
    const insurance = Array.isArray(localDataInsurance)
      ? localDataInsurance
      : [localDataInsurance];

    const isInsuranceSet = getIsInsuranceSet(localDataInsurance);
    const firstName = getUserFirstName(profile);
    const lastName = getUserLastName(profile);
    const displayName = getUserDisplayName(profile);
    return {
      role: 'user',
      showContentfulTools: false,
      brand: getLocalData('brand') || 'none',
      insurance: isInsuranceSet ? insurance : [],
      ...profile,
      firstName,
      lastName,
      displayName,
      providerData: providerData[0],
      verified,
    };
  },
};

export default { env, getFirebaseConfig, rrfConfig };
