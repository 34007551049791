import React from 'react';

export default (color = '#FFFFFF') => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 19 19"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="video" transform="translate(0.4500122 0.44999695)">
      <path
        d="M18 3.86887C18.0002 2.84289 17.7168 1.85882 17.2122 1.13324C16.7076 0.407648 16.0231 -4.3401e-08 15.3094 0L2.68983 0C1.97623 0.000298325 1.29195 0.408078 0.787508 1.13363C0.283068 1.85919 -0.00020668 2.84308 9.10988e-07 3.86887L9.10988e-07 14.13C-0.00041432 15.156 0.282769 16.1402 0.787232 16.866C1.29169 17.5918 1.9761 17.9997 2.68983 18L15.3094 18C16.0233 18 16.7079 17.5922 17.2125 16.8664C17.7171 16.1405 18.0004 15.1562 18 14.13L18 3.86887ZM6.38944 13.4314L6.38944 3.68887L13.8059 8.56125L6.38944 13.4314Z"
        id="Shape"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
