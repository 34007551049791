import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';

export function getAlgoliaRatingQuery(clientDetails) {
  if (!clientDetails) return '';
  const {
    averageRatingCutoff,
    expertRatingCutoff,
    userRatingCutoff,
  } = clientDetails;
  let algoliaRatingCutOffQuery = '';

  if (expertRatingCutoff) {
    algoliaRatingCutOffQuery += ` AND cutoffExpertRating >= ${expertRatingCutoff}`;
  }
  if (averageRatingCutoff) {
    const resourcesWeightings = _get(
      clientDetails,
      'metadata.algoliaResourcesWeight',
    );
    let field = 'cutoffAvgRating';
    if (resourcesWeightings === '75-25') field = 'cutoffAvgRating7525';

    algoliaRatingCutOffQuery += ` AND ${field} >= ${averageRatingCutoff}`;
  }
  if (userRatingCutoff) {
    algoliaRatingCutOffQuery += ` AND cutoffUserRating >= ${userRatingCutoff}`;
  }
  return algoliaRatingCutOffQuery;
}

export function getContentfulRatingQuery(clientDetails) {
  let contentfulRatingQuery = {};
  if (!_isEmpty(clientDetails)) {
    const {
      averageRatingCutoff,
      expertRatingCutoff,
      userRatingCutoff,
    } = clientDetails;
    if (expertRatingCutoff) {
      contentfulRatingQuery = {
        ...contentfulRatingQuery,
        'fields.calculatedExpertRating[gte]': expertRatingCutoff,
      };
    }
    if (averageRatingCutoff) {
      contentfulRatingQuery = {
        ...contentfulRatingQuery,
        'fields.calculatedAverageRating[gte]': averageRatingCutoff,
      };
    }
    if (userRatingCutoff) {
      contentfulRatingQuery = {
        ...contentfulRatingQuery,
        'fields.calculatedUserRating[gte]': userRatingCutoff,
      };
    }
  }
  return contentfulRatingQuery;
}

export function getTopicsIdQuery(clientDetails) {
  const clientExcludedTopic = _get(
    clientDetails,
    'excludeTopicCollection.items',
  );
  const excludedTopicsId = !_isEmpty(clientExcludedTopic)
    ? clientExcludedTopic.map(topic => topic.sys.id)
    : [];
  return excludedTopicsId;
}

export function getAlgoliaTopicsQuery(
  field,
  excludeOnly = true,
  clientDetails,
  clientLanding,
) {
  const isCovidCenter = _get(clientLanding, 'slug', '') === 'covid-19';
  let algoliaTopicsQuery = '';
  const clientExcludedTopic = _get(
    clientDetails,
    'excludeTopicCollection.items',
  );
  let fieldToUse = 'title';
  if (field === 'filterTopics' || field === 'allTopics.slug')
    fieldToUse = 'slug';
  const excludedTopicField = !_isEmpty(clientExcludedTopic)
    ? clientExcludedTopic.map(topic => _get(topic, [fieldToUse]))
    : [];
  if (!_isEmpty(excludedTopicField)) {
    _forEach(excludedTopicField, value => {
      algoliaTopicsQuery += ` AND NOT ${field}:'${value}'`;
    });
  }
  if (!excludeOnly && (!_isEmpty(clientDetails) || isCovidCenter)) {
    const topicsCollections = _get(clientLanding, 'topTopicsCollection.items');
    if (!_isEmpty(topicsCollections)) {
      algoliaTopicsQuery += 'AND ';
      _forEach(topicsCollections, ({ slug }, index) => {
        algoliaTopicsQuery += `allTopics.slug:'${slug}'`;
        if (index !== topicsCollections.length - 1) {
          algoliaTopicsQuery += ' OR ';
        }
      });
    }
  }

  return algoliaTopicsQuery;
}

export function getContentfulTopicsQuery(isTopic, clientDetails) {
  const clientExcludedTopic = _get(
    clientDetails,
    'excludeTopicCollection.items',
  );
  let contentfulTopicsQuery = {};
  const excludedTopicIDs = !_isEmpty(clientExcludedTopic)
    ? clientExcludedTopic.map(topic => topic.sys.id)
    : [];
  if (!_isEmpty(excludedTopicIDs)) {
    if (isTopic)
      contentfulTopicsQuery = {
        'sys.id[nin]': excludedTopicIDs.join(','),
      };
    else
      contentfulTopicsQuery = {
        'fields.topics.sys.id[nin]': excludedTopicIDs.join(','),
      };
  }
  return contentfulTopicsQuery;
}
