/**
 *
 * useSiteCopySelector
 *
 */

import { useSelector } from 'react-redux';
import { makeSelectSiteCopy } from 'containers/Main/selectors';

function useSiteCopySelector(slugs = []) {
  const siteCopyArr = useSelector(makeSelectSiteCopy());

  const relevantSiteCopy = slugs.map(slug => {
    const [siteCopy] = siteCopyArr.filter(item => item.slug === slug);
    return siteCopy;
  });

  return relevantSiteCopy;
}

export default useSiteCopySelector;
