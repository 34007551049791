import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Colors from 'utils/colors';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  richTextWrapper: {
    fontSize: '1.25rem',
    fontFamily: 'Mada',
    fontWeight: 400,
    lineHeight: 1.5,
    direction: ({ textDirection }) => textDirection,
    color: ({ color }) => color || theme.palette.primary.contrastText,
    '& a': {
      color: ({ linkColor }) => linkColor || Colors.link,
      textDecoration: 'none',
    },
    '& .embedded-link': {
      textDecoration: 'none',
    },
    '& .embedded-link-inactive': {
      textDecoration: 'none',
      cursor: 'default',
      pointerEvents: 'none',
    },
    '& video': {
      width: '100%',
    },
    '& img': {
      maxWidth: '100%',
    },
    '& p,h1,h2,h3,h4,h5,h6': {
      marginTop: '1em',
      marginBottom: '1em',
      minHeight: '1.5em',
      '&:first-child': {
        marginTop: 0,
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
    '& button': {
      // Default Material-UI Button styles
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      boxSizing: 'border-box',
      minWidth: '64px',
      padding: '6px 16px',
      borderRadius: '4px',
      fontSize: '0.875rem',
      fontWeight: 500,
      textTransform: 'uppercase',
      letterSpacing: '0.02857em',
      border: 'none',
      transition:
        'background-color 0.3s, box-shadow 0.3s, border-color 0.3s, color 0.3s',

      '&.primary': {
        backgroundColor: '#0D73B1',
        fontSize: '0.9375rem',
        minWidth: 120,
        fontFamily: 'MadaSemiBold',
        borderRadius: 4,
        color: '#fff',
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
      '&.secondary': {
        backgroundColor: '#fff',
        border: '1px solid #01619B',
        fontSize: '0.9375rem',
        minWidth: 120,
        fontFamily: 'MadaSemiBold',
        borderRadius: 4,
        color: '#0D73B1',
        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
        },
      },
      '&.rounded': {
        backgroundColor: '#0D73B1',
        fontSize: '0.875rem',
        minWidth: 106,
        fontFamily: 'MadaRegular',
        borderRadius: 20,
        color: '#fff',
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
      '&.inactive': {
        backgroundColor: '#757575',
        fontSize: '0.75rem',
        minWidth: 64,
        fontFamily: 'MadaRegular',
        borderRadius: 5,
        color: '#fff',
        cursor: 'not-allowed',
      },
      '&.download': {
        backgroundColor: '#fff',
        border: '1px solid #01619B',
        fontSize: '0.875rem',
        minWidth: 64,
        fontFamily: 'MadaRegular',
        borderRadius: 20,
        color: '#0D73B1',
        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
        },
      },
    },
    textAlign: ({ justify, centered }) =>
      justify ? 'justify' : centered ? 'center' : 'left',
  },

  richTextWrapperWithoutHeaders: {
    '& h1': {
      fontSize: '2em',
      lineHeight: '1.43',
    },
    '& h2': {
      fontSize: '1.5em',
      lineHeight: '1.43',
    },
    '& h3': {
      fontSize: '1.17em',
      lineHeight: '1.43',
    },
    '& h4': {
      fontSize: '1em',
      lineHeight: '1.43',
    },
    '& h5': {
      fontSize: '0.83em',
      lineHeight: '1.43',
    },
    '& h6': {
      fontSize: '0.67em',
      lineHeight: '1.43',
    },
  },
  clampLines: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
  },
}));
const injectScriptsAndStyles = htmlContent => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const { head } = document;

  // Inject styles
  const linkElements = doc.querySelectorAll('link[rel="stylesheet"]');
  linkElements.forEach(link => {
    const newLink = document.createElement('link');
    newLink.rel = 'stylesheet';
    newLink.href = link.href;
    newLink.integrity = link.integrity;
    newLink.crossOrigin = link.crossOrigin;
    head.appendChild(newLink);
  });

  // Inject scripts
  const scriptElements = doc.querySelectorAll('script');
  scriptElements.forEach(script => {
    const newScript = document.createElement('script');
    newScript.src = script.src;
    newScript.integrity = script.integrity;
    newScript.crossOrigin = script.crossOrigin;
    head.appendChild(newScript);
  });
};

const DynamicContent = ({ htmlContent, limit }) => {
  const classes = useStyles();
  useEffect(() => {
    injectScriptsAndStyles(htmlContent);
  }, [htmlContent]);

  return (
    <div
      className={classNames(
        classes.richTextWrapper,
        classes.richTextWrapperWithoutHeaders,
        limit && classes.clampLines,
      )}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
};

export default DynamicContent;
