/**
 *
 * useCustomFavicon
 *
 */

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { makeSelectClientDetails } from 'containers/Main/selectors';

function useCustomFavicon() {
  const clientDetails = useSelector(makeSelectClientDetails());

  useEffect(() => {
    const hasCustomFavicon = _has(clientDetails, 'metadata.customFavicon');
    if (hasCustomFavicon) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
      }
      link.href = _get(clientDetails, 'metadata.customFavicon');
    }
  }, [clientDetails]);
}

export default useCustomFavicon;
