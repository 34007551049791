import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import { makeSelectFirestoreStatus } from 'containers/Main/selectors';
import Mixpanel from 'utils/mixpanelService';
import useSiteConfigSelector from 'components/useSiteConfigSelector';
import NavBarContext from '../navBarContext';
import AccountInfo from './AccountInfo';
import AccountButton from './NewAccountMenu/AccountButton';
import LoginMenu from './LoginMenu';
import { isPublicMode } from '../../../utils/localDataStore';

const trackUserMenuEvent = (data = {}) =>
  Mixpanel.track('User Menu - Clicked', data);

function AccountMenu() {
  const { clientDetails, isAuthenticated, logout, profile } = useContext(
    NavBarContext,
  );
  const history = useHistory();
  const firestoreStatus = useSelector(makeSelectFirestoreStatus());
  const [siteConfig] = useSiteConfigSelector(['Profile Sidebar']);
  const isPublicDevice = isPublicMode();

  const shouldUseAccountSidebar = !_get(
    siteConfig,
    'config.clientsDisabled',
    [],
  ).includes(_get(clientDetails, 'shortName'));

  if (!firestoreStatus || isPublicDevice) return null;

  return isAuthenticated ? (
    <>
      {shouldUseAccountSidebar ? (
        <AccountButton />
      ) : (
        <AccountInfo
          profile={profile}
          logout={logout}
          history={history}
          trackUserMenuEvent={trackUserMenuEvent}
        />
      )}
    </>
  ) : (
    <LoginMenu trackUserMenuEvent={trackUserMenuEvent} />
  );
}

export default AccountMenu;
