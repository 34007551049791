import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';
import _orderBy from 'lodash/orderBy';
import _partition from 'lodash/partition';
import _some from 'lodash/some';
import _uniq from 'lodash/uniq';
import _flattenDeep from 'lodash/flattenDeep';
import { getStore } from 'configureStore';
import { getAlgoliaRatingQuery } from 'containers/HomePage/services/api-helper';
import { getAlgoliaClientQuery } from 'containers/Main/utils';
import {
  getClientAudienceFilters,
  filterHandpickedResourceByCutoff,
  getArticlesMinimumWordCount,
} from 'containers/Resources/utils';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { replacePlaceholders } from 'utils/stringUtils';
import {
  getListPageCardGridSize,
  RESOURCE_PAGE_LAYOUT_RESPONSIVE,
} from 'components/ResourcePageLayout/utils';

export const DEFAULT_LIST_TYPES = [
  'Apps',
  'Articles',
  'Assessments',
  'FAQ',
  'Insights',
  'News',
  'Online Programs',
  'Organizations',
  'People',
  'Podcasts',
  'Videos',
];

// only for list.context.previewWideCards
// calculates the responsive to match the card size on the list page
export const getCarouselListPreviewResponsive = (
  breakpoints,
  containerWidth,
  childrenCount,
) => {
  const keys = breakpoints.keys.slice().reverse();
  const breakpointKey = keys.find(
    key => window.innerWidth >= breakpoints.values[key],
  );

  if (!breakpointKey) {
    return null;
  }

  const gridSize = getListPageCardGridSize();
  const percentages = Object.keys(gridSize).reduce((res, breakpoint) => {
    res[breakpoint] = 100 / (12 / gridSize[breakpoint]) / 100;
    return res;
  }, {});

  const listPageContainerOffset = RESOURCE_PAGE_LAYOUT_RESPONSIVE[breakpointKey]
    ? RESOURCE_PAGE_LAYOUT_RESPONSIVE[breakpointKey].asideWidth +
      RESOURCE_PAGE_LAYOUT_RESPONSIVE[breakpointKey].padding * 2
    : 0;
  const listPageContainerWidth =
    document.body.offsetWidth - listPageContainerOffset;
  const singleCardWidth =
    percentages[breakpointKey] * listPageContainerWidth + 10;
  const items = Math.floor(containerWidth / singleCardWidth);
  const partialVisibilityGutter =
    childrenCount > items
      ? (containerWidth - items * singleCardWidth) / items
      : 0;
  return {
    all: {
      breakpoint: {
        min: 0,
        max: 4000,
      },
      items,
      partialVisibilityGutter,
      slidesToSlide: items,
    },
  };
};

// Key is slug for Topics and id for Audience and Tags
export const getTitleFromKey = (siteCopy, type, key) =>
  _get(siteCopy, ['pageCopy', type, key]);

export const getKeyFromTitle = (siteCopy, type, title) => {
  let key;
  let i = 0;
  const mapping = _get(siteCopy, ['pageCopy', type], {});
  const keys = Object.keys(mapping);

  while (_isUndefined(key) && i < keys.length) {
    if (_get(siteCopy, ['pageCopy', type, keys[i]]) === title) key = keys[i];

    i += 1;
  }

  return key || title;
};

const getCostDescriptionMapping = () => {
  const store = getStore();
  const resourceItemSiteCopy = _get(store.getState(), 'main.siteCopy', []).find(
    ({ slug }) => slug === 'resource-item-page',
  );
  return _get(resourceItemSiteCopy, 'pageCopy.costDescriptionTypeMapping', {});
};

export const isListSaved = (savedResources = [], list, topic) => {
  const id = _get(list, 'sys.id');

  const [item] = savedResources.filter(el => el.resourceID === id);
  if (item) {
    if (topic) {
      return _get(item, 'topics', [])
        .map(el => el.slug)
        .includes(topic);
    }

    return true;
  }

  return false;
};

export const getAlgoliaListFilters = params => {
  const {
    context,
    types,
    handpicked,
    currentTopic,
    topics = [],
    themesTopics = [],
    audiences,
    tags,
    cost,
    languages,
    length,
    profileFilters,
    isPeerReview = false,
    client,
    clientDetails,
    clientExcludedTopic,
    clientExcludedCost = [],
    clientExcludedResourceTypes = [],
    averageRatingCutoff,
    expertRatingCutoff,
    userRatingCutoff,
    excludedAssessments,
    excludedAudiences,
    excludedTags,
    locale,
    shouldApplyLengthFilter,
    featuresConfig,
    shouldFilterShortArticles,
    isAssessmentResultsPage = false,
    averageRatingLimit = false,
    filtersTranslationsSiteCopy,
  } = params;

  const finalTypes = types || DEFAULT_LIST_TYPES;

  const prefix = locale !== 'en-US' ? `${locale}_` : '';
  const filtering = _get(context, 'filtering', '');

  const ratingFilter = getAlgoliaRatingQuery({
    averageRatingCutoff,
    expertRatingCutoff,
    userRatingCutoff,
  });

  let topicsFieldName;
  if (isPeerReview) {
    topicsFieldName = 'expandedAllTopics.slug';
  } else {
    topicsFieldName = 'allTopics.slug';
  }
  let excludeTopicsFieldName;
  if (isPeerReview) {
    excludeTopicsFieldName = 'expandedFilterTopics';
  } else {
    excludeTopicsFieldName = 'filterTopics';
  }

  const assessmentResultsPageTypesQuery =
    'NOT expandedType:"Topics" AND NOT expandedType:"Assessments"';
  let typesQuery = '';
  let topicsQuery = '';
  let audiencesQuery = '';
  let tagsQuery = '';
  let costQuery = '';
  let languageQuery = '';
  let lengthQuery = '';
  let handpickedQuery = '';
  let algoliaAssessmentsQuery = '';
  const excludeResourceTypesQuery = _isEmpty(clientExcludedResourceTypes)
    ? ''
    : ` AND ${clientExcludedResourceTypes
        .map(type => `NOT expandedType:'${type}'`)
        .join(' AND ')}`;

  finalTypes.forEach((type, index) => {
    if (index === 0) typesQuery += '(';
    typesQuery += `${prefix}expandedType:"${type === 'FAQs' ? 'FAQ' : type}"`;
    if (index !== finalTypes.length - 1) {
      typesQuery += ' OR ';
    }
    if (index === finalTypes.length - 1) typesQuery += ')';
  });

  const finalTopics = !_isEmpty(themesTopics)
    ? themesTopics
    : !_isEmpty(topics)
    ? topics
    : currentTopic
    ? [currentTopic]
    : [];
  finalTopics.forEach((topic, index) => {
    if (index === 0) topicsQuery += '(';
    topicsQuery += `${topicsFieldName}:"${getKeyFromTitle(
      filtersTranslationsSiteCopy,
      'topics',
      topic,
    )}"`;
    if (index !== finalTopics.length - 1) {
      topicsQuery += ' OR ';
    }
    if (index === finalTopics.length - 1) topicsQuery += ')';
  });

  if (clientExcludedTopic) {
    clientExcludedTopic.forEach((topic, index) => {
      if (index === 0 && !topicsQuery) {
        topicsQuery += ` NOT ${excludeTopicsFieldName}:"${topic.slug}"`;
      }
      topicsQuery += ` AND NOT ${excludeTopicsFieldName}:"${topic.slug}"`;
    });
  }

  if (!_isEmpty(audiences)) {
    audiences.forEach((audience, index) => {
      if (index === 0) audiencesQuery += '(';
      audiencesQuery += `expandedAudience:"${getKeyFromTitle(
        filtersTranslationsSiteCopy,
        'audience',
        audience.trim(),
      )}"`;
      if (index !== audiences.length - 1) {
        audiencesQuery += ' OR ';
      }
      if (index === audiences.length - 1) audiencesQuery += ')';
    });
  }

  if (!_isEmpty(tags)) {
    tags.forEach((tag, index) => {
      if (index === 0) tagsQuery += '(';
      tagsQuery += `expandedTags:"${getKeyFromTitle(
        filtersTranslationsSiteCopy,
        'tags',
        tag.trim(),
      )}"`;
      if (index !== tags.length - 1) {
        tagsQuery += ' OR ';
      }
      if (index === tags.length - 1) tagsQuery += ')';
    });
  }

  if (!_isEmpty(languages)) {
    languages.forEach((language, index) => {
      if (index === 0) languageQuery += '(';
      languageQuery += `availableIn:"${getKeyFromTitle(
        filtersTranslationsSiteCopy,
        'tags',
        language,
      )}"`;
      if (index !== languages.length - 1) {
        languageQuery += ' OR ';
      }
      if (index === languages.length - 1) languageQuery += ')';
    });
  }
  if (!_isEmpty(cost)) {
    const costMapping = getCostDescriptionMapping();
    if (cost.includes(costMapping.Free)) {
      costQuery = '(mappedCost = 0 OR mappedCost = -1)';
    }
  }
  if (!_isEmpty(handpicked)) {
    handpicked.forEach((slug, index) => {
      handpickedQuery += `NOT slug:"${slug}"`;
      if (index !== handpicked.length - 1) {
        handpickedQuery += ' AND ';
      }
    });
  }
  if (
    shouldApplyLengthFilter &&
    !_isEmpty(length) &&
    length.includes('Under 5 Minutes')
  ) {
    lengthQuery = 'underFiveMinutes:true AND isPodcastSeries:false';
  }

  let requiredTagsQuery = 'requiredTags:"none"';
  if (!_isEmpty(_get(profileFilters, 'insuranceTags'))) {
    requiredTagsQuery = `(requiredTags:"none" OR ${profileFilters.insuranceTags
      .map(tag => `requiredTags:"${tag.id}"`)
      .join(' OR ')})`;
  }

  let excludedTagsQuery = '';
  if (!_isEmpty(_get(profileFilters, 'tags'))) {
    excludedTagsQuery = ` AND ${profileFilters.tags
      .map(tag => `NOT excludedTags:"${tag.id}"`)
      .join(' AND ')}`;
  }

  let stateFilters = '';
  if (!_isEmpty(_get(profileFilters, 'state'))) {
    if (profileFilters.state !== 'all') {
      stateFilters = `(state:"all" OR state:"${profileFilters.state}")`;
    }
  }

  const clientAudienceQuery = getClientAudienceFilters(
    profileFilters,
    'audience.id',
  );

  let clientQuery;
  if (_isEmpty(client)) {
    clientQuery = getAlgoliaClientQuery(clientDetails);
  } else {
    clientQuery = `client.shortName:"${clientDetails.shortName}"`;
  }

  if (!_isEmpty(excludedAudiences)) {
    if (audiencesQuery) audiencesQuery += ' AND ';
    _forEach(excludedAudiences, (item, index) => {
      audiencesQuery += `${
        index > 0 ? ' AND ' : ''
      }NOT expandedAudience:'${getKeyFromTitle(
        filtersTranslationsSiteCopy,
        'audience',
        item,
      )}'`;
    });
  }
  if (!_isEmpty(excludedTags)) {
    if (tagsQuery) tagsQuery += ' AND ';
    _forEach(excludedTags, (item, index) => {
      tagsQuery += `${
        index > 0 ? ' AND ' : ''
      }NOT expandedTags:'${getKeyFromTitle(
        filtersTranslationsSiteCopy,
        'tags',
        item,
      )}'`;
    });
    if (tagsQuery) filters += algoliaAssessmentsQuery;
  }

  let filters = '';
  if (isAssessmentResultsPage) {
    filters += assessmentResultsPageTypesQuery;
  } else if (typesQuery) {
    filters += typesQuery;
  }
  if (topicsQuery) {
    if (filters !== '') filters += ' AND ';
    filters += topicsQuery;
  }
  if (audiencesQuery) {
    if (filters !== '') filters += ' AND ';
    filters += audiencesQuery;
  }
  if (tagsQuery) {
    if (filters !== '') filters += ' AND ';
    filters += tagsQuery;
  }
  if (costQuery) {
    if (filters !== '') filters += ' AND ';
    filters += costQuery;
  }
  if (lengthQuery) {
    if (filters !== '') filters += ' AND ';
    filters += lengthQuery;
  }
  if (languageQuery) {
    if (filters !== '') filters += ' AND ';
    filters += languageQuery;
  }
  if (handpickedQuery) {
    if (filters !== '') filters += ' AND ';
    filters += handpickedQuery;
  }
  if (filters !== '') filters += ' AND ';
  filters += clientQuery;
  if (filters !== '') filters += ' AND ';
  filters += requiredTagsQuery;
  filters += excludedTagsQuery;
  if (stateFilters) {
    if (filters !== '') filters += ' AND ';
    filters += stateFilters;
  }
  if (clientAudienceQuery) {
    filters += clientAudienceQuery;
  }
  if (shouldFilterShortArticles) {
    const articlesMinimumWordCount = getArticlesMinimumWordCount(
      featuresConfig,
      clientDetails,
    );
    filters += ` AND wordCount > ${articlesMinimumWordCount}`;
  }

  if (!_isEmpty(clientExcludedCost)) {
    if (filters !== '') filters += ' AND ';
    filters += clientExcludedCost
      .map(item => `NOT costDescription:'${item}'`)
      .join(' AND ');
  }

  if (ratingFilter) filters += ratingFilter;
  if (filtering) filters += ` AND ${filtering}`;
  if (excludedAssessments) {
    _forEach(excludedAssessments, ({ slug }) => {
      algoliaAssessmentsQuery += ` AND NOT slug:'${slug}'`;
    });
    if (algoliaAssessmentsQuery) filters += algoliaAssessmentsQuery;
  }
  // add average rating limit if available
  if (averageRatingLimit) filters += ` AND avgRating >= ${averageRatingLimit}`;
  filters += excludeResourceTypesQuery;

  return filters;
};

export const getFiltersFromContentResources = (
  resources = [],
  field,
  clientName,
) => {
  const counters = {};

  if (field === 'types') {
    resources.forEach(resource => {
      const type = getExpandedReverseType(resource);
      if (type) {
        if (counters[type]) {
          counters[type] += 1;
        } else {
          counters[type] = 1;
        }
      }
    });
  } else if (field === 'client') {
    resources.forEach(resource => {
      _get(resource, 'fields.client', []).forEach(e => {
        if (_get(e, 'fields.shortName') === clientName) {
          if (counters[_get(e, 'fields.formalShortName')]) {
            counters[_get(e, 'fields.formalShortName')] += 1;
          } else {
            counters[_get(e, 'fields.formalShortName')] = 1;
          }
        }
      });
    });
  } else if (field === 'length') {
    resources.forEach(resource => {
      if (
        ![
          'book',
          'onlineProgram',
          'topics',
          'person',
          'organization',
          'activity',
          'application',
          'link',
        ].includes(_get(resource, 'sys.contentType.sys.id'))
      ) {
        let minutes = 6;
        if (_get(resource, 'sys.contentType.sys.id') === 'video')
          minutes = _get(resource, 'fields.minutes', 6);
        else if (_get(resource, 'sys.contentType.sys.id') === 'podcast')
          minutes = _get(resource, 'fields.length', 6);
        else if (_get(resource, 'sys.contentType.sys.id') === 'assessments')
          minutes = _get(resource, 'fields.time', 6);

        if (minutes <= 5) {
          if (counters['Under 5 Minutes']) {
            counters['Under 5 Minutes'] += 1;
          } else {
            counters['Under 5 Minutes'] = 1;
          }
        }
      }
    });
  } else if (field === 'language') {
    // TODO: Separate this logic into a site config record to avoid hardcoding the ids
    resources.forEach(resource => {
      _get(resource, 'fields.tags', []).forEach(e => {
        if (
          _get(e, 'sys.id') === '01uO1Tey6SU3SALlMA8mbs' &&
          _get(e, 'fields.reviewStatus') === 'Accepted'
        ) {
          const counterField = _get(e, 'fields.title');
          if (counterField) {
            if (counters[counterField]) {
              counters[counterField] += 1;
            } else {
              counters[counterField] = 1;
            }
          }
        }
      });
    });
  } else if (field === 'audienceType' || field === 'tags') {
    resources.forEach(resource => {
      _get(resource, ['fields', field], []).forEach(e => {
        if (
          _get(e, 'sys.id') !== '01uO1Tey6SU3SALlMA8mbs' &&
          _get(e, 'fields.reviewStatus') === 'Accepted'
        ) {
          const filterId = _get(e, 'sys.id');
          if (filterId) {
            if (counters[filterId]) {
              counters[filterId] += 1;
            } else {
              counters[filterId] = 1;
            }
          }
        }
      });
    });
  } else {
    resources.forEach(resource => {
      _get(resource, ['fields', field], []).forEach(e => {
        if (_get(e, 'fields.reviewStatus') === 'Accepted') {
          const counterField =
            _get(e, 'fields.name') || _get(e, 'fields.title');
          if (counterField) {
            if (counters[counterField]) {
              counters[counterField] += 1;
            } else {
              counters[counterField] = 1;
            }
          }
        }
      });
    });
  }

  const filters = Object.keys(counters).map(key => ({
    value: key,
    count: counters[key],
  }));

  return filters;
};

export const getExpandedReverseType = item => {
  if (_get(item, 'subtype') === 'News') return 'News';
  if (_get(item, 'sys.contentType.sys.id') === 'activity') {
    if (_get(item, 'fields.blog')) return 'Blogs';
    if (_get(item, 'fields.type') === 'News') return 'News';
    return 'Articles';
  }
  if (_get(item, 'sys.contentType.sys.id') === 'application') {
    return 'Apps';
  }
  if (_get(item, 'sys.contentType.sys.id') === 'list') {
    return 'Lists';
  }
  if (_get(item, 'sys.contentType.sys.id') === 'assessments') {
    return 'Assessments';
  }
  if (_get(item, 'sys.contentType.sys.id') === 'book') {
    return 'Books';
  }
  if (_get(item, 'sys.contentType.sys.id') === 'onlineProgram') {
    return 'Online Programs';
  }
  if (_get(item, 'sys.contentType.sys.id') === 'organization') {
    return 'Organizations';
  }
  if (_get(item, 'sys.contentType.sys.id') === 'person') {
    return 'People';
  }
  if (_get(item, 'sys.contentType.sys.id') === 'podcast') {
    return 'Podcasts';
  }
  if (_get(item, 'sys.contentType.sys.id') === 'source') {
    return 'FAQ';
  }
  if (_get(item, 'sys.contentType.sys.id') === 'topics') {
    return 'Topics';
  }
  if (_get(item, 'sys.contentType.sys.id') === 'video') {
    return 'Videos';
  }
  if (_get(item, 'sys.contentType.sys.id') === 'services') {
    return 'Services';
  }
  if (_get(item, 'sys.contentType.sys.id') === 'link') {
    return 'Links';
  }
  if (_get(item, 'sys.contentType.sys.id') === 'series') {
    return 'Series';
  }
  return null;
};

export const getIsAutomatic = item =>
  !_get(item, 'fields.handpickedOnly') &&
  _get(item, 'fields.style') !== 'Topic';

export const sortByScoringWithoutRepeatedTypes = (
  data,
  prioritizeClientResources = false,
) => {
  let noClientHits = data;
  let clientHits = [];
  const sortedData = [];
  const scoringMap = {};

  if (prioritizeClientResources) {
    [clientHits, noClientHits] = _partition(data, hit =>
      hit.client.some(c => c.shortName !== 'none'),
    );
  }

  noClientHits.forEach(hit => {
    const parsedRating = Math.round(hit.avgRating * 10) / 10;
    let sortingRating = parsedRating * 1000;
    if (!scoringMap[hit.expandedType]) {
      scoringMap[hit.expandedType] = [sortingRating];
    } else {
      while (scoringMap[hit.expandedType].includes(sortingRating)) {
        sortingRating -= 1;
      }
      scoringMap[hit.expandedType].push(sortingRating);
    }

    sortedData.push({
      ...hit,
      sortingRating,
    });
  });

  return clientHits.concat(_orderBy(sortedData, 'sortingRating', 'desc'));
};

export const getPaginationInfo = ({
  maxItems,
  hitsPerPage,
  handpickedCount,
  page,
}) => {
  let offset = page * hitsPerPage;
  let length = hitsPerPage;

  if (!maxItems) {
    return { offset, length };
  }

  const numOfHits = maxItems - hitsPerPage * page - handpickedCount;
  if (numOfHits < hitsPerPage) {
    length = numOfHits;
    offset = hitsPerPage * page;
  }

  return { offset, length };
};

const partitionClientResources = ({
  resources,
  clientField,
  clientShortNameField,
  clientShortName,
  clientGroupShortName,
}) => {
  const [clientResources, noClientResources] = _partition(resources, resource =>
    _get(resource, clientField, []).some(
      resourceClient =>
        _get(resourceClient, clientShortNameField) === clientShortName ||
        _get(resourceClient, clientShortNameField) === clientGroupShortName,
    ),
  );

  clientResources.sort((a, b) => {
    const isClientExclusiveA = _get(a, clientField, [])
      .map(resourceClient => _get(resourceClient, clientShortNameField))
      .includes(clientShortName);
    const isClientExclusiveB = _get(b, clientField, [])
      .map(resourceClient => _get(resourceClient, clientShortNameField))
      .includes(clientShortName);

    if (isClientExclusiveA && !isClientExclusiveB) return -1;
    if (!isClientExclusiveA && isClientExclusiveB) return 1;
    return 0;
  });

  return [clientResources, noClientResources];
};

export const partitionClientHandpickedResources = ({
  handpickedResources,
  prioritizeClientResources,
  clientShortName,
  clientGroupShortName,
}) =>
  prioritizeClientResources
    ? partitionClientResources({
        resources: handpickedResources,
        clientShortName,
        clientGroupShortName,
        clientField: 'fields.client',
        clientShortNameField: 'fields.shortName',
      })
    : [handpickedResources, []];

export const partitionClientAutomaticResources = ({
  automaticResources,
  prioritizeClientResources,
  clientShortName,
  clientGroupShortName,
}) =>
  prioritizeClientResources
    ? partitionClientResources({
        resources: automaticResources,
        clientShortName,
        clientGroupShortName,
        clientField: 'client',
        clientShortNameField: 'shortName',
      })
    : [automaticResources, []];

export const shouldFilterHandpickedResource = ({
  resource,
  averageRatingCutoff,
  userRatingCutoff,
  expertRatingCutoff,
  excludedAssessmentsSlugs = [],
  excludedTopicsSlugs = [],
  clientExcludedCost = [],
  clientExcludedResourceTypes = [],
  excludedAudiences = [],
  excludedTags = [],
  insuranceTags = [],
  query,
  typesFilters,
  languageFilters,
  audiencesFilters,
  tagsFilters,
  lengthFilters,
  state,
  shouldFilterShortArticles,
  featuresConfig,
  isArticleTypesExcluded,
  clientDetails,
}) => {
  if (
    !filterHandpickedResourceByCutoff({
      resource,
      averageRatingCutoff,
      userRatingCutoff,
      expertRatingCutoff,
    })
  )
    return false;
  if (
    shouldFilterShortArticles &&
    _get(resource, 'sys.contentType.sys.id') === 'activity' &&
    !filterHandpickedArticleByWordCount({
      resource,
      featuresConfig,
      clientDetails,
    })
  )
    return false;

  if (
    _get(resource, 'sys.contentType.sys.id') === 'assessments' &&
    excludedAssessmentsSlugs.includes(_get(resource, 'fields.slug'))
  )
    return false;

  if (excludedTopicsSlugs.length > 0) {
    const resourceTopics = _get(resource, 'fields.topics', []).map(topic =>
      _get(topic, 'fields.slug'),
    );
    if (_some(resourceTopics, topic => excludedTopicsSlugs.includes(topic)))
      return false;
  }

  if (clientExcludedCost.includes(_get(resource, 'fields.costDescription')))
    return false;

  if (isArticleTypesExcluded && _isEmpty(isArticleTypesExcluded(resource))) {
    return false;
  }

  if (clientExcludedResourceTypes.includes(getExpandedReverseType(resource)))
    return false;

  if (
    !_isEmpty(typesFilters) &&
    !typesFilters.includes(getExpandedReverseType(resource))
  ) {
    return false;
  }
  if (
    !_isEmpty(languageFilters) &&
    !_some(languageFilters, filter =>
      _get(resource, 'fields.language', [])
        .map(language => _get(language, 'fields.title'))
        .includes(filter),
    )
  ) {
    return false;
  }
  if (
    !_isEmpty(audiencesFilters) &&
    !_some(audiencesFilters, filter =>
      _get(resource, 'fields.audienceType', [])
        .map(audience => _get(audience, 'fields.name'))
        .includes(filter),
    )
  ) {
    return false;
  }
  if (
    !_isEmpty(excludedAudiences) &&
    _some(excludedAudiences, filter =>
      _get(resource, 'fields.audienceType', [])
        .map(audience => _get(audience, 'fields.name'))
        .includes(filter),
    )
  ) {
    return false;
  }
  if (
    !_isEmpty(tagsFilters) &&
    !_some(tagsFilters, filter =>
      _get(resource, 'fields.tags', [])
        .map(tag => _get(tag, 'fields.title'))
        .includes(filter),
    )
  ) {
    return false;
  }
  if (
    !_isEmpty(excludedTags) &&
    _some(excludedTags, filter =>
      _get(resource, 'fields.tags', [])
        .map(tag => _get(tag, 'fields.title'))
        .includes(filter),
    )
  ) {
    return false;
  }
  if (
    !_isEmpty(_get(resource, 'fields.requiredTags', [])) &&
    !_some(_get(resource, 'fields.requiredTags', []), tag =>
      insuranceTags.includes(_get(tag, 'sys.id')),
    )
  ) {
    return false;
  }
  if (
    state &&
    !_isEmpty(_get(resource, 'fields.state')) &&
    !_get(resource, 'fields.state').includes(state)
  ) {
    return false;
  }
  if (!_isEmpty(lengthFilters)) {
    if (
      [
        'book',
        'onlineProgram',
        'topics',
        'person',
        'organization',
        'activity',
        'application',
        'link',
      ].includes(_get(resource, 'sys.contentType.sys.id'))
    )
      return false;

    let minutes = -1;
    if (_get(resource, 'sys.contentType.sys.id') === 'video')
      minutes = _get(resource, 'fields.minutes', -1);
    else if (_get(resource, 'sys.contentType.sys.id') === 'podcast') {
      if (_get(resource, 'fields.type') === 'Series') return false;
      minutes = _get(resource, 'fields.length', -1);
    } else if (_get(resource, 'sys.contentType.sys.id') === 'assessments')
      minutes = _get(resource, 'fields.time', -1);

    if (minutes > 5) return false;
  }

  if (query && query !== '') {
    const bagOfWords = [];
    if (_get(resource, 'fields.name')) {
      bagOfWords.push(
        ...resource.fields.name
          .toLowerCase()
          .replace(/[^\w\s]/g, '')
          .split(' '),
      );
    }
    if (_get(resource, 'fields.title')) {
      bagOfWords.push(
        ...resource.fields.title
          .toLowerCase()
          .replace(/[^\w\s]/g, '')
          .split(' '),
      );
    }
    if (_get(resource, 'fields.article')) {
      bagOfWords.push(
        ...resource.fields.article
          .toLowerCase()
          .replace(/[^\w\s]/g, '')
          .split(' '),
      );
    }
    if (_get(resource, 'fields.content')) {
      bagOfWords.push(
        ...documentToPlainTextString(resource.fields.content)
          .toLowerCase()
          .replace(/[^\w\s]/g, '')
          .split(' '),
      );
    }
    if (_get(resource, 'fields.description')) {
      bagOfWords.push(
        ...documentToPlainTextString(resource.fields.description)
          .toLowerCase()
          .replace(/[^\w\s]/g, '')
          .split(' '),
      );
    }

    let searchTermResult = false;
    const bagSearchTerm = query
      .replace(/,/g, ' ')
      .toLowerCase()
      .split(' ');
    let i = 0;
    while (!searchTermResult && i < bagSearchTerm.length) {
      if (bagOfWords.includes(bagSearchTerm[i])) searchTermResult = true;
      i += 1;
    }

    if (!searchTermResult) return false;
  }

  if (
    _get(resource, 'sys.contentType.sys.id') === 'source' &&
    _get(resource, 'fields.type') !== 'FAQ'
  )
    return false;

  return _get(resource, 'fields.reviewStatus') === 'Accepted';
};

export const getSlimItems = ({
  lists,
  parentListTopicTitle,
  assessmentSlug,
  resourceItemSiteCopy,
}) => {
  const acceptedLengthTypes = ['Videos', 'Podcasts', 'Assessments'];

  return lists.map(item => {
    const types = _get(item, 'fields.resourceTypes', []);
    const shouldApplyLengthFilter =
      _isEmpty(types) || types.some(type => acceptedLengthTypes.includes(type));
    const handpickedSlugs = _get(item, 'fields.resources', [])
      .map(resource => _get(resource, 'fields.slug'))
      .filter(resource => !!resource);
    const handpicked = assessmentSlug
      ? handpickedSlugs.concat(assessmentSlug)
      : handpickedSlugs;
    const topicsTitle = _get(
      item,
      'fields.whatHelpsTopics',
      _get(item, 'fields.topics', []),
    ).map(listTopic => _get(listTopic, 'fields.title'));
    const topics = parentListTopicTitle
      ? [...parentListTopicTitle, ...topicsTitle]
      : topicsTitle;

    return {
      title: _get(item, 'fields.title'),
      isAutomatic: getIsAutomatic(item),
      handpicked,
      context: _get(item, 'fields.context', ''),
      types: types.map(type =>
        _get(resourceItemSiteCopy, [
          'pageCopy',
          'algoliaResourceTypeMapping',
          type,
        ]),
      ),
      topics,
      themesTopics: _uniq(
        _flattenDeep(
          _get(
            item,
            'fields.whatHelpsThemes',
            _get(item, 'fields.themes', []),
          ).map(listTheme => _get(listTheme, 'fields.topics')),
        )
          .filter(
            themeTopic =>
              _get(themeTopic, 'fields.reviewStatus') === 'Accepted',
          )
          .map(themeTopic => _get(themeTopic, 'fields.title')),
      ),
      audiences: _get(item, 'fields.audienceType', []).map(listAudience =>
        _get(listAudience, 'fields.name'),
      ),
      tags: _get(item, 'fields.tags', []).map(listTag =>
        _get(listTag, 'fields.title'),
      ),
      shouldApplyLengthFilter,
    };
  });
};

export const getGlobalListTitle = (item, topicSlug = '') => {
  const customTitle = _get(item, 'fields.context.customTitle');
  const slug = _get(item, 'fields.topics.0.fields.slug', topicSlug);
  if (!_get(customTitle, slug)) {
    return _get(item, 'fields.title', '').replace(
      '<topic>',
      _get(item, 'fields.topics.0.fields.title', ''),
    );
  }

  return replacePlaceholders(customTitle[slug], {
    '#topic': _get(item, 'fields.topics.0.fields.title', ''),
  });
};

export const filterHandpickedArticleByWordCount = ({
  resource,
  featuresConfig,
  clientDetails,
}) => {
  if (_get(resource, 'sys.contentType.sys.id') !== 'activity') return true;
  if (_get(resource, 'fields.blog')) return true;
  if (_get(resource, 'fields.type') === 'Informational Link') return true;
  const articlesMinimumWordCount = getArticlesMinimumWordCount(
    featuresConfig,
    clientDetails,
  );
  return _get(resource, 'fields.wordCount', 0) > articlesMinimumWordCount;
};

export const getListPurposeField = () => {
  const state = getStore().getState();
  const siteConfigs = _get(state, 'main.siteConfig') ?? [];
  const featuresSiteConfig = siteConfigs.find(s => s.title === 'Features');
  return _get(featuresSiteConfig, 'config.listPurposeField', 'purpose');
};
