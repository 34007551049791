import React, { useState, useRef } from 'react';
import { ButtonBase, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getImageFile } from 'utils/stringUtils';
import classNames from 'classnames';
import _get from 'lodash/get';

const useStyles = makeStyles(theme => ({
  topicItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    justifyContent: props => !props.isInLanding && 'start',
    width: props => props.isInLanding && 100,
    minWidth: props => props.isInLanding && 100,
  },
  image: props => ({
    width: props.isInLanding ? 59 : 100,
    height: props.isInLanding ? 59 : 100,
    borderRadius: props.isInLanding ? '50%' : 20,
    border: !props.isInLanding && '1px solid #f2ecec',
    minHeight: props.isInLanding && 59,
    [theme.breakpoints.up(600)]: {
      '&:hover': {
        border: '2px solid #0061A0',
        borderColor: props.isInLanding
          ? theme.palette.primary.secondary
          : theme.palette.primary.main,
      },
    },
    '&.error:hover': {
      border: '2px solid #DA294A',
    },
    '&.selected': {
      border: '2px solid',
      borderColor: props.isInLanding
        ? theme.palette.primary.secondary
        : theme.palette.primary.main,
      padding: 2,
    },
  }),
  imageWrapper: {
    position: 'relative',
    background: 'white',
  },
  title: {
    fontSize: 12,
    fontFamily: 'PoppinsRegular',
    marginTop: 10,
    '&:hover': {
      color: props =>
        props.isInLanding
          ? theme.palette.primary.secondary
          : theme.palette.primary.main,
    },
  },
  selectedTitle: {
    color: props =>
      props.isInLanding
        ? theme.palette.primary.secondary
        : theme.palette.primary.main,
  },
  tooltip: {
    backgroundColor: '#FFFFFF',
    color: '#000',
    border: `1px solid #d7d7d7`,
    borderRadius: 5,
    opacity: '1 !important',
    fontSize: 13,
  },
  popper: {
    zIndex: 1301,
  },
  arrow: {
    '&::before': {
      border: `1px solid #d7d7d7`,
      backgroundColor: '#FFFFFF',
      boxSizing: 'border-box',
    },
  },
}));

export const OnboardingTopics = ({
  data,
  selectedTopics,
  onClick,
  isInLanding,
  toolTipMessage,
  isMobile,
  checkIcon,
  isMaxReached,
}) => {
  const classes = useStyles({ isInLanding });
  const [idToShowTooltip, setIdToShowTooltip] = useState();
  const currentTooltipId = useRef();

  const onTopicClick = id => {
    setIdToShowTooltip(id);
    onClick(id);
  };

  const onOpen = id => {
    setIdToShowTooltip(id);
    currentTooltipId.current = id;
  };

  const onClose = id => {
    if (currentTooltipId.current === id) {
      setIdToShowTooltip(undefined);
    }
  };

  return data.map(({ fields, sys: { id } }) => {
    const imageField = _get(fields, 'image');
    const image = _get(fields, 'imageUrl')
      ? fields.imageUrl
      : getImageFile(imageField ? fields : _get(fields, 'resources.0.fields'));
    const isSelected = selectedTopics.includes(id);
    const topicTitle = _get(fields, 'shortTitle') || _get(fields, 'title');

    return (
      <Tooltip
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.arrow,
          popper: classes.popper,
        }}
        key={`onboarding-topics-${id}`}
        placement="top"
        arrow
        title={isMaxReached && !isSelected ? toolTipMessage : ''}
        open={id === idToShowTooltip}
        enterTouchDelay={0}
        leaveTouchDelay={2000}
        disableHoverListener={isMobile}
        disableFocusListener={isMobile}
        onOpen={isMaxReached ? () => onOpen(id) : undefined}
        onClose={isMaxReached ? () => onClose(id) : undefined}
      >
        <ButtonBase
          disableRipple
          className={classes.topicItem}
          onClick={() => onTopicClick(id)}
        >
          <div className={classes.imageWrapper}>
            <img
              src={image}
              alt="Topic Item"
              className={classNames(
                classes.image,
                isSelected && 'selected',
                isMaxReached && !isSelected && 'error',
              )}
            />
            {isSelected && checkIcon}
          </div>
          <span
            className={classNames(
              classes.title,
              isSelected && classes.selectedTitle,
            )}
          >
            {topicTitle}
          </span>
        </ButtonBase>
      </Tooltip>
    );
  });
};
