import axios from 'axios';
import _get from 'lodash/get';
import Config from 'utils/getEnvConfig';

const {
  LAMBDA: {
    TRANSLATION: { URL, API_KEY },
  },
} = Config;

export const getTranslatedQuery = async (query, locale) => {
  const response = await axios.post(
    URL,
    {
      text: query,
      source: locale,
      target: 'en-US',
    },
    {
      headers: {
        'x-api-key': API_KEY,
        'Content-Type': 'application/json',
      },
    },
  );

  return _get(response, 'data.0');
};
