/*
 *
 * Assessments actions
 *
 */

import { createAction } from 'redux-actions';
import {
  GET_ASSESSMENTS,
  GET_ASSESSMENTS_SUCCESS,
  GET_ASSESSMENTS_FAIL,
  PROCESSING,
  SECTION_PROCESSING,
  GET_TAKEN_ASSESSMENTS,
  GET_TAKEN_ASSESSMENTS_RESULT,
  GET_SAVED_ASSESSMENTS,
  GET_SAVED_ASSESSMENTS_RESULT,
  GET_RECOMMENDED_RESOURCES,
  GET_RECOMMENDED_RESOURCES_RESULT,
  SAVE_ASSESSMENT,
  SAVE_ASSESSMENT_RESULT,
  GET_ALL_ASSESSMENTS_RESULTS,
  GET_ALL_ASSESSMENTS_RESULTS_RESULT,
} from './constants';

export const processing = createAction(PROCESSING);
export const getAssessments = createAction(GET_ASSESSMENTS);
export const getAssessmentsSuccess = createAction(GET_ASSESSMENTS_SUCCESS);
export const getAssessmentsFail = createAction(GET_ASSESSMENTS_FAIL);
export const getTakenAssessments = createAction(GET_TAKEN_ASSESSMENTS);
export const getTakenAssessmentsResult = createAction(
  GET_TAKEN_ASSESSMENTS_RESULT,
);
export const getSavedAssessments = createAction(GET_SAVED_ASSESSMENTS);
export const getSavedAssessmentsResult = createAction(
  GET_SAVED_ASSESSMENTS_RESULT,
);
export const getRecommendedResources = createAction(GET_RECOMMENDED_RESOURCES);
export const getRecommendedResourcesResult = createAction(
  GET_RECOMMENDED_RESOURCES_RESULT,
);
export const saveAssessment = createAction(SAVE_ASSESSMENT);
export const saveAssessmentResult = createAction(SAVE_ASSESSMENT_RESULT);
export const sectionProcessing = createAction(SECTION_PROCESSING);
export const getAllAssessmentsResults = createAction(
  GET_ALL_ASSESSMENTS_RESULTS,
);
export const getAllAssessmentsResultsResult = createAction(
  GET_ALL_ASSESSMENTS_RESULTS_RESULT,
);
