import React from 'react';
import PropTypes from 'prop-types';
import _classNames from 'classnames';
import _get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import ScalePoint from './ScalePoint';
import CloseIcon from './close.svg';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 237,
    background: '#FFFFFF',
    border: '1px solid #D8D8D8',
    position: 'fixed',
    right: 0,
    bottom: 0,
    left: 0,
    padding: '25px 25px 33px 25px',
    zIndex: 999,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      padding: '16px 18px 14px 18px',
    },
  },
  npsFinalRoot: {
    height: 173,
  },
  closeButton: {
    position: 'absolute',
    top: 23,
    right: 25,
    [theme.breakpoints.down('xs')]: {
      top: 12,
      right: 13,
    },
  },
  container: {
    width: 820,
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  header: {
    fontSize: '1.5rem',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      fontSize: 16,
      lineHeight: '23px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  inputWrapper: {
    marginTop: 30,
    position: 'relative',
    background: '#ffffff',
    height: 55,
    [theme.breakpoints.down('xs')]: {
      height: 43,
      marginTop: 4,
    },
  },
  input: {
    width: 'calc(100% - 115px)',
    height: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    cursor: 'text',
    border: '1px solid #E9ECED',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderRight: 'none',
    '-webkit-appearance': 'none',
    fontFamily: 'MadaRegular',
    color: '#4B4B4B',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 74px)',
      fontSize: '12px !important',
      paddingLeft: 12,
      paddingRight: 12,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '17px !important',
    },
  },
  submitContainer: {
    position: 'absolute',
    height: '100%',
    right: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    background: '#01619B',
    width: 115,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    [theme.breakpoints.down('xs')]: {
      width: 74,
      padding: 6,
    },
  },
  submitDisabled: {
    opacity: 0.4,
  },
  submitLabel: {
    fontSize: 17,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
}));

const NPS = ({
  answers,
  handleClose,
  handleNPSClick,
  handleSubmit,
  handleTextChange,
  labels,
  step,
}) => {
  const classes = useStyles();

  const content = () => {
    if (step === 0)
      return (
        <>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              color="textPrimary"
              className={classes.header}
            >
              {_get(labels, 'header')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ScalePoint answers={answers} onClick={handleNPSClick} />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.inputWrapper}>
              <input
                placeholder={_get(labels, 'placeholder')}
                value={answers.npsFeedback}
                onChange={handleTextChange('npsFeedback')}
                className={classes.input}
                autoComplete="off"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                classes={{
                  root: classes.submitContainer,
                  disabled: classes.submitDisabled,
                }}
                disabled={typeof answers.npsScale !== 'number'}
              >
                <Typography variant="button" className={classes.submitLabel}>
                  {_get(labels, 'submitLabel')}
                </Typography>
              </Button>
            </div>
          </Grid>
        </>
      );
    if (step === 1)
      return (
        <Grid item xs={12}>
          <Typography
            variant="h5"
            color="textPrimary"
            className={classes.header}
          >
            Thanks for your feedback.
          </Typography>
        </Grid>
      );

    return null;
  };

  return (
    <div
      className={_classNames({
        [classes.root]: true,
        [classes.npsFinalRoot]: step === 1,
      })}
    >
      <ButtonBase
        className={classes.closeButton}
        onClick={handleClose}
        disableRipple
      >
        <img src={CloseIcon} alt="Close" />
      </ButtonBase>
      <Grid container alignItems="center" className={classes.container}>
        {content()}
      </Grid>
    </div>
  );
};

NPS.propTypes = {
  answers: PropTypes.object,
  handleClose: PropTypes.func,
  handleNPSClick: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleTextChange: PropTypes.func,
  labels: PropTypes.object,
  step: PropTypes.number,
};

export default NPS;
