import { createAction } from 'redux-actions';
import {
  GET_TAKEN_SERIES,
  GET_TAKEN_SERIES_PROCESSING,
  GET_TAKEN_SERIES_SUCCESS,
  MARK_SERIES_COMPLETED,
  MOVE_ANONYMOUS_SERIES,
  SAVE_SERIES_COMPLETED_RESOURCES,
  LAST_VIEWED_RESOURCE_SAVED,
  SERIES_COMPLETED_RESOURCES_SAVED,
  SERIES_COMPLETED,
  SAVE_LAST_VIEWED_RESOURCE,
  SAVE_RESOURCE_PROGRESS,
  RESOURCE_PROGRESS_SAVED,
  SERIES_SAVE_CUSTOM_QUESTION,
  SERIES_CUSTOM_QUESTION_SAVED,
} from './constants';

export const saveSeriesCompletedResources = createAction(
  SAVE_SERIES_COMPLETED_RESOURCES,
);
export const seriesCompletedResourcesSaved = createAction(
  SERIES_COMPLETED_RESOURCES_SAVED,
);
export const saveLastViewedResource = createAction(SAVE_LAST_VIEWED_RESOURCE);
export const lastViewedResourceSaved = createAction(LAST_VIEWED_RESOURCE_SAVED);
export const saveResourceProgress = createAction(SAVE_RESOURCE_PROGRESS);
export const resourceProgressSaved = createAction(RESOURCE_PROGRESS_SAVED);
export const getTakenSeries = createAction(GET_TAKEN_SERIES);
export const getTakenSeriesProcessing = createAction(
  GET_TAKEN_SERIES_PROCESSING,
);
export const getTakenSeriesSuccess = createAction(GET_TAKEN_SERIES_SUCCESS);
export const markSeriesCompleted = createAction(MARK_SERIES_COMPLETED);
export const moveAnonymousSeries = createAction(MOVE_ANONYMOUS_SERIES);
export const seriesCompleted = createAction(SERIES_COMPLETED);
export const saveSeriesCustomQuestion = createAction(
  SERIES_SAVE_CUSTOM_QUESTION,
);
export const seriesCustomQuestionSaved = createAction(
  SERIES_CUSTOM_QUESTION_SAVED,
);
