import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeSelectAuth } from 'containers/Auth/selectors';

function PrivateRoute({ component: Component, ...rest }) {
  const auth = useSelector(makeSelectAuth());
  return (
    <Route
      {...rest}
      render={routeProps =>
        auth.isLoaded && !auth.isEmpty ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              search: `?redirect=${routeProps.location.pathname}`,
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
