import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import Logo from 'components/NavBarMenu/images/big-logo.svg';
import Button from './Button';
import CloseIcon from './close.svg';
import Progress from './Progress';

const useStyles = makeStyles(theme => ({
  root: {
    width: 502,
    background: '#FFFFFF',
    border: '1px solid #E3E3E3',
    borderRadius: 10,
    boxShadow: '0 2px 14px 0 rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    right: 40,
    bottom: 72,
    padding: '17px 20px 36px 25px',
    zIndex: 999,
    [theme.breakpoints.down('xs')]: {
      width: '92%',
      bottom: 12,
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingBottom: 17,
    },
  },
  topWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  title: {
    fontSize: '1.315rem',
    lineHeight: '2.35rem',
    marginBottom: 15,
  },
  bottomWrapper: {
    marginTop: 13,
    height: 42,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 148,
    height: 20,
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  input: {
    fontSize: '1.0625rem',
    border: '1px solid #E9ECED',
    borderRadius: 10,
    width: '100%',
    color: '#4B4B4B',
    padding: 16,
    backgroundColor: '#FFFFFF',
    '& > input, > textarea': {
      padding: 0,
      '&::placeholder': {
        color: '#999999',
        opacity: 1,
      },
    },
  },
  submit: {
    display: 'flex',
    height: 42,
    width: 146,
    borderRadius: 6,
    color: 'white',
    backgroundColor: '#01619B',
    justifyContent: 'center',
    ...theme.typography.caption,
  },
  submitDisabled: {
    opacity: 0.4,
  },
}));

const Survey = ({
  answers,
  handleCheckboxClick,
  handleClose,
  handleNext,
  handleRadioClick,
  handleSubmit,
  handleTextChange,
  labels,
  step,
  survey,
}) => {
  const classes = useStyles();
  const content = useMemo(() => {
    const {
      header,
      positiveHeader,
      negativeHeader,
      condition,
      name,
      type,
      options,
    } = survey[step];

    const questions = () => {
      if (type === 'radio')
        return (
          <div>
            {options.map(option => (
              <Button
                key={option.label}
                iconName={option.icon}
                label={option.label}
                value={option.value}
                type="radio"
                onClick={handleRadioClick(name)}
                checked={answers[name] === option.value}
              />
            ))}
          </div>
        );

      if (type === 'checkbox')
        return (
          <div>
            {options.map(option => (
              <Button
                key={option.label}
                iconName={option.icon}
                label={option.label}
                value={option.value}
                type="checkbox"
                onClick={handleCheckboxClick(name)}
                onTextChange={handleTextChange(`${name}-other`)}
                checked={(answers[name] || []).includes(option.value)}
                otherValue={answers[`${name}-other`]}
                other={option.other}
                placeholder={option.otherPlaceholder}
              />
            ))}
          </div>
        );

      if (type === 'text')
        return (
          <div>
            <InputBase
              placeholder={options.placeholder}
              name={name}
              multiline={options.multiline}
              rows={options.numRows}
              classes={{
                root: classes.input,
              }}
              value={answers[name]}
              onChange={handleTextChange(name)}
            />
          </div>
        );

      return null;
    };

    let finalHeader = header;
    if (!header) {
      const [match] = condition.match(/\[[^+\s,]+\]/g);
      const relatedAnswer = answers[match.substring(1, match.length - 1)];

      const expectedValue = Number(condition.split(' = ')[1]);

      if (relatedAnswer === expectedValue) finalHeader = positiveHeader;
      else finalHeader = negativeHeader;
    }

    return (
      <>
        <Typography variant="h5" color="textPrimary" className={classes.title}>
          {finalHeader}
        </Typography>
        {questions()}
      </>
    );
  }, [answers, step]);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} className={classes.topWrapper}>
          <Progress progress={(step + 1) / survey.length} />
          <ButtonBase onClick={handleClose} disableRipple>
            <img src={CloseIcon} alt="Close" className={classes.icon} />
          </ButtonBase>
        </Grid>
        <Grid item xs={12}>
          {content}
        </Grid>
        <Grid item xs={12} className={classes.bottomWrapper}>
          <div className={classes.logoWrapper}>
            <img src={Logo} alt="CredibleMind logo" className={classes.logo} />
          </div>
          {['checkbox', 'text'].includes(survey[step].type) && (
            <ButtonBase
              classes={{
                root: classes.submit,
                disabled: classes.submitDisabled,
              }}
              onClick={
                step <
                survey.filter(question => question.type !== 'message').length -
                  1
                  ? handleNext
                  : handleSubmit
              }
              disabled={
                survey[step].required && _isEmpty(answers[survey[step].name])
              }
            >
              {step < survey.length - 2
                ? _get(labels, 'next')
                : _get(labels, 'submit')}
            </ButtonBase>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

Survey.propTypes = {
  answers: PropTypes.object,
  handleCheckboxClick: PropTypes.func,
  handleClose: PropTypes.func,
  handleNext: PropTypes.func,
  handleRadioClick: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleTextChange: PropTypes.func,
  labels: PropTypes.object,
  step: PropTypes.number,
  survey: PropTypes.object,
};

export default Survey;
