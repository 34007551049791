/*
 *
 * Topics constants
 *
 */

export const DEFAULT_ACTION = 'app/Topics/DEFAULT_ACTION';
export const PROCESSING = 'app/Topics/PROCESSING';
export const GET_TOPICS = 'app/Topics/GET_TOPICS';
export const GET_TOPICS_SUCCESS = 'app/Topics/GET_TOPICS_SUCCESS';
export const GET_ALL_TOPICS = 'app/Topics/GET_ALL_TOPICS';
export const GET_ALL_TOPICS_SUCCESS = 'app/Topics/GET_ALL_TOPICS_SUCCESS';
export const GET_SAVED_TOPICS = 'app/Topics/GET_SAVED_TOPICS';
export const GET_SAVED_TOPICS_RESULT = 'app/Topics/GET_SAVED_TOPICS_RESULT';
export const SAVE_TOPIC = 'app/Topics/SAVE_TOPIC';
export const SAVE_TOPIC_RESULT = 'app/Topics/SAVE_TOPIC_RESULT';
export const GET_MENU_TOPICS = 'app/Topics/GET_MENU_TOPICS';
export const GET_MENU_TOPICS_SUCCESS = 'app/Topics/GET_MENU_TOPICS_SUCCESS';
export const SECTION_PROCESSING = 'app/Topics/SECTION_PROCESSING';
