import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  makeSelectAICCUser,
  makeSelectClientDetails,
} from 'containers/Main/selectors';
import { isIE } from 'utils/stringUtils';
import _get from 'lodash/get';
import ArrowIcon from './images/arrow-back.svg';

const INITIAL_ASSESSMENT_REGEX = new RegExp(
  /\/assessments\/[a-zA-Z0-9-]+(\/page[0-9]+)?/,
);
const RESULTS_ASSESSMENT_REGEX = new RegExp(
  /\/assessments\/[a-zA-Z0-9-]+\/results/,
);

const useStyles = makeStyles(theme => ({
  root: {
    height: 36,
    zIndex: 999,
    background: '#F8F9F9',
    width: '100%',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: props => (props.isIE ? 'space-between' : 'center'),
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '0 5%',
    position: 'relative',
    [theme.breakpoints.up('1140')]: {
      width: 1140,
      padding: '0 14px',
    },
  },
  buttonWrapper: {
    minWidth: '20%',
    display: 'flex',
    '&:last-child': {
      justifyContent: 'flex-end',
    },
  },
  button: {
    display: 'flex',
    '& img': {
      marginRight: 11,
    },
    color: '#848484',
    fontSize: 14,
    fontFamily: 'MadaRegular',
  },
}));

const AICCBackHeader = () => {
  const [completed, setCompleted] = useState(false);
  const classes = useStyles({ isIE: isIE() });
  const history = useHistory();
  const location = useLocation();
  const clientDetails = useSelector(makeSelectClientDetails());
  const aiccUser = useSelector(makeSelectAICCUser());
  const touchURL = useRef(location.pathname);

  useEffect(() => {
    if (
      !completed &&
      INITIAL_ASSESSMENT_REGEX.test(touchURL.current) &&
      RESULTS_ASSESSMENT_REGEX.test(location.pathname)
    ) {
      setCompleted(true);
    }
  }, [completed, location]);

  const isAICC = _get(clientDetails, 'aicc', false);

  if (!isAICC) return null;

  const back = () => history.goBack();
  const close = () => window.close();

  const completedAlready = RESULTS_ASSESSMENT_REGEX.test(
    _get(aiccUser, 'lesson_location', ''),
  );
  const exitLabel =
    completed && !completedAlready
      ? 'Exit to HealthStream to mark as complete'
      : 'Exit and go back to Healthstream';

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.buttonWrapper}>
          {history.length > 1 && (
            <ButtonBase className={classes.button} disableRipple onClick={back}>
              <img src={ArrowIcon} alt="Back" />
              Go Back
            </ButtonBase>
          )}
        </div>
        <div className={classes.buttonWrapper}>
          <ButtonBase className={classes.button} disableRipple onClick={close}>
            {exitLabel}
          </ButtonBase>
        </div>
      </div>
    </div>
  );
};

export default AICCBackHeader;
