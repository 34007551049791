import { gql } from '@apollo/client';
export const SourceFragment = gql`
  fragment ClientSourceFields on Source {
    slug
    url
    imageUrl
    image {
      url
    }
    article
    callToAction
    subtitle
    reviewStatus
    __typename
    sys {
      id
    }
  }
`;
export const FontsFragment = gql`
  fragment FontsFields on Fonts {
    stylesheet {
      url
    }
    __typename
    sys {
      id
    }
  }
`;

export const ActivityFragment = gql`
  fragment ClientActivityFields on Activity {
    url
    imageUrl
    image {
      url
    }
    name
    slug
    callToAction
    shortDescription
    reviewStatus
    inCrisisOrder
    __typename
    sys {
      id
    }
  }
`;

export const ServiceFragment = gql`
  fragment ClientServiceFields on Services {
    url
    imageUrl
    image {
      url
    }
    name
    slug
    callToAction
    subtitle
    reviewStatus
    homeOrder
    resourceOrder
    show
    inCrisisOrder
    __typename
    sys {
      id
    }
  }
`;

export const LinkFragment = gql`
  fragment ClientLinkFields on Link {
    title
    subtitle
    url
    callToAction
    reviewStatus
    image {
      url
    }
    show
    resourceOrder
    sys {
      id
    }
    __typename
  }
`;

export const LandingFragment = gql`
  fragment LandingFields on Landing {
    shortName
    slug
    __typename
  }
`;

export const ReferralShowFragment = gql`
  fragment ReferralAssessmentFields on Assessments {
    sys {
      id
    }
  }
  fragment ReferralAssessmentResultsFields on AssessmentResults {
    sys {
      id
    }
  }
  fragment ReferralLandingFields on Landing {
    sys {
      id
    }
  }
`;

export const ReferralClientFragment = gql`
  fragment ReferralClientFields on Client {
    shortName
  }
  fragment ReferralClientGroupFields on ClientGroup {
    shortName
  }
`;
