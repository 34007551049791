/**
 *
 * useAppEmbedMode
 *
 */

import { useEffect, useRef } from 'react';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import { makeSelectClientDetails } from 'containers/Main/selectors';

function useAppEmbedMode() {
  const hasVerifiedKey = useRef(false);
  const clientDetails = useSelector(makeSelectClientDetails());
  const history = useHistory();
  const location = useLocation();

  const isAppEmbed = _get(clientDetails, 'metadata.useAppEmbedStyle', false);
  const embedKey = _get(clientDetails, 'metadata.appEmbedKey');

  useEffect(() => {
    if (!hasVerifiedKey.current && isAppEmbed && embedKey) {
      const queryParams = qs.parse(location.search.slice(1));
      const { key, ...restParams } = queryParams;

      if (
        (key && key === embedKey) ||
        location.pathname.includes('/client-admin')
      ) {
        const search = qs.stringify(restParams);
        history.replace(`${location.pathname}?${search}`);
      } else {
        history.replace('/unavailable');
      }
      hasVerifiedKey.current = true;
    }
  }, [embedKey, isAppEmbed]);
}

export default useAppEmbedMode;
