import { createAction } from 'redux-actions';
import {
  COURSE_COMPLETED_SERIES_SAVED,
  COURSE_COMPLETE_SERIES,
  COURSE_SAVE_SERIES_COMPLETED_RESOURCES,
  COURSE_SERIES_COMPLETED_RESOURCES_SAVED,
  COURSE_UPDATE_RESOURCE,
  COURSE_UPDATE_RESOURCE_SAVED,
  GET_TAKEN_COURSES,
  GET_TAKEN_COURSES_PROCESSING,
  GET_TAKEN_COURSES_SUCCESS,
  COURSE_SAVE_CUSTOM_QUESTION,
  COURSE_CUSTOM_QUESTION_SAVED,
  COURSE_ENROLL,
  COURSE_ENROLLED,
  COURSE_SAVE_SERIES_ASSESSMENT,
  COURSE_SERIES_ASSESSMENT_SAVED,
  COURSE_SAVE_RESOURCE_FOR_LATER,
  COURSE_RESOURCE_FOR_LATER_SAVED,
  COURSE_RESOURCE_FOR_LATER_PROCESSING,
  COURSE_RESOURCE_FOR_LATER_ERROR,
  COURSE_SAVE_WORKSHEET_PROGRESS,
} from './constants';

export const getTakenCourses = createAction(GET_TAKEN_COURSES);
export const getTakenCoursesProcessing = createAction(
  GET_TAKEN_COURSES_PROCESSING,
);
export const getTakenCoursesSuccess = createAction(GET_TAKEN_COURSES_SUCCESS);
export const updateCourseResource = createAction(COURSE_UPDATE_RESOURCE);
export const updateCourseResourceSaved = createAction(
  COURSE_UPDATE_RESOURCE_SAVED,
);
export const courseSaveSeriesCompletedResources = createAction(
  COURSE_SAVE_SERIES_COMPLETED_RESOURCES,
);
export const courseSeriesCompletedResourcesSaved = createAction(
  COURSE_SERIES_COMPLETED_RESOURCES_SAVED,
);

export const completeModuleSeries = createAction(COURSE_COMPLETE_SERIES);
export const courseCompletedSeriesSaved = createAction(
  COURSE_COMPLETED_SERIES_SAVED,
);
export const saveCourseCustomQuestion = createAction(
  COURSE_SAVE_CUSTOM_QUESTION,
);
export const courseCustomQuestionsSaved = createAction(
  COURSE_CUSTOM_QUESTION_SAVED,
);
export const enrollCourse = createAction(COURSE_ENROLL);
export const courseEnrolled = createAction(COURSE_ENROLLED);
export const courseSaveSeriesAssessment = createAction(
  COURSE_SAVE_SERIES_ASSESSMENT,
);
export const courseSeriesAssessmentSaved = createAction(
  COURSE_SERIES_ASSESSMENT_SAVED,
);
export const courseSaveResourceForLater = createAction(
  COURSE_SAVE_RESOURCE_FOR_LATER,
);
export const courseResourceForLaterSaved = createAction(
  COURSE_RESOURCE_FOR_LATER_SAVED,
);
export const courseResourceForLaterError = createAction(
  COURSE_RESOURCE_FOR_LATER_ERROR,
);
export const courseResourceForLaterProcessing = createAction(
  COURSE_RESOURCE_FOR_LATER_PROCESSING,
);
export const courseSaveWorksheetProgress = createAction(
  COURSE_SAVE_WORKSHEET_PROGRESS,
);
