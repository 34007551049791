/*
 *
 * Account reducer
 *
 */
import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { processing, getSavedBestResourcesSuccess } from './actions';

export const initialState = {
  processing: false,
  savedBestResources: [],
};
const accountReducer = handleActions(
  {
    [processing](state, action) {
      return update(state, { processing: { $set: action.payload } });
    },
    [getSavedBestResourcesSuccess](state, action) {
      return update(state, { savedBestResources: { $set: action.payload } });
    },
  },
  initialState,
);

export default accountReducer;
