import _get from 'lodash/get';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { isPublicMode, removeLocalData } from 'utils/localDataStore';
import {
  makeSelectClientDetails,
  makeSelectClientDetailsLoaded,
  makeSelectClinicMode,
} from 'containers/Main/selectors';
import { makeSelectAuth, makeSelectProfile } from 'containers/Auth/selectors';
import { useHistory } from 'react-router-dom';
import { isEmbedded } from 'utils/embedded';
import { getIsClientAdmin } from 'containers/Auth/utils';
import { setIsEndingSession } from '../../Main/actions';
import { logout, regenerateAnonymousId } from '../../Auth/actions';

const stateSelector = createStructuredSelector({
  clientDetails: makeSelectClientDetails(),
  clientDetailsLoaded: makeSelectClientDetailsLoaded(),
  auth: makeSelectAuth(),
  clinicMode: makeSelectClinicMode(),
});

export const useIdleConfig = () => {
  const { auth, clientDetails, clientDetailsLoaded, clinicMode } = useSelector(
    stateSelector,
  );
  const { pathname } = window.location;
  const history = useHistory();
  const dispatch = useDispatch();

  const profile = useSelector(makeSelectProfile());
  const isAdmin =
    (['admin', 'contentAdmin'].includes(profile.role) ||
      getIsClientAdmin(profile, clientDetails)) &&
    !isEmbedded();

  const handleClinicModeIdle = () => {
    dispatch(setIsEndingSession(true));
    removeLocalData('userIdTemp');
    dispatch(regenerateAnonymousId());
    dispatch(setIsEndingSession(false));
    history.replace(`${isPublicDevice ? '/?mode=public' : '/'}`, {
      showReminderOverlay: false,
    });
  };

  const handleLoggedInIdle = () => {
    dispatch(logout());
    history.replace(`${isPublicDevice ? '/?mode=public' : '/'}`, {
      showReminderOverlay: false,
    });
  };

  const clinicModeInactivityTimeoutInSec = _get(
    clientDetails,
    'metadata.clinicModeInactivityTimeoutInSec',
    undefined,
  );

  const loggedInInactivityTimeoutInSec = _get(
    clientDetails,
    'metadata.loggedInInactivityTimeoutInSec',
    undefined,
  );

  const isPublicDevice = isPublicMode();

  const enableClinicModeInactivity =
    ((clinicMode &&
      pathname.includes('/assessments/') &&
      clientDetailsLoaded) ||
      isPublicDevice) &&
    clinicModeInactivityTimeoutInSec;

  const enableLoggedInModeInactivity =
    auth?.isLoaded &&
    !auth?.isEmpty &&
    clientDetailsLoaded &&
    loggedInInactivityTimeoutInSec &&
    !isAdmin;

  const timeoutConfig = [
    {
      enable: enableLoggedInModeInactivity,
      timeout: loggedInInactivityTimeoutInSec,
      handleIdle: handleLoggedInIdle,
    },
    {
      enable: enableClinicModeInactivity,
      timeout: clinicModeInactivityTimeoutInSec,
      handleIdle: handleClinicModeIdle,
    },
  ];

  return timeoutConfig.find(config => config.enable) || {};
};
