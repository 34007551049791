import React from 'react';

export default (color = '#FFFFFF') => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 18 17"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="FAQ" transform="translate(0.3899994 0.39001465)">
      <path
        d="M16.8562 11.6825C16.8562 12.0858 16.5281 12.4127 16.1233 12.4127L8.06166 12.4127L5.13014 15.3333L5.13014 12.4127L0.732878 12.4127C0.328121 12.4127 8.13658e-17 12.0858 0 11.6825L0 0.730159C-4.06829e-17 0.326903 0.328121 0 0.732878 0L16.1233 0C16.5281 0 16.8562 0.326903 16.8562 0.730159L16.8562 11.6825Z"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5959 4.38095C6.59607 3.28697 7.40635 2.36084 8.49411 2.21135C9.58186 2.06187 10.6135 2.73487 10.911 3.78796C11.2084 4.84104 10.6804 5.9513 9.67399 6.38889C9.13959 6.62125 8.79425 7.14709 8.79453 7.728L8.79453 8.03175"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.79453 9.6746C8.89572 9.6746 8.97775 9.75633 8.97775 9.85714C8.97775 9.95796 8.89572 10.0397 8.79453 10.0397C8.69334 10.0397 8.61131 9.95796 8.61131 9.85714C8.61131 9.75633 8.69334 9.6746 8.79453 9.6746"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.78"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
