/*
 *
 * Sources actions
 *
 */

import { createAction } from 'redux-actions';
import {
  PROCESSING,
  GET_SOURCES,
  GET_SOURCES_SUCCES,
  GET_SOURCES_FAIL,
} from './constants';

export const processing = createAction(PROCESSING);
export const getSources = createAction(GET_SOURCES);
export const getSourcesSuccess = createAction(GET_SOURCES_SUCCES);
export const getSourcesFail = createAction(GET_SOURCES_FAIL);
