import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import RichTextReactRenderer from 'components/RichTextReactRenderer';

const useStyles = makeStyles(theme => ({
  root: {
    '& > div': {
      height: 41,
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      background: props => `#${props.backgroundColor}`,
      alignItems: 'center',
      [theme.breakpoints.down('500')]: {
        padding: '0 5%',
        height: 45,
      },
      [theme.breakpoints.down('390')]: {
        padding: '0 1%',
      },
      '& p': {
        display: 'flex',
        margin: 0,
        padding: 0,
        minHeight: 0,
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
      '& span': {
        fontFamily: 'MadaRegular',
        fontSize: 14,
        color: props => `#${props.textColor}`,
        [theme.breakpoints.down('380')]: {
          fontSize: 10,
        },
        '& a': {
          marginLeft: 5,
          fontFamily: 'MadaBold',
          textDecoration: 'underline',
          color: props => `#${props.textColor}`,
        },
      },
    },
  },
}));

const Content = ({ data }) => {
  const {
    pageCopy: { backgroundColor = 'F8AC24', textColor = '3D2700' },
    body: { json },
  } = data;
  const classes = useStyles({ backgroundColor, textColor });
  return (
    <div className={classes.root}>
      <RichTextReactRenderer data={json} />
    </div>
  );
};

Content.propTypes = {
  data: PropTypes.object,
};

export default React.memo(Content);
