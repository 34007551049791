/*
 *
 * ClientLanding actions
 *
 */

import { createAction } from 'redux-actions';
import {
  GET_CLIENT_LANDING_INFO,
  GET_CLIENT_LANDING_INFO_SUCCESS,
  GET_TOPIC_RESOURCES_INFO,
  GET_TOPIC_RESOURCES_INFO_SUCCESS,
  GET_PAGE_LANDING_INFO,
  GET_PAGE_LANDING_INFO_SUCCESS,
  PAGE_PROCESSING,
  PROCESSING,
  CLEAR_LANDING_INFO,
} from './constants';

export const getClientLandingInfo = createAction(GET_CLIENT_LANDING_INFO);
export const getClientLandingInfoSuccess = createAction(
  GET_CLIENT_LANDING_INFO_SUCCESS,
);
export const getPageLandingInfo = createAction(GET_PAGE_LANDING_INFO);
export const getPageLandingInfoSuccess = createAction(
  GET_PAGE_LANDING_INFO_SUCCESS,
);
export const getTopicResourcesInfo = createAction(GET_TOPIC_RESOURCES_INFO);
export const getTopicResourcesInfoSuccess = createAction(
  GET_TOPIC_RESOURCES_INFO_SUCCESS,
);
export const processing = createAction(PROCESSING);
export const pageProcessing = createAction(PAGE_PROCESSING);
export const clearLandingInfo = createAction(CLEAR_LANDING_INFO);
