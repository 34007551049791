import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _some from 'lodash/some';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { makeSelectClientLanding } from 'containers/ClientLanding/selectors';
import {
  makeSelectClientDetails,
  makeSelectContentBlocks,
} from 'containers/Main/selectors';
import ClientFontWrapper from 'components/ClientFontWrapper';
import Item from './Item';
import { CONTENT_BLOCK_POSITIONS } from './utils';

const COMMON_PAGES_MAPPING = {
  assessments: /^\/assessments\/?$/,
  'in-crisis': /^\/in-crisis\/?$/,
  blogs: /^\/(blogs|insights)\/?$/,
  'sign-in': /^\/login\/?$/,
  'sign-up': /^\/signup\/?$/,
  topics: /^\/topics\/?$/,
  'user-resources': /^\/user-resources\/?$/,
  'blogs-pages': /^\/(blogs|insights)\/[a-z0-9A-Z-]+$/,
  'all-home': /^\/(landing\/[a-z0-9A-Z]+)?$/,
  'admin-portal-home': /^\/client-admin\/?$/,
  'all-series': /^\/series\/?$/,
  'learning-lab': /^\/learning-lab\/?$/,
};

const ITEM_PAGE_REGEX = /^\/(landing|lists|topics)\/([a-zA-Z0-9-]+)/;

const ContentBlock = ({
  type,
  id,
  position,
  index,
  fullWidth = false,
  rounded,
  marginTop,
  marginBottom,
  classes,
  excludedTypes,
}) => {
  const location = useLocation();
  const items = useSelector(makeSelectContentBlocks());
  const clientLanding = useSelector(makeSelectClientLanding());
  const clientDetails = useSelector(makeSelectClientDetails());

  const [commonPage] = Object.keys(COMMON_PAGES_MAPPING).filter(key =>
    COMMON_PAGES_MAPPING[key].test(location.pathname),
  );

  const isClientAdmin = location.pathname.includes('/client-admin');

  const includedStyles = excludedTypes
    ? items.filter(item => !excludedTypes.includes(item.type))
    : items;
  const data = includedStyles.filter(item => {
    if (!item.pagePosition || !item.pagePosition.includes(position))
      return false;

    const commonPages = item.commonPages || [];
    const associatedPages = _get(item, 'associatedPages', []);

    if (type === 'common-page') {
      if (_isEmpty(commonPages) && _isEmpty(associatedPages)) return false;

      if (
        (commonPage && commonPages.includes(commonPage)) ||
        (commonPages.includes('all-pages') && !isClientAdmin)
      ) {
        return (
          item.pagePosition !== CONTENT_BLOCK_POSITIONS.USE_LANDING_SECTIONS ||
          item.sortOrder === index
        );
      }

      if (associatedPages.length > 0) {
        if (ITEM_PAGE_REGEX.test(location.pathname)) {
          const associatedPagesSlugs = associatedPages
            .map(resource => _get(resource, 'fields.slug'))
            .filter(resource => !_isNil(resource));
          const [, , slug] = location.pathname.match(ITEM_PAGE_REGEX);
          return slug && associatedPagesSlugs.includes(slug);
        }
        if (location.pathname === '/') {
          const homePageId = _get(clientLanding, 'sys.id');
          const associatedPagesIds = associatedPages
            .map(resource => _get(resource, 'sys.id'))
            .filter(resourceId => !_isNil(resourceId));
          return homePageId && associatedPagesIds.includes(homePageId);
        }
      }
    }
    if (type === 'item-page') {
      const associatedPagesIds = associatedPages
        .map(page => _get(page, 'sys.id'))
        .filter(page => !_isNil(page));
      return associatedPagesIds.includes(id);
    }
    if (type === 'assessment-results') {
      const associatedPagesIds = associatedPages
        .map(page => _get(page, 'sys.id'))
        .filter(page => !_isNil(page));
      return (
        _some(id.split(','), el => associatedPagesIds.includes(el)) &&
        index === item.sortOrder
      );
    }

    return false;
  });

  if (_isEmpty(data)) return null;

  const isClientActive = _get(clientDetails, 'activeClient') ?? true;
  if (!isClientActive) return null;

  return (
    <Box
      width={fullWidth ? '100%' : 'auto'}
      className={classes && classes.root}
    >
      {data.map(item => {
        const commonPages = _get(item, 'commonPages', []);
        const shouldInherit =
          !commonPages.includes('all-pages') &&
          (commonPages.length === 1 && commonPages.includes('all-home'));

        return (
          <ClientFontWrapper key={item.sys.id} shouldInherit={shouldInherit}>
            <Item
              className={classes && classes.item}
              data={item}
              type={type}
              fullWidth={fullWidth}
              rounded={rounded}
              marginTop={marginTop}
              marginBottom={marginBottom}
              position={position}
              isClientAdmin={isClientAdmin}
            />
          </ClientFontWrapper>
        );
      })}
    </Box>
  );
};

ContentBlock.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  position: PropTypes.string,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  rounded: PropTypes.bool,
  marginTop: PropTypes.bool,
  marginBottom: PropTypes.bool,
};

export default ContentBlock;
