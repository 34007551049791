import axios from 'axios';
import qs from 'qs';
import Config from 'utils/getEnvConfig';

const {
  PROXY: { URL, API_KEY },
} = Config;

export const getAICC = (url, path, sid) =>
  axios.post(
    `${URL}${path}`,
    qs.stringify({
      command: 'GetParam',
      version: '4',
      session_id: sid,
    }),
    {
      headers: {
        'x-api-key': API_KEY,
        'target-url': url,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );

export const setCompletionAICC = (url, path, sid, params) => {
  const { lesson_location } = params;
  const aiccData = `[CORE]\r\nlesson_location=${lesson_location}\r\nlesson_status=completed`;

  return axios.post(
    `${URL}${path}`,
    qs.stringify({
      command: 'PutParam',
      version: '4',
      session_id: sid,
      aicc_data: encodeURIComponent(aiccData),
    }),
    {
      headers: {
        'x-api-key': API_KEY,
        'target-url': url,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
};

export const exitAICC = (url, path, sid) =>
  axios.post(
    `${URL}${path}`,
    qs.stringify({
      command: 'ExitAU',
      version: '4',
      session_id: sid,
    }),
    {
      headers: {
        'x-api-key': API_KEY,
        'target-url': url,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
