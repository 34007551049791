/*
 *
 * Auth reducer
 *
 */
import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { emitter } from '@marvelapp/react-ab-test';
import { generateKey } from 'utils/stringUtils';
import {
  processing,
  authError,
  signUp,
  checkEmailAvailability,
  checkEmailAvailabilityResult,
  completeSignUp,
  storeItemForSignIn,
  showSubscriptionModal,
  hideSubscriptionModal,
  subscriptionProcessing,
  submitSubscription,
  submitSubscriptionResult,
  getSubscriptionStatus,
  getSubscriptionStatusResult,
  setAuthModalState,
  setAuthModalAction,
  showAuthCTAModal,
  hideAuthCTAModal,
  setHeroVariant,
  setProfileFilters,
  regenerateAnonymousId,
  incrementSignUpCount,
} from './actions';
import { isEqualProfileFilters } from './utils';

const INITIAL_SUBSCRIPTION_STATUS = { status: '', mergeFields: [], email: '' };

export const initialState = {
  processing: false,
  storedItem: {},
  error: {},
  signUpCount: 0,
  subscriptionModalStatus: false,
  subscriptionResult: {},
  subscriptionProcessing: false,
  subscriptionStatus: INITIAL_SUBSCRIPTION_STATUS,
  authModal: { show: false, type: '' },
  authModalAction: 'sign-up',
  authCTAModalStatus: false,
  authCTAModalProvider: null,
  emailAvailability: null,
  heroVariant: emitter.getActiveVariant('hero-variant') || '',
  profileFilters: { isLoaded: false },
  anonymousId: `anonymous-${generateKey()}`,
};

const authReducer = handleActions(
  {
    [processing](state, action) {
      return update(state, {
        processing: { $set: action.payload },
        error: { $set: {} },
      });
    },
    [authError](state, action) {
      return update(state, { error: { $set: action.payload } });
    },
    [incrementSignUpCount](state) {
      return update(state, {
        signUpCount: { $set: state.signUpCount + 1 },
      });
    },
    [signUp](state) {
      return update(state, {
        error: { $set: {} },
      });
    },
    [checkEmailAvailability](state) {
      return update(state, {
        emailAvailability: { $set: null },
      });
    },
    [checkEmailAvailabilityResult](state, action) {
      return update(state, {
        emailAvailability: { $set: action.payload },
      });
    },
    [completeSignUp](state) {
      return update(state, {
        error: { $set: {} },
      });
    },
    [storeItemForSignIn](state, action) {
      return update(state, { storedItem: { $set: action.payload } });
    },
    [showSubscriptionModal](state) {
      return update(state, { subscriptionModalStatus: { $set: true } });
    },
    [hideSubscriptionModal](state) {
      return update(state, { subscriptionModalStatus: { $set: false } });
    },
    [subscriptionProcessing](state, action) {
      return update(state, {
        subscriptionProcessing: { $set: action.payload },
      });
    },
    [submitSubscription](state) {
      return update(state, { subscriptionResult: { $set: {} } });
    },
    [submitSubscriptionResult](state, action) {
      return update(state, { subscriptionResult: { $set: action.payload } });
    },
    [getSubscriptionStatus](state) {
      return update(state, {
        subscriptionStatus: { $set: INITIAL_SUBSCRIPTION_STATUS },
      });
    },
    [getSubscriptionStatusResult](state, action) {
      return update(state, { subscriptionStatus: { $set: action.payload } });
    },
    [setAuthModalState](state, action) {
      const shouldUpdateAction = action.payload.show;
      if (shouldUpdateAction)
        return update(state, {
          authModal: { $set: action.payload },
          authModalAction: { $set: 'sign-up' },
          authCTAModalStatus: { $set: false },
          authCTAModalProvider: { $set: null },
        });
      return update(state, { authModal: { $set: action.payload } });
    },
    [setAuthModalAction](state, action) {
      return update(state, { authModalAction: { $set: action.payload } });
    },
    [showAuthCTAModal](state, action) {
      return update(state, {
        authCTAModalStatus: { $set: true },
        authCTAModalProvider: { $set: action.payload },
      });
    },
    [hideAuthCTAModal](state) {
      return update(state, {
        authCTAModalStatus: { $set: false },
        authCTAModalProvider: { $set: null },
      });
    },
    [setHeroVariant](state, action) {
      return update(state, {
        heroVariant: { $set: action.payload },
      });
    },
    [setProfileFilters](state, action) {
      if (isEqualProfileFilters(state.profileFilters, action.payload)) {
        return state;
      }
      return update(state, {
        profileFilters: { $set: action.payload },
      });
    },
    [regenerateAnonymousId](state) {
      return update(state, {
        anonymousId: { $set: `anonymous-${generateKey()}` },
      });
    },
  },
  initialState,
);

export default authReducer;
