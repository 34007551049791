import React from 'react';

export default (color = '#FFFFFF') => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 20 9"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>
      streamline-icon-donation-charity-hand-receive-heart-1@48x48 (1)
    </title>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Services-Resources-Page--2022"
        transform="translate(-387.000000, -1707.000000)"
        stroke={color}
        strokeWidth="0.6"
      >
        <g
          id="Other-Services-from-this-Organization"
          transform="translate(378.000000, 1475.000000)"
        >
          <g id="Services" transform="translate(0.000000, 95.000000)">
            <g
              transform="translate(10.000000, 133.000000)"
              id="streamline-icon-donation-charity-hand-receive-heart-1@48x48-(1)"
            >
              <g transform="translate(0.000000, 5.000000)">
                <rect
                  id="Rectangle"
                  x="0"
                  y="0"
                  width="3.13043478"
                  height="6.26086957"
                  rx="0.25"
                ></rect>
                <path
                  d="M3.13043478,5.13626087 C6.65217391,6.31017391 8.15869565,7.04347826 9.46956522,7.04347826 C11.3478261,7.04347826 12.6273913,5.86408696 18,3.17973913 C17.4595029,2.45155983 16.5214792,2.13888525 15.6521739,2.39713043 L12.2744348,3.46695652"
                  id="Path"
                ></path>
                <path
                  d="M3.13043478,0.782608696 L5.47826087,0.782608696 C7.31973913,0.782608696 8.60869565,2.34782609 9,2.34782609 L11.7391304,2.34782609 C12.5217391,2.34782609 12.5217391,3.91304348 11.7391304,3.91304348 L7.43478261,3.91304348"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
