import React from 'react';

export default (color = '#FFFFFF') => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 17 16"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group-10" transform="translate(0.4289856 0.42895508)">
      <g id="streamline-icon-messages-bubble@48x48">
        <path
          d="M8 0C3.58191 0 0 2.89977 0 6.47727C0.0375837 8.28132 0.907351 9.97147 2.36522 11.0734L0.695652 15L5.12835 12.5175C6.05691 12.8072 7.02554 12.9546 8 12.9545C12.4181 12.9545 16 10.0548 16 6.47727C16 2.89977 12.4181 0 8 0Z"
          id="Path"
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeWidth="0.78"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g id="streamline-icon-hash@48x48" transform="translate(5 3)">
        <path
          d="M0.521739 2.08696L6 2.08696"
          id="Path"
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeWidth="0.858"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0 3.91304L5.47826 3.91304"
          id="Path"
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeWidth="0.858"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.5707 6L2.61678 0"
          id="Path"
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeWidth="0.858"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.39678 6L4.44287 0"
          id="Path"
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeWidth="0.858"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);
