import { createSelector } from 'reselect';
import _get from 'lodash/get';

const getCourseProgress = (entry, taken) => {
  const modulesLength = _get(entry, 'fields.modules.length', 0);
  const completedModuleCount = Object.keys(
    _get(taken, 'seriesProgress', {}),
  ).filter(key =>
    _get(taken.seriesProgress[key], 'lastViewedResource.completed'),
  ).length;
  const progress = completedModuleCount
    ? Math.round((completedModuleCount / modulesLength) * 100)
    : 0;

  return progress;
};

export const getAllCourses = createSelector(
  [state => state.courses.takenCourses, state => state.courses.allCourses],
  (takenCourses, allCourses) => {
    const completedIds = Object.keys(takenCourses).filter(
      key => takenCourses[key].completed,
    );
    const inProgressIds = Object.keys(takenCourses)
      .filter(key => !takenCourses[key].completed)
      .sort((a, b) => {
        const progressA = getCourseProgress(
          allCourses.find(item => item.sys.id === a),
          takenCourses[a],
        );
        const progressB = getCourseProgress(
          allCourses.find(item => item.sys.id === b),
          takenCourses[b],
        );

        return progressB - progressA;
      });

    const sortedAllSeries = allCourses.sort((a, b) => {
      if (completedIds.includes(a.sys.id) && !completedIds.includes(b.sys.id)) {
        return 1;
      }
      if (!completedIds.includes(a.sys.id) && completedIds.includes(b.sys.id)) {
        return -1;
      }
      if (completedIds.includes(a.sys.id) && completedIds.includes(b.sys.id)) {
        if (
          takenCourses[a.sys.id].completedAt >
          takenCourses[b.sys.id].completedAt
        ) {
          return -1;
        }
        return 1;
      }

      if (
        inProgressIds.includes(a.sys.id) &&
        !inProgressIds.includes(b.sys.id)
      ) {
        return -1;
      }
      if (
        !inProgressIds.includes(a.sys.id) &&
        inProgressIds.includes(b.sys.id)
      ) {
        return 1;
      }
      if (
        inProgressIds.includes(a.sys.id) &&
        inProgressIds.includes(b.sys.id)
      ) {
        return (
          inProgressIds.indexOf(a.sys.id) - inProgressIds.indexOf(b.sys.id)
        );
      }
      return 0;
    });

    return sortedAllSeries;
  },
);

export const getTakenCourses = state => state.courses.takenCourses;

export const getTakenCoursesProcessing = state =>
  state.courses.takenCoursesProcessing;

export const getCourseResourcesSaveForLaterProcessing = state =>
  state.courses.courseResourcesSaveForLaterProcessingList;
