import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import qs from 'qs';
import Grid from '@material-ui/core/Grid';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import AppBar from '@material-ui/core/AppBar';
import { useHistory, useLocation } from 'react-router-dom';
import ClassNames from 'classnames';
import { useFirebase, isEmpty, isLoaded } from 'react-redux-firebase';
import format from 'date-fns/format';
import { useQuery } from 'react-query';
import _get from 'lodash/get';
import ContentBlock from 'components/ContentBlock';
import { CONTENT_BLOCK_POSITIONS } from 'components/ContentBlock/utils';
import useSiteCopySelector from 'components/useSiteCopySelector';
import _isEmpty from 'lodash/isEmpty';
import HomePageProNavBar from 'containers/HomePagePro/components/NavBar';
import { logout, showAuthCTAModal } from 'containers/Auth/actions';
import useClientResources from 'components/useClientResources';
import {
  makeSelectAudienceTagsRelations,
  makeSelectClientDetails,
  makeSelectRegion,
  makeSelectAllowSignIn,
  makeSelectClientDetailsFetching,
  makeSelectShowRegionBanner,
  makeSelectLandingMenu,
  makeSelectShowHomepagePro,
  makeSelectLanguage,
  makeSelectShowLanguageBanner,
  makeSelectOAuthOptions,
} from 'containers/Main/selectors';
import useContentfulLocale from 'components/useContentfulLocale';
import useSiteConfigSelector from 'components/useSiteConfigSelector';
import { makeSelectClientLanding } from 'containers/ClientLanding/selectors';
import { filterClientExclusiveResources } from 'containers/Resources/utils';
import {
  makeSelectAuth,
  makeSelectProfile,
  makeSelectProfileFilters,
} from 'containers/Auth/selectors';
import { getHomeResources } from 'containers/HomePage/services/api';
import {
  getClientLandingInfo,
  getPageLandingInfo,
} from 'containers/ClientLanding/actions';
import { getClientDetails, getThemes } from 'containers/Main/actions';
import BrowserTracker from 'utils/browserTrackerService';
import { setLocalData, getLocalData } from 'utils/localDataStore';
import Mixpanel from 'utils/mixpanelService';
import { getSubdomain, isBot } from 'utils/stringUtils';
import { makeSelectAssessment } from 'containers/Assessment/selectors';
import { isEmbedded, isBareEmbedded, isWebView } from '../../utils/embedded';
import Content from './components/Content';
import NavBarContext from './navBarContext';
import DialogMenu from './components/DialogMenu';
import getArticles from './services/getArticles';
import { getLandingSlug } from './utils';

const useStyles = makeStyles(theme => ({
  bar: {
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    height: 149,
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 9,
    [theme.breakpoints.up('1140')]: {
      width: 1140,
      padding: 0,
    },
    width: '100%',
    padding: '0 5%',
    [theme.breakpoints.down('600')]: {
      padding: '34px 18px 8px',
      height: 'auto',
    },
  },
  appBarWrapper: {
    borderBottom: '1px solid #E3E3E3',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
  },
  embedded: {
    height: 'auto',
    position: 'static',
  },
  toolbarWrapper: {
    zIndex: 999,
  },
  sticky: {
    width: '100%',
  },
  mt36: {
    marginTop: 36,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  mt36AICC: {
    marginTop: 36,
  },
  contentBlock: {
    width: 1140,
    margin: '0 auto',
  },
  contentBlockItem: {
    '&:first-child': {
      marginTop: '0px !important',
    },
  },
}));

const stateSelector = createStructuredSelector({
  allowSignIn: makeSelectAllowSignIn(),
  audienceTagsRelations: makeSelectAudienceTagsRelations(),
  auth: makeSelectAuth(),
  authOptions: makeSelectOAuthOptions(),
  clientDetails: makeSelectClientDetails(),
  clientFetching: makeSelectClientDetailsFetching(),
  clientLanding: makeSelectClientLanding(),
  landingMenu: makeSelectLandingMenu(),
  profile: makeSelectProfile(),
  region: makeSelectRegion(),
  assessment: makeSelectAssessment(),
  showRegionBanner: makeSelectShowRegionBanner(),
  showHomepagePro: makeSelectShowHomepagePro(),
  language: makeSelectLanguage(),
  showLanguageBanner: makeSelectShowLanguageBanner(),
  profileFilters: makeSelectProfileFilters(),
});

// TODO: separate each api call as hooks after a/b testing
function NavBarMenu() {
  const classes = useStyles();
  const currentLanguage = useRef();
  const [dialogMenuOpen, setDialogMenuOpen] = useState(false);
  const [menus, setMenus] = useState([]);
  const {
    allowSignIn,
    audienceTagsRelations,
    auth,
    authOptions,
    clientDetails,
    clientFetching,
    clientLanding,
    landingMenu,
    profile,
    language,
    showHomepagePro,
    assessment,
    profileFilters,
  } = useSelector(stateSelector);
  const [navbarPageSiteCopy] = useSiteCopySelector(['navbar-menu']);
  const [featuresConfig] = useSiteConfigSelector(['Features']);
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const subdomain = getSubdomain();
  const history = useHistory();
  const location = useLocation();
  const { pathname, search } = location;
  const bot = isBot();
  const shouldHideNavBarSubMenu = _get(
    clientDetails,
    'metadata.hideNavBarSubMenu',
    false,
  );
  const isEmbed = isEmbedded();
  // const isAbout = pathname.includes('/company/');
  const isHomePage = pathname === '/';
  const isLandingPage = pathname.includes('/landing/');
  const ctfLocaleFilter = useContentfulLocale();
  const fetchData = !bot;
  const hasClientDetails = !_isEmpty(clientDetails);
  const clientShortName = _get(clientDetails, 'shortName');
  const brand = getLocalData('brand');
  const isAICC = _get(clientDetails, 'aicc', false);
  const queryParams = qs.parse(search.slice(1));
  const isPreview = _get(queryParams, 'mode') === 'preview';

  const linkSlug = hasClientDetails
    ? landingMenu || getLandingSlug(clientDetails, profileFilters)
    : 'home';

  const sendMixpanelEvent = (eventTitle, eventProps) => {
    const finalProps = {
      ...eventProps,
    };
    Mixpanel.track(eventTitle, finalProps);
  };

  const getLink = slug => {
    const links = {
      news: '/news',
      'covid-19': '/landing/covid-19',
      'client-exclusive': '/client-resources',
      'in-crisis': '/in-crisis',
      assessments: '/assessments',
      'how-it-works': '/how-it-works',
      home: '/',
      topics: '/topics',
      insights: '/insights',
      'client-tools': '/admin/clients-tool',
      learning: '/series',
      'learning-lab': '/learning-lab',
    };

    return links[slug];
  };

  useEffect(() => {
    if (subdomain && !bot && subdomain !== 'development') {
      setLocalData('brand', subdomain);
      dispatch(getClientDetails(subdomain));
    }
    // if (hasClientDetails) {
    //   if (brand && brand !== 'none') Mixpanel.init(brand);
    // }
    const firstTimer = getLocalData('firstTimeUser');
    if (!firstTimer) setLocalData('firstTimeUser', true);
    else setLocalData('firstTimeUser', false);
  }, []);

  useEffect(() => {
    if (auth.isLoaded && auth.isEmpty) {
      const method = getLocalData('signMethod');

      if (hasClientDetails) {
        if (
          (method === 'facebook' && authOptions.facebook) ||
          (method === 'google' && authOptions.google) ||
          (method === 'microsoft.com' && authOptions.ms)
        ) {
          dispatch(
            showAuthCTAModal({
              type: 'welcome-back',
              method,
            }),
          );
        }
      } else if (
        method === 'facebook' ||
        method === 'google' ||
        method === 'microsoft.com'
      ) {
        dispatch(
          showAuthCTAModal({
            type: 'welcome-back',
            method,
          }),
        );
      }
    }

    if (auth.isLoaded) {
      // Mixpanel.identify(true);
      BrowserTracker.track();
    }
  }, [auth]);

  useEffect(() => {
    if (profile.isLoaded && !profile.isEmpty) {
      const currentMonth = format(Date.now(), 'yyyy-MM');
      const activeMonths = _get(profile, 'activeMonths', []);
      if (!activeMonths.includes(currentMonth)) {
        activeMonths.push(currentMonth);
        firebase.updateProfile({
          activeMonths,
        });
      }
    }
  }, [profile]);

  useEffect(() => {
    if (fetchData || isHomePage) {
      dispatch(getThemes());
    }
  }, [fetchData, isHomePage, language]);

  useEffect(() => {
    if ((!bot || subdomain) && !clientFetching) {
      if (
        (brand &&
          brand !== 'none' &&
          hasClientDetails &&
          linkSlug === 'home') ||
        !linkSlug
      )
        return;
      if (
        clientLanding.slug !== linkSlug ||
        currentLanguage.current !== language
      ) {
        currentLanguage.current = language;
        dispatch(getClientLandingInfo({ slug: linkSlug, isPreview }));
      }
    }
    if (dialogMenuOpen) {
      setDialogMenuOpen(false);
    }
  }, [
    clientDetails,
    linkSlug,
    location,
    clientLanding,
    clientFetching,
    brand,
    language,
  ]);

  useEffect(() => {
    if ((!bot || subdomain) && !clientFetching) {
      const landingSlug = isLandingPage ? pathname.split('/landing/')[1] : '';
      if (!landingSlug) return;
      if (isLandingPage) {
        dispatch(getPageLandingInfo({ slug: landingSlug, isPreview }));
      }
    }
    if (dialogMenuOpen) {
      setDialogMenuOpen(false);
    }
  }, [location, clientFetching, language]);

  const { clientResources } = useClientResources();
  const finalClientResources = clientResources.filter(
    item =>
      _get(item, 'sys.id') &&
      ([
        'Source',
        'Link',
        'Services',
        'Activity',
        'Video',
        'Application',
        'Podcast',
      ].includes(_get(item, '__typename')) ||
        [
          'source',
          'link',
          'services',
          'activity',
          'video',
          'application',
          'podcast',
        ].includes(_get(item, 'sys.contentType.sys.id'))),
  );

  const isAuthenticated = !isEmpty(auth) && isLoaded(auth);

  const shouldHideInAuthPage =
    pathname.includes('/login') ||
    (pathname.includes('/signup') && queryParams.cr !== '1') ||
    pathname.includes('/forgot_password') ||
    pathname.includes('/new_password') ||
    isWebView();
  const isResourcesPage = pathname.includes('/resources');
  const renderEnterpriseNavbar =
    pathname === '/' && (showHomepagePro || queryParams.showcorp === '1');

  // get assessments
  const { data: assessments } = useQuery(
    fetchData && [
      'h-assessments',
      {
        limit: 15,
        clientDetails,
        clientLandingInfo: clientLanding,
        audienceTagsRelations,
        profileFilters,
      },
    ],
    getHomeResources,
  );

  const clientExcludedTopic = _get(
    clientDetails,
    'excludeTopicCollection.items',
  );
  const averageRatingCutoff = _get(clientDetails, 'averageRatingCutoff');
  const expertRatingCutoff = _get(clientDetails, 'expertRatingCutoff');
  const userRatingCutoff = _get(clientDetails, 'userRatingCutoff');

  // get blogs
  const { data: blogs } = useQuery(
    fetchData && [
      'top-blogs',
      {
        type: 'activity',
        shouldFilterShortArticles: false, // blogs aren't affect by this filter
        clientExcludedTopic,
        averageRatingCutoff,
        expertRatingCutoff,
        userRatingCutoff,
        payload: { 'fields.blog': true, order: '-fields.publicationDate' },
        localeFilters: ctfLocaleFilter,
        clientDetails,
      },
    ],
    getArticles,
  );

  // get news
  const { data: news } = useQuery(
    fetchData && [
      'top-news',
      {
        type: 'activity',
        shouldFilterShortArticles: _get(
          clientDetails,
          'metadata.filterShortArticles',
          false,
        ),
        clientExcludedTopic,
        averageRatingCutoff,
        expertRatingCutoff,
        payload: {
          'fields.type': 'News',
          order: '-fields.publicationDate',
        },
        localeFilters: ctfLocaleFilter,
        featuresConfig,
        clientDetails,
      },
    ],
    getArticles,
  );

  const doLogout = () => dispatch(logout());
  const redirect = (link, target) => {
    if (target === 'blank') {
      window.open(link, '_blank', 'noopener');
    } else {
      setDialogMenuOpen(false);
      if (link === '/') {
        doLogout();
      }
      history.push(link, { showReminderOverlay: false });
    }
  };

  // if (isAbout) return <CorporateHeader />;
  if (clientFetching) return null;
  if (shouldHideInAuthPage) return null;

  const useAppEmbedStyle =
    isEmbed || _get(clientDetails, 'metadata.useAppEmbedStyle', false);
  if (isBareEmbedded()) return null;
  return (
    <>
      <NavBarContext.Provider
        value={{
          assessments: filterClientExclusiveResources({
            resources: _get(assessments, 'hits', []),
            clientShortName,
            clientGroupShortName: _get(clientDetails, 'clientGroup.shortName'),
          }),
          blogs: _get(blogs, 'items', []),
          news: _get(news, 'items', []),
          clientDetails,
          allowSignIn,
          siteCopy: navbarPageSiteCopy,
          auth,
          profile,
          clientResources: finalClientResources,
          openDialog: setDialogMenuOpen,
          isAuthenticated,
          clientLanding,
          sendMixpanelEvent,
          logout: doLogout,
          clientFetching,
          isEmbed,
          menus,
          setMenus,
          dialogMenuOpen,
          getLink,
          redirect,
        }}
      >
        {renderEnterpriseNavbar ? (
          <HomePageProNavBar />
        ) : (
          <>
            <DialogMenu />
            <Grid
              container
              className={ClassNames(classes.toolbarWrapper, {
                [classes.sticky]: !useAppEmbedStyle,
                [classes.mobileResourcesToolbarPage]: isResourcesPage,
                [classes.mt36AICC]: isAICC,
              })}
            >
              <Grid item xs={12} className={classes.appBarWrapper}>
                <AppBar
                  component="div"
                  position="static"
                  className={ClassNames(classes.bar, {
                    [classes.embedded]:
                      useAppEmbedStyle || shouldHideNavBarSubMenu,
                  })}
                >
                  <Content />
                </AppBar>
              </Grid>
              <ContentBlock
                fullWidth
                type="common-page"
                position={CONTENT_BLOCK_POSITIONS.BELOW_MENU_STICKY}
              />
              <ContentBlock
                type="item-page"
                id={_get(assessment, 'items.0.sys.id')}
                position={CONTENT_BLOCK_POSITIONS.BELOW_MENU_STICKY}
                classes={{
                  root: classes.contentBlock,
                  item: classes.contentBlockItem,
                }}
                marginBottom={false}
                marginTop={false}
              />
            </Grid>
          </>
        )}
      </NavBarContext.Provider>
    </>
  );
}

export default React.memo(NavBarMenu);
