import { take, takeLatest, put, select, call } from 'redux-saga/effects';
import { getFirebase, actionTypes } from 'react-redux-firebase';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import MailChimpService from 'utils/mailchimpService';
import { patch } from 'utils/api';
import MixPanel from 'utils/mixpanelService';
import { toast } from 'react-toastify';
import { makeSelectTakenAssessments } from 'containers/Assessments/selectors';
import { removeLocalData, setLocalData } from 'utils/localDataStore';
import { getSavedTopics, saveTopic } from 'containers/Topics/actions';
import {
  moveAnonymousAssessments,
  putAssessment,
  sendAssessmentResultsByEmail,
  setTakenInSession,
} from 'containers/Assessment/actions';
import {
  getSavedAssessments,
  getTakenAssessments,
  saveAssessment,
} from 'containers/Assessments/actions';
import { GET_TAKEN_ASSESSMENTS_RESULT } from 'containers/Assessments/constants';
import {
  hidePersonalEmailModal,
  saveRemindMeLaterResource,
} from 'containers/Main/actions';
import {
  makeSelectClientDetails,
  makeSelectOAuthOptions,
} from 'containers/Main/selectors';
import {
  getSavedResources,
  saveResource,
  rateResource,
} from 'containers/Resources/actions';
import {
  getTakenSeriesSuccess,
  moveAnonymousSeries,
} from 'containers/Series/actions';
import Config from 'utils/getEnvConfig';
import {
  getSubdomain,
  getUserDisplayName,
  getUserFirstName,
  getUserLastName,
} from 'utils/stringUtils';
import { enrollCourse } from 'containers/Course/actions';
import {
  SIGN_UP,
  CHECK_EMAIL_AVAILABILITY,
  COMPLETE_SIGN_UP,
  LOGOUT,
  SUBMIT_SUBSCRIPTION,
  GET_SUBSCRIPTION_STATUS,
  UPDATE_SUBSCRIPTION_STATUS,
} from './constants';
import {
  processing,
  authError,
  subscriptionProcessing,
  checkEmailAvailabilityResult,
  submitSubscriptionResult,
  getSubscriptionStatusResult,
  showAuthCTAModal,
  logout,
  setAuthModalState,
} from './actions';
import {
  makeSelectAuth,
  makeSelectProfile,
  makeSelectStoredItem,
  makeSelectAuthModal,
} from './selectors';
import { getUserDomainAllowed } from './utils';

const {
  LAMBDA: { MAILCHIMP },
} = Config;

const mailchimpHeader = {
  headers: {
    'x-api-key': MAILCHIMP.API_KEY,
  },
};

function* signUpSaga({ payload }) {
  const {
    provider = {},
    data = {},
    subscription = [],
    isBackdoor = false,
  } = payload;
  let response;
  try {
    yield put(processing(true));
    const isLogin = _isEmpty(data);
    if (isLogin) {
      response = yield getFirebase().login(provider);
    } else {
      const {
        email,
        password,
        firstName,
        lastName,
        gender,
        genderOther,
        genderLGBTQp,
        birthdayMonthYear,
        stateOfResidence,
      } = data;
      const commonFields = {
        firstName,
        lastName,
        displayName: `${firstName} ${lastName}`,
        email,
      };
      const userProfile = {
        ...commonFields,
      };
      if (gender) {
        userProfile.gender = gender;
      }
      if (!_isNil(genderOther)) {
        userProfile.genderOther = genderOther;
      }
      if (genderLGBTQp) {
        userProfile.genderLGBTQp = genderLGBTQp;
      }
      if (stateOfResidence) {
        userProfile.stateOfResidence = stateOfResidence;
      }
      if (birthdayMonthYear) {
        userProfile.birthdayMonthYear = birthdayMonthYear;
      }
      if (data.role === 'customerAdmin') {
        const subdomain = getSubdomain();

        userProfile.isClientAdmin = true;
        userProfile.adminInClients = [subdomain];
      }
      response = yield getFirebase().createUser(
        { ...commonFields, password },
        userProfile,
      );
    }

    let auth = yield select(makeSelectAuth());
    if (_isEmpty(auth) || auth.isEmpty) {
      yield take(actionTypes.LOGIN);
      auth = yield select(makeSelectAuth());
    }
    let profile = yield select(makeSelectProfile());
    if (_isEmpty(profile) || profile.isEmpty) {
      yield take(actionTypes.SET_PROFILE);
      profile = yield select(makeSelectProfile());
    }

    const clientDetails = yield select(makeSelectClientDetails());

    const isUserDomainAllowed = getUserDomainAllowed({
      profile,
      response,
      isBackdoor,
      clientDetails,
    });

    if (isUserDomainAllowed) {
      let event;
      if (
        !isLogin ||
        (auth.providerData[0].providerId !== 'password' &&
          Date.now() - auth.createdAt < 5 * 60 * 1000)
      ) {
        event = 'Sign Up - Successful';
        MixPanel.alias(auth.uid);
      } else {
        event = 'Login - Successful';
        MixPanel.forceIdentify();
      }
      MixPanel.track(event, { authMethod: auth.providerData[0].providerId });
      const maskedEmail = profile.email
        ? `@${profile.email.split('@')[1]}`
        : undefined;
      MixPanel.people.set({
        $created: auth.createdAt,
        $last_login: auth.lastLoginAt,
        maskedEmail,
        firebaseID: auth.uid,
        gender: profile.gender,
        birthdayMonthYear: profile.birthdayMonthYear,
        socialLogin: auth.providerData[0].providerId,
      });

      const shouldHideSubscription = _get(
        clientDetails,
        'metadata.hideSubscription',
        false,
      );
      if (
        !shouldHideSubscription &&
        subscription.length > 0 &&
        (_get(profile, 'email') || response.email)
      ) {
        const subscriptionResponse = yield call(() =>
          MailChimpService.getUserStatus(
            _get(profile, 'email') || response.email,
          ),
        );
        if (subscriptionResponse.status === 204) {
          yield call(() =>
            MailChimpService.subscribe({
              email: isLogin ? _get(profile, 'email') : response.email,
              subscription,
              firstName: _get(profile, 'firstName') || response.firstName,
              lastName: _get(profile, 'lastName') || response.lastName,
            }),
          );
        }
      }

      const shouldUpdateEmail =
        provider &&
        provider.provider === 'facebook' &&
        _get(response, 'profile.email');
      if (shouldUpdateEmail) {
        const profileData = {
          email: _get(response, 'profile.email'),
        };
        yield getFirebase().updateProfile(profileData);
      }

      setLocalData('isSubscribed', subscription.length > 0, {
        expires: 365,
      });
      setLocalData('signMethod', (provider && provider.provider) || 'email', {
        expires: 365,
      });

      const storedItem = yield select(makeSelectStoredItem());
      if (!_isEmpty(storedItem)) {
        if (storedItem.type === 'resource')
          yield put(saveResource(storedItem.payload));
        else if (storedItem.type === 'topic')
          yield put(saveTopic(storedItem.payload));
        else if (storedItem.type === 'assessment')
          yield put(saveAssessment(storedItem.payload));
        else if (storedItem.type === 'rate')
          yield put(rateResource(storedItem.payload));
        else if (storedItem.type === 'assessmentResult')
          yield put(putAssessment(storedItem.payload));
        else if (storedItem.type === 'remind-me')
          yield put(
            saveRemindMeLaterResource({
              ...storedItem.payload,
              fromAuth: true,
            }),
          );
        else if (storedItem.type === 'send-assessment-email')
          yield put(sendAssessmentResultsByEmail(storedItem.payload));
        else if (storedItem.type === 'move-assessments')
          yield put(moveAnonymousAssessments());
        else if (storedItem.type === 'series') {
          yield put(moveAnonymousSeries());
          MixPanel.track('Series - Sign Up Successful', {
            slug: _get(storedItem.payload, 'item.fields.slug'),
            name: _get(storedItem.payload, 'item.fields.title'),
            path: 'series',
          });
        } else if (storedItem.type === 'course') {
          const coursePayload = {
            item: _get(storedItem, 'payload.item'),
            userId: auth.uid,
            collection: 'user_resources',
            courseId: _get(storedItem, 'payload.item.sys.id'),
            isAuthenticated: true,
            isAfterSuccessfulAuth: true,
          };

          yield put(enrollCourse(coursePayload));
        }
      }
    } else {
      yield put(logout(true));
      toast('Sorry, this email domain is not allowed to access this site.', {
        type: 'error',
        toastId: 'blocked-domain',
      });
    }
  } catch (error) {
    let hasError = true;
    if (error.code === 'auth/account-exists-with-different-credential') {
      hasError = false;
      try {
        const clientDetails = yield select(makeSelectClientDetails());
        const authOptions = yield select(makeSelectOAuthOptions());
        const hasClientDetails = !_isEmpty(clientDetails);

        const getPreviousProvider = yield getFirebase()
          .functions()
          .httpsCallable('getPreviousProvider');
        const providerResponse = yield getPreviousProvider({
          email: error.email,
        });

        const providerName = providerResponse.data.providerId;
        if (providerName !== 'password') {
          if (hasClientDetails) {
            if (
              (['facebook', 'facebook.com'].includes(providerName) &&
                authOptions.facebook) ||
              (['google', 'google.com'].includes(providerName) &&
                authOptions.google) ||
              (providerName === 'microsoft.com' && authOptions.ms)
            ) {
              const isLogin = _isEmpty(data);
              if (isLogin) MixPanel.track('Login - Failed');
              else MixPanel.track('Sign Up - Failed');
              yield put(
                showAuthCTAModal({
                  type: 'previous-provider',
                  method: providerName,
                }),
              );
            } else {
              hasError = true;
            }
          } else {
            const isLogin = _isEmpty(data);
            if (isLogin) MixPanel.track('Login - Failed');
            else MixPanel.track('Sign Up - Failed');
            yield put(
              showAuthCTAModal({
                type: 'previous-provider',
                method: providerName,
              }),
            );
          }
        } else {
          hasError = true;
        }
      } catch (e) {
        hasError = true;
      }
    }

    if (hasError) {
      const isLogin = _isEmpty(data);
      if (isLogin) MixPanel.track('Login - Failed');
      else MixPanel.track('Sign Up - Failed');

      const parsedMessage = error.message
        .replace('Firebase: ', '')
        .replace(/\s+\(auth\/[a-zA-Z0-9-]+\)./, '');
      yield put(
        authError({
          ...error,
          message: parsedMessage,
        }),
      );
    }
  } finally {
    yield put(processing(false));
  }
}

function* completeSignUpSaga({ payload }) {
  try {
    yield put(processing(true));
    const {
      data: { email, subscription = [], data = {} },
    } = payload;
    if (!_isEmpty(email)) {
      const methods = yield getFirebase()
        .auth()
        .fetchSignInMethodsForEmail(email);
      if (methods.length > 0) {
        toast(
          'Email is already in use. Please, use another email or try signing in.',
          {
            type: 'error',
          },
        );
      } else {
        const displayNameData = {};
        const shouldUpdateDisplayName =
          _has(data, 'firstName') || _has(data, 'lastName');
        if (shouldUpdateDisplayName) {
          const profile = yield select(makeSelectProfile());
          displayNameData.displayName = getUserDisplayName({
            ...profile,
            ...data,
          });
        }
        yield getFirebase()
          .auth()
          .currentUser.updateEmail(email);
        yield getFirebase().updateProfile({
          email,
          signUpCompleted: true,
          ...data,
          ...displayNameData,
        });

        if (subscription.length > 0) {
          const subscriptionResponse = yield call(() =>
            MailChimpService.getUserStatus(email),
          );
          if (subscriptionResponse.status === 204) {
            const user = yield getFirebase().auth().currentUser;
            const providerId = _get(user, 'providerData.0.providerId', '');
            const isSamlProvider = providerId.startsWith('saml');

            const profile = yield select(makeSelectProfile());
            const firstName = getUserFirstName(profile);
            const lastName = getUserLastName(profile);

            yield call(() =>
              MailChimpService.subscribe({
                email,
                subscription,
                firstName,
                lastName,
                isSamlProvider,
              }),
            );
          }
        }

        const maskedEmail = email ? `@${email.split('@')[1]}` : undefined;
        MixPanel.track('Email change - Successful');
        MixPanel.people.set({
          maskedEmail,
        });
      }
    }
  } catch (error) {
    MixPanel.track('Email change - Failed');
  } finally {
    const authModalState = yield select(makeSelectAuthModal());
    if (authModalState.show) {
      yield put(setAuthModalState({ show: false, type: '' }));
    }
    yield put(processing(false));
  }
}

function* logoutSaga({ payload = false }) {
  try {
    yield put(processing(true));
    yield MixPanel.track(
      payload ? 'Forced Logout - Successful' : 'Logout - Successful',
    );
    if (payload) {
      removeLocalData('signMethod');
    }
    yield MixPanel.reset();
    yield getFirebase().logout();
  } finally {
    yield put(processing(false));
    yield put(getSavedTopics());
    yield put(getSavedAssessments());
    yield put(getTakenAssessments());
    yield put(setTakenInSession([]));
    yield put(getSavedResources());
    yield put(hidePersonalEmailModal());
    yield put(getTakenSeriesSuccess({ takenSeries: {} }));
    removeLocalData('insurance');
  }
}

function* submitSubscriptionSaga({ payload }) {
  const {
    email,
    subscription,
    subscribeSection = false,
    isPersonalEmail = false,
  } = payload;
  try {
    yield put(subscriptionProcessing(true));

    const methods = yield getFirebase()
      .auth()
      .fetchSignInMethodsForEmail(email);
    if (methods.length > 0) {
      yield put(
        submitSubscriptionResult({
          error: true,
          exists: true,
          subscribeSection,
          errorMessage:
            'This email address is being used for a user account. Do you want to Sign In and manage subscriptions?',
        }),
      );
    } else {
      const profile = yield select(makeSelectProfile());
      const firstName = getUserFirstName(profile);
      const lastName = getUserLastName(profile);

      const user = yield getFirebase().auth().currentUser;
      const providerId = _get(user, 'providerData.0.providerId', '');
      const isSamlProvider = providerId.startsWith('saml');

      const response = yield call(() =>
        MailChimpService.subscribe({
          email,
          subscription,
          firstName,
          lastName,
          isSamlProvider,
        }),
      );

      if (response.status === 200) {
        MixPanel.track('Subscription status added');

        const auth = yield select(makeSelectAuth());
        const clientDetails = yield select(makeSelectClientDetails());

        const shouldUsePersonalEmail = _get(
          clientDetails,
          'metadata.requirePersonalEmail',
          false,
        );
        if (
          shouldUsePersonalEmail &&
          isPersonalEmail &&
          auth.isLoaded &&
          !auth.isEmpty
        ) {
          yield put(getTakenAssessments());
          yield take(GET_TAKEN_ASSESSMENTS_RESULT);
          const takenAssessments = yield select(makeSelectTakenAssessments());

          if (!_isEmpty(takenAssessments)) {
            const slugs = takenAssessments
              .map(item => _get(item, 'assessment.slug'))
              .join(',');

            patch(
              MAILCHIMP.ASSESSMENT_TAKEN,
              {
                email,
                slug: slugs,
              },
              mailchimpHeader,
            );
          }
        }

        yield put(submitSubscriptionResult({ error: false, subscribeSection }));
      } else {
        yield put(
          submitSubscriptionResult({
            error: true,
            exists: false,
            subscribeSection,
            errorMessage: 'Something went wrong. Please, try again!',
          }),
        );
      }
    }
  } catch (error) {
    if (error.status === 400) {
      if (error.data.title === 'Member Exists') {
        yield put(
          submitSubscriptionResult({
            error: true,
            exists: true,
            subscribeSection,
            errorMessage: `${
              error.data.detail.split(' ')[0]
            } is already subscribed.`,
          }),
        );
      } else {
        yield put(
          submitSubscriptionResult({
            error: true,
            exists: true,
            subscribeSection,
            errorMessage: error.data.detail,
          }),
        );
      }
    } else {
      yield put(
        submitSubscriptionResult({
          error: true,
          exists: false,
          subscribeSection,
          errorMessage: 'Something went wrong. Please, try again!',
        }),
      );
    }
  } finally {
    yield put(subscriptionProcessing(false));
  }
}

function* getSubscriptionStatusSaga({ payload }) {
  const email = payload;
  try {
    yield put(subscriptionProcessing(true));
    const response = yield call(() => MailChimpService.getUserStatus(email));
    if (response.status === 200) {
      const { data } = response;
      const { status, mergeFields } = data;

      const subscription = Object.keys(mergeFields)
        .filter(el => mergeFields[el] === 'Y')
        .map(el => el.toLowerCase());
      yield put(getSubscriptionStatusResult({ status, subscription, email }));
    } else {
      yield put(
        getSubscriptionStatusResult({ status: '', subscription: [], email }),
      );
    }
  } catch (error) {
    yield put(
      getSubscriptionStatusResult({ status: '', subscription: [], email }),
    );
  } finally {
    yield put(subscriptionProcessing(false));
  }
}

function* updateSubscriptionStatusSaga({ payload }) {
  try {
    yield put(subscriptionProcessing(true));
    const { email, subscription, isPersonalEmail = false } = payload;
    const profile = yield select(makeSelectProfile());
    const clientDetails = yield select(makeSelectClientDetails());

    const shouldUsePersonalEmail = _get(
      clientDetails,
      'metadata.requirePersonalEmail',
      false,
    );

    const profileEmail = shouldUsePersonalEmail
      ? profile.personalEmail
      : profile.email;

    const oldEmail = profileEmail;
    const firstName = getUserFirstName(profile);
    const lastName = getUserLastName(profile);

    const response = yield call(() =>
      MailChimpService.updateSubscription(
        email,
        subscription,
        firstName,
        lastName,
        oldEmail,
      ),
    );

    if (response.status === 200) {
      try {
        if (!shouldUsePersonalEmail) {
          yield getFirebase()
            .auth()
            .currentUser.updateEmail(email);
          yield getFirebase().updateProfile({
            email,
          });
        } else {
          yield getFirebase().updateProfile({
            personalEmail: email,
          });
        }

        const maskedEmail = email ? `@${email.split('@')[1]}` : undefined;
        MixPanel.people.set({
          maskedEmail,
        });
        MixPanel.track('Subscription status changed');

        const auth = yield select(makeSelectAuth());
        if (
          shouldUsePersonalEmail &&
          isPersonalEmail &&
          auth.isLoaded &&
          !auth.isEmpty
        ) {
          yield put(getTakenAssessments());
          yield take(GET_TAKEN_ASSESSMENTS_RESULT);
          const takenAssessments = yield select(makeSelectTakenAssessments());

          if (!_isEmpty(takenAssessments)) {
            const slugs = takenAssessments
              .map(item => _get(item, 'assessment.slug'))
              .join(',');

            patch(
              MAILCHIMP.ASSESSMENT_TAKEN,
              {
                email,
                slug: slugs,
              },
              mailchimpHeader,
            );
          }
        }

        yield put(submitSubscriptionResult({ error: false }));
      } catch (error) {
        yield call(() =>
          MailChimpService.updateSubscription(oldEmail, subscription, email),
        );

        yield put(
          submitSubscriptionResult({
            error: true,
            exists: false,
            errorMessage: 'Something went wrong. Please, try again!',
          }),
        );
      }
    } else {
      yield put(
        submitSubscriptionResult({
          error: true,
          exists: false,
          errorMessage: 'Something went wrong. Please, try again!',
        }),
      );
    }
  } catch (error) {
    yield put(
      submitSubscriptionResult({
        error: true,
        exists: _get(error, 'data.errors', [])
          .map(item => item.field)
          .includes('email address'),
        errorMessage: 'Something went wrong. Please, try again!',
      }),
    );
  } finally {
    yield put(subscriptionProcessing(false));
  }
}

function* checkEmailAvailabilitySaga({ payload }) {
  try {
    yield put(processing(true));
    const email = payload;
    const methods = yield getFirebase()
      .auth()
      .fetchSignInMethodsForEmail(email);
    if (methods.length > 0) {
      yield put(
        checkEmailAvailabilityResult({
          exists: false,
        }),
      );
    } else {
      yield put(
        checkEmailAvailabilityResult({
          available: true,
        }),
      );
    }
  } catch (error) {
    yield put(
      checkEmailAvailabilityResult({
        error: true,
      }),
    );
  } finally {
    yield put(processing(false));
  }
}

// Individual exports for testing
export default function* defaultSaga() {
  yield takeLatest(SIGN_UP, signUpSaga);
  yield takeLatest(CHECK_EMAIL_AVAILABILITY, checkEmailAvailabilitySaga);
  yield takeLatest(COMPLETE_SIGN_UP, completeSignUpSaga);
  yield takeLatest(LOGOUT, logoutSaga);
  yield takeLatest(SUBMIT_SUBSCRIPTION, submitSubscriptionSaga);
  yield takeLatest(GET_SUBSCRIPTION_STATUS, getSubscriptionStatusSaga);
  yield takeLatest(UPDATE_SUBSCRIPTION_STATUS, updateSubscriptionStatusSaga);
}
