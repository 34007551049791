/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useContext } from 'react';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ClassNames from 'classnames';
import _get from 'lodash/get';
import {
  makeSelectClientDetails,
  makeSelectThemes,
} from 'containers/Main/selectors';
import Hidden from '@material-ui/core/Hidden';
import useWindowSize from 'components/useWindowSize';
import TrendingTopics from './TrendingTopics';
import ViewAllLink from '../ViewLink';
import ArrowRightDefault from '../../images/arrow-right-default.svg';
import ArrowRightBlue from '../../images/arrow-right.svg';
import TopicLists from './TopicLists';
import NavBarContext from '../../navBarContext';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '22px 0',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: '22px 5%',
    },
    [theme.breakpoints.up('600')]: {
      display: 'flex',
    },
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '17px 34px',
  },
  header: {
    marginBottom: 10,
  },
  lists: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
    width: 254,
    height: '100%',
    borderRight: '1px solid #E3E3E3',
    [theme.breakpoints.down('xs')]: {
      borderRight: 'none',
      width: '100%',
    },
  },
  image: {
    width: '100%',
    height: 139,
    marginBottom: 6,
  },
  listItem: {
    padding: '0 24px',
    height: 38,
    fontSize: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
  },
  linkList: {
    padding: '0 24px',
  },
  activeList: {
    background: '#ECF4F8',
    color: '#01619B',
    fontFamily: 'MadaBold',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      display: 'block',
      overflow: 'hidden',
      paddingTop: 12,
      '& img': {
        marginTop: 5,
        float: 'right',
      },
    },
  },
}));

function Topics() {
  const classes = useStyles();
  const themes = useSelector(makeSelectThemes());
  const clientDetails = useSelector(makeSelectClientDetails());
  const { width } = useWindowSize();
  const { siteCopy, sendMixpanelEvent } = useContext(NavBarContext);
  const [selectedTheme, setSelectedTheme] = useState(width <= 600 ? null : 0);
  const clickHandler = (name, element) => {
    sendMixpanelEvent('Menu Item - Clicked', {
      type: 'Topics',
      name,
      element,
    });
  };
  if (!themes) return null;

  const sector = _get(clientDetails, 'sector', 'Community');

  const filteredThemes = themes.filter(theme => {
    if (!_get(theme, 'hideInMainMenu', false)) {
      if (
        _get(theme, 'clientCollection.items', [])
          .map(client => client.slug)
          .includes(_get(clientDetails, 'slug'))
      )
        return true;

      if (!Array.isArray(theme.sectors) || theme.sectors.length === 0)
        return true;

      if (theme.sectors.includes(sector)) return true;
      return false;
    }

    return false;
  });
  return (
    <div className={classes.root}>
      <Hidden smUp>
        <TrendingTopics isSubMenu />
      </Hidden>
      <div>
        <ul className={classes.lists} data-test-id="topics-themes">
          {filteredThemes.map(({ title }, index) => (
            <li
              key={title}
              onClick={() =>
                setSelectedTheme(selectedTheme === index ? null : index)
              }
              className={ClassNames(
                classes.listItem,
                index === selectedTheme ? classes.activeList : undefined,
              )}
            >
              {title}
              <img
                src={
                  index === selectedTheme ? ArrowRightBlue : ArrowRightDefault
                }
                alt=""
              />
              <Hidden smUp>
                {index === selectedTheme && (
                  <TopicLists
                    data={filteredThemes[selectedTheme]}
                    clickHandler={clickHandler}
                    showAll
                  />
                )}
              </Hidden>
            </li>
          ))}
          <Hidden xsDown>
            <li className={classes.linkList}>
              <ViewAllLink
                label={_get(siteCopy, [
                  'pageCopy',
                  'submenu-topics',
                  'view-all',
                ])}
                link="/topics"
              />
            </li>
          </Hidden>
        </ul>
      </div>
      <Hidden smUp>
        <ViewAllLink
          label={_get(siteCopy, [
            'pageCopy',
            'submenu-topics',
            'view-all-mobile',
          ])}
          link="/topics"
        />
      </Hidden>
      <Hidden xsDown>
        <TopicLists
          data={filteredThemes[selectedTheme]}
          clickHandler={clickHandler}
        />
      </Hidden>
    </div>
  );
}

export default Topics;
