import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import _get from 'lodash/get';
import { makeSelectLanguage } from 'containers/Main/selectors';
import getLanguageByCode from 'containers/Main/services/getLanguageByCode.gql';

const useLanguage = () => {
  const locale = useSelector(makeSelectLanguage());

  const { data, isFetching } = useQuery(
    locale && ['language', locale],
    getLanguageByCode,
  );

  const isRtlLanguage = !!_get(data, 'rtlLanguage');
  const textDirection = isRtlLanguage ? 'rtl' : 'ltr';

  return {
    isLoading: isFetching,
    language: data,
    textDirection,
    isRtlLanguage,
  };
};

export default useLanguage;
