import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import MobileAccountInfo from './MobileAccountInfo';
import NavBarContext from '../navBarContext';
import MobileNavBar from './MobileNavBar';

const useStyles = makeStyles(() => ({
  dialogContainer: {
    height: 'calc(100dvh - 75px)',
    overflow: 'hidden',
  },
  dialogBackdrop: {
    backgroundColor: 'transparent',
    bottom: '75px',
  },
  dialogPaper: {
    boxShadow: 'none',
  },
  navbarContainer: {
    padding: '34px 18px 8px',
  },
  accountInfoContainer: {
    overflowY: 'auto',
  },
}));

const DialogMenu = () => {
  const classes = useStyles();
  const { openDialog, dialogMenuOpen } = useContext(NavBarContext);

  return (
    <Dialog
      fullScreen
      open={dialogMenuOpen}
      onClose={() => openDialog(false)}
      BackdropProps={{
        className: classes.dialogBackdrop,
      }}
      PaperProps={{
        className: classes.dialogPaper,
      }}
      className={classes.dialogContainer}
    >
      <Box className={classes.navbarContainer}>
        <MobileNavBar />
      </Box>
      <Box className={classes.accountInfoContainer}>
        <MobileAccountInfo />
      </Box>
    </Dialog>
  );
};

export default DialogMenu;
