import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import useSiteCopySelector from 'components/useSiteCopySelector';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 350,
    width: 350,
    height: 66,
    [theme.breakpoints.down('xs')]: {
      width: 300,
      minWidth: 300,
    },
  },
  select: {
    height: 50,
    paddingTop: 8,
    paddingBottom: 8,
    minHeight: 50,
    fontSize: 17,
    lineHeight: '23px',
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function Dropdown({ error, onChange, options, value }) {
  const classes = useStyles();
  const clientDetails = useSelector(makeSelectClientDetails());
  const [siteCopy] = useSiteCopySelector(['insurance-modal']);

  const finalSiteCopy = update(_get(siteCopy, 'pageCopy', {}), {
    $merge: _get(clientDetails, 'metadata.insuranceModal', {}),
  });

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        value={value}
        onChange={onChange}
        classes={{ root: classes.select }}
        error={error}
      >
        <MenuItem value="-1" disabled>
          <em>{_get(finalSiteCopy, 'dropdown-placeholder')}</em>
        </MenuItem>
        {options.map(option => (
          <MenuItem value={option.value} key={option.value}>
            {option.label || option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

Dropdown.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
};
