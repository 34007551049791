/*
 *
 * Topics actions
 *
 */
import { createAction } from 'redux-actions';
import {
  GET_TOPICS,
  GET_TOPICS_SUCCESS,
  GET_ALL_TOPICS,
  GET_ALL_TOPICS_SUCCESS,
  GET_SAVED_TOPICS,
  GET_SAVED_TOPICS_RESULT,
  SAVE_TOPIC,
  SAVE_TOPIC_RESULT,
  PROCESSING,
  SECTION_PROCESSING,
} from './constants';

export const processing = createAction(PROCESSING);
export const getTopics = createAction(GET_TOPICS);
export const getTopicsSuccess = createAction(GET_TOPICS_SUCCESS);
export const getAllTopics = createAction(GET_ALL_TOPICS);
export const getAllTopicsSuccess = createAction(GET_ALL_TOPICS_SUCCESS);
export const getSavedTopics = createAction(GET_SAVED_TOPICS);
export const getSavedTopicsResult = createAction(GET_SAVED_TOPICS_RESULT);
export const saveTopic = createAction(SAVE_TOPIC);
export const saveTopicResult = createAction(SAVE_TOPIC_RESULT);
export const sectionProcessing = createAction(SECTION_PROCESSING);
