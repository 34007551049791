import { createClient } from 'contentful';
import Config from 'utils/getEnvConfig';

const {
  CONTENTFUL: { SPACE_ID, ACCESS_TOKEN, CDN_URL, ENVIRONMENT },
} = Config;

export const getContentfulEntryUrl = id =>
  `https://app.contentful.com/spaces/${SPACE_ID}/${
    ENVIRONMENT === 'master' ? '' : `environments/${ENVIRONMENT}/`
  }entries/${id}`;

const client = createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN,
  host: CDN_URL,
  environment: ENVIRONMENT,
  retryOnError: false,
});

export default client;
