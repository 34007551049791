import React from 'react';

export default (color = '#FFFFFF') => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 19"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Podcast" transform="translate(0.4500122 0.44999695)">
      <path
        d="M2.98464 10.1635C1.71092 8.0235 2.11837 5.22781 3.94335 3.58535C5.76832 1.94289 8.46295 1.94674 10.2837 3.59441C12.1044 5.24208 12.5046 8.03893 11.2253 10.1752"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.87227 12.504C-0.726872 9.53335 -0.6054 4.93231 2.14649 2.11731C4.89838 -0.697694 9.28378 -0.706887 12.0464 2.09655C14.8089 4.9 14.9479 9.50049 12.3601 12.482"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.54215 12.6767C9.58894 12.233 9.45358 11.7894 9.16991 11.4567C8.88624 11.1241 8.48109 10.9339 8.05575 10.9337L6.16166 10.9337C5.73619 10.9337 5.33084 11.1238 5.047 11.4564C4.76317 11.7891 4.62771 12.2329 4.67451 12.6767L5.09118 16.6024C5.17504 17.3977 5.81523 17.9997 6.57758 18L7.63983 18C8.40304 18.0009 9.04452 17.3986 9.12847 16.6024L9.54215 12.6767Z"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.23855 7.4335C5.23855 8.51756 6.07585 9.39637 7.10871 9.39637C8.14156 9.39637 8.97886 8.51756 8.97886 7.4335C8.97886 6.34944 8.14156 5.47064 7.10871 5.47064C6.07585 5.47064 5.23855 6.34944 5.23855 7.4335L5.23855 7.4335Z"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
