import React from 'react';

export const More = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19px"
    height="19px"
    viewBox="0 0 19 19"
    version="1.1"
  >
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Mobile-Menu---Default---2023---option-9"
        transform="translate(-331.000000, -757.000000)"
        stroke="currentColor"
        strokeWidth="1.28"
      >
        <g id="Menu" transform="translate(0.000000, 743.000000)">
          <g id="Account-Copy" transform="translate(329.000000, 15.000000)">
            <path
              d="M3,0.5 L20,0.5 M3,8.5 L20,8.5 M3,16.5 L20,16.5"
              id="Combined-Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
