import React from 'react';
import _classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  root: {
    margin: '50px auto',
    height: 100,
    textAlign: 'center',
    fontSize: 10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  card: {
    margin: '11.5px auto',
  },
});

const LoadingIndicator = ({ card = false }) => {
  const classes = useStyles();
  return (
    <div
      className={_classNames({
        [classes.root]: true,
        [classes.card]: card,
      })}
    >
      <CircularProgress disableShrink />
    </div>
  );
};

export default LoadingIndicator;
