import React from 'react';

const NextStepIcon = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" width="40" height="40" rx="20" fill="#01619B" />
    <g>
      <path
        d="M9 20C9 21.5102 9.29746 23.0056 9.87539 24.4009C10.4533 25.7961 11.3004 27.0639 12.3683 28.1317C13.4361 29.1996 14.7039 30.0467 16.0991 30.6246C17.4944 31.2025 18.9898 31.5 20.5 31.5C22.0102 31.5 23.5056 31.2025 24.9009 30.6246C26.2961 30.0467 27.5639 29.1996 28.6317 28.1317C29.6996 27.0639 30.5467 25.7961 31.1246 24.4009C31.7025 23.0056 32 21.5102 32 20C32 18.4898 31.7025 16.9944 31.1246 15.5991C30.5467 14.2039 29.6996 12.9361 28.6317 11.8683C27.5639 10.8004 26.2961 9.95331 24.9009 9.37539C23.5056 8.79746 22.0102 8.5 20.5 8.5C18.9898 8.5 17.4944 8.79746 16.0991 9.37539C14.7039 9.95331 13.4361 10.8004 12.3683 11.8683C11.3004 12.9361 10.4533 14.2039 9.87539 15.5991C9.29746 16.9944 9 18.4898 9 20Z"
        stroke="white"
      />
      <path d="M23 15.5H25V24.5H23V15.5Z" stroke="white" />
      <path
        d="M16 23.375C16 23.4716 16.028 23.5661 16.0806 23.6471C16.1332 23.7282 16.2081 23.7922 16.2963 23.8316C16.3845 23.8709 16.4823 23.8839 16.5777 23.8689C16.6731 23.8539 16.7621 23.8115 16.834 23.747L20.587 20.369C20.639 20.3221 20.6807 20.2648 20.7091 20.2008C20.7376 20.1368 20.7524 20.0676 20.7524 19.9975C20.7524 19.9275 20.7376 19.8582 20.7091 19.7942C20.6807 19.7302 20.639 19.6729 20.587 19.626L16.834 16.249C16.7622 16.1845 16.6732 16.1422 16.5779 16.1272C16.4826 16.1122 16.385 16.125 16.2968 16.1643C16.2086 16.2035 16.1337 16.2674 16.081 16.3483C16.0283 16.4291 16.0002 16.5235 16 16.62V23.375Z"
        stroke="white"
      />
    </g>
  </svg>
);

export default NextStepIcon;
