export const findElement = selector => {
  const max = 20;
  let retries = 0;
  return new Promise(res => {
    const interval = setInterval(() => {
      const elem = document.querySelector(selector);
      if (elem || retries > max) {
        res(elem);
        clearInterval(interval);
      } else {
        retries += 1;
      }
    }, 500);
  });
};
