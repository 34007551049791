/**
 *
 * TopicItem
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useAlgoliaLocale from 'components/useAlgoliaLocale';
import { getEmbedlyImageUrl } from 'utils/embedlyUtils';
import { getAlgoliaLocalizedField } from 'utils/localeUtils';
import { fallbackImage } from 'utils/stringUtils';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    position: 'relative',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    height: 60,
    width: 60,
    marginRight: 12,
    objectFit: 'cover',
  },
  title: {
    fontSize: 14,
    lineHeight: '18px',
    display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: 54 /* Fallback for non-webkit */,
    width: 'calc(100% - 72px)',
  },
});

const TopicItem = ({ data, onClick, variantELocale }) => {
  const classes = useStyles();
  const locale = useAlgoliaLocale();

  if (_isEmpty(data)) return null;

  const { url, nameTitle, imageUrl } = data;

  const handleClick = () => {
    onClick({
      type: 'Topics',
      name: nameTitle,
      entryId: data.objectID,
    });
  };
  // react-router issue
  return (
    <Link
      to={`${url}?lang=${variantELocale || locale}`}
      className={classes.link}
      onClick={handleClick}
    >
      <div className={classes.wrapper}>
        <img
          src={getEmbedlyImageUrl(imageUrl) || fallbackImage}
          alt={`${getAlgoliaLocalizedField(
            data,
            'nameTitle',
            variantELocale || locale,
          )} Topic`}
          className={classes.image}
          onError={e => {
            if (e.target.src !== imageUrl) {
              e.target.src = imageUrl;
            } else {
              e.target.src = fallbackImage;
            }
          }}
        />
        <Typography color="textPrimary" className={classes.title}>
          {getAlgoliaLocalizedField(
            data,
            'nameTitle',
            variantELocale || locale,
          )}
        </Typography>
      </div>
    </Link>
  );
};

TopicItem.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
};

export default TopicItem;
