import React from 'react';
import { makeStyles } from '@material-ui/core';
import ClassNames from 'classnames';
import { isIE } from 'utils/stringUtils';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    height: 36,
    minHeight: 36,
    background: 'white',
    width: '100%',
    borderBottom: '1px solid #E3E3E3',
    [theme.breakpoints.down('900')]: {
      borderBottom: 'none',
    },
    [theme.breakpoints.down('600')]: {
      position: 'inherit',
      height: 'auto',
    },
  },
  container: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.up('1140')]: {
      width: 1140,
      padding: 0,
    },
    width: '100%',
    padding: '0 5%',
    [theme.breakpoints.down('350')]: {
      padding: 0,
    },
  },
  rightMenuWrapper: {
    position: 'absolute',
    right: 0,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('1140')]: {
      right: '5%',
    },
    '& a, & button': {
      ...theme.typography.overline,
      color: '#6C6C6C',
      padding: '0 5px',
    },
    '& button': {
      marginRight: 12,
      textTransform: 'uppercase',
    },
  },
  rightMenuWrapperIE11: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    top: 0,
  },
}));

const NavBarHeaderWrapper = ({
  leftContent,
  rightContent,
  sectionClassName,
  containerClassName,
}) => {
  const classes = useStyles();

  return (
    <section className={ClassNames(classes.root, sectionClassName)}>
      <div className={ClassNames(classes.container, containerClassName)}>
        {leftContent}
        <div
          className={ClassNames({
            [classes.rightMenuWrapper]: true,
            [classes.rightMenuWrapperIE11]: isIE(),
          })}
        >
          {rightContent}
        </div>
      </div>
    </section>
  );
};

export default NavBarHeaderWrapper;
