import React from 'react';

export default (color = '#FFFFFF') => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 14 19"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="book" transform="translate(0.4500122 0.44999695)" fill={color}>
      <path
        d="M0.351672 0.139068L7.57591 2.99476C7.88283 3.11598 8.08421 3.4097 8.08442 3.73645L8.08442 17.2021C8.08398 17.467 7.95079 17.7145 7.72881 17.8629C7.50682 18.0112 7.22488 18.0412 6.97604 17.943L0.50851 15.3854C0.201858 15.2643 0.000539378 14.9709 0 14.6445L0 0.799241C0 0.357832 0.361952 8.87336e-17 0.808442 0L12.1266 0C12.5731 0 12.9351 0.357832 12.9351 0.799241L12.9351 14.3863C12.9351 14.8277 12.5731 15.1856 12.1266 15.1856L10.5097 15.1856"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.44626 0.0847195L9.85328 1.87262C10.2342 1.9447 10.5097 2.27406 10.5097 2.65748L10.5097 15.9848C10.5097 16.4262 10.1478 16.7841 9.7013 16.7841L8.08442 16.7841"
        id="Path"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
