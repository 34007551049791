import Config from 'utils/getEnvConfig';
import axios from 'axios';

const {
  ADMIN_TOOLS: { PRIVATE_SERVER_URL, PRIVATE_SERVER_KEY },
} = Config;
/* eslint-disable  */

const getAssessmentResults = (start, end) => {
  return axios.get(`${PRIVATE_SERVER_URL}?start=${start}&end=${end}`, {
    headers: {
      key04: PRIVATE_SERVER_KEY,
    },
  });
};

export default {
  getAssessmentResults,
};
