import { takeLatest, all, put } from 'redux-saga/effects';
import { getFirebase } from 'react-redux-firebase';
import { GET_SAVED_BEST_RESOURCES } from './constants';
import { processing, getSavedBestResourcesSuccess } from './actions';

function* getSaveBestResourcesSaga() {
  try {
    yield put(processing(true));
    const userID = yield getFirebase().auth().currentUser.uid;
    const savedResources = yield getFirebase()
      .firestore()
      .collection('user_resources')
      .doc(userID)
      .collection('saved_best_resources')
      .get();
    const bestResources = savedResources.docs.map(doc => doc.data());
    const response = yield all(
      bestResources.map(async ({ topicSlug, type }) => {
        try {
          const resources = await getFirebase()
            .firestore()
            .collection('best_resources')
            .doc(topicSlug)
            .collection(type)
            .doc(`best-${type}`)
            .get();
          return { type, resourceData: resources.data(), topicSlug };
        } catch (error) {
          return null;
        }
      }),
    );
    yield put(getSavedBestResourcesSuccess(response));
  } catch (error) {
    yield put(getSavedBestResourcesSuccess([]));
  } finally {
    yield put(processing(false));
  }
}

// Individual exports for testing
export default function* defaultSaga() {
  yield takeLatest(GET_SAVED_BEST_RESOURCES, getSaveBestResourcesSaga);
}
