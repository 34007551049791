import React from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import useSiteCopySelector from 'components/useSiteCopySelector';
import Content from './Content';

const Ribbon = () => {
  const [homeAnnouncement] = useSiteCopySelector(['home-announcement']);
  const hasData = !_isEmpty(_get(homeAnnouncement, 'body.json'));
  if (!hasData) return null; // don't display if no content
  return <Content data={homeAnnouncement} />;
};

export default React.memo(Ribbon);
