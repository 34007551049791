/**
 *
 * InsuranceContent
 *
 */

import React, { useState } from 'react';
import _classNames from 'classnames';
import update from 'immutability-helper';
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { toast } from 'react-toastify';
import _get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { hideInsuranceModal } from 'containers/Main/actions';
import {
  makeSelectClientDetails,
  makeSelectFirestoreStatus,
  makeSelectShowLanguageBanner,
} from 'containers/Main/selectors';
import { makeSelectAuth } from 'containers/Auth/selectors';
import useSiteCopySelector from 'components/useSiteCopySelector';
import { setLocalData } from 'utils/localDataStore';
import InsuranceStep from './InsuranceStep';

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: 'white',
    height: 'min-content',
    maxHeight: '90%',
    borderRadius: 5,
    overflowY: 'auto',
    position: 'absolute',
    [theme.breakpoints.up('md')]: {
      width: 800,
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      width: 995,
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: 'auto',
    },
  },
  paddedWrapper: {
    top: 36,
  },
  imageContainer: {
    width: '36%',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  contentContainer: {
    padding: '92px 44px 108px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: '52px 24px 80px',
    },
  },
}));

const InsuranceContent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const auth = useSelector(makeSelectAuth());
  const clientDetails = useSelector(makeSelectClientDetails());
  const firestoreStatus = useSelector(makeSelectFirestoreStatus());
  const showLanguageBanner = useSelector(makeSelectShowLanguageBanner());
  const [siteCopy] = useSiteCopySelector(['insurance-modal']);
  const [step, setStep] = useState(0);
  const [insurance, setInsurance] = useState('-1');
  const [error, setError] = useState(false);

  const finalSiteCopy = update(_get(siteCopy, 'pageCopy', {}), {
    $merge: _get(clientDetails, 'metadata.insuranceModal', {}),
  });

  const handleChange = e => {
    setError(false);
    setInsurance(e.target.value);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleSubmit = () => {
    const shouldSetInsurance = _get(
      finalSiteCopy,
      ['pages', step, 'displayDropdown'],
      false,
    );
    const shouldClose = step + 1 >= _get(finalSiteCopy, 'pages', []).length;
    if (shouldSetInsurance) {
      if (insurance !== '-1') {
        if (firestoreStatus && !auth.isEmpty) {
          firebase
            .updateProfile({
              insurance: [insurance],
            })
            .then(() => {
              if (shouldClose) dispatch(hideInsuranceModal());
              else setStep(step + 1);
            })
            .catch(() => {
              toast(_get(finalSiteCopy, 'errorMessage', ''), {
                type: 'error',
              });
            });
        } else {
          try {
            setLocalData('insurance', insurance);
            if (shouldClose) dispatch(hideInsuranceModal());
            else setStep(step + 1);
          } catch (e) {
            toast(_get(finalSiteCopy, 'errorMessage', ''), {
              type: 'error',
            });
          }
        }
      } else {
        setError(true);
      }
    } else if (shouldClose) dispatch(hideInsuranceModal());
    else setStep(step + 1);
  };

  return (
    <Grid
      container
      className={_classNames(classes.wrapper, {
        [classes.paddedWrapper]: showLanguageBanner,
      })}
    >
      <Hidden smDown>
        <Grid item className={classes.imageContainer}>
          <img
            src={_get(finalSiteCopy, 'image')}
            alt="Insurance modal"
            className={classes.image}
          />
        </Grid>
      </Hidden>
      <Grid item xs={12} md className={classes.contentContainer}>
        <InsuranceStep
          error={error}
          insurance={insurance}
          siteCopy={_get(finalSiteCopy, ['pages', step], {})}
          onInsuranceChange={handleChange}
          onSubmit={handleSubmit}
          onBack={handleBack}
          showBackButton={step > 0}
        />
      </Grid>
    </Grid>
  );
};

export default InsuranceContent;
